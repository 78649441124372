// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';

import { makeStyles } from '@mui/styles';

//
// Components
//
import Applications from './desk/pages/Applications';
import Settings from './desk/pages/Settings';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'auto',
    },

    content: {
        // expand content, using all available space
        flexGrow: 1,

        // enable vertical scroll on overflow
        overflowY: 'auto',
    },
}));

//
// Props
//
type Props = {
    //
    // Children
    //
    children: any[],

    //
    // Menus
    //
    menus: any,
};

//
// Desk
//
const Desk = (props: Props): any => {
    // Hooks
    const classes = useStyles();
    // End hooks

    return (
        <Grid
            container
            direction='row'
            wrap='nowrap'
            className={classes.root}
        >
            <Grid item container className={classes.content}>
                {props.children}
            </Grid>
        </Grid>    
    );
};
export default Desk;
