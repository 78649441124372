// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { makeStyles } from '@mui/styles';

//
// Components
//
import NavigationMenuHeaderItem from './header/NavigationMenuHeaderItem';
import LogoHeaderItem from './header/LogoHeaderItem';
import TenantHeaderItem from './header/TenantHeaderItem';
import UserHeaderItem from './header/UserHeaderItem';
import MainMenuHeaderItem from './header/MainMenuHeaderItem';
import HomeHeaderItem from './header/HomeHeaderItem';
import SettingsHeaderItem from './header/SettingsHeaderItem';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    gap: {
        width: theme.spacing(2.5),
    },
}));

//
// Props
//
type Props = {
    //
    // State
    //
    showNavigationMenu: boolean,

    //
    // Handler
    //
    onToggleNavigationMenu: () => void,
};

//
// Component
//
const Header = (props: Props): any => {
    const classes = useStyles();

    return (
        <AppBar position='static'>
            <Toolbar variant='dense'>
                {(props.showNavigationMenu) ? (
                    <NavigationMenuHeaderItem 
                        onToggleNavigationMenu={props.onToggleNavigationMenu}
                    />
                ) : null}

                <LogoHeaderItem />

                <TenantHeaderItem />

                <div className={classes.grow} />

                <HomeHeaderItem />
                <SettingsHeaderItem />
                <div className={classes.gap} />
                <UserHeaderItem />
            </Toolbar>
        </AppBar>
    );
};
export default Header;
