// @flow

//
// Operations
//
import logOut from './operations/logOut';

//
// Reducer
//
export { reducer } from './reducer';

//
// Duck Name
//
export const DUCK_NAME = 'session';

//
// Actions
//
export { default as Actions } from './actions';

//
// Selectors
//
export { default as Selectors } from './selectors';

export const operations = [
    logOut,
];
