// @flow

const getConfiguration = (props: any) => {
    return {
        enableToolbar: true,
        enableToolbarSearchField: false,
        enableHeader: false,
        enablePagination: false,
    };
}
export default getConfiguration;