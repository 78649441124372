// @flow

//
// React
//
import React, { type Node } from 'react';

import {
    createItemCellRenderer,
} from '../../../trix-web-components-table';

const createWarehouseStorageLocationTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: false,
        width: 100,

        renderCell: createItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            getItem: (props: any) => {
                return (props.value && props.value.name) ? 
                        props.value.name : '';
            },
        }),

        valueFormatter: (props: any) => {
            return (props.value && props.value.name) ? props.value.name : '';
        }
    }
}
export default createWarehouseStorageLocationTableColumn;