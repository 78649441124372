// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React, {
    useCallback,
} from 'react';

//
// Trix Middleware
//
import { get as httpGet } from '../../../trix-web-middleware-commons';

//
// Formatter
//
import { 
    createOmni,
    fmtOmni
} from '../../../trix-web-data-commons';

//
// Trix Data Commons
//
import { buildUrl } from '../../../trix-web-data-commons';

//
// Components
//
import {
    TextFormPaneField,
    I8nTextFormPaneField,
    ComboBoxFormPaneField,
    CheckboxFormPaneField,
    DateFormPaneField,
    type FormPaneContentProps,
} from '../../../trix-web-components-pane';

//
// API
//
export const getFindPropertyTypeByCodeValuesUrl = (params: any): string => {
    return buildUrl(
        '/api/v1/core/property/propertyTypes/byCode/{code}/values/search/byFreeText?text={text}&page={page}&size={size}',
        params
    );
};

export const getFindPersonsUrl = (params: any): string => {
    return buildUrl(
        '/api/v1/core/person/people/search/byFreeText?text={text}&page={page}&size={size}',
        params
    );
};

export const getFindCompaniesUrl = (params: any): string => {
    return buildUrl(
        '/api/v1/core/company/companies/search/byFreeText?text={text}&page={page}&size={size}',
        params
    );
};

//
// Props
//
type Props = {
    id: string;

    valueSource: string;
    valueType: string;
    code: string;
    name: string;
    description: any;
    
    mandatory: boolean;
    
    value?: any;
    error: Error;

    onChange: (data: any, meta: any) => void;

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string, 
}

//
// Component
//
const PropertyValueFormPaneField = (props: Props): any => {
    const loadPropertyValuesHandler = useCallback(
        (value: any, reject: any, resolve: any) => {
            let url = '';
            const page = 0;
            const size = 20;

            if (props.valueType === 'COMPANY') {
                url = getFindCompaniesUrl({
                    text: value,
                    page,
                    size,
                });
            } else if (props.valueType === 'PERSON') {
                url = getFindPersonsUrl({
                    text: value,
                    page,
                    size,
                });
            } else {
                url = getFindPropertyTypeByCodeValuesUrl({
                    code: props.code,
                    text: value,
                    page,
                    size,
                });
            }

            httpGet(
                // url
                url,
                // options
                null,
                // reject callback
                reject,
                // resolve callback
                data => {
                    let d: any = [];
                    if (props.valueType === 'COMPANY') {
                        d = get(data, `body._embedded.companies`, []).map(e => {
                            return {
                                code: e.code,
                                value: {
                                    valueType: props.valueType,
                                    companyValue: e,
                                }
                            };
                        });
                    } else if (props.valueType === 'PERSON') {
                        d = get(data, `body._embedded.people`, []).map(e => {
                            return {
                                code: e.code,
                                value: {
                                    valueType: props.valueType,
                                    personValue: e,
                                }
                            };
                        });
                    } else {
                        // NOTE: we need propert value code + property value (type + raw value)
                        // console.log('AAAA>>> PropertyValueFormPaneField: data:', data);
                        // d = get(data, `body._embedded.propertyTypeValues`, []).map(e => {
                        //     return e.value;
                        // });
                        d = get(data, `body._embedded.propertyTypeValues`, []);
                    }
                    resolve(d)
                }
            )
        }, [props.valueType]);
    const getPropertyValueOptionLabelHandler = useCallback(
        (option: any) => {
            if (option.code) {
                return `${option.code} / ${fmtOmni(option.value)}`;
            } else {
                return fmtOmni(option.value);
            }
            // console.log('OPTION:', option);
            // return fmtOmni(option);
        }, []);
    const getPropertyValueOptionSelectedHandler = useCallback(
        (option: any, value: any) => {
            if (!value) {
                return false;
            }

            if (option.id) {
                return option.id === value.id;
            }

            return get(option, '_links.self.href') === get(value, '_links.self.href');
        }, []);

    const changePropertyValueHandler = useCallback(
        (value: any, meta: any) => {
            console.log('CHANGE PROPERTY VALUE HANDLER: OMNI:', createOmni(props.valueType, value));
            props.onChange({ code: value, value: createOmni(props.valueType, value) }, meta);
        }, [props.valueType]);

    if (props.valueSource === 'DYNAMIC') {
        return (
            <ComboBoxFormPaneField
                key={props.id}
                freeSolo={false}
                label={`${props.code} / ${props.name}`}
                description={get(props.description, 'bg', '')}
                
                value={props.value}
                error={props.error}

                required={props.mandatory}

                onLoadData={loadPropertyValuesHandler}
                onGetOptionLabel={getPropertyValueOptionLabelHandler}
                isOptionEqualToValue={getPropertyValueOptionSelectedHandler}

                onChange={props.onChange}
                dataPath={props.dataPath}
            />
        );
    }

    if (props.valueSource === 'STATIC') {
        // PATCH:
        const v = (props.value && props.value.value) ? props.value.value : null;

        if (props.valueType === 'TEXT' ||
                props.valueType === 'INTEGER' ||
                props.valueType === 'LONG' ||
                props.valueType === 'DOUBLE'
        ) {
            return (
                <TextFormPaneField
                    key={props.id}
                    autoFocus={false}
                    label={`${props.code} / ${props.name}`}
                    description={get(props.description, 'bg', '')}
                    required={props.mandatory}

                    value={fmtOmni(v, { default: '' })}
                    error={props.error}

                    onChange={changePropertyValueHandler}
                    dataPath={props.dataPath}
                />
            );
        }

        if (props.valueType === 'I8N_TEXT') {
            return (
                <I8nTextFormPaneField
                    key={props.id}
                    autoFocus={false}
                    disabled={false}
                    label={`${props.code} / ${props.name}`}
                    description={get(props.description, 'bg', '')}
                    required={props.mandatory}
                    
                    value={fmtOmni(v, { default: '' })}
                    error={props.error}
                    
                    onChange={changePropertyValueHandler}
                    dataPath={props.dataPath}
                />
            );
        }

        if (props.valueType === 'DATE') {
            return (
                <DateFormPaneField
                    key={props.id}
                    autoFocus={false}
                    label={`${props.code} / ${props.name}`}
                    description={get(props.description, 'bg', '')}
                    required={props.mandatory}

                    value={fmtOmni(v, { default: '' })}
                    error={props.error}

                    onChange={changePropertyValueHandler}                    
                    dataPath={props.dataPath}
                />
            );
        }

        if (props.valueType === 'DOUBLE') {
            return (
                <TextFormPaneField
                    key={props.id}
                    autoFocus={false}
                    label={`${props.code} / ${props.name}`}
                    description={get(props.description, 'bg', '')}
                    required={props.mandatory}

                    value={fmtOmni(v, { default: '' })}
                    error={props.error}

                    onChange={changePropertyValueHandler}
                    dataPath={props.dataPath}
                />
            );
        }

        if (props.valueType === 'BOOLEAN') {
            return (
                <CheckboxFormPaneField
                    key={props.id}
                    autoFocus={false}
                    disabled={false}
                    label={`${props.code} / ${props.name}`}
                    description={get(props.description, 'bg', '')}
                    required={props.mandatory}

                    value={v && v.booleanValue ? true : false}
                    error={props.error}

                    onChange={changePropertyValueHandler}
                    dataPath={props.dataPath}
                />
            );
        }
    }

    console.log('Dont know how to create field for property:', props);

    return null;
};
export default PropertyValueFormPaneField;
