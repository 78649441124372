// @flow

//
// React
//
import * as React from 'react';

/**
 * Gets the string type of the component or core html (JSX) element. 
 * React Fragments will return type 'react.fragment'. Priority will be given to the prop '__TYPE'.
 *
 * @param {ReactNode} component - The component to type check
 * @returns {string} - The string representation of the type
 */
export const typeOfComponent = (component: any): string =>
    component?.props?.__TYPE ||
    component?.type?.toString().replace('Symbol(react.fragment)', 'react.fragment') ||
    undefined;

/**
 * Find first child from specified type and returns it
 * 
 * @param children 
 * @param type 
 */
export const findChildByType = (children: any, type: string): any => {
    const childrenArray = React.Children.toArray(children);
    for (let i = 0; i < childrenArray.length; i++) {
        if (typeOfComponent(childrenArray[i]) === type) {
            return childrenArray[i];
        }
    }
    return null;
}
