// @flow

//
// React
//
import React, {
    useCallback,
    useMemo,
} from 'react';

//
// Trix Middleware
//
import { 
    get as httpGet,
} from '../../../../../lib/trix-web-middleware-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';

import { makeStyles } from '@mui/styles';

//
// Trix Data
//
import {
    buildUrl,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    TextFormPaneField,
    I8nTextFormPaneField,
    RadioGroupFormPaneField,
    ComboBoxFormPaneField,
    CheckboxFormPaneField,
    type FormPaneContentProps,
} from '../../../../../lib/trix-web-components-pane';

//
// Other
//
import {
    resolvePropertyValueSource,
    resolvePropertyValueType,
} from '../../../../../lib/trix-web-components-property';

const getFindPropertyTypesUrl = (params: any) => {
    return buildUrl(
        '/api/v1/core/property/propertyTypes/search/byFreeText?text={text}&page={page}&size={size}',
        params
    );
};

const ContentForStaticSource = (props: Props) => {
    const valueTypeOptions = useMemo(() => [
        { value: 'TEXT', label: resolvePropertyValueType('TEXT') },
        {
            value: 'I8N_TEXT',
            label: resolvePropertyValueType('I8N_TEXT'),
        },
        {
            value: 'INTEGER',
            label: resolvePropertyValueType('INTEGER'),
        },
        {
            value: 'DOUBLE',
            label: resolvePropertyValueType('DOUBLE'),
        },
        { value: 'DATE', label: resolvePropertyValueType('DATE') },
        {
            value: 'BOOLEAN',
            label: resolvePropertyValueType('BOOLEAN'),
        },
    ], []);

    return (
        <React.Fragment>
            <TextFormPaneField
                autoFocus={true}
                label='Код'
                description='Вътрешен за системата код на продуктова характеристика'

                value={props.data.code}
                error={props.error}

                onChange={props.onChange}

                dataPath='code'
            />

            <TextFormPaneField
                autoFocus={false}
                label='Наименование'
                description='Наименование продуктова характеристика'

                value={props.data.name}
                error={props.error}

                onChange={props.onChange}

                dataPath='name'
            />

            <I8nTextFormPaneField
                autoFocus={false}
                disabled={false}
                label='Описание'
                description='Описание продуктова характеристика'

                value={props.data.description}
                error={props.error}

                onChange={props.onChange}

                dataPath='description'
            />

            <RadioGroupFormPaneField
                autoFocus={false}
                label='Тип'
                description='Тип на стойностите, които ще бъдат създадени за тази характеристика'

                value={props.data.valueType}
                error={props.error}

                onChange={props.onChange}

                options={valueTypeOptions}
                
                dataPath='valueType'
            />
        </React.Fragment>
    );
};

const ContentForDynamicSource = (props: Props) => {
    const loadPropertyTypesHandler = useCallback(
        (value, reject, resolve) =>
            httpGet(
                // url
                getFindPropertyTypesUrl({
                    // filter param
                    text: value,
                    // paging
                    page: 0,
                    size: 20,
                }),
                
                // options
                null,
                
                // reject callback
                reject,

                // resolve callback
                (data) =>
                    resolve(
                        (data.body && data.body._embedded && data.body._embedded.propertyTypes) ?
                                data.body._embedded.propertyTypes : []
                    )
            ), []);
    const getPropertyTypeOptionLabelHandler = useCallback(
        (option) => `${option.code} ${option.name}`, []);
    const getPropertyTypeOptionSelectedHandler = useCallback(
        (option, value) => {
            return (option.id == value.id);
        }, []);

    const changePropertyTypeHandler = useCallback(
        (value, meta) => {
            props.onChange(value, { path: 'serviceProperty' });
            props.onChange(value.id, { path: 'servicePropertyId' });
            props.onChange(value.code, { path: 'code' });
            props.onChange(value.name, { path: 'name' });
            props.onChange(value.description, { path: 'description' });
            props.onChange(value.valueType, { path: 'valueType' });
        }, []);

    return (
        <React.Fragment>
            <ComboBoxFormPaneField
                label='Характеристика услуга'
                description='Избор на предефинирана характеристика'
                
                value={props.data.serviceProperty}
                error={props.error}
                
                onLoadData={loadPropertyTypesHandler}
                onGetOptionLabel={getPropertyTypeOptionLabelHandler}
                isOptionEqualToValue={getPropertyTypeOptionSelectedHandler}

                onChange={changePropertyTypeHandler}

                dataPath=''
            />

            <TextFormPaneField
                disabled={true}
                autoFocus={true}
                label='Код'
                description='Вътрешен за системата код на характеристика'
                
                value={props.data.code}
                error={props.error}
               
                onChange={props.onChange}

                dataPath='code'
            />

            <TextFormPaneField
                disabled={true}
                autoFocus={false}
                label='Наименование'
                description='Наименование характеристика'

                value={props.data.name}
                error={props.error}

                onChange={props.onChange}

                dataPath='name'
            />

            <I8nTextFormPaneField
                disabled={true}
                autoFocus={false}
                label='Описание'
                description='Описание характеристика'

                value={props.data.description}
                error={props.error}

                onChange={props.onChange}

                dataPath='description'
            />

            <TextFormPaneField
                disabled={true}
                autoFocus={false}
                label='Тип'
                description='Тип на стойностите, които ще бъдат създадени за тази характеристика'

                value={resolvePropertyValueType(props.data.valueType)}
                error={props.error}
                
                onChange={props.onChange}

                dataPath='name'
            />
        </React.Fragment>
    );
};

const ContentForAutoSource = (props: Props) => {
    const valueTypeOptions = useMemo(() => [
        {
            value: 'INTEGER',
            label: resolvePropertyValueType('INTEGER'),
        },
    ], []);

    return (
        <React.Fragment>
            <TextFormPaneField
                autoFocus={true}
                label='Код'
                description='Вътрешен за системата код на характеристика'

                value={props.data.code}
                error={props.error}

                onChange={props.onChange}

                dataPath='code'
            />

            <TextFormPaneField
                autoFocus={false}
                label='Наименование'
                description='Наименование характеристика'

                value={props.data.name}
                error={props.error}

                onChange={props.onChange}

               dataPath='name'
            />

            <I8nTextFormPaneField
                disabled={false}
                autoFocus={false}
                label='Описание'
                description='Описание характеристика'

                value={props.data.description}
                error={props.error}

                onChange={props.onChange}

                dataPath='description'
            />

            <RadioGroupFormPaneField
                autoFocus={false}
                label='Тип'
                description='Тип на стойностите, които ще бъдат създадени за тази'
                
                value={props.data.valueType}
                error={props.error}
                
                onChange={props.onChange}

                options={valueTypeOptions}
                
                dataPath='valueType'
            />
        </React.Fragment>
    );
};

//
// Styles
//
const useStyles = makeStyles((theme) => ({
    root: {
        // minHeight: 400,
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    data: any,

    //
    // Error
    //
    error: any,

    //
    // Actions
    //
    onChange: (value: any, meta: any) => any,
};

//
// Component
//
const Content = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    const valueSourceOptions = useMemo(() => [
        {
            value: 'STATIC',
            label: resolvePropertyValueSource('STATIC'),
        },
        {
            value: 'DYNAMIC',
            label: resolvePropertyValueSource('DYNAMIC'),
        },
        {
            value: 'AUTO',
            label: resolvePropertyValueSource('AUTO'),
        },
    ], []);

    const accessTypeOptions = useMemo(() => [
        { value: 'PUBLIC', label: 'Публична' },
        { value: 'SYSTEM', label: 'Системна' },
    ], []);

    return (
        <Grid container direction='column' className={classes.root}>
            <RadioGroupFormPaneField
                autoFocus={false}
                className={classes.header}
                label='Източник стойност'
                description='Източник на стойността за тази характеристика'

                value={props.data.valueSource}
                error={props.error}
                
                options={valueSourceOptions}
                
                onChange={props.onChange}

                dataPath='valueSource'
            />

            {props.data.valueSource === 'STATIC' ? (
                <ContentForStaticSource
                    {...props}
                />
            ) : null}
            {props.data.valueSource === 'DYNAMIC' ? (
                <ContentForDynamicSource
                    {...props}
                />
            ) : null}
            {props.data.valueSource === 'AUTO' ? (
                <ContentForAutoSource
                    {...props}
                />
            ) : null}

            <RadioGroupFormPaneField
                autoFocus={false}
                label='Достъпност'
                description='Достъпност на тази характеристика'

                value={props.data.accessType}
                error={props.error}

                options={accessTypeOptions}

                onChange={props.onChange}
                
                dataPath='accessType'
            />

            <CheckboxFormPaneField
                autoFocus={false}
                disabled={false}
                label='Задължително'
                description='Задължително въвеждане на ст-т за тази характеристика в досието на услуга'

                value={!!props.data.mandatory}
                error={props.error}

                onChange={props.onChange}

                dataPath='mandatory'
            />
        </Grid>
    );
};
export default Content;
