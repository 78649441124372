// @flow

//
// React
//
import React from 'react';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../types/CellProps';

//
// Formatter
//
import { fmtQuantity } from '../../../../trix-web-data-commons';
//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//
// Helper
//
import { renderAsLink } from './tools';

//
// Component
//
const createItemSubItemCellRenderer = (cfg: any): any => (props: any): any => {
    let alignItems = 'start';
    if (cfg.align === 'right') {
        alignItems = 'end';
    }

    let item = '';
    let subItem = '';

    if (cfg.getItem) {
        item = cfg.getItem(props);
    }
    if (cfg.getSubItem) {
        subItem = cfg.getSubItem(props);
    }

    return (
        <Grid container direction='column' alignItems={alignItems}>
            <Grid>
                {renderAsLink(cfg.onClick, props.row)(item)}
            </Grid>
            {(subItem) ? 
                (
                    <Grid>
                        <Typography variant='caption' display='block'>
                            {subItem}
                        </Typography>
                    </Grid>
                ) : null
            }
        </Grid>
    );
};
export default createItemSubItemCellRenderer;

