// @flow

//
// Lodash
//
import debounce from 'lodash/debounce';

//
// React
//
import React, { 

    useCallback,
    useMemo
} from 'react';

//
// Material UI
//
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    input: {
        fontSize: '12px',
    },
}));

//
// Props
//
type Props = {
    //
    // Value
    //
    value: string,

    //
    // Handlers
    //
    onChange: (text: string) => void,
};

//
// Component
//
const SearchField = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    //
    // Debounced load data handler
    //
    const debouncedChangeHandler = debounce(value => {
        if (props.onChange) {
            props.onChange(value);
        }
    }, 500);

    const handleTextChange = useCallback((e: any) => {
        debouncedChangeHandler(e.target.value);
    }, []);

    const inputProps = useMemo(() => {
        return {
                classes: classes,
                startAdornment: (
                    <InputAdornment position='start'>
                        <SearchIcon color='disabled' />
                    </InputAdornment>
                ),
            }
        }, []);

    return (
        <TextField
            placeholder='Търси...'
            size='small'
            margin='dense'
            variant='outlined'

            defaultValue={props.value}
            onChange={handleTextChange}
            
            InputProps={inputProps}
        />
    );
};
export default SearchField;