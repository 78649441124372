// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React from 'react';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../types/CellProps';

//
// Helper
//
import { renderAsLink } from './tools';

//
// Component
//
const createBooleanCellRenderer = (cfg: CellRendererFactoryProps): any => (props: CellProps): any => {
    const booleanValue = (cfg.dataPath) ? get(props.value, cfg.dataPath, '') : props.value;

    const content = (booleanValue) ? 'Да' : 'Не';

    return (
        <div>
            {renderAsLink(cfg.onClick, props.row)(content)}
        </div>
    );
};
export default createBooleanCellRenderer;
