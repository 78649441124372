// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Formatter
//
import {
    fmtNumber,
} from '../../../trix-web-data-commons';

import {
    createNumberCellRenderer,
} from '../../../trix-web-components-table';

const createDocumentNoTableColumn = (cfg: any): any => {
    return {
        align: 'right',
        flex: false,
        width: 100,

        renderCell: createNumberCellRenderer(cfg)
    }
}
export default createDocumentNoTableColumn;