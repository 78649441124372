// @flow

//
// React
//
import * as React from 'react';

//
// Material UI
//
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

import CloseIcon from '@mui/icons-material/Close';

//
// Props
//
type Props = {
    title: string,
    onClose: ?((redirectTo?: string) => void),
};

//
// Component
//
const HalFormsHeader = (props: Props): any => {
    return (
        <Grid 
            container 
            alignItems='center'
            sx={{
                padding: theme => theme.spacing(1, 2, 1),
            }}
        >
            <Grid item>
                <Typography 
                    variant='h6' 
                    sx={{
                        color: theme => theme.palette.text.secondary,
                        fontWeight: 700,
                    }}
                >
                    {props.title}
                </Typography>
            </Grid>

            <Grid
                item 
                sx={{
                    flexGrow: 1,
                }}
            />
            
            <Grid item>
                {(props.onClose) ? (
                    <IconButton
                        edge='end'
                        size='small'
                        onClick={props.onClose}
                        color='inherit'
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </Grid>
        </Grid>
    );
};
export default HalFormsHeader;
