// @flow

//
// Lodash
//
import get from 'lodash/get'

//
// React
//
import React from 'react';

//
// Formatter
//
import { 
    fmtDate,
    fmtMoney,
    fmtQuantity,

    findAllErrorCauses,
    formatErrorCauses,
} from '../../../../../lib/trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.secondary.main,
    },
    
    propertiesTable: {
    },
    propertiesTableLastRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    propertiesText: {
        fontSize: '.625rem',
    },
    
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    title?: string,
    item: any,
};

const renderAddress = (value: any) => {
    let lines = [];

    let line = '';
    const country = get(value, 'country.name', '');
    const city = get(value, 'city.name', '');
    if (country) {
        line += country;
    }
    if (city) {
        if (line) {
            line += ', ';
        }
        line += city; 
    }
    const neighbourhood = get(value, 'neighbourhood.name', '');
    if (neighbourhood) {
        if (line) {
            line += ', ';
        }
        line += neighbourhood; 
    }
    if (line) { lines.push(line); }

    line = '';
    const street = get(value, 'street.name', '');
    const houseNumber = get(value, 'houseNumber', '');
    if (street) {
        line += street;
    }
    if (houseNumber) {
        if (line) {
            line += ' ';
        }
        line += houseNumber; 
    }
    const place = get(value, 'place.name', '');
    if (place) {
        if (line) {
            line += ', ';
        }
        line += place; 
    }
    if (line) { lines.push(line); }

    line = get(value, 'address1', '');
    if (line) { lines.push(line); }
    
    line = get(value, 'address2', '');
    if (line) { lines.push(line); }

    line = get(value, 'details', '');
    if (line) { lines.push(line); }

    return (
        <>
            {lines.map(l => (<div>{l}</div>))}
        </>
    );
}

const renderCustomer = (value: any) => {
    let details = '';
    if (value.phone) { 
        details += `phone: ${value.phone}`;
    }
    if (value.email) {
        if (details) {
            details += ', ';
        }
        details += `email: ${value.email}`;
    }
    return (
        <>
            <div>{value.code} / {value.name}</div>
            {(details) ? (<div>({details})</div>) : null}
        </>
    );
}

const renderWarehouse = (value: any) => {
    return `${value.code} / ${value.name}`;
}

const renderStock = (value: any) => {
    return (
        <>
            <div>{value.sku}</div>
            <div>{value.name.en}</div>
        </>
    );
}

const renderError = (value: any) => {
    const causes = findAllErrorCauses(value);
    if (causes.length === 0) {
        return null;
    }

    return (
        <>
            <div>{formatErrorCauses(causes)}</div>
        </>
    );
}

const renderAuditLogEntryPayload = (valueType: string, value: any) => {
    switch (valueType) {
        case 'TEXT':
            return value;
        case 'I8N_TEXT': 
            return value.bg || '';
        case 'LONG':
            return value;
        case 'INTEGER':
            return value;
        case 'DOUBLE':
            return value;
        case 'DATE':
            return fmtDate(value);
        case 'BOOLEAN':
            return value;
        case 'MONEY':
            return fmtMoney(value);
        case 'QUANTITY':
            return fmtQuantity(value);
        case 'ADDRESS':
            return renderAddress(value);
        case 'CUSTOMER':
            return renderCustomer(value);
        case 'WAREHOUSE':
            return renderWarehouse(value);
        case 'STOCK':
            return renderStock(value);
        case 'ERROR':
            return renderError(value);
    }
    return `Unknown value type ${valueType}`;
}

//
// Component
//
const AuditLogEntryPropertiesPane = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();

    // End Hooks

    return (
        <>
            {(props.title) ? 
                (
                    <Typography 
                        className={classes.title}
                        variant='caption' 
                        display='block'
                    >
                        {props.title}
                    </Typography>
                ) : null
            }

            <Table 
                className={classes.propertiesTable}
                size='small' 
            >
                <TableBody>
                    {
                        ((props.item && props.item.properties) ? props.item.properties : []).map(
                            (row, index, array) => (
                                <TableRow 
                                    key={index}
                                    className={(index === array.length - 1) ? classes.propertiesTableLastRow : null}
                                >
                                    <TableCell
                                        component='th'
                                        size='small'
                                        className={classes.propertiesText}
                                    >
                                        {/* {row.code}  */}{row.name}
                                    </TableCell>
                                    <TableCell 
                                        size='small'
                                        align='right'
                                        className={classes.propertiesText}
                                    >
                                        {renderAuditLogEntryPayload(row.valueType, row.value)}
                                    </TableCell>
                                </TableRow>
                            )
                        )
                    }
                </TableBody>
            </Table>
        </>
    );
}
export default AuditLogEntryPropertiesPane;
