// @flow

//
// React
//
import React, { useContext } from 'react';

//
// Redux
//
import { useDispatch } from 'react-redux';

//
// Reducer context
//
import ReducerContext from '../enhancers/ReducerContext';

//
// Hook
//
const useDispatchLocal = (): any => {
    // hooks
    const dispatch = useDispatch();
    const reducerKeyContext = useContext(ReducerContext);

    // retrieve reducer key
    const reducerKey = reducerKeyContext.reducerKey;

    // alter action with reducerKey meta
    return (action: any) => {
        const modifiedAction = {
            ...action,
            meta: {
                ...action.meta,
                reducerKey,
            }
        };
        dispatch(modifiedAction);
    }
}
export default useDispatchLocal;
