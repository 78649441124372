// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../trix-fp-fantasy';

//
// React
//
import React, { useMemo, useCallback, useState } from 'react';

//
// React router
//
import { useParams } from 'react-router-dom';

//
// Material UI
//
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import { makeStyles } from '@mui/styles';

//
// Context
//
import HalFormsContext, {
    createEventDispatcherContext
} from '../../context/HalFormsContext';

//
// Extenders
//
import {
    withComponentsRegistry,
} from '../../../trix-web-components-registry';

//
// Components
//
import HalFormsContainer from '../HalFormsContainer';
import HalFormsContainerRowPaper from '../halformscontainer/HalFormsContainerRowPaper';
import HalFormsModal from '../HalFormsModal';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
    row: {
    }
}));

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        actions: any,
        selectors: any,
    },

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // Callbacks
    //
    onClose: () => void,
} // End of Props

//
// Component
//
const HalFormsPageContent = (props: Props): any => {
    //
    // Hooks
    //
    const params = useParams();
    
    const classes = useStyles();

    // manage modals' (if any) state
    const [ modalState, setModalState ] = useState({
        isOpen: false,
        
        halFormsTemplate: {},
        ducks: {},

        data: {},
        container: {},
        meta: {},
    });

    const onOpenModal = useCallback(
        (halFormsTemplate: any, ducks: any, data: any, container: any, meta: any) => {
            setModalState({
                isOpen: true,

                halFormsTemplate,
                ducks,

                //
                // Data
                //
                // initial data
                data,
                // container data
                container,
                // meta data
                meta,
            })
    }, []);

    const onCloseModal = useCallback(() => {
        setModalState({
            isOpen: false,
            
            halFormsTemplate: {},
            ducks: {},

            data: {},
            container: {},
            meta: {},
        });
    }, []);

    const halFormsContext = useMemo(() => {
        // create events dispatcher context
        const eventDispatcherContext = createEventDispatcherContext();

        // create modal context
        const modalContext = {
            onOpenModal: onOpenModal,
            onCloseModal: onCloseModal,
        }

        return {
            ...eventDispatcherContext,
            ...modalContext
        }
    }, []);

    return (
        <HalFormsContext.Provider value={halFormsContext}>
            <Container 
                maxwidth='md'
            >
                <HalFormsContainer
                    key='halFormsPageContent-0'

                    HalFormsContainerRow={HalFormsContainerRowPaper}

                    __halFormsTemplate={props.__halFormsTemplate}
                    __ducks={props.__ducks}
                    __dataSourcesRef={props.__dataSourcesRef}

                    data={params}

                    // on close references page closing 
                    onClose={props.onClose}
                />
            </Container>
            <HalFormsModal
                isOpen={modalState.isOpen}

                __halFormsTemplate={modalState.halFormsTemplate}
                __ducks={modalState.ducks}
                __dataSourcesRef={props.__dataSourcesRef}

                data={modalState.data}
                container={modalState.container}
                meta={modalState.meta}

                // on close references modal closing
                onClose={onCloseModal}
            />
        </HalFormsContext.Provider>
    );
}
export default HalFormsPageContent;
