// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    TextFloatPaneField,
} from '../../../../../lib/trix-web-components-pane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    }
}));

//
// Props
//
type Props = {
    label: string,

    //
    // Data
    //
    value: any,
};

//
// Component
//
const ServiceGroupNameField = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();

    const code = (props.value) ? props.value.code : '';
    const name = (props.value && props.value.name) ? props.value.name.bg : '';

    return (
        <TextFloatPaneField
            label={props.label}
            value={`${code} ${name}`}
        />
    );
};
export default ServiceGroupNameField;
