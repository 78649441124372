// @flow

import saleLinesTableConfiguration from './components/configuration/saleLinesTableConfiguration';
import addSaleLineModalConfiguration from './components/configuration/addSaleLineModalConfiguration';
import saleSourcesTableConfiguration from './components/configuration/saleSourcesTableConfiguration';
import saleOrderSelectorFieldConfiguration from './components/configuration/saleOrderSelectorFieldConfiguration';
import saleSourceSelectorFieldConfiguration from './components/configuration/saleSourceSelectorFieldConfiguration';
import saleSourceItemSelectorFieldConfiguration from './components/configuration/saleSourceItemSelectorFieldConfiguration';

import SaleLineQuantitiesTableColumn from './components/table/SaleLineQuantitiesTableColumn';
import SaleLineDiscountTableColumn from './components/table/SaleLineDiscountTableColumn';

import TradeTimelineItem from '../../trix-web-plugin-wms-trade/web/components/timeline/TradeTimelineItem';

export const components = {
    'configurationTable:trix-web-plugin-wms-sale.manageSalePage.container.saleSourcesTable.table': saleSourcesTableConfiguration,
    'configuration:trix-web-plugin-wms-sale.manageSalePage.container.saleSourcesTable.action.onAttachSaleOrder.editor._._.saleOrder': saleOrderSelectorFieldConfiguration,

    'configuration:trix-web-plugin-wms-sale.manageSalePage.container.saleLinesTable.action.onAddItemByReservation.editor._.saleSource': saleSourceSelectorFieldConfiguration,
    'configuration:trix-web-plugin-wms-sale.manageSalePage.container.saleLinesTable.action.onAddItemByReservation.editor._.saleSourceItem': saleSourceItemSelectorFieldConfiguration,

    'tableColumn:saleLineQuantities': SaleLineQuantitiesTableColumn,
    'tableColumn:saleLineDiscount': SaleLineDiscountTableColumn,

    'configuration:trix-web-plugin-wms-sale.manageSalePage.container.saleLinesTable.table': saleLinesTableConfiguration,
    'configuration:trix-web-plugin-wms-sale.manageSalePage.container.saleLinesTable.action.onAddItem.editor.modal': addSaleLineModalConfiguration,

    'timelineItem:trix-web-plugin-wms-sale.manageSalePage.container.trade': TradeTimelineItem,
}
