// @flow

//
// React
//
import React from 'react';

import {
    createItemCellRenderer,
} from '../../../../../lib/trix-web-components-table';

//
// Component
//
const createDialogueDomainCellRenderer = (cfg: any): any => {
    return {
        align: 'left',
        flex: true,
        width: 300,

        renderCell: createItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            getItem: (props: any) => {
                return (props.value && props.value.name) ? 
                        props.value.name : '';
            },
        }),
    }
};
export default createDialogueDomainCellRenderer;
