// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    TextFloatPaneField,
} from '../../../../../lib/trix-web-components-pane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    }
}));

//
// Props
//
type Props = {
    label: string,

    //
    // Data
    //
    data: any,
};

//
// Component
//
const DialogueConnectorFloatPaneField = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();

    const name = (props.data) ? props.data.name : '';

    return (
        <TextFloatPaneField
            label={props.label}
            value={name}
        />
    );
};
export default DialogueConnectorFloatPaneField;
