// @flow

//
// React
//
import React, {
    useMemo,
    useCallback,
    type ComponentType,
    type Node,
} from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

//
// Components
//
import SearchField from './toolbar/SearchField';
import ExportField from './toolbar/ExportField';

//
// Props
//
export type DataTableToolbarButtonsProps = {
    //
    // State
    //
    // links?: any,

    //
    // Items
    //
    items: any[],

    //
    // Pagination
    //
    page: any;

    //
    // Selection
    //
    selectedItems?: any[],

    //
    // Filter
    //
    filter?: any,
}

type Props = {
    //
    // Configuration
    //
    enableToolbarSearchField?: boolean,
    enableToolbarExportField?: boolean,

    //
    // Componets
    //
    DataTableToolbarButtons?: ComponentType<DataTableToolbarButtonsProps>,
    DataTableLeftToolbarPane?: ComponentType<any>,

    //
    // Items
    //
    items: any[],

    //
    // Pagination
    //
    page: any;

    //
    // Selection
    //
    selectedItems?: any[],

    //
    // Filter
    //
    filter?: any,
    onFilterChange?: (text: string) => void,
}; // End of Props

//
// Sub components
//
const ToolbarSearchField = (props: {
    filter: any,
    onFilterChange?: (filter: any) => void,
}) => {
    // Hooks
    const handleFilterChange = useCallback((e: any) => {
        if (props.onFilterChange) {
            props.onFilterChange({
                ...props.filter,
                text: e,
            });
        }
    }, [props.onFilterChange, props.filter]);
    // End hooks

    return (
        <Grid item 
            sx={{
                marginLeft: theme => theme.spacing(2),
                marginRight: theme => theme.spacing(2)
            }}
        >
            <SearchField
                value={props.filter && props.filter.text ? props.filter.text : ''}
                onChange={handleFilterChange}
            />
        </Grid>
    );
};

//
// Component
//
const DataTableToolbar = (props: Props): any => {
    // TODO: useMemo
    const leftToolbarPane = props.DataTableLeftToolbarPane ?
        (
            <props.DataTableLeftToolbarPane
                // links={props.links}
                items={props.items}
                page={props.page}
                selectedItems={props.selectedItems}
            />
        ) : null;

    // TODO: useMemo
    const toolbarButtons = props.DataTableToolbarButtons ?
        (
            <props.DataTableToolbarButtons
                // links={props.links}
                items={props.items}
                page={props.page}
                selectedItems={props.selectedItems}
                filter={props.filter}
            />
        ) : null;

    return (
        <Grid
            container
            direction='row'
            wrap='nowrap'
            component={Toolbar}
            disableGutters={true}
        >
            {(!!props.enableToolbarSearchField) ? (
                    <ToolbarSearchField
                        filter={props.filter}
                        onFilterChange={props.onFilterChange}
                    />
                ) : null
            }
            
            {(leftToolbarPane) ? (
                <Grid item>{leftToolbarPane}</Grid>
            ) : null}

            <Grid 
                item
                sx={{
                    flexGrow: 1,
                }}
            />

            <Grid item>{toolbarButtons}</Grid>

            {(!!props.enableToolbarExportField) ? (
                <ExportField
                />
            ) : null}
        </Grid>
    );
};
export default DataTableToolbar;
