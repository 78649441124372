// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

//
// Props
//
type Props = {
    //
    // Action
    //
    onClose: () => void,

    //
    // Children
    title: string,
};

//
// Component
//
const DataDialogHeader = (props: Props): any => {
    //
    // Hooks
    //
    return (
        <Grid
            container
            direction='row'
            alignItems='center'
        >
            <Grid 
                item 
                sx={{
                    paddingLeft: theme => theme.spacing(1), 
                    flexGrow: 1
                }}
            >
                <Typography variant='h6'>{props.title}</Typography>
            </Grid>

            <Grid item>
                <IconButton 
                    onClick={props.onClose} 
                    size='large'
                    sx={{
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};
export default DataDialogHeader;
