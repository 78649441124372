// @flow

//
// Duck
//
import { Actions } from './actions';
import reducer, {
    Selectors,
} from './reducer';

//
// Duck Name
//
export const DUCK_NAME = 'app';

export default reducer;
export {
    Actions,
    Selectors,
};


