// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React from 'react';

//
// Compnents
//
import SelectorItemsRendererPane from './SelectorItemsRendererPane';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../../../trix-web-components-table/components/table/types/CellProps';

//
// Component
//
const createSelectorCellRenderer = (cfg: CellRendererFactoryProps): any => (props: CellProps): any => {
    return (
        <div>
            <SelectorItemsRendererPane
                selector={props.value}
            />
        </div>
    );
};
export default createSelectorCellRenderer;
