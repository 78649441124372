// @flow

import saleInvoiceSourceItemSelectorFieldConfiguration from './components/configuration/saleInvoiceSourceItemSelectorFieldConfiguration';
import saleInvoiceItemSelectorFieldConfiguration from './components/configuration/saleInvoiceItemSelectorFieldConfiguration';

import TradeTimelineItem from '../../trix-web-plugin-wms-trade/web/components/timeline/TradeTimelineItem';

export const components = {
    'configuration:trix-web-plugin-fms-saleinvoice.manageSaleInvoicePage.container.saleInvoiceLinesTable.action.onAddItemFromSource.editor._.saleInvoiceSourceItem': saleInvoiceSourceItemSelectorFieldConfiguration,
    'timelineItem:trix-web-plugin-fms-saleinvoice.manageSaleInvoicePage.container.trade': TradeTimelineItem,

    'configuration:trix-web-plugin-fms-saleinvoice.manageCreditNotePage.container.creditNoteLinesTable.action.onAddItem.editor._.saleInvoiceItem': saleInvoiceItemSelectorFieldConfiguration,
    'configuration:trix-web-plugin-fms-saleinvoice.manageCreditNotePage.container.creditNoteLinesTable.action.onAddItemFromSaleReturn.editor._.saleInvoiceSourceItem': saleInvoiceSourceItemSelectorFieldConfiguration,
    'timelineItem:trix-web-plugin-fms-saleinvoice.manageCreditNotePage.container.trade': TradeTimelineItem,
}
