// @flow

import {
    createChatParticipantTableColumn,
} from '../../../lib/trix-web-components-domain-comm';

export const components = {
    'tableColumn:chatParticipant': createChatParticipantTableColumn,
}

