// @flow

//
// Lodash
//
import fp from 'lodash/fp';

//
// React
//
import React from 'react';

//
// Redux
//
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

//
// Tenant
//
import {
    withNavigator,
} from '../../../lib/trix-web-components-tenant';

//
// Connectors
//
import {
    connectAction,
} from '../../../lib/redux-fantasy-reducers';

// Actions
import ReducerActions from '../../../lib/redux-fantasy-reducers/actions/Actions';

export default fp.compose(
    withNavigator(),

    connect(
        // map state to props
        (store: any, props: any) => {
            return {
                currentPageInstanceId: (store['trix-web-shell-classic'] != null) ? 
                        store['trix-web-shell-classic'].app.currentPageInstanceId : '',
                pages: (store['trix-web-shell-classic'] != null) ? 
                        store['trix-web-shell-classic'].app.pages : [],
            };
        },

        // bind action creators
        (dispatch: any, props: any) => {
            return bindActionCreators(
                {
                    onClosePage: (instanceId?: string) => {
                        return (dispatch, getState) => {
                            const store = getState();

                            let instanceIdToClose = '';
                            if (instanceId) {
                                instanceIdToClose = instanceId;
                            } else {
                                instanceIdToClose = (store['trix-web-shell-classic'] != null) ? 
                                    store['trix-web-shell-classic'].app.currentPageInstanceId : '';
                            }

                            const pages = (store['trix-web-shell-classic'] != null) ? 
                                store['trix-web-shell-classic'].app.pages : [];
                            let navigateToPath = '';
                            let reducerKey = '';
                            for (let i=0; i<pages.length; i++) {
                                if (pages[i].instanceId === instanceIdToClose) {
                                    reducerKey = pages[i].reducerKey;
                                    break;
                                }
                                navigateToPath = pages[i].url;
                            }
                            if (!reducerKey) {
                                console.error('Could not determine reducer key for page, that needs to be closed.');
                                return;
                            }

                            // navigate away
                            if (navigateToPath) {
                                // navigate away, using absolute path
                                props.navigateToAbsolutePath(navigateToPath);
                            } else {
                                // navigate away, using in-tenant path
                                props.navigateTo('/');
                            }

                            // destroy page's store reducer instance
                            dispatch(
                                connectAction (reducerKey) (ReducerActions.destroy) ({
                                    instanceId: instanceIdToClose
                                })
                            );
                        }
                    }
                },
                dispatch
            );
        }
    )
);