// @flow

//
// React
//
import React, { useContext } from 'react';

//
// Redux
//
import { useSelector } from 'react-redux';

//
// Reducer context
//
import ReducerContext from '../enhancers/ReducerContext';
import { localState } from '../connectors/connectSelector';

//
// Hook
//
const useLocalSelector = (selector: Function, equalityFn?: Function): any => {
    // hooks
    const reducerKeyContext = useContext(ReducerContext);

    // retrieve reducer key
    const reducerKey = reducerKeyContext.reducerKey;

    return useSelector (
        store => selector(localState(reducerKey)(store)),
        equalityFn
    );
};
export default useLocalSelector;
