// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

type ConditionElement = {
    field: string,
    op: string,
    value: any
}

type ConditionExpression = {
    elements: ConditionElement[],
}

export const evaluateCondition = (condition: ConditionExpression, context: {
    container: any,
    data: any,
}): boolean => {
    if (!condition || !condition.elements) {
        return true;
    }

    for (let i=0; i<condition.elements.length; i++) {
        const field = condition.elements[i].field;
        const op = condition.elements[i].op;
        const conditionValue = condition.elements[i].value;

        // resolve field value
        let fieldValue = S.maybeToNullable (S.gets (S.is ($.String)) (field.split('.')) (context));

        // evaluate expression
        if (op.toUpperCase() === 'EQ') {
            if (!(fieldValue === conditionValue)) {
                return false;
            }
        }
        if (op.toUpperCase() === 'GT') {
            if (!(fieldValue > conditionValue)) {
                return false;
            }
        }
        if (op.toUpperCase() === 'GE') {
            if (!(fieldValue >= conditionValue)) {
                return false;
            }
        }
        if (op.toUpperCase() === 'LT') {
            if (!(fieldValue < conditionValue)) {
                return false;
            }
        }
        if (op.toUpperCase() === 'LE') {
            if (!(fieldValue <= conditionValue)) {
                return false;
            }
        }
    }

    return true;
}