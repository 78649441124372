// @flow

//
// Types
//
import { type Error } from '../../../../../trix-web-data-commons';

//
// Action types
//
export const ActionsTypes = {
    SUCCESS_NOTIFICATION_OPENED: `@@app/notification/SUCCESS_NOTIFICATION_OPENED`,
    SUCCESS_NOTIFICATION_CLOSED: `@@app/notification/SUCCESS_NOTIFICATION_CLOSED`,

    ERROR_NOTIFICATION_OPENED: `@@app/notification/ERROR_NOTIFICATION_OPENED`,
    ERROR_NOTIFICATION_CLOSED: `@@app/notification/ERROR_NOTIFICATION_CLOSED`,
}

//
// Action creators
//
export const showSuccessNotification = (data: { message: string }): any => {
    return {
        type: ActionsTypes.SUCCESS_NOTIFICATION_OPENED,
        payload: {
            message: data.message,
        },
    }
};
export const closeSuccessNotification = (): any => {
    return {
        type: ActionsTypes.SUCCESS_NOTIFICATION_CLOSED,
    }
};

export const showErrorNotification = (data: { error: Error }): any => {
    return {
        type: ActionsTypes.ERROR_NOTIFICATION_OPENED,
        payload: {
            error: data.error,
        },
    }
};
export const closeErrorNotification = (): any => {
    return {
        type: ActionsTypes.ERROR_NOTIFICATION_CLOSED,
    }
};
