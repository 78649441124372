// @flow

//
// React
//
import React from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
    useDispatchLocal,
} from '../../../../redux-fantasy-reducers';

//
// Material UI
//
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CachedIcon from '@mui/icons-material/Cached';

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Data Source Extender props
    //
    loadData: (params: any) => void,

    //
    // Data
    //
    page: any,
    filter: any,
};

//
// Component
//
const HalFormsTableToolbarIconButtonReload = (props: Props): any => {
    //
    // Hooks
    //
    const dispatchLocal = useDispatchLocal();
    // End hooks

    return (
        <Tooltip title='Презареди'>
            <IconButton
                onClick={() => {
                    props.loadData({
                        page: props.page.number || 0,
                        size: props.page.size || 10,
                        ...props.filter
                    })
                }}
                size='large'
            >
                <CachedIcon fontSize='small' />
            </IconButton>
        </Tooltip>
    );
}
export default HalFormsTableToolbarIconButtonReload;