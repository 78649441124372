// @flow

//
// React
//
import React from 'react';

//
// Redux
//
import { useDispatch, useSelector } from 'react-redux';

//
// Ducks (Note: not exactly ducks. Need to be wraped up into duck and then used here)
//
import {
    closeSuccessNotification,
    createAppNotificationsSelectors,
} from '../../../../../../../lib/trix-web-data-reducers';

//
// Ducks
//
import ReducerActions from '../../../../../../../lib/redux-fantasy-reducers/actions/Actions';
import { appSelector } from '../../../../../state/ducks';
import { Selectors } from '../../../../../state/ducks/app/reducer';

//
// Material UI
//
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//
// Props
//
type Props = {};

//
// Selectors (TODO: export into shell ducks)
//
const appNotificationsSelectors = createAppNotificationsSelectors(
    state => state['trix-web-shell-open'].notifications
);

//
// Component
//
const SuccessNotificationSnackbar = (props: Props): any => {
    //
    // Hooks
    //
    const dispatch = useDispatch();

    const isOpen = useSelector(
        appNotificationsSelectors.success.isOpen
    );
    const message = useSelector(
        appNotificationsSelectors.success.getMessage
    );

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={isOpen}
            autoHideDuration={20000}
            onClose={(event: any, reason: string) => {
                if (reason === 'timeout') {
                    dispatch(closeSuccessNotification());
                }
            }}
        >
            <Alert
                onClose={() => dispatch(closeSuccessNotification())}
                severity='success'
            >
                {message}
            </Alert>
        </Snackbar>
    );
};
export default SuccessNotificationSnackbar;
