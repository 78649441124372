// @flow

//
// Sanctuary
//
import S from '../../trix-fp-fantasy';

/**
 * Connect @param {actionCreator} to a reducer by @param {reducerKey}.
 * Curried function, which wraps @param {actionCreator} and alters
 * returned action, adding meta: { reducerKey: @param {reducerKey} }.
 *
 * @param {string} key reducer key
 * @param {string} actionCreator to be connected
 * @return {object} wrapped actionCreator
 */
// TODO: think about memoizing them in cache with size like 10, 
// to avoid poluting memory with a lot of unnecessary functions..
export const connectAction = (reducerKey: string): ((actionCreator: any) => any) => (actionCreator: (some: any) => any) => {
    return (...args) => {
        const action = actionCreator.apply(this, args);
        return {
            ...action,
            meta: {
                ...action.meta,
                reducerKey,
            }
        };
    }
}
export default connectAction;