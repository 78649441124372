// @flow

//
// React
//
import React, { type Node } from 'react';

import {
    createItemSubItemCellRenderer,
} from '../../../trix-web-components-table';

const createWarehouseTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: false,
        width: 100,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            getItem: (props: any) => {
                return (props.value && props.value.code) ? 
                        props.value.code : '';
            },

            getSubItem: (props: any) => {
                if (props.value && props.value.name) {
                    return props.value.name;
                } else {
                    return (<i>Not defined</i>);
                }
            }
        }),

        valueFormatter: (props: any) => {
            return (props.value && props.value.name) ? props.value.name : '';
        }
    }
}
export default createWarehouseTableColumn;