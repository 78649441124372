// @flow

//
// React
//
import React from 'react';

//
// Type
//
import { type CellRendererProps } from '../../../../../lib/trix-web-components-table';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        // take width of the content
        width: '1px',
        whiteSpace: 'nowrap',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    // listItemSecondary: {
    //     fontSize: '0.8rem',
    // },

    sku: {
        color: theme.palette.text.secondary,
    },

    extIcon: {
        marginLeft: theme.spacing(1),
    },
}));

//
// Props
//
type Props = CellRendererProps & {
    //
    // Callbacks
    //
    onClick?: () => void,

    //
    // Data
    //
    value: any,
};

//
// Component
//
const PriceListCellRenderer = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End Hooks

    const priceListName = (props.value && props.value.name) ? props.value.name : 'N/A';

    return (
        <TableCell 
            padding="normal" 
            className={classes.root}
        >
            {priceListName}
        </TableCell>
    );
};
export default PriceListCellRenderer;
