// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { makeStyles } from '@mui/styles';

//
// Trix Data
//
//
// Trix Data
//
import {
    LANGUAGES,

    type Error,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../trix-web-data-commons';

//
// Component
//
import PlaceholderForFormPaneField from './PlaceholderForFormPaneField';

import { type FormPaneFieldMeta } from './formPaneTypes';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
    textField: {
        marginBottom: theme.spacing(2),
    }
}));

//
// Props
//
type Props = {
    //
    // State
    //
    autoFocus: boolean,
    disabled: boolean,
    label: string,
    description: string,
    required?: boolean,

    //
    // Action handlers
    //
    onChange: (value: any, meta: FormPaneFieldMeta) => void,

    //
    // Data
    //
    value: {},
    error: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const I8nTextFormPaneField = (props: Props) => {
    const classes = useStyles();

    const hasError = (props.dataPath) ? hasErrorCausesForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : false;
    const helperText = (props.dataPath) ? formatErrorsForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : '';

    return (
        <PlaceholderForFormPaneField>
            {
                LANGUAGES.map((l: string) => 
                    (
                        <TextField
                            key={l}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>{l}</InputAdornment>,
                            }}
                            className={classes.textField}
                            disabled={props.disabled}
                            size='small'
                            fullWidth={true}

                            variant='filled'

                            label={`${props.label}${(props.required) ? ' *' : ''}`}
                            description={props.description}

                            value={get(props.value, l, '') || ''}
                            onChange={event => props.onChange({
                                ...props.value,
                                [l]: event.target.value,
                            }, {
                                path: props.dataPath
                            })}
                            error={hasError}
                            helperText={helperText}
                            autoFocus={props.autoFocus}
                        />
                    )
                )
            }
        </PlaceholderForFormPaneField>
    );
};

//
// Use memo to create new component, which is sensible on just a several 
// important properties whose change is going to trigger re-render.
// This pattern is important performance optimization. As a side effect
// all other properties should be initialized on create and will not be 
// possible to be changed after component is created 
//
export default React.memo<Props>(I8nTextFormPaneField,
    (prevProps: any, nextProps: any) => {
        return prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
		    prevProps.disabled === nextProps.disabled && 
            prevProps.autoFocus === nextProps.autoFocus && 
            prevProps.onChange === nextProps.onChange && 
            prevProps.onKeyDown === nextProps.onKeyDown;
    }
);