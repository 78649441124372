// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { makeStyles } from '@mui/styles';

//
// Components
//
import TopbarLogoItem from './topbar/TopbarLogoItem';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    gap: {
        width: theme.spacing(2.5),
    },
}));

//
// Props
//
type Props = {};

//
// Component
//
const Topbar = (props: Props): any => {
    const classes = useStyles();

    return (
        <AppBar position='static'>
            <Toolbar variant='dense'>
                <TopbarLogoItem />

                <div className={classes.grow} />
            </Toolbar>
        </AppBar>
    );
};
export default Topbar;
