// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// Helpers 
//
export function buildUrl(urlTemplate: string, params: any): string {
    if (!urlTemplate) {
        console.error('Invalid URL template: [' + urlTemplate + ']');
        return '';
    }

    const paramsNames = [...urlTemplate.matchAll(/\{(.*?)\}/g)];
    let url = urlTemplate;
    for (let i=0; i<paramsNames.length; i++) {
        const paramName = paramsNames[i][1];
        var paramValue = get(params, paramName);
        if (paramValue == null) {
            paramValue = ''; 
        }
        url = url.replace(`{${paramName}}`, paramValue);
    }
    return url;
}

export function buildJsonBody(bodyTemplate: string, params: any, defaultParmsValues: any): any {
    if (!bodyTemplate) {
        console.error('Invalid body template: [' + bodyTemplate + ']');
        return '';
    }

    const paramsNames = [...bodyTemplate.matchAll(/\{(\w+)\}/g)];
    for (let i=0; i<paramsNames.length; i++) {
        const paramName = paramsNames[i][1];
        var paramValue = get(params, paramName);
        if (paramValue == null) {
            if (defaultParmsValues && defaultParmsValues[paramName]) {
                paramValue = defaultParmsValues[paramName];
            } else {
                paramValue = '';
            } 
        } else {
            if (typeof paramValue === 'object') {
                paramValue = JSON.stringify(paramValue);
            }
        }
        bodyTemplate = bodyTemplate.replace(`{${paramName}}`, paramValue);
    }
    return JSON.parse(bodyTemplate);
}

