// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

//
// Components
//
import AppMenuItem from './AppMenuItem';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        padding: '16px',
    },
}));

//
// Properties
//
export type Props = {
    menus: any,
};

//
// Components
//
const AppMenu = (props: Props): any => {
    const classes = useStyles();

    // PATCH: temp and needs to be fixed:
    const menuSectionTitles = {
        '200': 'Контрагенти',
        '300': 'Номенклатура',
        '350': 'Плащане / настройки',
        '500': 'Склад / наличност',
        '510': 'Склад / документи',
        '520': 'Склад / настройки',
        '530': 'Разходи',
        '540': 'Фактури',
        '600': 'Договори',
        '700': 'Бизнес процес',
        '800': 'Диалог',
        '900': 'Репорти',

        '11000': 'Склад',
    };

    return(
        <Grid 
            container
        >
            {(Object.keys(props.menus) || []).map((menuSection, i) => (
                <React.Fragment key={i}>
                    <Grid>
                        <Typography variant='h6'>
                            {menuSectionTitles[menuSection]}
                        </Typography>
                    </Grid>
                    <Grid
                        container 
                        direction='row' 
                        wrap='wrap'
                        style={{
                            alignContent: 'flex-start',
                            gap: '8px',
                            paddingBottom: '16px'
                        }}
                    >
                        {props.menus[menuSection].map((menuItem, j) => (
                            <AppMenuItem
                                key={j}
                                menuItem={menuItem}
                            />
                        ))}
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
}
export default AppMenu;