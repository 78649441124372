// @flow

//
// React
//
import React, { type Node, useState, useMemo } from 'react';

//
// Material UI
//
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { makeStyles } from '@mui/styles';

//
// Types
//
import { type FloatPaneContentProps } from './type/FloatPaneContentProps';
import { type FloatPaneMenuProps } from './type/FloatPaneMenuProps';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },

    title: {
        fontWeight: 200,
        lineHeight: 2,
    },

    progress: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
    },

    content: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(8),
    },

    menuButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },

    menuIcon: {
        fontSize: '1.2rem',
    },
}));

//
// Props
//
type Props = {
    //
    // Components factories
    //
    Content: (props: FloatPaneContentProps) => Node,
    Menu?: (props: FloatPaneMenuProps) => Node,

    //
    // Visual
    //
    className?: any,

    //
    // State
    //
    isLoading: boolean,
    inProgress: boolean,

    //
    // Data
    //
    title?: string,
    container: any,
    data: any,
    error?: Error,
}; // End of Props

//
// Component
//
const FloatPane = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const menuButtons = useMemo(() => {
        if (!props.Menu) {
            return null;
        }

        return (
            <div className={classes.menuButton}>
                <IconButton onClick={handleMenuOpen} size="large">
                    <MoreVertIcon 
                        fontSize='small' 
                        className={classes.menuIcon}
                    />
                </IconButton>
                <props.Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onCloseMenu={handleMenuClose}
                    container={props.container}
                    data={props.data}
                />
            </div>
        );
    }, [menuAnchorEl]);

    const content = useMemo(() => {
        if (!props.Content) {
            return null;
        }

        return (
            <props.Content
                data={props.data}
            />
        );
    }, [props.data]);

    return (
        <Grid container direction='column' className={classes.root}>
            <div className={classes.progress}>
                {props.inProgress ? (
                    <LinearProgress variant='indeterminate' />
                ) : null}
            </div>

            <Grid
                item
                container
                direction='column'
                className={classes.content}
            >
                {props.isLoading ? (
                    <Grid item style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid item container spacing={2}>
                        {content}          
                    </Grid>
                )}
            </Grid>
            {menuButtons}
        </Grid>
    );
};
export default FloatPane;
