// @flow

//
// React
//
import React from 'react';

//
// Trix Data
//
import {
    type Money,
    type Error,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../trix-web-data-commons';


//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';

import { makeStyles } from '@mui/styles';

//
// Components
//
import PlaceholderForFormPaneField from './PlaceholderForFormPaneField';

import { type FormPaneFieldMeta } from './formPaneTypes';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {},

    textFieldCell: {
        width: '80px',
    }, 

    selectRoot: {
        marginRight: theme.spacing(4),
        marginLeft: theme.spacing(1),
    },
}));

//
// Props
//
type Props = {
    //
    // State
    //
    autoFocus: boolean,
    required?: boolean,
    label: string,
    description: string,
    
    //
    // Action handlers
    //
    onChange: (value: Money, meta: FormPaneFieldMeta) => void,

    //
    // Data
    //
    value: Money,
    error: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const MoneyFormPaneField = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();

    const amount = (props.value) ? props.value.amount : 0.0;
    const currency = (props.value) ? props.value.currency : 'BGN';

    const hasError = (props.dataPath) ? hasErrorCausesForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : false;
    const helperText = (props.dataPath) ? formatErrorsForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : '';

    return (
        <PlaceholderForFormPaneField>
            <Grid container wrap='nowrap' direction='row' spacing={1}>
                <Grid item className={classes.textFieldCell}>
                    <TextField
                        size='small'
                        fullWidth={true}

                        label={`${props.label}${(props.required) ? ' *' : ''}`}
                        description={props.description}

                        value={amount}
                        onChange={event =>
                            props.onChange({
                                amount: event.target.value,
                                currency: currency,
                            }, {
                                path: props.dataPath
                            })
                        }
                        error={hasError}
                        helperText={helperText}
                        autoFocus={props.autoFocus}
                    />
                </Grid>
                <Grid item>
                    <Select
                        value={currency}
                        onChange={event =>
                            props.onChange({
                                amount: amount,
                                currency: event.target.value,
                            }, {
                                path: props.dataPath
                            })
                        }
                        input={<InputBase className={classes.selectRoot}/>}
                    >
                        <MenuItem value='EUR'>EUR</MenuItem>
                        <MenuItem value='BGN'>BGN</MenuItem>
                        <MenuItem value='USD'>USD</MenuItem>
                        <MenuItem value='GBP'>GBP</MenuItem>
                        <MenuItem value='RON'>RON</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </PlaceholderForFormPaneField>
    );
};

//
// Use memo to create new component, which is sensible on just a several 
// important properties whose change is going to trigger re-render.
// This pattern is important performance optimization. As a side effect
// all other properties should be initialized on create and will not be 
// possible to be changed after component is created 
//
export default React.memo<Props>(MoneyFormPaneField,
    (prevProps: any, nextProps: any) => {
        return prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
		    prevProps.disabled === nextProps.disabled &&
            prevProps.autoFocus === nextProps.autoFocus && 
            prevProps.onChange === nextProps.onChange;
    }
);