// @flow

//
// API
//
import {
    type Link,
} from './api/Link';

import {
    type Error,
    type Cause,
    type Reason,
    type LocationType,
} from './api/Error';

import {
    type Page,
    type ListNavigationLinks,
} from './api/Page';

import {
    type Money,
} from './domain/Money';
import {
    type Quantity,
} from './domain/Quantity';

import {
    type ActionState,
} from './state/ActionState';

// Find request
// import {
//     type FindRequestFilterExpression,
//     type FindRequestFilter,
//     type FindRequestSortBy,
//     type FindRequest,
// } from './api/request/FindRequest';

export type { Link };

export type { Error };
export type { Cause };
export type { Reason };
export type { LocationType };

export {
    emptyError,
    toError,
    concatErrors,

    serverError,
    networkError,
    clientError,
    requiredFieldError,
    invalidFieldValueError,

    isError,
    isOK,
    hasErrorCausesForLocation,
    hasErrorCausesForParameter,
    findAllErrorCauses,
    findErrorCausesByReason,
    findErrorCausesByLocation,
    formatError,
    formatErrorsForLocation,
    formatErrorsForParameter,
    formatErrorCauses
} from './api/Error';

export type { Page };
export type { ListNavigationLinks };

// export type { FindRequestFilterExpression };
// export type { FindRequestFilter };
// export type { FindRequestSortBy };
// export type { FindRequest };

// export type { PostRequest } from './api/request/PostRequest';

export type { HttpRequest } from './api/request/HttpRequest';

export {
    buildUrl,
    buildJsonBody,
} from './api/request/utils';

//
// I8n
//
export const LANGUAGES = [ 'bg', 'en', 'fr', 'de', 'el', 'it', 'ro', 'es' ];

//
// Domain
//

export type { Money };
export type { Quantity };

export {
    fmtDate,
    fmtTime,
} from './formatter/fmtDate';

export {
    default as fmtMoney,
} from './formatter/fmtMoney';

export {
    fmtNumber,
    fmtDocumentNumber,
} from './formatter/fmtNumber';

export {
    default as fmtPercent,
} from './formatter/fmtPercent';

export {
    default as fmtQuantity,
} from './formatter/fmtQuantity';

export {
    createOmni,
    default as fmtOmni,
} from './formatter/fmtOmni';

//
// Redux
//
export type { ActionState };

//
// Entity Id
//
export const getEntityIdFromSelf = (selfHref: any): string => {
    if (!selfHref) {
        return '';
    }
    const tokens = selfHref.split('/');
    return tokens[tokens.length - 1];
};
