// @flow

//
// React
//
import React from 'react';

//
// Formatter
//
import { 
    fmtPercent,
    fmtMoney,
} from '../../../../../lib/trix-web-data-commons';

import {
    createItemSubItemCellRenderer,
} from '../../../../../lib/trix-web-components-table';

const createSaleLineDiscountTableColumn = (cfg: any): any => {
    return {
        align: 'right',
        flex: false,
        width: 120,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            align: 'right',

            getItem: (props: any) => {
                return fmtPercent((props.row && props.row.discountPercent) ? 
                        props.row.discountPercent : undefined);
            },

            getSubItem: (props: any) => {
                return fmtMoney((props.row && props.row.unitPrice) ? 
                        props.row.unitPrice : undefined);
            }
        })
    }
}
export default createSaleLineDiscountTableColumn;
