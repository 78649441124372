// @flow

//
// React
//
import React from 'react';

//
// Formatter
//
import { 
    fmtPercent,
    fmtMoney,
} from '../../../trix-web-data-commons';

import {
    createItemSubItemCellRenderer,
} from '../../../trix-web-components-table';

const createEntityPropertyTypeTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: true,
        width: 300,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            align: 'left',

            getItem: (props: any) => {
                return `${props.row.code} / ${props.row.name}`;
            },

            getSubItem: (props: any) => {
                return (props.row.description) ? props.row.description.bg || props.row.description.en : '';
            }
        })
    }
}
export default createEntityPropertyTypeTableColumn;
