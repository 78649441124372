// @flow

//
// Lodash
//
import fp from 'lodash/fp';

//
// Duck
//
import { type State } from './reducer';

//
// Selectors
//
const getError = (state: State) => state.error;
const isInProgress = (state: State) => state.inProgress;
const getExecutions = (state: State) => state.executions;

export const createOperationSelectors = (localState: (state: any) => State): any => {
    return {
        getError: fp.compose(getError, localState),
        isInProgress: fp.compose(isInProgress, localState),
        getExecutions: fp.compose(getExecutions, localState),
    };
};
