// @flow

const getConfiguration = (props: any) => {
    return {
        enableToolbar: true,
        enableToolbarSearchField: false,
        enableHeader: false,
        enableItemSelection: true,
        enablePagination: false,
    }
}
export default getConfiguration;