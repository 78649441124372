// @flow

//
// Lodash
//
import assign from 'lodash/assign';
import get from 'lodash/get';
import omit from 'lodash/omit';
import merge from 'lodash/merge';

//
// Types
//
import {
    type ActionState,
} from '../../../../trix-web-data-commons';

//
// State
//
export type State = {
    configuration: {
        columns: any[],
    },
    page: {
        number: number,
        size: number,
    },
    filter: any,
    sortBy: any,
    selectedItems: Object,
};

//
// Configuration
//
type Configuration = {
    // ActionsTypes: {
    //     TABLE_INITIALIZED: string;
    //     COLUMN_STATE_CHANGED: string,
    //     FILTER_CHANGED: string,
    //     ROWS_PER_PAGE_CHANGED: string,
    // },
    reduce: (state: State, action: any) => State,
};

//
// Reducer factory
//
export const createComponentTableReducer = (cfg: Configuration): any => (
    state: State = {
        configuration: {
            columns: [],
        },
        page: {
            number: 0,
            size: 10,
        },
        filter: {},
        sortBy: {
            property: 'test',
            direction: 'asc',
        },

        selectedItems: {},
    },
    action: any
): State => {
    return cfg.reduce(state, action);
    // if (cfg.reduce) {
    //     state = cfg.reduce(state, action);
    // }

    // if (!cfg.ActionsTypes) {
    //     return state;
    // }

    // switch (action.type) {
    //     case cfg.ActionsTypes.TABLE_INITIALIZED:
    //         return reduceTableInitialized(state, action);

    //     case cfg.ActionsTypes.COLUMN_STATE_CHANGED:
    //         return reduceColumnStateChanged(state, action);

    //     case cfg.ActionsTypes.FILTER_CHANGED:
    //         return reduceFilterChanged(state, action);

    //     case cfg.ActionsTypes.ROWS_PER_PAGE_CHANGED:
    //         return reduceRowsPerPageChanged(state, action);

    //     default:
    //         return state;
    // }
};

//
// Composite reducers
//
export const reduceComponentTableInitialized = (state: State, action: any): any => {
    return {
        ...state,
        configuration: merge({}, state.configuration, {
            columns: action.payload.columns,
        }),
    };
};

export const reduceComponentTableFilterChanged = (state: State, action: any): any => {
    return {
        ...state,
        filter: action.payload
        // filter: {
        //     ...state.filter,
        //     [action.payload.property]: action.payload.value,
        // }
        // filter: [
        //     ...state.filter.filter(
        //         e => e.property !== action.payload.property
        //     ),
        //     {
        //         property: action.payload.property,
        //         value: action.payload.value,
        //     },
        // ],
    };
};

export const reduceComponentTablePageNumberChanged = (state: State, action: any): any => {
    return {
        ...state,
        page: {
            ...state.page,
            number: action.payload.pageNumber
        }
    };
};

export const reduceComponentTableRowsPerPageChanged = (state: State, action: any): any => {
    return {
        ...state,
        page: {
            ...state.page,
            size: action.payload.rowsPerPage
        }
    };
};

export const reduceComponentTableColumnStateChanged = (state: State, action: any): any => {
    return {
        ...state,
        configuration: merge({}, state.configuration, {
            columns: state.configuration.columns.map(c => {
                if (c.id === action.payload.id) {
                    c.enabled = action.payload.enabled;
                }
                return c;
            })
        }),
    };
};

export const reduceComponentTableSelectedItemsChanged = (state: any, action: any): any => {
    return {
        ...state,
        selectedItems: action.items,
    };
};

export const reduceComponentTableItemsSelectionCleared = (): any => (
    state: any,
    action: any
) => {
    return {
        ...state,
        selectedItems: {},
    };
};
