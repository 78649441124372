// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React, {
    useMemo,
    useCallback,
    useState,
    type Node,
    type ComponentType,
} from 'react';

//
// Types
//
import { type Page } from '../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import {
    DataGrid,
    GridActionsCellItem,
} from '@mui/x-data-grid';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

import LinearProgress from '@mui/material/LinearProgress';

import { makeStyles } from '@mui/styles';

// import clsx from 'clsx';

//
// Components
//
import DataTableToolbar, { type DataTableToolbarButtonsProps } from './DataTableToolbar';

import { type Column } from './types/Column';
import { type Action } from './types/Action';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    progress: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
}));

//
// Props
//
type DataTableBodyProps = {
    //
    // Configuration
    //
    enableItemSelection?: boolean,
    enableRowExtension?: boolean,
    onIsRowSelectionEnabled?: (item: any) => boolean,

    //
    // Components factories
    //
    DataTableRowExtension?: ComponentType<any>,
    onCreateCellRenderer?: (column: Column, props: any) => Node,

    //
    // State
    //
    columns: Column[],

    //
    // Data
    //
    page: Page,
    items: any[],

    //
    // Selection
    //
    selectedItems?: Object,
    onSelectItems?: (ids: string[]) => void,
    onDeselectItems?: (ids: string[]) => void,

    //
    // Handlers
    //
    onRowClick?: (item: any) => void
}

type Props = {
    //
    // Configuration
    //
    columns: Column[],
    enableToolbar?: boolean,
    enableToolbarSearchField?: boolean,
    enableToolbarExportField?: boolean,
    enableHeader?: boolean,
    enableItemSelection?: boolean,
    // enableRowExtension?: boolean,
    enablePagination?: boolean,

    getRowId?: (row: any) => string,

    // onIsRowSelectionEnabled?: (item: any) => boolean,

    //
    // Components factories
    //
    DataTableFilter?: ComponentType<any>,
    DataTableToolbarButtons?: ComponentType<DataTableToolbarButtonsProps>,
    DataTableLeftToolbarPane?: ComponentType<any>,
    // DataTableRowExtension?: ComponentType<any>,

    // onCreateCellRenderer?: (column: Column, props: any) => Node,
    
    //
    // Visual
    //
    sx?: any,

    //
    // State
    //
    isLoading?: boolean,
    inProgress?: boolean,

    //
    // Data
    //
    items: any[],

    //
    // Pagination
    //
    page: Page,
    onChangePage?: (page: number) => void,
    onChangeRowsPerPage?: (rowsPerPage: number) => void,

    //
    // Selection
    //
    selectedItems?: any[],
    onChangeSelectedItems?: (ids: string[]) => void,

    //
    // Filter
    //
    filter?: any,
    onFilterChange?: (filter: any) => void,

    //
    // Handlers
    //
    onRowClick?: (item: any) => void,

    //
    // Actions
    //
    getActions?: (row: any) => any[],

    //
    // Expandable
    //
    idField: string,
    expandableSubRowsField: string,
}; // End of Props

//
// Component
//
const DataTable = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    // handle filter
    const dataTableFilter = useMemo(() => {
        if (!props.DataTableFilter) {
            return null;
        }
        return (
            <props.DataTableFilter
                {...props}
            />
        );
    }, [props]);

    const [expandedRows, setExpandedRows] = useState({});
    
    const isRowExpandable = useCallback((item: any) => {
        return true;
    });
    
    const expandRow = useCallback((setExpandedRows: (expandedRows: any) => void, item: any) => {
        setExpandedRows({
            [item.id]: true
        });
    });

    // prepare columns
    const columns = useMemo(() => {
        let columns: any[] = props.columns.map((c, i) => {
            return {
                align: c.align,
                headerAlign: c.align,

                field: c.name,
                headerName: c.label,
                description: c.description,
                
                minWidth: c.width,
                width: c.width,
                flex: c.flex,

                renderCell: c.renderCell,

                valueGetter: ({ row }) => {
                    return get(row, c.name);
                },
                valueFormatter: c.valueFormatter,
            }
        });

        if (props.getActions) {
            columns = [
                ...columns,
                {
                    field: 'actions',
                    type: 'actions',
                    getActions: (params: any) => {
                        if (props.getActions) {
                            return props.getActions(params).map(a => {
                                return (
                                    <GridActionsCellItem 
                                        icon={a.icon}
                                        label={a.label}
                                        onClick={a.onClick}
                                        showInMenu={a.showInMenu}
                                    />
                                )
                            });
                        } else {
                            return [];
                        }
                    }
                }
            ];
        }

        // NOTE: disable sorting, as it is not currently implemented into the backend
        // TODO: remove, after implementing into the backend
        columns = columns.map(c => { return { ...c, sortable: false }});

        return columns;
    }, [props.columns, props.getActions]);

    // prepare rows
    const rows = useMemo(() => {
        // let items = [];
        let items = props.items ? props.items : [];

        // if (props.items) {
        //     for (let i=0; i<props.items.length; i++) {
        //         items.push(props.items[i]);
        //         // if (expandedRows[props.items[i]['id']]) {
        //         //     items.push(props.items[i]);
        //         //     items.push(props.items[i]);
        //         // }
        //     }
        // }
        
        // set dummy ids, if no id and no gerRowId specified
        if (!props.getRowId && items.length > 0 && !items[0]['id']) {
            for (let i=0; i<items.length; i++) {
                items[i]['id'] = `rowId-${i}`;
            }
        }

        return items;
    }, [props.items, props.getRowId]);

    // prepare pagination
    const pagination = useMemo(() => {
        return {
            pageSize: props.page.size,
            page: props.page.number,
        }
    }, [props.page]);

    // construct data table sx
    const dataTableSx = useMemo(() => {
        let sx = {
            ...props.sx,

            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 400,
                fontSize: '0.75rem',
                color: (theme: any) => theme.palette.info.dark,
            }
        };
        if (props.onRowClick) {
            sx = {
                ...sx,
                '& .MuiDataGrid-cell:hover': {
                    cursor: 'pointer',
                }
            }
        };
        return sx;
    }, [props.onRowClick]);

    // compose data header configuration
    let dataGridConfiguration = useMemo(() => {
        let configuration = {};

        // header
        configuration = {
            ...configuration,

            disableColumnFilter: true,
        }
        if (!props.enableHeader) {
            configuration = {
                ...configuration,

                // hide table column headers
                // https://github.com/mui/mui-x/issues/3282
                columnHeaderHeight: 0,
            }
        }

        // footer
        configuration = {
            ...configuration,

            hideFooter: !props.enablePagination && !props.enableItemSelection,
            hideFooterPagination: !props.enablePagination,
            hideFooterSelectedRowCount: !props.enableItemSelection,
        };
        
        // selection
        configuration = {
            ...configuration,
            
            checkboxSelection: !!props.enableItemSelection,
            disableRowSelectionOnClick: !!props.enableItemSelection,
        };

        // row
        if (props.getRowId) {
            configuration = {
                ...configuration,
                getRowId: props.getRowId,
            }
        }

        return configuration;
    }, [props.enableHeader, props.enablePagination, props.enableItemSelection, props.getRowId]);

    const slots = useMemo(() => {
        return {
            toolbar: (!!props.enableToolbar) ? DataTableToolbar : null,
        }
    }, [props.enableToolbar]);

    const slotsProps = useMemo(() => {
        return {
            toolbar: {
                enableToolbarSearchField: !!props.enableToolbarSearchField,
                enableToolbarExportField: !!props.enableToolbarExportField,
                DataTableLeftToolbarPane: props.DataTableLeftToolbarPane,
                DataTableToolbarButtons: props.DataTableToolbarButtons,
                items: props.items,
                page: props.page,
                selectedItems: props.selectedItems,
                filter: props.filter,
                onFilterChange: props.onFilterChange,
            }
        };
    }, [props.enableToolbarSearchField, props.DataTableLeftToolbarPane, props.DataTableToolbarButtons,
            props.items, props.page, props.selectedItems, props.filter, props.onFilterChange]);

    const getRowHeight = useCallback(
        (params: { id: string, densityFactor: string }): any => {
            return 'auto';
        }, []);

    const pageSizeOptions = useMemo(() => [ 5, 10, 20, 50 ], []);

    const onPaginationModelChange = useCallback((newPagination: any) => {
        if (newPagination.page != pagination.page && props.onChangePage) {
            props.onChangePage(newPagination.page);
        }
        if (newPagination.pageSize != pagination.pageSize && props.onChangeRowsPerPage) {
            props.onChangeRowsPerPage(newPagination.pageSize);
        }
    }, [props.onChangePage, props.onChangeRowsPerPage]);

    const onRowSelectionModelChange = useCallback((newSelectedItems: any) => {
        if (props.onChangeSelectedItems) {
            props.onChangeSelectedItems(newSelectedItems);
        }
    }, [props.onChangeSelectedItems]);

    const onRowClick = useCallback((params: any, event: any) => {
        if (props.onRowClick) {
            props.onRowClick(params.row);
        }
    }, [props.onRowClick]);

    return (
        <>
            <div className={classes.progress}>
                {props.inProgress ? (
                    <LinearProgress variant='indeterminate' />
                ) : null}
            </div>
            <Grid>
                {dataTableFilter}
                <DataGrid
                    // sx
                    sx={dataTableSx}

                    // Configuration
                    {...dataGridConfiguration}
                    
                    slots={slots}
                    slotProps={slotsProps}

                    // Visual
                    autoHeight
                    // density='standard'
                    density='compact'
                    // callback to specify row height
                    getRowHeight={getRowHeight}

                    // State
                    loading={props.isLoading}

                    // Pagination
                    pageSizeOptions={pageSizeOptions}
                    paginationMode='server'
                    paginationModel={pagination}
                    onPaginationModelChange={onPaginationModelChange}

                    // Selection
                    rowSelectionModel={props.selectedItems}
                    onRowSelectionModelChange={onRowSelectionModelChange}

                    // Data
                    columns={columns}
                    rows={rows}
                    // rowCount={props.page.totalElements}
                    rowCount={Math.max(props.page.totalElements, rows.length)}

                    //
                    // Handler
                    //
                    onRowClick={onRowClick}
                />
            </Grid>
        </>
    );
};
export default DataTable;
