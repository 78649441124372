// @flow

import get from 'lodash/get';

import * as numeral from 'numeral';
import * as moment from 'moment';

import {
    type Quantity,
} from '../domain/Quantity';

export function fmtAmount(amount: number, fmt: string): string {
    return numeral(amount).format(fmt);
}

export function createOmni(valueType: string, value: any): any {
    if (valueType === 'TEXT') {
        return {
            valueType,
            textValue: value
        };
    }
    if (valueType === 'I8N_TEXT') {
        return {
            valueType,
            i8nTextValue: value
        };
    }
    if (valueType === 'INTEGER') {
         return {
            valueType,
            integerValue: value
        };
    }
    if (valueType === 'LONG') {
         return {
            valueType,
            longValue: value
        };
    }
    if (valueType === 'DOUBLE') {
         return {
            valueType,
            doubleValue: value
        };
    }
    if (valueType === 'DATE') {
         return {
            valueType,
            dateValue: value
        };
    }
    if (valueType === 'TIME') {
         return {
            valueType,
            dateValue: value
        };
    }
    if (valueType === 'DATE_TIME') {
         return {
            valueType,
            dateValue: value
        };
    }
    if (valueType === 'BOOLEAN') {
         return {
            valueType,
            booleanValue: value
        };
    }
    if (valueType === 'QUANTITY') {
         return {
            valueType,
            quantityValue: value
        };
    }
    if (valueType === 'MONEY') {
         return {
            valueType,
            moneyValue: value
        };
    }
    if (valueType === 'PERSON') {
         return {
            valueType,
            personValue: value
        };
    }
    if (valueType === 'COMPANY') {
         return {
            valueType,
            companyValue: value
        };
    }

    return {};
}

export default function fmtOmni(value: any, options?: {
    fmt?: string,
    default?: any,
}): string {
    if (!value) {
        return (options && options.default !== undefined) ? options.default : 'N/A';
    }
    
    if (value.valueType === 'TEXT') {
        return get(value, 'textValue');
    }
    if (value.valueType === 'I8N_TEXT') {
        return get(value, 'i8nTextValue.bg');
    }
    if (value.valueType === 'INTEGER') {
        return get(value, 'integerValue');
    }
    if (value.valueType === 'LONG') {
        return get(value, 'longValue');
    }
    if (value.valueType === 'DOUBLE') {
        const currentFmt = (options && options.fmt) ? options.fmt : '0,0.00';
        return (value && value.doubleValue) ?
            numeral(value.doubleValue).format(currentFmt) : numeral(0).format(currentFmt);
    }
    if (value.valueType === 'DATE') {
        return moment(value.dateValue).format((options && options.fmt) ? options.fmt : 'LL');;
    }
    if (value.valueType === 'TIME') {
        return moment(value.dateValue).format((options && options.fmt) ? options.fmt : 'LT');
    }
    if (value.valueType === 'DATE_TIME') {
        return moment(value.dateValue).format((options && options.fmt) ? options.fmt : 'LL');
    }
    if (value.valueType === 'BOOLEAN') {
        return (value.booleanValue) ? 'Да' : 'Не';
    }
    if (value.valueType === 'QUANTITY') {
        const amount = (value && value.quantityValue && value.quantityValue.amount) ? 
                value.quantityValue.amount : 0;
        const unit = (value && value.quantityValue && value.quantityValue.unit) ? 
                value.quantityValue.unit : ' - ';
        return `${fmtAmount(amount, '0,0.00')} ${unit}`;
    }
    if (value.valueType === 'MONEY') {
        const amount = (value && value.moneyValue && value.moneyValue.amount) ? 
                value.moneyValue.amount : 0;
        const currency = (value && value.moneyValue && value && value.moneyValue.currency) ? 
                value.moneyValue.currency : ' - ';
        return `${fmtAmount(amount, '0,0.00')} ${currency}`;
    }
    if (value.valueType === 'PERSON') {
        const firstName = (value && value.personValue) ? value.personValue.firstName : '';
        const middleName = (value && value.personValue) ? value.personValue.middleName : '';
        const lastName = (value && value.personValue) ? value.personValue.lastName : '';
        return `${firstName} ${middleName} ${lastName}`;
    }
    if (value.valueType === 'COMPANY') {
        const name = (value && value.companyValue) ? value.companyValue.name : '';
        return name;
    }

    if (value.valueType === 'WAREHOUSE') {
        const name = (value && value.warehouseValue) ? `${value.warehouseValue.code} / ${value.warehouseValue.name}` : '';
        return name;
    }

    return 'Unknown type';
}