// @flow

//
// React
//
import React, { useContext } from 'react';

//
// React router
//
import { useNavigate } from 'react-router-dom';

//
// Tenant
//
import {
    useNavigateTo,
    useBuildLocationPath,
} from '../../../../../../lib/trix-web-components-tenant';

//
// Ducks
//
import ReducerActions from '../../../../../../lib/redux-fantasy-reducers/actions/Actions';
import { appSelector } from '../../../../state/ducks';
import { Selectors } from '../../../../state/ducks/app/reducer';

//
// Shell
//
import withPages from '../../../../enhancers/withPages';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import CloseIcon from '@mui/icons-material/Close';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        overflow: 'hidden',

        width: '12rem',
        minWidth: '12rem',
        padding: theme.spacing(1),

        // set vertical scroll on overflow
        overflow: 'hidden',
        overflowY: 'auto',

        // show vertical scrollbar only on hover
        '&:not(:hover)::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
        },
    },
}));

//
// Props
//
type Props = {
    //
    // Handlers
    //
    onToggleNavigationMenu: () => void,
    
    onClosePage: (instanceId: string) => void,

    //
    // Pages
    //
    pages: any[],
};

//
// Component
//
const Navigation = (props: Props): any => {
    //
    // Hooks
    //
    const navigate = useNavigate();

    const classes = useStyles();

    const navigateTo = useNavigateTo();
    const buildLocationPath = useBuildLocationPath();
    // End hooks

    // TODO: 
    const currentUrl = ''; // `${location.pathname}${location.search}`;

    return (
        <Grid container direction='column' wrap='nowrap' className={classes.root} >
            {/* <Grid item>
                <List dense>
                    <ListItem
                        button
                        key='/home'
                        selected={buildLocationPath('/home') === currentUrl}
                        onClick={() => navigateTo('/home')}
                    >
                        <ListItemText primary='Начало' />
                    </ListItem>
                </List>
            </Grid> */}

            {/* <Divider /> */}

            <Grid item style={{ flexGrow: 1 }}>
                <List dense>
                    {(props.pages || []).map(instance => {
                        const selected = instance.url === currentUrl;
                        return (
                            <ListItem
                                button
                                key={instance.url}
                                selected={selected}
                                onClick={() => navigate(instance.url)}
                            >
                                <ListItemText primary={instance.title} />
                                <ListItemSecondaryAction>
                                    {selected ? (
                                        <IconButton
                                            edge='end'
                                            size='small'
                                            onClick={(e) => {
                                                props.onClosePage(instance.instanceId);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <CloseIcon color='action' />
                                        </IconButton>
                                    ) : null}
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>

            <Grid item>
                <Typography
                    variant='caption'
                    color='textSecondary'
                    align='center'
                >
                    Версия 6.7<br />
                    {'Copyright © '}
                    <Link color='inherit' href='https://threeix.com/'>
                        3ix
                    </Link>{' '}
                    {new Date().getFullYear()}
                </Typography>
            </Grid>
        </Grid>
    );
};
export default Navigation;
