// @flow

//
// React
//
import React, {
    useCallback,
} from 'react';

//
// Trix Middleware
//
import { get as httpGet } from '../../../../../lib/trix-web-middleware-commons';

//
// Trix Data
//
import {
    buildUrl,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    ComboBoxFormPaneField,
    type FormPaneContentProps,
} from '../../../../../lib/trix-web-components-pane';

import { PropertyValueFormPaneField } from '../../../../../lib/trix-web-components-property';

export const getFindServiceGroupsUrl = (params: any) => {
    return buildUrl(
        '/api/v1/core/service/serviceGroups/search/byFreeText?text={text}&page={page}&size={size}',
        params
    );
};

//
// Props
//
type Props = FormPaneContentProps & {
    //
    // Data
    //
    data: any,

    //
    // Error
    //
    error: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => any,
};

//
// Component
//
const Content = (props: Props) => {
    //
    // Hooks
    //
    // End hooks

    const loadServiceGroupsHandler = useCallback((value, reject, resolve) => {
        httpGet(
            // url
            getFindServiceGroupsUrl({
                // filter param
                text: value,
                // paging
                page: 0,
                size: 20,
            }),

            // options
            null,

            // reject callback
            reject,

            // resolve callback
            (data) =>
                resolve(
                    (data && data.body && data.body._embedded && data.body._embedded.serviceGroups) ?
                            data.body._embedded.serviceGroups : []
                )
        );
    }, []);
    const getOptionLabelServiceGroupsHandler = useCallback((option) =>
        `${option.code} ${option.name.bg}`, []);
    const isOptionEqualToValueServiceGroupsHandler = useCallback((option, value) => {
        return (option.id === value.id)
    }, []);

    return (
        <React.Fragment>
            <ComboBoxFormPaneField
                label='Група услуга'
                description='Изберете група услуга'
                
                value={props.data.group}
                error={props.error}

                onLoadData={loadServiceGroupsHandler}
                onGetOptionLabel={getOptionLabelServiceGroupsHandler}
                isOptionEqualToValue={isOptionEqualToValueServiceGroupsHandler}
                onChange={props.onChange}
                
                dataPath='group'
            />

            {(props.data.group && props.data.group.mainProperties
                ? props.data.group.mainProperties
                : []
            ).map((p) => (
                <PropertyValueFormPaneField
                    key={p.id}
                    id={p.id}
                    valueType={p.valueType}
                    valueSource={p.valueSource}
                    code={p.code}
                    name={p.name}
                    description={p.description}
                    mandatory={p.mandatory}
                    
                    value={(props.data && props.data.properties) ? props.data.properties[p.code] : null}
                    error={props.error}
                    
                    onChange={props.onChange}

                    dataPath={`properties.${p.code}`}
                />
            ))}
        </React.Fragment>
    );
};
export default Content;
