// @flow

//
// Lodash
//
import assign from 'lodash/assign';
import get from 'lodash/get';
import omit from 'lodash/omit';

//
// Data Types
//
import {
    type Page,
    type ListNavigationLinks,
} from '../../../../trix-web-data-commons/api/Page';

//
// State
//
export type State = {
    items: ?Array<any>,
    page: Page,
    links: any,
};

//
// Compound Reducers
//
type Configuration = {
    reduce: (state: State, action: any) => any,
};

//
// Reducer
//
export const createDataListReducer = (cfg: Configuration): any => (
    state: State = {
        items: undefined,
        page: {
            number: 0,
            size: 10,
            totalElements: 0,
            totalPages: 0,
        },
        links: {},
    },
    action: any
): State => {
// console.log('DATA LIST REDUCER: state:', state, ', action:', action);
    return cfg.reduce(state, action);
};

//
// Composite reducers
//
export const reduceDataSet = (payloadPath?: string): any => (
    state: any,
    action: any
) => {
    const actionData = payloadPath
        ? get(action.payload, payloadPath)
        : action.payload;
    return {
        items: actionData.items,
        page: actionData.page,
        links: actionData.links,
        // selectedItems: {},
    };
};

export const reduceItemAdded = (payloadPath?: string): any => (
    state: any,
    action: any
) => {
    const actionItem = payloadPath
        ? get(action.payload, payloadPath)
        : action.payload;
    return {
        ...state,
        items: (state.items) ? [...state.items, actionItem] : [ actionItem ],
    };
};

export const reduceItemChanged = (payloadPath?: string): any => (
    state: any,
    action: any
) => {
    const actionItem = payloadPath
        ? get(action.payload, payloadPath)
        : action.payload;
    return {
        ...state,
        items: state.items.map((item) =>
            item.id === actionItem.id
                ? actionItem
                : item
        ),
    };
};

export const reduceItemMerged = (payloadPath?: string): any => (
    state: any,
    action: any
) => {
console.log('REDUCER ITEM MERGED: state:', state, ', action:', action, ', payloadPath:', payloadPath);
    const actionItem = payloadPath
        ? get(action.payload, payloadPath)
        : action.payload;
console.log('REDUCER ITEM MERGED: action item:', actionItem);
    return {
        ...state,
        items: state.items.map((item) =>
            item.id === actionItem.id
                ? {
                    ...item,
                    ...actionItem
                } : item
        ),
    };
};

export const reduceItemAddedOrChanged = (payloadPath?: string): any => (
    state: any,
    action: any
) => {
    const actionItem = payloadPath
        ? get(action.payload, payloadPath)
        : action.payload;
    const a = state.items.filter(
        (item) => item.id === actionItem.id
    );

    if (a.length > 0) {
        // update item
        return {
            ...state,
            items: state.items.map((item) =>
                item.id === actionItem.id
                    ? actionItem
                    : item
            ),
        };
    } else {
        // add item
        return {
            ...state,
            items: [...state.items, actionItem],
        };
    }
};

export const reduceItemDeleted = (): any => (state: any, action: any) => ({
    ...state,
    items: state.items.filter(
        (item) => {
            const _id = (action.meta && action.meta._id) ? action.meta._id : '';
            return item.id !== _id;
        }
    ),
    // selectedItems: {},
});
