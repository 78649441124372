// @flow

//
// React
//
import React from 'react';

//
// React Router
//
import { Route } from 'react-router';

//
// Components
//
// import MainPageContent from './components/pages/mainpage/MainPageContent';

// Index route
export const IndexRoute = (): any => {
    // return(
    //     <Route 
    //         path='/'
    //         component={MainPageContent} />
    // );
    return null;
};

