// @flow

//
// React
//
import React, { useState, useMemo } from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
} from '../../redux-fantasy-reducers';

//
// Data
//
import {
    concatErrors,
} from '../../trix-web-data-commons';

//
// Hal forms
//
import {
	getSelector,
} from '../tools/halFormsRedux';

//
// Material UI
//
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Components
//
import {
    withComponentsRegistry,
} from '../../trix-web-components-registry';

import HalFormsContainer from './HalFormsContainer';
import HalFormsContainerRowPaper from './halformscontainer/HalFormsContainerRowPaper';

//
// Functions
//
const tabs = (cfg: {
    classes: any,
}) => (props: {
    __currentHalFormTabIndex: number,
    __renderHalFormTab: (index: number) => void,
    __halFormTabs: any[],
    children: any,
}) => {
    return(
        <>
            <Tabs
                value={props.__currentHalFormTabIndex}
                onChange={(event, i) => props.__renderHalFormTab(i)}
                indicatorColor='primary'
                textColor='primary'
                centered
            >
                {
                    props.__halFormTabs.map((t, i) => {
                        const label = (t.title) ? t.title : t.name;
                        return (
                            <Tab
                                key={i}
                                label={label}
                            />
                        )
                    })
                }
            </Tabs>

            <Box className={cfg.classes.content}>
                {props.children}
            </Box>
        </>
    );
}

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {

    },    
    content: {
        padding: theme.spacing(1),
    }
}));

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // Data container
    //
    __context: any,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,
    error: any,

    //
    // Meta
    //
    meta: any,

    //
    // Components registry
    //
    __componentsRegistry: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => void,
    onClose: () => void,
} // End of Props

//
// Component
//
const HalFormsTabs = (props: Props) => {
    //
    // Hooks
    //
    const pageInstanceState = useLocalSelector (s => s);
    const classes = useStyles();

    const [ halFormTabIndex, renderHalFormTab ] = useState(0);
    // End hooks

    const FormLayoutComponent = useMemo(() => {
        const formLayoutComponentId = `formLayout:${props.__halFormsTemplate.id}`;
        let Component = props.__componentsRegistry.getComponent(formLayoutComponentId);
        if (!Component) {
            Component = tabs({
                classes
            });
        }
        return Component;
    }, [props.__halFormsTemplate.id]);
   
    return (
        <FormLayoutComponent
            __currentHalFormTabIndex={halFormTabIndex}
            __renderHalFormTab={renderHalFormTab}
            __halFormTabs={props.__halFormsTemplate.components}
        >
            <Container 
                maxwidth='md'
            >
                <HalFormsContainer
                    key='halFormsTabs-0'

                    HalFormsContainerRow={HalFormsContainerRowPaper}

                    __halFormsTemplate={props.__halFormsTemplate.components[halFormTabIndex]}
                    __ducks={props.__ducks}
                    __dataSourcesRef={props.__dataSourcesRef}
                    __context={props.__context}

                    container={props.container}
                    data={props.data}
                    error={props.error}
                    
                    onChange={props.onChange}
                    onClose={props.onClose}
                />
            </Container>
        </FormLayoutComponent>
    );
};
export default withComponentsRegistry (HalFormsTabs);