// @flow

//
// Trix UI App Commons
//
import {
    get,
    post,
} from '../../../../lib/trix-web-middleware-commons';

import { type Credentials } from '../domain';

/**
 * Request authentication from the server
 * 
 * @param credentials
 * @param reject
 * @param resolve
 * @return requestInstance
 */
export function authenticate(credentials: Credentials,
        reject: (error: any) => void, 
        resolve: (user: any) => void): any {
    return post('/api/v1/security/user/authorization/login', credentials, 
        (error) => {
            deleteAllCookies();
            reject(error);
        }, 
        resolve
    );
}

/**
 * Request 'loadMe' endpoint to retrieve Person data for the 
 * currently logged user
 * 
 * @param tenantId
 * @param reject
 * @param resolve
 * @return requestInstance
 */
export function loadMe(
        tenantId: number,
        reject: (error: any) => void, 
        resolve: (user: any) => void): any {
    return get('/api/v1/security/oauth2/me',
        {
            headers: {
                'X-Tenant': tenantId,
            }
        }, 
        (error) => {
            deleteAllCookies();
            reject(error);
        },
        (response) => resolve(response.body)
    );
}

/**
 * Request authentication from the server
 * 
 * @param reject
 * @param resolve
 * @return requestInstance
 */
export function logout(
        reject: (error: any) => void, 
        resolve: (user: any) => void): any {
    return post('/api/v1/security/user/authorization/logout', {},
        reject, 
        (user: any) => {
            deleteAllCookies();
            resolve(user);
        }
    );
}

export function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}