// @flow

const actionHandlers = {
    onSuccess: (cfg: any) => {
        if (!cfg.onSetData) {
            return;
        }
        cfg.onSetData({
            ...cfg.data,
            sku: '',
        });
    }
};
export default actionHandlers;
