// @flow

/**
 * Error reason
 *  - ERROR Generic type of error
 *  - VALIDATION Validation failed type of error
 *
 */
export type Reason = 'ERROR' | 'VALIDATION';

/**
 * Error location Types
 * - SERVER Error originates from the server
 * - NETWORK Error due to the network problem
 * - CLIENT Error due to the client error
 * - PARAMETER Error in parameter, specified in the 'value' field
 */
export type LocationType = 'SERVER' | 'NETWORK' | 'CLIENT' | 'PARAMETER';

/**
 * Error location
 */
export type Location = {
    type: LocationType,
    value?: string,
};

/**
 * Cause of error
 */
export type Cause = {
    reason: Reason,
    location: Location,
    message: string,
};

export type Error = {
    causes: Cause[],
};

/**
 * Error class
 */
export function emptyError(): Error {
    return {
        causes: [],
    };
}

export function toError(causes?: Cause[]): Error {
    return {
        causes: causes || [],
    };
}

export function concatErrors(errors: any[]): any {
    const causes = errors.reduce(
        (r: any, e) => (e && e.causes ? [...r, ...e.causes] : r),
        []
    );
    return (causes.length > 0) ? {
        causes,
    } : undefined;
}

//
// Error builder functions
//

export const serverError = (message: string): any => {
    return toError([
        {
            reason: 'ERROR',
            location: {
                type: 'SERVER',
            },
            message,
        },
    ]);
};

export const networkError = (message: string): any => {
    return toError([
        {
            reason: 'ERROR',
            location: {
                type: 'NETWORK',
            },
            message,
        },
    ]);
};

export const clientError = (message: string): any => {
    return toError([
        {
            reason: 'ERROR',
            location: {
                type: 'CLIENT',
            },
            message,
        },
    ]);
};

export const requiredFieldError = (field: string, message: string): any => {
    return toError([
        {
            reason: 'VALIDATION',
            location: {
                type: 'PARAMETER',
                value: field,
            },
            message,
        },
    ]);
};

export const invalidFieldValueError = (field: string, message: string): any => {
    return toError([
        {
            reason: 'VALIDATION',
            location: {
                type: 'PARAMETER',
                value: field,
            },
            message,
        },
    ]);
};

export const isError = (json: { causes?: Cause[] }): any => {
    return !!(json && json.causes);
};

//
// Selectors
//

/**
 * Check if error object contains errors
 *
 * @param error
 */
export function isOK(error: ?Error): boolean {
    return !(error && error.causes && error.causes.length > 0);
}

/**
 * Find all error causes
 *
 * @param error
 * @param reason
 */
export function findAllErrorCauses(error: ?Error): any {
    return error && error.causes ? error.causes : [];
}

/**
 * Find error causes, matching specified reason
 *
 * @param error
 * @param reason
 */
export function findErrorCausesByReason(
    error: ?Error,
    reason: Reason
): Cause[] {
    return error && error.causes
        ? error.causes.filter(c => c.reason === reason)
        : [];
}

/**
 * Find causes by location
 *
 * @param error
 * @param type
 * @param value
 */
export function findErrorCausesByLocation(
    error: ?Error,
    type: LocationType,
    value?: string
): Cause[] {
    return error && error.causes
        ? error.causes.filter(
              c =>
                  c.location.type === type &&
                  (!value || c.location.value === value)
          )
        : [];
}

/**
 * Find and format errors for location
 *
 * @param error
 * @param type
 * @param value
 */
export function formatErrorsForLocation(error: ?Error, type: LocationType, value?: string): string {
    return error && error.causes
        ? formatErrorCauses(error.causes
              .filter(
                  c =>
                      c.location.type === type &&
                      (!value || c.location.value === value)
              ))
        : '';
}

export function formatErrorsForParameter(error: Error, parameter: string): string {
    return formatErrorsForLocation(error, 'PARAMETER', parameter);
}

/**
 * Format errors causes to string
 *
 * @param causes
 */
export function formatErrorCauses(causes: Cause[]): any {
    return causes
            .map(c => c.message)
            .join(' ');
}

export function formatError(error: Error): any {
    if (!error || !error.causes) {
        return '';
    }
    return formatErrorCauses(error.causes);
}

/**
 * Has causes for location
 *
 * @param error
 * @param type
 * @param value
 */
export function hasErrorCausesForLocation(error: ?Error, type: LocationType, value?: string): any {
    return findErrorCausesByLocation(error, type, value).length > 0;
}

export function hasErrorCausesForParameter(error: Error, parameter: string): any {
    return hasErrorCausesForLocation(error, 'PARAMETER', parameter).length > 0;
}