// @flow

//
// React
//
import React, { type Node, useState } from 'react';

//
// Formatter
//
import {
    fmtOmni,
} from '../../../../lib/trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.secondary.main,
    },
    
    propertiesTable: {
    },
    propertiesTableLastRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    propertiesText: {
        fontSize: '.625rem',
    },
    
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    title?: string,
    service: any,
};

//
// Component
//
const ServicePropertiesPane = (props: Props): Node => {
    //
    // Hooks
    //
    const classes = useStyles();

    const [isPropertiesVisible, setPropertiesVisible] = useState(false);
    // End Hooks

    return (
        <>
            {(props.title) ? 
                (
                    <Typography 
                        className={classes.title}
                        variant='caption' 
                        display='block'
                    >
                        {props.title}
                    </Typography>
                ) : null
            }
            <Typography 
                variant='caption' 
                display='block' 
                className={clsx(classes.link, classes.propertiesText)}
            >
                <div
                    onClick={() => setPropertiesVisible(!isPropertiesVisible)}
                >
                    {isPropertiesVisible ? 
                        (<>Скрий характеристики</>) : (<>Покажи характеристики</>)
                    }
                </div>
            </Typography>

            <Table 
                className={classes.propertiesTable}
                size='small' 
            >
                <TableBody>
                    {isPropertiesVisible ? 
                            ((props.service && props.service.properties) ? props.service.properties : []).map((row, index, array) => (
                                <TableRow 
                                    key={index}
                                    className={(index === array.length - 1) ? classes.propertiesTableLastRow : null}
                                >
                                    <TableCell
                                        component='th'
                                        size='small'
                                        className={classes.propertiesText}
                                    >
                                        {row.code} {row.name}
                                    </TableCell>
                                    <TableCell 
                                        size='small'
                                        align='right'
                                        className={classes.propertiesText}
                                    >
                                        {fmtOmni(row.valueType, { fmt: row.value })}
                                    </TableCell>
                                </TableRow>
                            )
                    ) : null}
                </TableBody>
            </Table>
        </>
    );
}
export default ServicePropertiesPane;
