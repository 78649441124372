// @flow

//
// Lodash
//
import fp from 'lodash/fp';
import getOr from 'lodash/fp/getOr';

//
// Duck
//
import { type State } from './reducer';

//
// Selectors
//
const getColumns = (state: State) => state.configuration.columns;
const getPage = (state: State) => state.page;
const getFilter = (state: State) => state.filter;
const getSortBy = (state: State) => state.sortBy;
const getSelectedItems = (state: State) => state.selectedItems;

export const createComponentTableSelectors = (getLocalState: (state: any) => State): any => {
    return {
        getColumns: fp.compose(getColumns, getLocalState), 

        getPage: fp.compose(getPage, getLocalState),

        getFilter: fp.compose(getFilter, getLocalState),

        getFilterDefinition: (property: string) =>
            fp.compose(fp.find(e => e.property === property), getFilter, getLocalState),

        getFilterAndSorting: (state: any) => {
            const localState = getLocalState(state);
            const filter = getFilter(localState);
            const sortBy = getSortBy(localState);
            return {
                filter,
                sortBy,
            }
        },

        getSelectedItems: fp.compose(getSelectedItems, getLocalState),
    };
};
