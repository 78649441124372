// @flow

//
// React
//
import React, { useEffect, useState } from 'react';

//
// Trix Middleware
//
import { get as httpGet } from '../../../../trix-web-middleware-commons';

//
// Trix Data
//
import {
    fmtNumber,
    fmtDate,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../trix-web-data-commons';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

//
// Extenders
//
import withHalFormsOptions from '../../../extender/withHalFormsOptions';

//
// Components
//
import {
    CheckboxGroupFormPaneField,
} from '../../../../trix-web-components-pane';

//
// Props
//
type Props = {
    //
    // Hal Forms Template
    //
    __halFormsTemplate: any,

    //
    // Data Source Extender props (in case data source set)
    //
    __dataSourceExtender: {
        dataSource: any,
        loadData: (params: any, reject: (error: any) => void, resolve: (data: any) => void) => void,
        isLoadingData: boolean,
        data: any,
    },

    //
    // Components registry
    //
    getComponent: (type: string) => any,
    getHalFormsCustomizer: (customizer: string, halFormsTemplate: any, target: string) => any,

    name: string,
    label: string,
    description: string,

    value: any,
    error: Error,

    onChange: (data: any) => void,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const HalFormsCheckboxGroupFormPaneField = (props: Props) => {
    // Hooks
    // const classes = useStyles();

    const [options, setOptions] = useState([]);

    useEffect(() => {
        props.__dataSourceExtender.loadData(
            // params
            {
                // filter param
                text: '',

                // paging
                page: 0,
                size: 200,
            },
            
            // reject callback
            (error) => {
                setOptions([]);
            },

            // resolve callback
            (data) => {
                const embedded = (data && data.body && data.body._embedded) ?
                    data.body._embedded : {};
                const values = Object.values(embedded);
                const items = (values && values.length > 0) ? (values[0]) : [];

                setOptions(items);
            }
        );
    }, []);

    // End Hooks

    // TODO: check for configuration object (similar of the one of the tables)
    const defaultConfiguration = {
        onGetOptionLabel: (option: any) => {
            if (typeof option === 'string') {
                return option;
            }

            // if (option.code && option.name) {
            //     return `${option.code} / ${option.name}`;
            // }
            if (option.name) {
                return option.name;
            }
            if (option.code) {
                return option.code;
            }
            if (option.label) {
                return option.label;
            }
            if (option.documentNo && option.documentDate) {
                return `${fmtNumber(option.documentNo, '0000000000')} / ${fmtDate(option.documentDate, 'LL')}`
            }
            return 'Unknown';
        },
        // enableToolbar: true,
        // enableToolbarSearchField: true,
        // enableHeader: false,
        // enableItemSelection: ((props.__halFormsTemplate && props.__halFormsTemplate.actions) ? 
        //         props.__halFormsTemplate.actions : [])
        //             .filter(a => a.target === 'bulkItems')
        //             .length > 0,
        // enableRowExtension: false,
        // enablePagination: page.size > 0,
    };
    // custom configuration (if any)
    const getCustomConfiguration = props.getHalFormsCustomizer('configuration', props.__halFormsTemplate, '');
    const customConfiguration = (getCustomConfiguration) ? getCustomConfiguration(props) : {};

    // merge default and custom configuration
    const configuration = {
        ...defaultConfiguration,
        ...customConfiguration
    };

    return (
        <CheckboxGroupFormPaneField
            // TODO:
            //required={props.required}

            label={props.label}
            description={props.description}
            
            value={props.value}
            error={props.error}
            
            options={options}
            
            // onGetOptionLabel={configuration.onGetOptionLabel}
            // isOptionEqualToValue={(option, value) => {
            //     if (typeof option === 'string') {
            //         return option === value;
            //     }

            //     return option.id === value.id;
            // }}
            
            onChange={props.onChange}

            dataPath={props.dataPath}
        />
    );
};
export default withHalFormsOptions (HalFormsCheckboxGroupFormPaneField);
