// @flow

//
// Trix Middleware
//
import { get } from '../../lib/trix-web-middleware-commons';

//
// Name
//
import { PLUGIN_NAME } from './plugin';

//
// Ducks
//
// import {
//     reducer,
//     operations,
// } from './state/ducks';

//
// Web components
//
// import { IndexRoute } from './web';

//
// Plugin Definition
//
const plugin = {
    name: PLUGIN_NAME,

    //
    // State
    //
    state: {
        // operations,
        // reducer,
    },

    //
    // Boot functions
    //
    boot: {
        load: (tenantId: number, reject: (e: any) => void, resolve: (data: any) => void) => {
            // reject({
            //     title: 'Index',
            //     error: 'N/A'
            // });

            get(
                // url
                '/api/v1/tenant/user/index', 

                // options
                {
                    headers: {
                        // On server disable X-Tenant header for 'index' request (index is same for all tenants)
                        'X-Tenant': 0
                    }
                },

                // reject callback
                reject, 

                // resolve callback
                (data) => resolve(data.body)
            );
        },
    },

    //
    // Web
    //
    web: {
        // public components
        components: {
        }
    },
}
export default plugin;