// @flow

//
// Duck
//
import { type State } from './reducer';

// Data
const user = (state: State): any => state.user;

// State
const sessionState = (state: State): any => state.sessionState;

// Actions
const actionLogOut = (state: State): any => state.actionLogOut;

const Selectors = {
    // data
    user,

    // state
    sessionState,

    // actions
    actionLogOut,
}
export default Selectors;