// @flow

import * as moment from 'moment';

export function fmtDate(value: Date, fmt?: string): string {
    if (!value) {
        return '';
    }
    return moment(value).format(fmt || 'LL');
}

export function fmtTime(value: Date, fmt?: string): string {
    if (!value) {
        return '';
    }
    return moment(value).format(fmt || 'LT');
}