// @flow

//
// Types
//
type Configuration = {
    reducerKey: string;
}

// type ReducerFactory = (cfg: Configuration) => (state: any, action: any) => any;

type Reducer = (state: any, action: any) => any;

/**
 * `compose` reducer factory
 *
 * TODO: doc..
 */
const compose = (reducers: { [key: string]: Reducer }): any => (cfg: Configuration) => {
    const keys = Object.keys(reducers);
    const finalReducers: { [key: string]: Reducer } = {};
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (process.env.NODE_ENV !== 'production') {
            if (typeof reducers[key] === 'undefined') {
                // TODO: implementa warning
                // warning(`No reducer provided for key "${key}"`);
                console.warn(`No reducer provided for key "${key}"`);
            }
        }

        if (typeof reducers[key] === 'function') {
            finalReducers[key] = reducers[key]({
                reducerKey: (cfg.reducerKey) ? `${cfg.reducerKey}.${key}` : key,
            });
        }
    }
    const finalReducerKeys = Object.keys(finalReducers);

    return (state: any = {
        _meta: {
            reducerKey: cfg.reducerKey,
        },
    }, action: any) => {
        let hasChanged = false;
        const nextState: { [key: string]: any } = {};
        for (let i = 0; i < finalReducerKeys.length; i++) {
            const key = finalReducerKeys[i];
            const reducer = finalReducers[key];
            const previousStateForKey = state[key];
            const nextStateForKey = reducer(previousStateForKey, action);
            if (typeof nextStateForKey === 'undefined') {
                const errorMessage = getUndefinedStateErrorMessage(key, action);
                throw new Error(errorMessage)
            }
            nextState[key] = nextStateForKey;
            hasChanged = hasChanged || nextStateForKey !== previousStateForKey;
        }
        return hasChanged ? nextState : state;
    }
}
export default compose;

function getUndefinedStateErrorMessage(key: string, action: any) {
    const actionType = action && action.type
    const actionDescription =
        (actionType && `action "${String(actionType)}"`) || 'an action'

    return (
        `Given ${actionDescription}, reducer "${key}" returned undefined. ` +
        `To ignore an action, you must explicitly return the previous state. ` +
        `If you want this reducer to hold no value, you can return null instead of undefined.`
    )
}
