// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// Sanctuary
//
import S from '../../trix-fp-fantasy';

export const localState = (path: string): ((store: any) => any) => (store: any) => {
    return get(store, path, {});
}

/**
 * Connect @param {selector} to a reducer by @param {reducerKey}.
 * Curried function, which finds reducer's state into the store and passes it 
 * to the @param {selector}.
 *
 * @param {reducerKey} reducerKey reducer key, reflecting the path in the store
 * @param {string} selector to be connected
 * @param {object} store
 * @return {object} selected value from the store
 */
 // TODO: this should create a function for each reducer key
 // TODO: think about caching them in cache with size like 10
 // to avoid poluting memory with a lot of unnecessary functions..
// const connectSelector = (reducerKey: string) =>
//         (selector: (state: any) => any) => (store: any) =>
//     S.pipe([
//         localState(reducerKey),
//         selector
//     ])(store);
// export default connectSelector;
export const connectSelector = (reducerKey: string, props: any): ((selector: any) => any) => 
        (selector: (state: any, props: any) => any) => (store: any) => {
    const state = localState(reducerKey)(store);
    return selector(state, props);
}
export default connectSelector;