import {
    create,
    env as envS,
} from 'sanctuary';

import {
    env as envF,
} from 'fluture-sanctuary-types';

import Future, {
    resolve,
    reject,
    after,
    rejectAfter,

    bimap,
    chain,
    race,
    both,
    parallel,

    fork,
    forkCatch,
    value,
    done, 

    isFuture,
} from 'fluture';

const S = create({
    // checkTypes: true,
    // checkTypes: false,
    checkTypes: localStorage.getItem ('SANCTUARY_CHECK_TYPES') === 'true',
    env: envS.concat(envF),
});
export default S;

export const F = {
    Future,

    resolve,
    reject,
    after,
    rejectAfter,

    bimap,
    chain,
    race,
    both,
    parallel,

    fork,
    forkCatch,
    value,
    done,

    isFuture, 
};
