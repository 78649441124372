//
// React
//
import React, { useContext } from 'react';

//
// React router
//
import { useNavigate } from 'react-router-dom';

//
// Tanant
//
import TenantContext from '../context/TenantContext';

//
// Tools
//
import { navigateTo } from '../tools/location';

const useNavigateTo = () => {
    const tenantId = useContext(TenantContext);
    const navigate = useNavigate();
    return navigateTo(navigate, tenantId);
}

export default useNavigateTo;