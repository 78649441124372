// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

//
// Props
//
type Props = {
    inProgress: boolean,
};

//
// Component
//
const ActionProgressBar = (props: Props): any => {
    if (!props.inProgress) {
        return null;
    }

    return (
        <Box 
            sx = {{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
            }}
        >
            <LinearProgress variant='indeterminate' />
        </Box>
    );
};
export default ActionProgressBar;