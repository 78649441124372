// @flow

//
// React
//
import React, { type Node, useState } from 'react';

//
// Formatter
//
import { 
    fmtQuantity,
    fmtDate,
    fmtDocumentNumber,
    fmtOmni,
} from '../../../../lib/trix-web-data-commons';

//
// Type
//
import { type CellRendererProps } from '../../../../lib/trix-web-components-table/components/table/types/CellRendererProps';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Tools
//
const formatSkuQuantity = (trackingStrategy: string, skuQuantity: any) => {
    if (trackingStrategy === 'BUNCH') {
        return fmtQuantity(skuQuantity.quantity);
    }
    if (trackingStrategy === 'PCS') {
        return fmtQuantity(skuQuantity.quantity);
    }
    if (trackingStrategy === 'IN_BULK') {
        return `${fmtQuantity(skuQuantity.quantity)} (${fmtQuantity(skuQuantity.bulkQuantity)})`;
    }
    return 'UTS';
}

//
// Styles
//
const useStyles = makeStyles(theme => ({
    inventoryTable: {
    },
    tableRowWithoutBottomBorder: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    inventoryText: {
        fontSize: '.625rem',
    },
    inventoryDataValue: {
        whiteSpace: 'nowrap',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Component
//
const StockInventoryRow = (props: {
    rowClassName: any,
    label: string,
    stock: any,
    skuQuantity: any,
    skuQuantityByWarehouses: any,
}) => {
    //
    // Hooks
    //
    const classes = useStyles();

    const [isInventoryDetailsVisible, setInventoryDetailsVisible] = useState(false);

    return (
        <React.Fragment>
            <TableRow 
                className={(isInventoryDetailsVisible) ? classes.tableRowWithoutBottomBorder : props.rowClassName}
            >
                <TableCell
                    component='th'
                    size='small'
                    className={classes.inventoryText}
                >
                    {props.label}
                </TableCell>
                <TableCell 
                    size='small'
                    align='right'
                    className={clsx(classes.inventoryText, classes.inventoryDataValue)}
                >
                    <div
                        onClick={() => setInventoryDetailsVisible(!isInventoryDetailsVisible)}
                        className={clsx(classes.link)}
                    >
                        {
                            // $FlowFixMe
                            formatSkuQuantity(props.stock.trackingStrategy, props.skuQuantity)
                        }
                    </div>
                </TableCell>
            </TableRow>
            {
                (props.skuQuantityByWarehouses) ? (
                    <TableRow>
                        <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={2}
                        >
                            <Collapse in={isInventoryDetailsVisible} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                    <Table size="small">
                                        <TableBody>
                                            {props.skuQuantityByWarehouses.map((row, index, array) => (
                                                <TableRow 
                                                    key={index}
                                                    className={(index === array.length - 1) ? 
                                                        classes.tableRowWithoutBottomBorder : null
                                                    }
                                                >
                                                    <TableCell
                                                        component='th'
                                                        size='small'
                                                        className={classes.inventoryText}
                                                    >
                                                        {(row.warehouse) ? row.warehouse.name : 'Няма'}  
                                                    </TableCell>
                                                    <TableCell
                                                        size='small'
                                                        align='right'
                                                        className={clsx(classes.inventoryText, classes.inventoryDataValue)}
                                                    >
                                                        {formatSkuQuantity(props.stock.trackingStrategy, row.skuQuantity)}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                ) : null
            }
        </React.Fragment>
    );
}

//
// Props
//
type Props = {
    //
    // Data
    //
    stock: any,

    skuInventory: {
        storeRequestedQuantity: any,
        storeRequestedQuantityByWarehouses: any[],

        inventoryQuantity: any,
        inventoryQuantityByWarehouses: any[],

        reservedQuantity: any,
        reservedQuantityByWarehouses: any[],

        shipRequestedQuantity: any,
        shipRequestedQuantityByWarehouses: any[],
    },
};

//
// Component
//
const StockInventoryPane = (props: Props): Node => {
    //
    // Hooks
    //
    const classes = useStyles();

    // End Hooks

    return (
        <Table 
            className={classes.inventoryTable}
            size='small' 
        >
            <TableBody>
                {[
                    {
                        'lbl': 'Налични',
                        'skuQuantity': (props.skuInventory && props.skuInventory.inventoryQuantity) ? 
                                props.skuInventory.inventoryQuantity : {},
                        'skuQuantityByWarehouses': (props.skuInventory && props.skuInventory.inventoryQuantityByWarehouses) ? 
                                props.skuInventory.inventoryQuantityByWarehouses : [],
                    },
                    {
                        'lbl': 'Резервирани',
                        'skuQuantity': (props.skuInventory && props.skuInventory.reservedQuantity) ? 
                                props.skuInventory.reservedQuantity : {},
                        'skuQuantityByWarehouses': (props.skuInventory && props.skuInventory.reservedQuantityByWarehouses) ? 
                                props.skuInventory.reservedQuantityByWarehouses : [],
                    },
                    {
                        'lbl': 'Заявени за експедиция',
                        'skuQuantity': (props.skuInventory && props.skuInventory.shipRequestedQuantity) ? 
                                props.skuInventory.shipRequestedQuantity : {},
                        'skuQuantityByWarehouses': (props.skuInventory && props.skuInventory.shipRequestedQuantityByWarehouses) ? 
                                props.skuInventory.shipRequestedQuantityByWarehouses : [],
                    },
                    {
                        'lbl': 'Заявени за заприхождаване',
                        'skuQuantity': (props.skuInventory && props.skuInventory.storeRequestedQuantity) ? 
                                props.skuInventory.storeRequestedQuantity : {},
                        'skuQuantityByWarehouses': (props.skuInventory && props.skuInventory.storeRequestedQuantityByWarehouses) ? 
                                props.skuInventory.storeRequestedQuantityByWarehouses : [],
                    }
                ].map((row, index, array) => (
                    <StockInventoryRow
                        key={index}
                        rowClassName={(index === array.length - 1) ? 
                            classes.tableRowWithoutBottomBorder : null}
                        label={row.lbl}
                        stock={props.stock}
                        skuQuantity={row.skuQuantity}
                        skuQuantityByWarehouses={row.skuQuantityByWarehouses}
                    />
                ))}
            </TableBody>
        </Table>
    );
}
export default StockInventoryPane;
