// @flow

//
// React
//
import React from 'react';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../../../../../lib/trix-web-components-table';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

import {
    createQuantityQuantityCellRenderer,
} from '../../../../../lib/trix-web-components-table';

//
// Component
//
const createAssemblyItemQuantityCellRenderer = (cfg: CellRendererFactoryProps): any => (props: CellProps): any => {
    // const value = (props.item && props.item.quantity) ? props.item.quantity : {};
    // const secondaryValue = (props.item && props.item.attachedQuantity) ? props.item.attachedQuantity : {};

    // return (
    //     <QuantityQuantityCellRenderer
    //         {...props}

    //         value={value}
    //         secondaryValue={secondaryValue}
    //     />
    // );
    return null;
};
export default createAssemblyItemQuantityCellRenderer;
