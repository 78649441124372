// @flow

//
// React
//
import React, { type Node } from 'react';

import {
    createItemSubItemCellRenderer,
} from '../../../trix-web-components-table';

const createProseTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: true,
        width: 200,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            getItem: (props: any) => {
                return (props.value && props.value.code) ? 
                        props.value.code : '';
            },

            getSubItem: (props: any) => {
                if (props.value && props.value.name) {
                    return props.value.name.bg;
                } else {
                    return (<i>Not defined</i>);
                }
            }
        })
    }
}
export default createProseTableColumn;