//
// Sanctuary
//
import S from './../trix-fp-fantasy';

//
// React
//
import React from 'react';

//
// Redux
//
import { combineReducers } from 'redux';

//
// Redux Concatenate Reducers
//
import concatenateReducers from 'redux-concatenate-reducers';

/**
 * Kernel
 * TODO: remove class !!!!!!!
 */
class Kernel {
    /**
     * Constructor
     */
    constructor() {
        this.shell = null;
        this.plugins = [];
        this.libs = [];
        this.menus = {};
    }

    /**
     * Register shell
     * 
     * @param shell
     */
    registerShell(shell) {
        // store sell
        this.shell = shell;
    }

    /**
     * Register plugin
     * 
     * @param plugin 
     */
    registerPlugin(plugin) {
        this.plugins.push(plugin);
    }

    /**
     * Register lib
     * 
     * @param lib 
     */
    registerLib(lib) {
        this.libs.push(lib);
    }

    registerMenus(menus) {
        this.menus = menus;
    }

    ///////////////////////////////////////////////////////////////////////////
    // State
    ///////////////////////////////////////////////////////////////////////////

    /**
     * Combine reducers published by the {@link Shell} and all {@link Plugins}
     * and configures Store.
     * TODO: refector. Use Sanctuary types to shape kernel, shell and plugins.
     */
     // TODO: remove from here !!! - this should be put into the main project !!!
    // configureStore(history, middlewares) {
    //     let reducersDef = {};
    //     if (this.shell) {
    //         reducersDef = S.concat(reducersDef)(this.shell.state.reducer());
    //     }
    //     if (this.plugins) {
    //         this.plugins.forEach(p => {
    //             reducersDef = S.concat(reducersDef)(p.state.reducer());
    //         });
    //     }

    //     const reducer = combineReducers(reducersDef);

    //     return configureStore(history, reducer, middlewares);
    // }

    ///////////////////////////////////////////////////////////////////////////
    // Web
    ///////////////////////////////////////////////////////////////////////////

    /**
     * Search all plugins for components with specified name
     * and concatenates all found components into a new container 
     * component
     */
    // combineComponents(componentName) {
    //     const components = [];
    //     // p.web.components
    //     if (this.plugins) {
    //         this.plugins.forEach(p => {
    //             if (p.web.components[componentName]) {
    //                 components.push(p.web.components[componentName]);
    //             }
    //             // p.web.components
    //             // reducersDef = S.concat(reducersDef)(p.state.reducer());
    //         });
    //     }

    //     return (
    //         <>
    //             {
    //                 // compose index fragments
    //                 components.map((c, i) => {
    //                     return (c) ? React.createElement(c, {
    //                         key: `PluginComponent-${i}`,
    //                     }) : null;
    //                 })
    //             }
    //         </>
    //     );
    //     // return null;
    // }

    /**
     * Concatenate reducers
     */
    reducer(pluginsReducers, otherReducers) {
        return concatenateReducers([
            // libs reducers
            ...(this.libs || []).map(l => l.state.reducer),

            // plugins reducers
            ...(pluginsReducers ? pluginsReducers : []),

            // TODO: drop in v6.1
            // plugins reducers
            // ...(this.plugins || []).map(p => p.state.reducer),

            // shell reducer
            this.shell.state.reducer,

            // other third party reducers
            ...(otherReducers ? [ otherReducers ] : []),
        ]);
    }

    /**
     * Concatenate operations published by the {@link Shell} and all {@link Plugins}
     */
    operations(pluginsOperations) {
        const operations = [];

        // add shell operations
        operations.push(...this.shell.state.operations);

        if (pluginsOperations) {
            operations.push(...pluginsOperations);
        }

        // add plugins operations
        this.plugins.forEach(p => {
            if (p.state.operations) {
                operations.push(...p.state.operations);
            }
        });

        return operations;
    }

    selectors() {
        let selectors = {};
        this.plugins.forEach(p => {
            if (!p.state.selectors) {
                return;
            }
            selectors = S.concat(selectors)(p.state.selectors);
        });
        return selectors;
    }

    actions() {
        let actions = {};
        this.plugins.forEach(p => {
            if (!p.state.actions) {
                return;
            }
            actions = S.concat(actions)(p.state.actions);
        });
        return actions;
    }

    indexRoute(halFormsRoutes) {
        return (
            <>
                {
                    (this.shell && this.shell.web && this.shell.web.indexRoute) ? 
                        React.createElement(this.shell.web.indexRoute, {
                            key: `ShellIndex`,
                        }) : null
                }
                {
                    // compose index fragments
                    this.plugins.map((p, i) => {
                        return (p.web.indexRoute) ? React.createElement(p.web.indexRoute, {
                            key: `PluginComponent-${i}`,
                        }) : null;
                    })
                }
                {
                    halFormsRoutes
                }
            </>
        );
    }

    components() {
        let components = {};
        this.plugins.forEach(p => {
            if (!p.web.components) {
                return;
            }
            components = {
                ...components,
                ...p.web.components
            };
        });

        return components;
    }

    enhancers() {
        let enhancers = {};
        this.plugins.forEach(p => {
            if (!p.web.enhancers) {
                return;
            }
            enhancers = S.concat(enhancers)(p.web.enhancers);
        });
        return enhancers;
    }

    resolvers() {
        let resolvers = [];
        this.plugins.forEach(p => {
            if (!p.resolvers) {
                return;
            }
            resolvers = [...resolvers, ...p.resolvers];
        });
        return resolvers;
    }

};
export default Kernel;
