// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

//
// Props
//
type Props = {};

//
// Component
//
const Copyright = (props: Props): any => {
    return (
        <Typography variant='body2' color='textSecondary' align='center'>
            {'Copyright © '}
            <Link color='inherit' href='https://threeix.com/'>
                3ix
            </Link>{' '}
            {new Date().getFullYear()} (Версия 6.0)
        </Typography>
    );
};
export default Copyright;
