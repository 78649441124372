// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Image from 'mui-image';

//
// Components
//
import StockPropertiesPane from '../pane/StockPropertiesPane';
import StockInventoryPane from '../pane/StockInventoryPane';

//
// Component
//
const createStockDataAndInventoryCellRenderer = (cfg: any): any => (props: any): any => {
    const row = (props.row) ? props.row : {};

    const stock = (row && row.stock) ? row.stock : {}
    const sku = (stock.sku) ? stock.sku : '';
    const name = (stock.name && stock.name.bg) ? stock.name.bg : '';

    // PATCH: 
    const imageUrl = `http://79.124.17.213/rp/${sku.substring(0, sku.length - 2)}_1.jpg`;
    // const imageUrl = 'https://img.tendenz.bg/2d0f648eb7b42b1bc5e429268d58b9dd0137269.jpg';

    return(
        <Grid container direction='row'>
            <Grid 
                item
                container
                style={{ justifyContent: 'center', alignItems: 'center' }}
                xs={12} sm={4} md={4} lg={4} xl={4}
            >
                <div style={{ width: '150px', height: '150px' }}>
                    <Image
                        src={imageUrl}
                        // onClick={() => console.log('onClick')}
                        // aspectRatio={(16/9)}
                        // disableSpinner
                    />
                </div>
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid item container direction='row'>
                    <Grid item>
                        <Typography variant='subtitle2' display='block'>
                            {sku}
                        </Typography>
                        <Typography variant='caption' display='block'>
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
                
                <Grid 
                    item 
                    container 
                    spacing={2}
                    direction='column'
                >
                    <Grid item>
                        <StockInventoryPane
                            stock={stock}
                            skuInventory={{
                                storeRequestedQuantity: row.storeRequestedQuantity,
                                storeRequestedQuantityByWarehouses: row.storeRequestedQuantityByWarehouses,

                                inventoryQuantity: row.inventoryQuantity,
                                inventoryQuantityByWarehouses: row.inventoryQuantityByWarehouses,

                                reservedQuantity: row.reservedQuantity,
                                reservedQuantityByWarehouses: row.reservedQuantityByWarehouses,

                                shipRequestedQuantity: row.shipRequestedQuantity,
                                shipRequestedQuantityByWarehouses: row.shipRequestedQuantityByWarehouses,
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <StockPropertiesPane
                            stock={stock}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const createStockDataAndInventoryTableColumn = (cfg: any): any => { 
    return {
        flex: true,
        width: 200,

        renderCell: createStockDataAndInventoryCellRenderer({
            ...cfg.cellRendererFactoryProps,
        })
    }
};
export default createStockDataAndInventoryTableColumn;
