// @flow

//
// React
//
import React, { useLayoutEffect, useMemo, type Node } from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Grid';

//
// Props
//
import { type HalFormsContainerRowProps } from './HalFormsContainerRowProps';

//
// Component
//
const HalFormsContainerRowPlain = (props: HalFormsContainerRowProps): Node => {
    let itemSx = {
        width: '100%'
    };
    if (!props.isLast) {
        itemSx = {
            ...itemSx,
            paddingBottom: (theme: any) => theme.spacing(2)
        }
    }

    return (
        <Grid
            item
            sx={itemSx}
        >
            {props.children}
        </Grid>
    );
}
export default HalFormsContainerRowPlain;