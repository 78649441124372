// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Formatter
//
import { 
    fmtQuantity,
} from '../../../../lib/trix-web-data-commons';

//
// Tools
//
const formatSkuQuantity = (skuQuantity: any) => {
    if (skuQuantity.bulkQuantity && skuQuantity.bulkQuantity.amount > 0) {
        return `${fmtQuantity(skuQuantity.quantity)} (${fmtQuantity(skuQuantity.bulkQuantity)})`;
    }

    return fmtQuantity(skuQuantity.quantity);
}


//
// Components
//
const StockInventoryRow = (props: {
    rowClassName: any,
    label: string,
    stock: any,
    skuQuantity: any,
}) => {
    //
    // Hooks
    //
    const classes = useStyles();

    return (
        <React.Fragment>
            <TableRow 
                className={props.rowClassName}
            >
                <TableCell
                    component='th'
                    size='small'
                    className={classes.inventoryText}
                >
                    {props.label}
                </TableCell>
                <TableCell 
                    size='small'
                    align='right'
                    className={clsx(classes.inventoryText, classes.inventoryDataValue)}
                >
                    <div
                        className={clsx(classes.link)}
                    >
                        {
                            // $FlowFixMe
                            formatSkuQuantity(props.skuQuantity)
                        }
                    </div>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

//
// Styles
//
const useStyles = makeStyles(theme => ({
    inventoryTable: {
    },
    tableRowWithoutBottomBorder: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    inventoryText: {
        fontSize: '.625rem',
    },
    inventoryDataValue: {
        whiteSpace: 'nowrap',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Component
//
const createStockInventoryCellRenderer = (cfg: any): any => (props: any): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    return(
        <Table 
            className={classes.inventoryTable}
            size='small' 
        >
            <TableBody>
                {[
                    {
                        'lbl': 'Налични',
                        'skuQuantity': (props.value && props.value.inventoryQuantity) ? 
                                props.value.inventoryQuantity : {},
                    },
                    {
                        'lbl': 'Резервирани',
                        'skuQuantity': (props.skuInventory && props.skuInventory.reservedQuantity) ? 
                                props.skuInventory.reservedQuantity : {},
                    },
                    {
                        'lbl': 'Заявени за експедиция',
                        'skuQuantity': (props.skuInventory && props.skuInventory.shipRequestedQuantity) ? 
                                props.skuInventory.shipRequestedQuantity : {},
                    },
                    {
                        'lbl': 'Заявени за заприхождаване',
                        'skuQuantity': (props.skuInventory && props.skuInventory.storeRequestedQuantity) ? 
                                props.skuInventory.storeRequestedQuantity : {},
                    }
                ].map((row, index, array) => (
                    <StockInventoryRow
                        key={index}
                        rowClassName={(index === array.length - 1) ? 
                            classes.tableRowWithoutBottomBorder : null}
                        label={row.lbl}
                        stock={props.stock}
                        skuQuantity={row.skuQuantity}
                    />
                ))}
            </TableBody>
        </Table>
    );
}

const createStockInventoryTableColumn = (cfg: any): any => { 
    return {
        flex: true,
        width: 200,

        renderCell: createStockInventoryCellRenderer({
            ...cfg.cellRendererFactoryProps,
        })
    }
};
export default createStockInventoryTableColumn;
