// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../lib/trix-fp-fantasy';

//
// React
//
import { combineReducers } from 'redux';
import concatenateReducers from 'redux-concatenate-reducers';

//
// Redux Fantasy Reducers
//
import {
    compose,
    instances,
    node,
} from '../../../lib/redux-fantasy-reducers';

import {
    createOperationReducer,
    createOperationSelectors,
    createHttpRequestReducer,
    createHttpRequestSelectors,
    createDataListReducer,
    createDataListSelectors,
    createDataRecordReducer,
    createDataRecordSelectors,
    createComponentTableReducer,
    createComponentTableSelectors,

    reduceAction,
    reduceDataListSet,
    reduceDataListItemAdded,
    reduceDataListItemChanged,
    reduceDataListItemMerged,
    reduceDataListItemDeleted,

    reduceComponentTableInitialized,
    reduceComponentTableFilterChanged,
    reduceComponentTablePageNumberChanged,
    reduceComponentTableRowsPerPageChanged,
    reduceComponentTableColumnStateChanged,
    reduceComponentTableSelectedItemsChanged,
    reduceComponentTableItemsSelectionCleared,

    reduceDataRecordSet,
    reduceDataRecordMerge,
    reduceDataRecordChanged,
    reduceDataRecordFlush,
} from '../../../lib/trix-web-data-reducers';

// createReducer :: StrMap a -> Array t -> r
export const createReducer = (actionsTypes: any) => (templates: any) => {
    // flatten all hal forms actions effects by data source. 
    // we'll need them when builiding post execution effect sub-reducers
    const actionsPostExecutionEffectsByDataSource = buildActionsPostExecutionEffectsByDataSource ({
        containerDataSourceId: '',
        dataSourceId: '',
    }) (templates);

    // group templates by plugin:page
    const mergedReducers = S.reduce (xs => x => {
        if (x.type !== 'page') {
            return xs;
        }

        const key = `${x.plugin}__${x.name}`;
        const reducerSection = createReducerSection (actionsTypes, actionsPostExecutionEffectsByDataSource) (x);
        if ((S.keys (reducerSection)).length > 0) {
            xs = S.insert (key) (instances(node(combineReducers(reducerSection)))) (xs);
        } else {
            // set empty reducer
            xs = S.insert (key) (instances(node(combineReducers({
                noop: (state = {}, action: any) => state
            })))) (xs);
        }
        return (xs);
    }) ({}) (templates);

    return compose({
        system: compose(mergedReducers),
    }) ({ reducerKey: '' });
}

// buildActionsPostEffectsByDataSource :: Array t -> Strmap a
export const buildActionsPostExecutionEffectsByDataSource = (context: {
    containerDataSourceId: string,
    dataSourceId: string,
}) => 
    S.reduce (xs => x => {
        // let r = xs;
        let r = {
            ...xs
        };

        let innerContext = context;
        if (x.dataSource && x.dataSource.id != innerContext.dataSourceId) {
            innerContext = {
                containerDataSourceId: context.dataSourceId,
                dataSourceId: x.dataSource.id
            };
        }

        // drill down the components, that have action to be collected
        if (x.components) {
            const subPostExecutionEffectsByDataSource = 
                    buildActionsPostExecutionEffectsByDataSource (innerContext) (x.components);

            // merge sub actions post execution effects
            const dataSourceIds = Object.keys(subPostExecutionEffectsByDataSource);
            for (let i=0; i<dataSourceIds.length; i++) {
                const dataSourceId = dataSourceIds[i];
                if (!r[dataSourceId]) {
                    r[dataSourceId] = [];
                }
                r[dataSourceId] = S.concat (r[dataSourceId]) (subPostExecutionEffectsByDataSource[dataSourceId]);
            };
        }

        // collect actions (if any)
        if (x.actions) {
            for (let i=0; i<x.actions.length; i++) {
                // drill down into some special actions
                if (x.actions[i].type === 'modal') {
                    const subPostExecutionEffectsByDataSource = 
                            buildActionsPostExecutionEffectsByDataSource (innerContext) ([x.actions[i].component]);
                    // merge sub actions post execution effects
                    const dataSourceIds = Object.keys(subPostExecutionEffectsByDataSource);
                    for (let i=0; i<dataSourceIds.length; i++) {
                        const dataSourceId = dataSourceIds[i];
                        if (!r[dataSourceId]) {
                            r[dataSourceId] = [];
                        }
                        r[dataSourceId] = S.concat 
                                (r[dataSourceId]) 
                                (subPostExecutionEffectsByDataSource[dataSourceId]);
                    };      
                }

                const postExecutionEffects = (x.actions[i].postExecutionEffects || []);
                for (let j=0; j<postExecutionEffects.length; j++) {
                    let dataSourceId = postExecutionEffects[j].dataSourceId;
                    if (dataSourceId === '__CONTAINER_DATA_SOURCE__') {
                        dataSourceId = context.containerDataSourceId;
                        if (!dataSourceId) {
                            console.error('Can not find container data source in post execution effect');
                        }
                    }

                    if (!r[dataSourceId]) {
                        r[dataSourceId] = [];
                    }
                    r[dataSourceId] = S.append ({
                        id: x.actions[i].id,
                        ...postExecutionEffects[j],
                    }) (r[dataSourceId]);
                };
            }
        }

        return r;
    }) ({});

// createReducerSection :: StrMap a, StrMap actionsPostExecutionEffectsByDataSource -> Object t -> StrMap r
export const createReducerSection = (actionsTypes: any, actionsPostExecutionEffectsByDataSource: any[]) => (t: any) => {
    let reducerSection = {};

    // create appropriate reducer for the template type
    if (t.type === 'container') {
        reducerSection = S.concat (reducerSection) (createContainerReducer (actionsTypes, actionsPostExecutionEffectsByDataSource) (t));
    }
    if (t.type === 'table') {
        reducerSection = S.concat (reducerSection) (createTableReducer (actionsTypes, actionsPostExecutionEffectsByDataSource) (t));
    }
    if (t.type === 'timeline') {
        reducerSection = S.concat (reducerSection) (createTimelineReducer (actionsTypes, actionsPostExecutionEffectsByDataSource) (t));
    }

    if (t.type === 'reportingTable') {
        reducerSection = S.concat (reducerSection) (createReportingReducer (actionsTypes, actionsPostExecutionEffectsByDataSource) (t));
    }

    // drill down components, that needs reducer sections to be created 
    if (t.components) {
        const subReducers = S.reduce(srs => c => {
            const reducerSection = createReducerSection (actionsTypes, actionsPostExecutionEffectsByDataSource) (c);
            if ((S.keys (reducerSection)).length > 0) {
                return S.concat (srs) (reducerSection);
            }
            return srs;
        }) ({}) (t.components);
        reducerSection = S.concat (reducerSection) (subReducers);
    }

    // drill down into actions
    if (t.actions) {
        for (let i=0; i<t.actions.length; i++) {
            const a = t.actions[i];
                
            if (a.type === 'modal' && a.component) {
                const subReducersSection = createReducerSection (actionsTypes, actionsPostExecutionEffectsByDataSource) (a.component);
                if ((S.keys (subReducersSection)).length > 0) {
                    reducerSection = S.concat (reducerSection) (subReducersSection);
                }
            }

            if (a.type === 'httpPost') {
                reducerSection = S.concat (reducerSection) (createHttpOperationReducer (actionsTypes[a.id]) (a));
            }
        }
    }

    return (reducerSection);
}

// createContainerReducer :: StrMap a, StrMap actionsPostExecutionEffectsByDataSource -> Object t -> StrMap r
const createContainerReducer = (actionsTypes: any, actionsPostExecutionEffectsByDataSource: any[]) => (t: any) => {
    let dataReducers = [];
    let operationsReducer = {};
    let httpRequestReducer = null;

    const containerActionsTypes = actionsTypes[t.id];

    // create operations reducers
    if (containerActionsTypes.operations) {
        if (containerActionsTypes.operations.load) {
            operationsReducer = S.concat (operationsReducer) ({
                load: createOperationReducer ({
                    operationActionsTypes: containerActionsTypes.operations.load,
                }),
            });
            dataReducers = S.concat (dataReducers) ([
                reduceAction(
                    containerActionsTypes.operations.load.OPERATION_EXECUTED_SUCCESS,
                    reduceDataRecordSet()
                ),
            ]);

            httpRequestReducer = createHttpRequestReducer ({
                operationActionsTypes: containerActionsTypes.operations.load,
            });
        }
    }

    if (containerActionsTypes.data) {
        dataReducers = S.concat (dataReducers) ([
                reduceAction(
                    containerActionsTypes.data.DATA_SET,
                    reduceDataRecordSet()
                ),
                reduceAction(
                    containerActionsTypes.data.DATA_CHANGED,
                    reduceDataRecordChanged()
                ),
            ]);
    }

    // find out all actions, that proves post execution effect to this data source.
    // create appropriate reducers, to apply post execution effect on the data
    // in the store for this data source
    const dataSourceId = (t.dataSource) ? t.dataSource.id : undefined;
    if (dataSourceId) {
        const actionsToReduce = findActionsEffectsToReduce (dataSourceId) (actionsPostExecutionEffectsByDataSource);
        for (let i=0; i<actionsToReduce.length; i++) {
            if (actionsToReduce[i].operation === 'update') {
                const sourcePath = (actionsToReduce[i].sourcePath) ? actionsToReduce[i].sourcePath : 
                        (actionsToReduce[i].path) ? actionsToReduce[i].path : undefined;
                dataReducers = S.append (
                    reduceAction(
                        actionsTypes[actionsToReduce[i].id].OPERATION_EXECUTED_SUCCESS,
                        reduceDataRecordSet(sourcePath)
                    )
                ) (dataReducers);
            }
            if (actionsToReduce[i].operation === 'merge') {
                const sourcePath = (actionsToReduce[i].sourcePath) ? actionsToReduce[i].sourcePath : 
                        (actionsToReduce[i].path) ? actionsToReduce[i].path : undefined;
                const destinationPath = actionsToReduce[i].destinationPath;
                dataReducers = S.append (
                    reduceAction(
                        actionsTypes[actionsToReduce[i].id].OPERATION_EXECUTED_SUCCESS,
                        reduceDataRecordMerge(sourcePath, destinationPath)
                    )
                ) (dataReducers);
            }
        }
    }

    // merge all reducers
    let reducers = {};
    if (dataReducers.length > 0) {
        reducers = S.concat (reducers) ({
            data: createDataRecordReducer({
                initialData: {
                },
                reduce: concatenateReducers(dataReducers)
            }),
        });
    }
    if ((S.keys (operationsReducer)).length > 0) {
        reducers = S.concat (reducers) ({
            operations: combineReducers(operationsReducer),
        });
    }
    if (httpRequestReducer) {
        reducers = S.concat (reducers) ({
            httpRequest: httpRequestReducer,
        });
    }

    if ((S.keys (reducers)).length > 0) {
        return {
            [t.sid]: combineReducers(reducers)
        };
    } else {
        return {};
    }

}
  
// createTableReducer :: StrMap a, StrMap actionsPostExecutionEffectsByDataSource -> Object t -> StrMap r
const createTableReducer = (actionsTypes: any, actionsPostExecutionEffectsByDataSource: any[]) => (t: any) => {
    let dataReducers = [];
    let componentReducers = [];
    let operationsReducer = {};
    let httpRequestReducer = null;

    const tableActionsTypes = actionsTypes[t.id];

    // component reducers
    componentReducers = S.concat (componentReducers) ([
        reduceAction(
            tableActionsTypes.component.TABLE_INITIALIZED,
            reduceComponentTableInitialized,
        ),
        reduceAction(
            tableActionsTypes.component.FILTER_CHANGED,
            reduceComponentTableFilterChanged,
        ),
        reduceAction(
            tableActionsTypes.component.COLUMN_STATE_CHANGED,
            reduceComponentTableColumnStateChanged,
        ),
        reduceAction(
            tableActionsTypes.component.PAGE_NUMBER_CHANGED,
            reduceComponentTablePageNumberChanged,
        ),
        reduceAction(
            tableActionsTypes.component.ROWS_PER_PAGE_CHANGED,
            reduceComponentTableRowsPerPageChanged,
        ),
        reduceAction(
            tableActionsTypes.component.SELECTED_ITEMS_CHANGED,
            reduceComponentTableSelectedItemsChanged
        )
    ]);

    // create operations reducers
    if (tableActionsTypes.operations) {
        if (tableActionsTypes.operations.load) {
            operationsReducer = S.concat (operationsReducer) ({
                load: createOperationReducer ({
                    operationActionsTypes: tableActionsTypes.operations.load,
                }),
            });
            dataReducers = S.concat (dataReducers) ([
                reduceAction(
                    tableActionsTypes.operations.load.OPERATION_EXECUTED_SUCCESS,
                    reduceDataListSet()
                ),
            ]);

            httpRequestReducer = createHttpRequestReducer ({
                operationActionsTypes: tableActionsTypes.operations.load,
            });
        }
    }

    // find out all actions, that proves post execution effect to this data source.
    // create appropriate reducers, to apply post execution effect on the data
    // in the store for this data source
    const dataSourceId = (t.dataSource) ? t.dataSource.id : undefined;
    if (dataSourceId) {
        const actionsToReduce = findActionsEffectsToReduce (dataSourceId) (actionsPostExecutionEffectsByDataSource);
        for (let i=0; i<actionsToReduce.length; i++) {
            if (actionsToReduce[i].operation === 'add') {
                const sourcePath = (actionsToReduce[i].sourcePath) ? actionsToReduce[i].sourcePath : 
                        (actionsToReduce[i].path) ? actionsToReduce[i].path : undefined;
                dataReducers = S.append (
                    reduceAction(
                        actionsTypes[actionsToReduce[i].id].OPERATION_EXECUTED_SUCCESS,
                        reduceDataListItemAdded(sourcePath)
                    )
                ) (dataReducers);
            }
            if (actionsToReduce[i].operation === 'update') {
                const sourcePath = (actionsToReduce[i].sourcePath) ? actionsToReduce[i].sourcePath : 
                        (actionsToReduce[i].path) ? actionsToReduce[i].path : undefined;
                dataReducers = S.append (
                    reduceAction(
                        actionsTypes[actionsToReduce[i].id].OPERATION_EXECUTED_SUCCESS,
                        reduceDataListItemChanged(sourcePath)
                    )
                ) (dataReducers);
            }
            if (actionsToReduce[i].operation === 'merge') {
                const sourcePath = (actionsToReduce[i].sourcePath) ? actionsToReduce[i].sourcePath : 
                        (actionsToReduce[i].path) ? actionsToReduce[i].path : undefined;
                dataReducers = S.append (
                    reduceAction(
                        actionsTypes[actionsToReduce[i].id].OPERATION_EXECUTED_SUCCESS,
                        reduceDataListItemMerged(sourcePath)
                    )
                ) (dataReducers);
            }
            if (actionsToReduce[i].operation === 'delete') {
                dataReducers = S.append (
                    reduceAction(
                        actionsTypes[actionsToReduce[i].id].OPERATION_EXECUTED_SUCCESS,
                        reduceDataListItemDeleted()
                    )
                ) (dataReducers);
            }
        }
    }

    let reducers = {};
    if (dataReducers && dataReducers.length > 0) {
        reducers = {
            ...reducers,
            data: createDataListReducer({
                reduce: concatenateReducers(dataReducers)
            })
        }
    }
    if (componentReducers && componentReducers.length > 0) {
        reducers = {
            ...reducers,
            component: createComponentTableReducer({
                reduce: concatenateReducers(componentReducers)
            }),
        }
    }
    if (operationsReducer && Object.keys(operationsReducer).length > 0) {
        reducers = {
            ...reducers,
            operations: combineReducers(operationsReducer),
        }
    }
    if (httpRequestReducer) {
        reducers = S.concat (reducers) ({
            httpRequest: httpRequestReducer,
        });
    }

    if (Object.keys(reducers).length > 0) {
        return {
            [t.sid]: combineReducers(reducers)
        }
    } else {
        return {}
    }
}

// createTimelineReducer :: StrMap a, StrMap actionsPostExecutionEffectsByDataSource -> Object t -> StrMap r
const createTimelineReducer = (actionsTypes: any, actionsPostExecutionEffectsByDataSource: any[]) => (t: any) => {
    let dataReducers = [];
    let operationsReducer = {};
    let httpRequestReducer = null;

    const tableActionsTypes = actionsTypes[t.id];

    // create operations reducers
    if (tableActionsTypes.operations) {
        if (tableActionsTypes.operations.load) {
            operationsReducer = S.concat (operationsReducer) ({
                load: createOperationReducer ({
                    operationActionsTypes: tableActionsTypes.operations.load,
                }),
            });
            dataReducers = S.concat (dataReducers) ([
                reduceAction(
                    tableActionsTypes.operations.load.OPERATION_EXECUTED_SUCCESS,
                    reduceDataListSet()
                ),
            ]);

            httpRequestReducer = createHttpRequestReducer ({
                operationActionsTypes: tableActionsTypes.operations.load,
            });
        }
    }

    // find out all actions, that proves post execution effect to this data source.
    // create appropriate reducers, to apply post execution effect on the data
    // in the store for this data source
    const dataSourceId = (t.dataSource) ? t.dataSource.id : undefined;
    if (dataSourceId) {
        const actionsToReduce = findActionsEffectsToReduce (dataSourceId) (actionsPostExecutionEffectsByDataSource);
        for (let i=0; i<actionsToReduce.length; i++) {
            if (actionsToReduce[i].operation === 'add') {
                dataReducers = S.append (
                    reduceAction(
                        actionsTypes[actionsToReduce[i].id].OPERATION_EXECUTED_SUCCESS,
                        reduceDataListItemAdded()
                    )
                ) (dataReducers);
            }
            if (actionsToReduce[i].operation === 'update') {
                dataReducers = S.append (
                    reduceAction(
                        actionsTypes[actionsToReduce[i].id].OPERATION_EXECUTED_SUCCESS,
                        reduceDataListItemChanged()
                    )
                ) (dataReducers);
            }
            if (actionsToReduce[i].operation === 'delete') {
                dataReducers = S.append (
                    reduceAction(
                        actionsTypes[actionsToReduce[i].id].OPERATION_EXECUTED_SUCCESS,
                        reduceDataListItemDeleted()
                    )
                ) (dataReducers);
            }
        }
    }

    let reducers = {};
    if (dataReducers && dataReducers.length > 0) {
        reducers = {
            ...reducers,
            data: createDataListReducer({
                reduce: concatenateReducers(dataReducers)
            })
        }
    }
    if (operationsReducer && Object.keys(operationsReducer).length > 0) {
        reducers = {
            ...reducers,
            operations: combineReducers(operationsReducer),
        }
    }
    if (httpRequestReducer) {
        reducers = S.concat (reducers) ({
            httpRequest: httpRequestReducer,
        });
    }

    if (Object.keys(reducers).length > 0) {
        return {
            [t.sid]: combineReducers(reducers)
        }
    } else {
        return {}
    }
}

// createReportingReducer :: StrMap a, StrMap actionsPostExecutionEffectsByDataSource -> Object t -> StrMap r
const createReportingReducer = (actionsTypes: any, actionsPostExecutionEffectsByDataSource: any[]) => (t: any) => {
    let dataReducers = [];
    let componentReducers = [];
    let operationsReducer = {};
    let httpRequestReducer = null;

    const tableActionsTypes = actionsTypes[t.id];

    // component reducers
    componentReducers = S.concat (componentReducers) ([
        reduceAction(
            tableActionsTypes.component.TABLE_INITIALIZED,
            reduceComponentTableInitialized,
        ),
        reduceAction(
            tableActionsTypes.component.FILTER_CHANGED,
            reduceComponentTableFilterChanged,
        ),
        reduceAction(
            tableActionsTypes.component.COLUMN_STATE_CHANGED,
            reduceComponentTableColumnStateChanged,
        ),
        reduceAction(
            tableActionsTypes.component.PAGE_NUMBER_CHANGED,
            reduceComponentTablePageNumberChanged,
        ),
        reduceAction(
            tableActionsTypes.component.ROWS_PER_PAGE_CHANGED,
            reduceComponentTableRowsPerPageChanged,
        ),
    ]);

    // create operations reducers
    if (tableActionsTypes.operations) {
        if (tableActionsTypes.operations.load) {
            operationsReducer = S.concat (operationsReducer) ({
                load: createOperationReducer ({
                    operationActionsTypes: tableActionsTypes.operations.load,
                }),
            });
            dataReducers = S.concat (dataReducers) ([
                reduceAction(
                    tableActionsTypes.operations.load.OPERATION_EXECUTED_SUCCESS,
                    reduceDataListSet()
                ),
            ]);

            httpRequestReducer = createHttpRequestReducer ({
                operationActionsTypes: tableActionsTypes.operations.load,
            });
        }
    }

    let reducers = {};
    if (dataReducers && dataReducers.length > 0) {
        reducers = {
            ...reducers,
            data: createDataListReducer({
                reduce: concatenateReducers(dataReducers)
            })
        }
    }
    if (componentReducers && componentReducers.length > 0) {
        reducers = {
            ...reducers,
            component: createComponentTableReducer({
                reduce: concatenateReducers(componentReducers)
            }),
        }
    }
    if (operationsReducer && Object.keys(operationsReducer).length > 0) {
        reducers = {
            ...reducers,
            operations: combineReducers(operationsReducer),
        }
    }
    if (httpRequestReducer) {
        reducers = S.concat (reducers) ({
            httpRequest: httpRequestReducer,
        });
    }

    if (Object.keys(reducers).length > 0) {
        return {
            [t.sid]: combineReducers(reducers)
        }
    } else {
        return {}
    }
}

// createHttpOperationReducer :: StrMap a -> Object t -> StrMap r
const createHttpOperationReducer = (actionsTypes: any) => (t: any) => {
    const reducers = createOperationReducer ({
        operationActionsTypes: actionsTypes,
    });
    return {
        [t.sid]: reducers,
    };
}

//
// helper functions
//

// findActionsEffectsToReduce :: String dataSourceId -> StrMap actionsPostExecutionEffectsByDataSource -> Array a
const findActionsEffectsToReduce = (dataSourceId: string) => (actionsPostExecutionEffectsByDataSource: any) => {
    let actionsEffects = (actionsPostExecutionEffectsByDataSource[dataSourceId]) ? 
            actionsPostExecutionEffectsByDataSource[dataSourceId] : [];
    return actionsEffects;
}