// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';

import { makeStyles } from '@mui/styles';

//
// Component
//
import {
    TextFloatPaneField,
} from '../../../trix-web-components-pane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // Main
    //
    label: string,

    //
    // Data
    //
    data: any,
}; // End of Props

//
// Component
//
const CustomerFloatPaneField = (props: Props): any => {
    const clases = useStyles();

    return(
        <Grid 
            container
            direction='column'
        >
            <Grid
                item
                container
                direction='row'
            >
                <TextFloatPaneField
                    label='Име'
                    value={(props.data && props.data.name) ? props.data.name : ''}
                />
                <TextFloatPaneField
                    label='Телефон'
                    value={(props.data && props.data.phone) ? props.data.phone : ''}
                />
                <TextFloatPaneField
                    label='Емайл'
                    value={(props.data && props.data.email) ? props.data.email : ''}
                />
            </Grid>
        </Grid>
    );
};
export default CustomerFloatPaneField;
