// @flow

import createPickingLineQuantityAndPickedQuantityTableColumn from './components/table/PickingLineQuantityAndPickedQuantityTableColumn';
import createSaleOrderLineQuantitiesTableColumn from './components/table/SaleOrderLineQuantitiesTableColumn';
import createSaleOrderLineQuantityAndSoldQuantityTableColumn from './components/table/SaleOrderLineQuantityAndSoldQuantityTableColumn';

import SaleOrderLineDiscountCellRenderer from './components/table/SaleOrderLineDiscountCellRenderer';
import SaleOrderLinesTableRowExtension from './components/table/SaleOrderLinesTableRowExtension';

import saleOrderLinesTableConfiguration from './components/configuration/saleOrderLinesTableConfiguration';
import addSaleOrderLineModalConfiguration from './components/configuration/addSaleOrderLineModalConfiguration';

import TradeTimelineItem from '../../trix-web-plugin-wms-trade/web/components/timeline/TradeTimelineItem';

import PickSaleOrderStockBarcodeField from './components/form/PickSaleOrderStockBarcodeField';
import PackSaleOrderStockBarcodeField from './components/form/PackSaleOrderStockBarcodeField';

import PackSaleOrderHumanTaskInfo from './components/table/PackSaleOrderHumanTaskInfo';

export const components = {
    'tableColumn:pickingLineQuantityAndPickedQuantity': createPickingLineQuantityAndPickedQuantityTableColumn,
    'tableColumn:saleOrderLineQuantities': createSaleOrderLineQuantitiesTableColumn,
    'tableColumn:saleOrderLineQuantityAndSoldQuantity': createSaleOrderLineQuantityAndSoldQuantityTableColumn,

    // 'tableCellRenderer:saleOrderLineDiscount': SaleOrderLineDiscountCellRenderer,
    'tableRowExtension:trix-web-plugin-wms-saleorder.manageSaleOrderPage.container.saleOrderLinesTable': SaleOrderLinesTableRowExtension,
    'configuration:trix-web-plugin-wms-saleorder.manageSaleOrderPage.container.saleOrderLinesTable.table': saleOrderLinesTableConfiguration,
    'configuration:trix-web-plugin-wms-saleorder.manageSaleOrderPage.container.saleOrderLinesTable.action.onAddItem.editor.modal': addSaleOrderLineModalConfiguration,

    'timelineItem:trix-web-plugin-wms-saleorder.manageSaleOrderPage.container.trade': TradeTimelineItem,

    // Human tasks
    'formPaneField:pickSaleOrderStockBarcode': PickSaleOrderStockBarcodeField,
    'formPaneField:packSaleOrderStockBarcode': PackSaleOrderStockBarcodeField,
    // 'tableCellRenderer:packSaleOrderHumanTaskInfo': PackSaleOrderHumanTaskInfo,
    
    // 'configuration:trix-web-plugin-core-human-task.executeHumanTask.container.humanTaskExecutionForm.       WMS_PICK_SALE_ORDERS_STOCK.container.???.form.form': (props: any) => {
    //     return {
    //         enableButtons: false,
    //     }
    // },
}
