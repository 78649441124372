// @flow

//
// React
//
import React, { useEffect, useState } from 'react';

//
// Trix Data
//
import {
    type Error,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { makeStyles } from '@mui/styles';

//
// Component
//
import PlaceholderForFormPaneField from './PlaceholderForFormPaneField';

import { type FormPaneFieldMeta } from './formPaneTypes';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // State
    //
    autoFocus: boolean,
    disabled: boolean,
    required?: boolean,
    label: string,
    description: string,

    //
    // Action handlers
    //
    onChange: (value: string, meta: FormPaneFieldMeta) => void,

    //
    // Data
    //
    options: any[],
    // value: boolean,
    error: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const CheckboxGroupFormPaneField = (props: Props) => {
    const classes = useStyles();

    const [state, setState] = useState({});

    const hasError = (props.dataPath) ? hasErrorCausesForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : false;
    const helperText = (props.dataPath) ? formatErrorsForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : '';

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = {
            ...state,
            [event.target.name]: event.target.checked,
        };
        setState(newState);

        if (props.onChange) {
            props.onChange(props.options.filter(item => !!newState[item.id]), {
                path: props.dataPath
            })
        }
    };

    return (
        <PlaceholderForFormPaneField>
            <FormControl 
                sx={{ m: 3 }} 
                component='fieldset' 
                variant='standard'
                required={props.required}
            >
                <FormLabel component='legend'>{props.label}</FormLabel>
                <FormGroup>
                    {
                        (props.options || []).map((item, index) => {
                            return (
                                <FormControlLabel
                                    key={`checkbox-${index}`}
                                    control={
                                        <Checkbox 
                                            checked={!!state[item.id]}
                                            onChange={handleChange}
                                            name={item.id} 
                                        />
                                    }
                                    label={item.name}
                                />
                            );
                        })
                    }
                </FormGroup>
            </FormControl>
        </PlaceholderForFormPaneField>
    );
};

//
// Use memo to create new component, which is sensible on just a several 
// important properties whose change is going to trigger re-render.
// This pattern is important performance optimization. As a side effect
// all other properties should be initialized on create and will not be 
// possible to be changed after component is created 
//
export default React.memo<Props>(CheckboxGroupFormPaneField,
    (prevProps: any, nextProps: any) => {
        return prevProps.options === nextProps.options &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.disabled === nextProps.disabled &&
            prevProps.autoFocus === nextProps.autoFocus && 
            prevProps.onChange === nextProps.onChange;
    }
);