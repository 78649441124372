// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../lib/trix-fp-fantasy';

// buildMenus :: Array -> m
const buildMenus = (a: any[]): any => {
    let menus = {
        'home': {},
        'settings': {}
    };
    for (let i=0; i<a.length; i++) {
        if (!a[i].menus) {
            continue;
        }

        for (let j=0; j<a[i].menus.length; j++) {
            const parent = a[i].menus[j].parent;

            let menuId = '';
            if (parent >= 0 && parent < 9000) {
                menuId = 'home';
            }
            if (parent >= 9000 && parent < 19000) {
                menuId = 'settings';
            }
            if (!menuId) {
                console.error('Menu with invalid parent found. Dont know how to merge it. parent:', parent);
                continue;
            }

            if (!menus[menuId][parent]) {
                menus[menuId][parent] = [];
            }
            if (a[i].menus[j].items) {
                menus[menuId][parent] = [
                    ...menus[menuId][parent],
                    ...a[i].menus[j].items
                ];
            }
        }
    }

    // TODO: sort menu itms by position

    // // flatten all menus and order them by parent
    // const pluginsMenus = S.reduce (sa => p => {
    //         return (p.menus) ? S.concat (sa) (p.menus) : sa;
    //     }) ([]) (a || []);

    // // build menus
    // const menus = S.reduce (r => m => {
    //     // merge menu into all menus
    //     let menuId = '';
    //     if (m.parent >= 0 && m.parent < 9000) {
    //         menuId = 'home';
    //     }
    //     if (m.parent >= 9000 && m.parent < 19000) {
    //         menuId = 'settings';
    //     }

    //     if (!menuId) {
    //         console.error('Menu with invalid parent found. Dont know how to merge it.', m);
    //         return r;
    //     }
    //     return S.insert (`${menuId}`) (mergeMenu (r[menuId]) (m)) (r);
    // }) ({
    //     // home menu
    //     'home': [],

    //     // settings menu
    //     'settings': []
    // }) (pluginsMenus);

    return menus;
}
export default buildMenus;

// mergeMenu :: Array m -> m -> Array m
// const mergeMenu = (menus: any[]) => (m: any) => {
    // for (let i=0; i<menus.length; i++) {
    //     if (menus[i].position === m.position) {
    //         menus[i] = {
    //             ...menus[i],
    //             items: [
    //                 ...menus[i].items,
    //                 ...m.items,
    //             ]
    //         };
    //         menus[i].items = S.sortBy (S.prop ('position')) (menus[i].items);
    //         return menus;
    //     }
    // }

    // // append menu
    // return S.append (m) (menus);
// }

