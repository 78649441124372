// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

//
// Components
//
import SimpleLinkField from '../../simple/SimpleLinkTextField';

//
// Props
//
type Props = {
    //
    // State
    //
    label: string,

    //
    // Data
    //
    value: any,

    //
    // Actions
    //
    onClick?: () => void;
}; // End of Props

//
// Component
//
const FloatPaneField = (props: Props): any => {
    return (
        <Grid 
            item 
            sx={{
                padding: theme => theme.spacing(1),
            }}
        >
            <Typography
                variant='body1'
                sx={{
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    color: (theme: any) => theme.palette.info.dark,
                }}
            >
                {props.label}
            </Typography>
            <Box
                sx={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                }}
            >
                <Typography
                    variant='body2'
                    color='textPrimary'
                    sx={{
                        fontSize: '0.875rem',
                        display: 'inline-block',
                    }}
                >
                    <SimpleLinkField
                        onClick={props.onClick}
                        value={props.value}
                    />
                </Typography>
            </Box>
        </Grid>
    );
};
export default FloatPaneField;
