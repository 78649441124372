// @flow

//
// React
//
import React from 'react';

//
// Types
//
import { type ToolbarButtonsProps } from '../../../trix-web-components-table';

//
// Material UI
//
import Grid from '@mui/material/Grid';

//
// Hal forms
//
import HalFormsTableToolbarIconButtonReload from './halformstabletoolbarbuttons/HalFormsTableToolbarIconButtonReload';
import HalFormsTableToolbarIconButton from './halformstabletoolbarbuttons/HalFormsTableToolbarIconButton';
import HalFormsTableToolbarMenu from './halformstabletoolbarbuttons/HalFormsTableToolbarMenu';

//
// Props
//
type Props = ToolbarButtonsProps & {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Context
    //
    __context: any,

    //
    // Data sources ref
    //
    __dataSourcesRef: any,

    //
    // Data Source Extender props
    //
    getDataSourceType: () => string,
    loadData: (params: any) => void,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,

    //
    // Handlers
    //
    onClose: () => void,
};

//
// Component
//
const HalFormsTableToolbarButtons = (props: Props): any => {
    const selectedItems = (props.selectedItems || [])
            .map(id => {
                return props.items.find(e => e.id === id)
            })
            .filter(e => !!e);

    return (
        <Grid container wrap='nowrap' alignItems='baseline'>           
            {
                (selectedItems.length > 0) ? (
                    (props.__halFormsTemplate.actions || [])
                        .filter(a => a.target === 'bulkItems')
                        .map((a, i, array) => {
                            return (
                                <Grid key={i} item>
                                    <HalFormsTableToolbarIconButton
                                        __halFormsTemplate={props.__halFormsTemplate}
                                        __ducks={props.__ducks}
                                        __context={props.__context}
                                        __dataSourcesRef={props.__dataSourcesRef}
                                        __halFormsAction={a}
                                        // container={props.container}
                                        container={props.data}
                                        data={props.data}
                                        selectedItems={selectedItems}

                                        onClose={props.onClose}
                                    />
                                </Grid>
                            );
                        })
                ) : undefined
            }
            {
                (props.getDataSourceType() === 'remote') ? (
                    <Grid item>
                        <HalFormsTableToolbarIconButtonReload
                            __halFormsTemplate={props.__halFormsTemplate}
                            __ducks={props.__ducks}
                            loadData={props.loadData}
                            page={props.page}
                            filter={props.filter}
                        />
                    </Grid>
                ) : null
            }
            <Grid item>
                <HalFormsTableToolbarMenu
                    __halFormsTemplate={props.__halFormsTemplate}
                    __ducks={props.__ducks}
                    __context={props.__context}
                    __halFormsActions={(props.__halFormsTemplate.actions || [])
                        .filter(a => a.target === 'table')}
                    __dataSourcesRef={props.__dataSourcesRef}
                    container={props.container}
                    data={props.data}

                    onClose={props.onClose}
                />
            </Grid>
        </Grid>
    );
};
export default HalFormsTableToolbarButtons;
