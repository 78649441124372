//
// React
//
import React, { useContext } from 'react';

//
// Tanant
//
import TenantContext from '../context/TenantContext';

//
// Tools
//
import { buildLocationPath } from '../tools/location';

const useBuildLocationPath = () => {
    const tenantId = useContext(TenantContext);
    return buildLocationPath(tenantId);
}

export default useBuildLocationPath;