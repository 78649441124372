// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Formatter
//
import {
    createDateTimeCellRenderer,
} from '../../../trix-web-components-table';

const createDocumentDateTimeTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: false,
        width: 150,

        renderCell: createDateTimeCellRenderer(cfg)
    }
}
export default createDocumentDateTimeTableColumn;