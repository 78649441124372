// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Formatter
//
import { 
    fmtQuantity,
} from '../../../trix-web-data-commons';

import {
    createItemSubItemCellRenderer,
} from '../../../trix-web-components-table';

const createStockQuantityTableColumn = (cfg: any): any => {
    return {
        align: 'right',
        flex: false,
        width: 100,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            align: 'right',

            getItem: (props: any) => {
                return fmtQuantity(props.value ? props.value.quantity : null);
            },

            getSubItem: (props: any) => {
                return props.value && props.value.bulkQuantity && props.value.bulkQuantity.unit ? 
                        fmtQuantity(props.value.bulkQuantity) : null;
            }
        }),

        valueFormatter: (props: any) => {
            return props.value && props.value.bulkQuantity && props.value.bulkQuantity.unit ? 
                        fmtQuantity(props.value.bulkQuantity) : 
                        fmtQuantity(props.value.quantity)
        }
    }
}
export default createStockQuantityTableColumn;