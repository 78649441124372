// @flow

//
// React
//
import React from 'react';

//
// Formatter
//
import { fmtDate } from '../../../../trix-web-data-commons';

//
// Components
//
import FloatPaneField from './FloatPaneField';

//
// Props
//
type Props = {
    //
    // Configuration
    //
    fmt?: string,

    //
    // State
    //
    label: string,

    //
    // Data
    //
    value: Date,

    //
    // Actions
    //
    onClick?: () => void;
}; // End of Props

//
// Component
//
const DateFloatPaneField = (props: Props): any => {
    const value = fmtDate(props.value, props.fmt ? props.fmt : 'LL');

    return (
        <FloatPaneField
            label={props.label}
            value={value}
            onClick={props.onClick}
        />
    );
};
export default DateFloatPaneField;
