// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Props
//
type Props = {
    onClick?: () => void, 
    value: string,
}; // End of Props

//
// Component
//
const SimpleLinkTextField = (props: Props): any => {
    const classes = useStyles();

    if (!props.onClick) {
        return (
            <>
                {(props.value) ? props.value : 'NA'}
            </>
        );
    }

    return (
        <div 
            className={classes.link} 
            onClick={() => (props.onClick) ? props.onClick() : null}
        >
            {(props.value) ? props.value : 'NA'}
        </div>
    );
};

export default SimpleLinkTextField;