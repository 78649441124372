// @flow

//
// React
//
import React, { type Node, useState } from 'react';

//
// Compnents
//
import {
    StockPropertiesPane,
} from '../../../../../lib/trix-web-components-domain-wms';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { makeStyles } from '@mui/styles';

import Image from 'mui-image';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },

    extension: {
        margin: theme.spacing(1),
    },

    buttonContainer: {
        marginLeft: 'auto',
    }
}));

//
// Props
//
type Props = {
    //
    // State
    //
    rowState: any,

    //
    // Data
    //
    item: any;
} // End of Props

//
// Component
//
const PriceListItemsTableRowExtension = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    // PATCH: 
    const sku = (props.item && props.item.stock && props.item.stock.sku) ? 
            props.item.stock.sku : '';
    const imageUrl = `http://79.124.17.213/rp/${sku.substring(0, sku.length - 2)}_1.jpg`;

    return (
        <Collapse 
            in={!!props.rowState.extended} 
            timeout="auto" 
            unmountOnExit
        >
            <Box margin={1}>
                <Grid 
                    container 
                    direction='row'
                >
                    <Grid 
                        item 
                        style={{ padding: '8px', width: '100px' }}
                    >
                        <Image
                            src={imageUrl}
                            // onClick={() => console.log('onClick')}
                            // aspectRatio={(16/9)}
                            // disableSpinner
                        />
                    </Grid>

                    <Grid 
                        item 
                        style={{ width: '400px' }}
                    >
                        <Grid 
                            container
                            direction='column'
                        >
                            {/* <Grid item>
                                <StockHeaderRendererPane
                                    stock={get(props.item, 'stock', {})}
                                />
                            </Grid> */}

                            <Grid container spacing={1}>
                                <Grid item>
                                    <StockPropertiesPane
                                        stock={(props.item && props.item.stock) ? props.item.stock : {}}
                                    />
                                </Grid>

                                <Grid item>
                                    {/* <SaleOrderLineInventoryPane
                                        supplyLine={props.item}
                                    /> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Collapse>
    );
}
export default PriceListItemsTableRowExtension;