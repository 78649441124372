// @flow

//
// React
//
import React, { useEffect, useMemo, type Node } from 'react';

//
// Trix Data
//
import {
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Trix Data Commons
//
import { buildUrl } from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    TextFormPaneField,
    I8nTextFormPaneField,
} from '../../../../../lib/trix-web-components-pane';

import { PersonComboBox } from '../../../../../lib/trix-web-components-domain-core';

//
// Props
//
type Props = {
    name: string,
    label: string,
    description: string,
    helperText: string,

    container: any,
    value: any,
    error: any,

    onChange: (data: any, meta: any) => void,
}

//
// Component
//
const PropertyTypeValueField = (props: Props): Node => {
    // initialize 'value' if we are about to add it
    useEffect(() => {
        if (!props.value || !props.value.valueType) {
            const valueType = (props.container && props.container.valueType) ?
                    props.container.valueType : '';
            props.onChange(props.container.valueType, {
                path: `${props.name}.valueType`
            });
        }
    }, []);

    const valueType = (props.value) ? props.value.valueType : '';

    const dataPath = useMemo(() => {
        if (valueType === 'TEXT') {
            return `${props.name}.textValue`;
        }
        if (valueType === 'I8N_TEXT') {
            return `${props.name}.i8nTextValue`;
        }
        if (valueType === 'INTEGER') {
            return `${props.name}.integerValue`;
        }
        if (valueType === 'LONG') {
            return `${props.name}.longValue`;
        }
        if (valueType === 'DOUBLE') {
            return `${props.name}.doubleValue`;
        }
        if (valueType === 'DATE') {
            return `${props.name}.dateValue`;
        }
        if (valueType === 'TIME') {
            return `${props.name}.dateValue`;
        }
        if (valueType === 'DATE_TIME') {
            return `${props.name}.dateValue`;
        }
        if (valueType === 'BOOLEAN') {
            return `${props.name}.booleanValue`;
        }
        if (valueType === 'QUANTITY') {
            return `${props.name}.quantityValue`;
        }
        if (valueType === 'MONEY') {
            return `${props.name}.moneyValue`;
        }
        if (valueType === 'PERSON') {
            return `${props.name}.personValue`;
        }
        if (valueType === 'COMPANY') {
            return `${props.name}.companyValue`;
        }
    }, [valueType]);

    if (valueType === 'TEXT') {
        return (
            <TextFormPaneField            
                label={props.label}
                description={props.description}
                
                value={props.value.textValue}
                error={props.error}

                onChange={props.onChange}

                dataPath={dataPath}
            />
        );
    }

    if (valueType === 'I8N_TEXT') {
        return (
            <I8nTextFormPaneField
                autoFocus={false}
                disabled={false}
                label={props.label}
                description={props.description}

                value={props.value.i8nTextValue}
                error={props.error}

                onChange={props.onChange}

                dataPath={dataPath}
            />
        );
    }

    if (valueType === 'DATE') {
        return (
            <TextFormPaneField
                label={props.label}
                description={props.description}

                value={props.value.dateValue}
                error={props.error}

                onChange={props.onChange}

                dataPath={dataPath}
            />
        );
    }

    if (valueType === 'PERSON') {
        return (
            <PersonComboBox
                name={props.name}

                label={props.label}
                description={props.description}
                helperText=''

                value={props.value.personValue}
                error={props.error}

                onChange={props.onChange}

                dataPath={dataPath}
            />
        );
    }

    return <div>Property type '{valueType}' is not supported</div>;
};
export default PropertyTypeValueField;
