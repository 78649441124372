// @flow

// import BusinessPremiseCellRenderer from './components/renderer/BusinessPremiseCellRenderer';
// import BusinessPremiseCodeNameFloatPaneField from './components/float/BusinessPremiseCodeNameFloatPaneField';

export const components = {
    // 'tableCellRenderer:businessPremise': BusinessPremiseCellRenderer,
    // 'formPaneField:warehouseStorageLocationSelector': FormPaneBusinessPremiseSelectorField,

    // 'floatPaneField:businessPremise': BusinessPremiseCodeNameFloatPaneField,
}
