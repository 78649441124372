// @flow

//
// FP
//
import S, { F } from '../../trix-fp-fantasy';

//
// Lodash
//
import merge from 'lodash/merge';

//
// Redux Fantasy
//
import {
    O,
} from '../../redux-fantasy-middleware';

//
// Trix Data
//
import {
    networkError,
} from '../../trix-web-data-commons';

//
// Configuration
//
type Configuration = {
    actions: {
        // requestOperation :: data -> action
        requestOperation: (data: any) => any;

        // operationExecuted_Success :: data -> action
        operationExecuted_Success: (data: any) => any;

        // operationExecuted_Failure :: data -> action
        operationExecuted_Failure: (error: any) => any;
    },

    // (action, reject, resolve) -> void
    readApi: (action: any, reject: any, resolve: any) => void,

    // transitionOnSuccess :: (prevState, nextState, action) -> transitions
    transitionOnSuccess?: (prevState: any, nextState: any, action: any) => any;

    // onSuccess :: Action -> Array Action
    onSuccess?: (a: any) => any[];

    // onFailure :: Action -> Array Action
    onFailure?: (a: any) => any[],
}

//
// Operation factory
//
const createReadOperation = (cfg: Configuration): any => 
            (actionPredicate: ((a: any) => Boolean)) => {
    // api :: Action -> Future Error Data
    const callReadApi = (action: any) => {
        return new F.Future((reject, resolve) => {
            cfg.readApi(action, 
                // reject,
                (error) => {
                    // execute onsuccess callback (if specified)
                    if (action.meta && action.meta.onFailure) {
                        action.meta.onFailure(error);
                    }
                    // call reject callback
                    reject(error);
                },

                // resolve
                (data) => {
                    // execute onsuccess callback (if specified)
                    if (action.meta && action.meta.onSuccess) {
                        action.meta.onSuccess(data.body);
                    }
                    // call resolve callback
                    resolve(data);
                });

            // TODO: fix after migration to Axious
            return () => null;
        });
    };

    // readDataFailure :: action -> error -> action
    const readDataFailure = (action: any) => (error: any) => {
        const actions = [];

        const failureAction = merge({}, cfg.actions.operationExecuted_Failure(error), {
            meta: {
                ...action.meta,
            },
        });
        actions.push(failureAction);

        if (cfg.onFailure) {
            actions.push(...cfg.onFailure(failureAction));
        }

        return actions;
    }

    // readDataSuccess :: action -> data -> Array action
    const readDataSuccess = (action: any) => (data: any) => {
        const actions = [];

        const transition = (cfg.transitionOnSuccess) ? {
            transition: cfg.transitionOnSuccess
        } : {};

        // enrich with initial action's meta
        const successAction = merge({}, {
                    meta: {
                        ...action.meta,
                        ...transition,
                    }
                },
                cfg.actions.operationExecuted_Success(data.body))
        actions.push(successAction);

        if (cfg.onSuccess) {
            actions.push(...cfg.onSuccess(successAction));
        }

        return actions;
    }

    // readTask :: Action -> Future Error Data
    const readTask = (action: any) => S.pipe([
        // Action -> Future () Action
        F.resolve,

        // Future () Action -> Future Error Data
        S.chain(callReadApi),

        // Timeout if API call takes more than 5 sec
        // TODO: use FP style of composition. Find the math !!!
        // Future Error Data -> Future Error Data
        F.race (F.rejectAfter (60000) (networkError('Connection timeout'))),

        // TODO: 
        // Cancel API call on CANCEL action
        // Future Error Data -> Future Error Data
        // F.race (rejectOnAction (a => a.type === 'CANCEL') ('Operation canceled')),

        // Future Error Data -> Future Action<Error> Action<Page>
        F.bimap (readDataFailure (action)) (readDataSuccess (action)),
    ]) (action);

    return O.takeAllAndFork (actionPredicate) (readTask);
}
export default createReadOperation;
