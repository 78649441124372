// @flow

import * as numeral from 'numeral';

import {
    type Money,
} from '../domain/Money';

export function fmtAmount(amount: number, fmt: string): string {
    return numeral(amount).format(fmt);
}

export default function fmtMoney(money?: Money): string {
    const amount = (money && money.amount) ? money.amount : 0;
    const currency = (money && money.currency) ? money.currency : ' - ';
    return `${fmtAmount(amount, '0,0.00')} ${currency}`;
}