// @flow

export const shallowEqual = (o1: any, o2: any): any => {
    if (o1 === o2) {
        return true;
    }

    const keys1 = Object.keys(o1);
    const keys2 = Object.keys(o2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (o1[key] !== o2[key]) {
            return false;
        }
    }

    return true;
}