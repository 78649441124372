// @flow

import saleReturnSourceItemSelectorFieldConfiguration from './components/configuration/saleReturnSourceItemSelectorFieldConfiguration';

import createSaleReturnLineQuantitiesTableColumn from './components/table/SaleReturnLineQuantitiesTableColumn';
import createSaleReturnLineDiscountTableColumn from './components/table/SaleReturnLineDiscountTableColumn';

import StoreSaleReturnStockBarcodeField from './components/form/StoreSaleReturnStockBarcodeField';

import TradeTimelineItem from '../../trix-web-plugin-wms-trade/web/components/timeline/TradeTimelineItem';

export const components = {
    'configuration:trix-web-plugin-wms-salereturn.manageSaleReturnPage.container.saleReturnLinesTable.action.onAddItem.editor._.saleReturnSourceItem': saleReturnSourceItemSelectorFieldConfiguration,

    'tableColumn:saleReturnLineQuantities': createSaleReturnLineQuantitiesTableColumn,
    'tableColumn:saleReturnLineDiscount': createSaleReturnLineDiscountTableColumn,

    // Human tasks
    'formPaneField:storeSaleReturnStockBarcode': StoreSaleReturnStockBarcodeField,

    'timelineItem:trix-web-plugin-wms-salereturn.manageSaleReturnPage.container.trade': TradeTimelineItem,
}
