// @flow

//
// Action types
//
export const ActionTypes = {
    INITIALIZED: '@@redux-unicorn/reducer/INITIALIZED',
    DESTROYED: '@@redux-unicorn/reducer/DESTROYED',
};

//
// Actions
//
export type InitializedAction = {
    type: '@@redux-unicorn/reducer/INITIALIZED',
    payload: {
        instanceId: string,
        reducerKey: string,
        title: string,
        url: string,
    },
};

export type DestroyedAction = {
    type: '@@redux-unicorn/reducer/DESTROYED',
    payload: {
        instanceId: string,
    },
};

//
// Action creators
//
const initialize = (data: {
    instanceId: string,
    reducerKey: string,
    title: string,
    url: string,
}): InitializedAction => {
    return {
        type: '@@redux-unicorn/reducer/INITIALIZED',
        payload: {
            instanceId: data.instanceId,
            reducerKey: data.reducerKey,
            title: data.title,
            url: data.url,
        },
    };
};

const destroy = (data: { instanceId: string }): DestroyedAction => {
    return {
        type: '@@redux-unicorn/reducer/DESTROYED',
        payload: {
            instanceId: data.instanceId,
        },
    };
};

const Actions = {
    initialize,
    destroy,
};
export default Actions;
