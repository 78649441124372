// @flow

//
// Ducks
//
import {
    reducer,
} from './state/ducks';

//
// Enhancers
//
export {
    default as withEntity,
} from './enancers/withEntity';

//
// Hooks
//
export {
    default as useEntity,
} from './hooks/useEntity';

export { Selectors } from './state/ducks';

//
// Lib Definition
//
const lib = {
    // name: LIB_NAME,

    //
    // State
    //
    state: {
        // operations,
        reducer,
    },
}
export default lib;
