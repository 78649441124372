// @flow

//
// React
//
import React, {
    type Node,
    useCallback,
    useMemo,
} from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { makeStyles } from '@mui/styles';

//
// Components
//
import Header from './mainpage/Header';
import Navigation from './mainpage/Navigation';
import Content from './mainpage/Content';

import SuccessNotificationSnackbar from './mainpage/snackbar/SuccessNotificationSnackbar';
import ErrorNotificationSnackbar from './mainpage/snackbar/ErrorNotificationSnackbar';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: 0,
        width: '100%',
        minHeight: '100%',
        height: '100%',
    },

    body: {
        flexGrow: 1,
        overflow: 'hidden',
    },

    navigation: {
    },

    content: {
        // expand content, using all available space
        flexGrow: 1,

        // enable vertical scroll on overflow
        overflowY: 'auto',
    }
}));

//
// Props
//
type Props = {
    //
    // HAL forms
    //
    __halFormsTemplates: any,
    __ducks: any,

    //
    // Routing
    //
    history: {
        push: (url: string) => void,
    },

    //
    // Menus
    //
    menus: any,

    //
    // Pages
    //
    pages: any[],

    //
    // Handlers
    //
    onClosePage: (instanceId?: string) => void,
};

//
// Main Page
//
const MainPage = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();
    const [isNavigationOpen, setNavigationOpen] = React.useState(false);
    // End hooks

    const toggleNavigationMenu = useCallback(() => setNavigationOpen(!isNavigationOpen), []);
    const closeNavigationMenu = useCallback(() => setNavigationOpen(false), []);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const navigation = useMemo(() => {
        return(
            <Navigation
                pages={props.pages}
                onClosePage={props.onClosePage}
                onToggleNavigationMenu={toggleNavigationMenu}
            />
        )
    }, [props.pages, props.onClosePage]);

    return (
        <React.Fragment>
            <Grid
                container
                direction='column'
                wrap='nowrap'
                className={classes.root}
            >
                <Grid item className={classes.header}>
                    <Header 
                        showNavigationMenu={isSmall}
                        onToggleNavigationMenu={toggleNavigationMenu}
                    />
                </Grid>

                <Grid
                    container
                    className={classes.body}
                    direction='row'
                    wrap='nowrap'
                    alignItems='stretch'
                >
                    {(!isSmall) ? (
                        <Grid item className={classes.navigation}>
                            {navigation}
                        </Grid>
                    ) : null}

                    <Grid item container className={classes.content}>
                        <Content
                            __halFormsTemplates={props.__halFormsTemplates}
                            __ducks={props.__ducks}
                            history={props.history}
                            menus={props.menus}
                            onClosePage={props.onClosePage}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Box>
                <Drawer
                    variant="temporary"
                    open={isNavigationOpen}
                    onClose={closeNavigationMenu}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {navigation}
                </Drawer>
            </Box>

            <SuccessNotificationSnackbar />
            <ErrorNotificationSnackbar />
        </React.Fragment>
    );
};
export default MainPage;
