// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../lib/trix-fp-fantasy';

//
// Trix web data reducers
//
import {
    createDataRecordActionsTypes,
    createDataRecordActions,

    createDataListActionsTypes,
    createDataListActions,

    createOperationActionsTypes,
    createOperationActions,
    
    createComponentTableActionsTypes,
    createComponentTableActions,
} from '../../../lib/trix-web-data-reducers';

// createActionsTypes :: Array t -> StrMap a
export const createActionsTypes = 
    S.reduce (xs => x => {
        let actionsTypes = {};

        // create appropriate actions for the template type
        if (x.type === 'container') {
            actionsTypes = S.insert (x.id) (createContainerActionsTypes(x)) (actionsTypes);
        }
        if (x.type === 'table') {
            actionsTypes = S.insert (x.id) (createTableActionsTypes(x)) (actionsTypes);
        }
        if (x.type === 'timeline') {
            actionsTypes = S.insert (x.id) (createTimelineActionsTypes(x)) (actionsTypes);
        }

        if (x.type === 'reportingTable') {
            actionsTypes = S.insert (x.id) (createReportingActionsTypes(x)) (actionsTypes);
        }

        // drill down components, that needs actions types to be created 
        if (x.components) {
            const subActionsTypes = createActionsTypes (x.components);
            actionsTypes = S.concat (actionsTypes) (subActionsTypes);
        }

        // drill down into actions
        if (x.actions) {
            for (let i=0; i<x.actions.length; i++) {
                const a = x.actions[i];
                
                if (a.type === 'modal' && a.component) {
                    const modalActionsTypes = createActionsTypes ([ a.component ]);
                    actionsTypes = S.concat (actionsTypes) (modalActionsTypes);
                }

                if (a.type === 'httpPost') {
                    const operationActionsTypes = createHttpRequestActionsTypes(a);
                    actionsTypes = S.insert (a.id) (operationActionsTypes) (actionsTypes);
                }
            }
        }
        
        return S.concat (actionsTypes) (xs);
    }) ({});

// createActions :: StrMap at -> Array t -> StrMap a
export const createActions = (actionsTypes: any) =>
    S.reduce (xs => x => {
        let actions = {};

        // create appropriate actions for the template type
        if (x.type === 'container') {
            actions = S.insert (x.id) (createContainerActions (actionsTypes) (x)) (actions);
        }
        if (x.type === 'table') {
            actions = S.insert (x.id) (createTableActions (actionsTypes) (x)) (actions);
        }
        if (x.type === 'timeline') {
            actions = S.insert (x.id) (createTimelineActions (actionsTypes) (x)) (actions);
        }

        if (x.type === 'reportingTable') {
            actions = S.insert (x.id) (createReportingActions (actionsTypes) (x)) (actions);
        }

        // drill down the components, that needs action to be created
        if (x.components) {
            const subActions = createActions (actionsTypes) (x.components);
            actions = S.concat (actions) (subActions);
        }

        // drill down into actions
        if (x.actions) {
            for (let i=0; i<x.actions.length; i++) {
                const a = x.actions[i];
                
                if (a.type === 'modal' && a.component) {
                    const modalActions = createActions (actionsTypes) ([ a.component ]);
                    actions = S.concat (actions) (modalActions);
                }

                if (a.type === 'httpPost') {
                    const operationActions = createHttpRequestActions (actionsTypes) (a);
                    actions = S.insert (a.id) (operationActions) (actions);
                }
            }
        }
        
        return S.concat (actions) (xs);
    }) ({});

//
// Actions Types
//

// createContainerActionsTypes :: Object -> a
const createContainerActionsTypes = (t: any) => {
    let actionsTypes = {};

    //
    // create data actions types
    //
    const dataActionsTypes = createDataRecordActionsTypes({
        ns: t.id,
        data: S.toUpper (t.name),
    });
    actionsTypes = S.concat (actionsTypes) ({
        data: dataActionsTypes,
    });

    //
    // create operations actions types
    //
    let operationsActionsTypes = {};
    const dataSource = (t.dataSource) ? t.dataSource : { options: {}};
    if (dataSource.type === 'remote') {
        const loadOperationActionsTypes = createOperationActionsTypes({
            ns: `${t.id}.action.onLoad`,
            operation: 'HTTP_GET',
        });
        operationsActionsTypes = S.concat (operationsActionsTypes) ({
            load: loadOperationActionsTypes
        });
    }
    actionsTypes = S.concat (actionsTypes) ({
        operations: operationsActionsTypes,
    });

    return actionsTypes;
}

// createTableActionsTypes :: Object -> a
const createTableActionsTypes = (t: any) => {
    let actionsTypes = {};

    //
    // create data actions types
    //
    const dataActionsTypes = createDataListActionsTypes({
        ns: t.id,
        data: S.toUpper (t.name),
    });
    actionsTypes = S.concat (actionsTypes) ({
        data: dataActionsTypes,
    });

    //
    // create component actions types
    //
    const componentActionsTypes = createComponentTableActionsTypes({
        ns: t.id,
        data: S.toUpper (t.name),
    });
    actionsTypes = S.concat (actionsTypes) ({
        component: componentActionsTypes,
    });

    //
    // create operations actions types
    //
    let operationsActionsTypes = {};
    const dataSource = (t.dataSource) ? t.dataSource : { options: {}};
    if (dataSource.type === 'remote') {
        const loadOperationActionsTypes = createOperationActionsTypes({
            ns: `${t.id}.action.onLoad`,
            operation: 'HTTP_GET'
        });
        operationsActionsTypes = S.concat (operationsActionsTypes) ({
            load: loadOperationActionsTypes
        });
    }
    actionsTypes = S.concat (actionsTypes) ({
        operations: operationsActionsTypes,
    });

    return actionsTypes;
}

// createTimelineActionsTypes :: Object -> a
const createTimelineActionsTypes = (t: any) => {
    let actionsTypes = {};

    //
    // create data actions types
    //
    const dataActionsTypes = createDataListActionsTypes({
        ns: t.id,
        data: S.toUpper (t.name),
    });
    actionsTypes = S.concat (actionsTypes) ({
        data: dataActionsTypes,
    });

    //
    // create operations actions types
    //
    let operationsActionsTypes = {};
    const dataSource = (t.dataSource) ? t.dataSource : { options: {}};
    if (dataSource.type === 'remote') {
        const loadOperationActionsTypes = createOperationActionsTypes({
            ns: `${t.id}.action.onLoad`,
            operation: 'HTTP_GET'
        });
        operationsActionsTypes = S.concat (operationsActionsTypes) ({
            load: loadOperationActionsTypes
        });
    }
    actionsTypes = S.concat (actionsTypes) ({
        operations: operationsActionsTypes,
    });

    return actionsTypes;
}

// createReportingActionsTypes :: Object -> a
const createReportingActionsTypes = (t: any) => {
    let actionsTypes = {};

    //
    // create data actions types
    //
    const dataActionsTypes = createDataListActionsTypes({
        ns: t.id,
        data: S.toUpper (t.name),
    });
    actionsTypes = S.concat (actionsTypes) ({
        data: dataActionsTypes,
    });

    //
    // create component actions types
    //
    const componentActionsTypes = createComponentTableActionsTypes({
        ns: t.id,
        data: S.toUpper (t.name),
    });
    actionsTypes = S.concat (actionsTypes) ({
        component: componentActionsTypes,
    });

    //
    // create operations actions types
    //
    let operationsActionsTypes = {};
    const dataSource = (t.dataSource) ? t.dataSource : { options: {}};
    if (dataSource.type === 'remote') {
        const loadOperationActionsTypes = createOperationActionsTypes({
            ns: `${t.id}.action.onLoad`,
            operation: 'HTTP_GET'
        });
        operationsActionsTypes = S.concat (operationsActionsTypes) ({
            load: loadOperationActionsTypes
        });
    }
    actionsTypes = S.concat (actionsTypes) ({
        operations: operationsActionsTypes,
    });

    return actionsTypes;
}

// createHttpRequestActionsTypes :: Object -> a
const createHttpRequestActionsTypes = (t: any) => {
    //
    // create operations actions types
    //
    const actionsTypes = createOperationActionsTypes({
        ns: t.id,
        operation: 'HTTP_POST'
    });

    return actionsTypes;
}

//
// Actions
//

// createContainerActions :: Object -> a
const createContainerActions = (actionsTypes: any) => (t: any) => {
    let actions = {};

    //
    // create data actions
    //
    const dataActions = createDataRecordActions(actionsTypes[t.id].data);
    actions = S.concat (actions) ({
        data: dataActions,
    });

    // create load data operations types, if data source is remote
    if (actionsTypes[t.id].operations) {
        let operationsActions = {};

        if (actionsTypes[t.id].operations.load) {
            const loadOperationActions = createOperationActions (actionsTypes[t.id].operations.load);
            operationsActions = S.concat (operationsActions) ({
                load: loadOperationActions
            });
        }
    
        actions = S.concat (actions) ({
            operations: operationsActions
        });
    }
    
    return actions;
}

// createTableActions :: Object -> a
const createTableActions = (actionsTypes: any) => (t: any) => {
    let actions = {};

    //
    // create data actions
    //
    const dataActions = createDataListActions(actionsTypes[t.id].data);
    actions = S.concat (actions) ({
        data: dataActions,
    });

    //
    // create component actions
    //
    const componentActions = createComponentTableActions(actionsTypes[t.id].component);
    actions = S.concat (actions) ({
        component: componentActions,
    });

    // create load data operations types, if data source is remote
    if (actionsTypes[t.id].operations) {
        let operationsActions = {};

        if (actionsTypes[t.id].operations.load) {
            const loadOperationActions = createOperationActions (actionsTypes[t.id].operations.load);
            operationsActions = S.concat (operationsActions) ({
                load: loadOperationActions
            });
        }
    
        actions = S.concat (actions) ({
            operations: operationsActions
        });
    }
    
    return actions;
}

// createTimelineActions :: Object -> a
const createTimelineActions = (actionsTypes: any) => (t: any) => {
    let actions = {};

    //
    // create data actions
    //
    const dataActions = createDataListActions(actionsTypes[t.id].data);
    actions = S.concat (actions) ({
        data: dataActions,
    });

    // create load data operations types, if data source is remote
    if (actionsTypes[t.id].operations) {
        let operationsActions = {};

        if (actionsTypes[t.id].operations.load) {
            const loadOperationActions = createOperationActions (actionsTypes[t.id].operations.load);
            operationsActions = S.concat (operationsActions) ({
                load: loadOperationActions
            });
        }
    
        actions = S.concat (actions) ({
            operations: operationsActions
        });
    }
    
    return actions;
}

// createReportingActions :: Object -> a
const createReportingActions = (actionsTypes: any) => (t: any) => {
    let actions = {};

    //
    // create data actions
    //
    const dataActions = createDataListActions(actionsTypes[t.id].data);
    actions = S.concat (actions) ({
        data: dataActions,
    });

    //
    // create component actions
    //
    const componentActions = createComponentTableActions(actionsTypes[t.id].component);
    actions = S.concat (actions) ({
        component: componentActions,
    });

    // create load data operations types, if data source is remote
    if (actionsTypes[t.id].operations) {
        let operationsActions = {};

        if (actionsTypes[t.id].operations.load) {
            const loadOperationActions = createOperationActions (actionsTypes[t.id].operations.load);
            operationsActions = S.concat (operationsActions) ({
                load: loadOperationActions
            });
        }
    
        actions = S.concat (actions) ({
            operations: operationsActions
        });
    }
    
    return actions;
}


// createHttpRequestActions :: Object -> a
const createHttpRequestActions = (actionsTypes: any) => (t: any) => {
    const operationActions = createOperationActions (actionsTypes[t.id]);
    return operationActions;
}
