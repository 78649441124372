// @flow

//
// React
//
import React, { useState } from 'react';

//
// Material UI
//
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//
// Props
//
type Props = {
    value: string;
    onChange: (value: string) => any;
    error: boolean;
    helperText: string;
};

//
// Component
//
const PasswordTextField = (props: Props): any => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            value={props.value}
            onChange={(event) => props.onChange(event.target.value)}
            error={props.error}
            helperText={props.helperText}
            variant='standard'
            fullWidth
            placeholder='Парола'
            autoComplete='password'
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <LockIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => setShowPassword(showPassword => !showPassword)}
                            onMouseDown={() => setShowPassword(showPassword => !showPassword)}
                            edge='end'
                            size="large">
                            {showPassword ? (
                                <VisibilityIcon />
                            ) : (
                                <VisibilityOffIcon />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};
export default PasswordTextField;
