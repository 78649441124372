// @flow

//
// React
//
import React from 'react';

//
// Formatter
//
import {
    fmtQuantity,
    type Quantity,
} from '../../../../trix-web-data-commons';

//
// Components
//
import FloatPaneField from './FloatPaneField';

//
// Props
//
type Props = {
    //
    // State
    //
    fmt?: string,
    label: string,

    //
    // Data
    //
    value: Quantity,

    //
    // Actions
    //
    onClick?: () => void;
}; // End of Props

//
// Component
//
const QuantityFloatPaneField = (props: Props): any => {
    const value = fmtQuantity(props.value);

    return(
        <FloatPaneField
            label={props.label}
            value={value}
            onClick={props.onClick}
        />
    );
};
export default QuantityFloatPaneField;
