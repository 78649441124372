// @flow

import get from 'lodash/get';

//
// React
//
import React, { useState } from 'react';

//
// Formatter
//
import { fmtOmni } from '../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.secondary.main,
    },

    propertiesTable: {
    },
    propertiesTableLastRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    propertiesText: {
        fontSize: '.625rem',
    },
    
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    title?: string,
    humanTask: any,
};

//
// Component
//
const HumanTaskPropertiesRendererPane = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    // End Hooks

    return (
        <>
            {(props.title) ? 
                (
                    <Typography 
                        className={classes.title}
                        variant='caption' 
                        display='block'
                    >
                        {props.title}
                    </Typography>
                ) : null
            }
            <Table
                className={classes.propertiesTable}
                size='small' 
            >
                <TableBody>
                    {(get(props.humanTask, 'properties') || [])
                            .filter(p => !p.system)
                            .map((p, index, array) => {
                        return (
                            <TableRow 
                                key={index}
                                className={(index === array.length - 1) ? classes.propertiesTableLastRow : null}
                            >
                                <TableCell
                                    component='th'
                                    size='small'
                                    className={classes.propertiesText}
                                >
                                    {p.label}
                                </TableCell>
                                <TableCell
                                    component='th'
                                    size='small'
                                    align='right'
                                    className={classes.propertiesText}
                                >
                                    {fmtOmni(p.value)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
}
export default HumanTaskPropertiesRendererPane;
