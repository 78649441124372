// @flow

//
// Actions
//
import {
    ActionTypes,
    type InitializedAction,
    type DestroyedAction,
} from '../../../../../lib/redux-fantasy-reducers/actions/Actions';

import {
    ActionTypes as ShellActionTypes,
    type LocationChangeAction,
    type OrganizationSetAction,
} from './actions';

//
// State
//
export type Action = InitializedAction | DestroyedAction | OrganizationSetAction | LocationChangeAction;

type PageInstance = {
    instanceId: string,
    reducerKey: string,
    title: string,
    url: string,
};

export type State = {
    pages: Array<PageInstance>,
    currentPageInstanceId: string,
    organization: any,
};

//
// Reducer
//
export const reducer = (
    state: State = {
        pages: [],
        currentPageInstanceId: '',
        organization: {},
    },
    action: Action
): any => {
    switch (action.type) {
        // Handle new state slice reducer initialization, when a new page instance
        // is created. We need to update open pages list.
        case ActionTypes.INITIALIZED:
            return reduceInitialized(state, action);

        case ActionTypes.DESTROYED:
            return reduceDestroyed(state, action);

        // handle router LOCATION_CHANGE action and update instance's url
        // when an internal page navigaion is issued
        case ShellActionTypes.LOCATION_CHANGE:
            return reduceLocationChanged(state, action);

        case ShellActionTypes.ORGANIZATION_SET:
            return reduceOrganizationSet(state, action);

        default:
            return state;
    }
};
export default reducer;

//
// Composite reducers
//
const reduceInitialized = (state: State, action: InitializedAction) => {
    // this reducer should be idempotent. do nothing if adding an already
    // added page instance
    const instance = state.pages.find(
        p => p.instanceId === action.payload.instanceId
    );

    return {
        ...state,
        pages: instance
            ? state.pages
            : [
                  ...state.pages,
                  {
                      instanceId: action.payload.instanceId,
                      reducerKey: action.payload.reducerKey,
                      title: action.payload.title,
                      url: action.payload.url,
                  },
              ],
    };
};

const reduceDestroyed = (state: State, action: DestroyedAction) => {
    return {
        ...state,
        pages: [
            ...state.pages.filter(
                p => p.instanceId !== action.payload.instanceId
            ),
        ],
    };
};

const reduceLocationChanged = (state: State, action: LocationChangeAction) => {
    // parse url parameters and search for param 'i'
    const paramName = 'i';
    const regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)');
    const results = regex.exec(action.payload.location.search);
    const instanceId = results === null ? '' : results[1].replace(/\+/g, ' ');
    
    if (!instanceId) {
        return state;
    }

    return {
        ...state,
        currentPageInstanceId: instanceId,
        pages: (state.pages || []).map<PageInstance>(p => {
            if (p.instanceId === instanceId) {
                return {
                    instanceId: p.instanceId,
                    reducerKey: p.reducerKey,
                    title: p.title,
                    url: `${action.payload.location.pathname}${action.payload.location.search}`,
                };
            }
            return p;
        })
    };
};

const reduceOrganizationSet = (state: State, action: OrganizationSetAction) => {
    return {
        ...state,
        organization: action.payload,
    };
};

//
// Selectors
//
export const Selectors = {
    //
    // Selector section: 'app'
    //
    pages: (state: State): any => state.pages,
    organization: (state: State): any => state.organization,

    // End selector sections
};
