// @flow

//
// React
//
import React from 'react';

//
// React Router
//
import { useLocation } from 'react-router-dom';

//
// Trix middleware
//
import { setHttpOptions } from '../../../../../lib/trix-web-middleware-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';

import { makeStyles } from '@mui/styles';

//
// Components
//
import Topbar from './mainpage/Topbar';

import Desk from './mainpage/Desk';

import SuccessNotificationSnackbar from './mainpage/snackbar/SuccessNotificationSnackbar';
import ErrorNotificationSnackbar from './mainpage/snackbar/ErrorNotificationSnackbar';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: 0,
        width: '100%',
        minHeight: '100%',
        height: '100%',
    },
}));

//
// Props
//
type Props = {
    //
    // Children
    //
    children: any[],

    //
    // Menus
    //
    menus: any,
};

//
// Main Page
//
const MainPage = (props: Props): any => {
    const classes = useStyles();

    const location = useLocation();

    // configure global http options, to be used for all API requests
    setHttpOptions({
        headers: {
            'X-Tenant': 0,
        }
    });

    return (
        <>
            <Grid
                container
                direction='column'
                wrap='nowrap'
                className={classes.root}
            >
                <Topbar />

                <Desk
                    children={props.children}
                    menus={props.menus}
                />
            </Grid>

            <SuccessNotificationSnackbar />
            <ErrorNotificationSnackbar />
        </>
    );
};
export default MainPage;
