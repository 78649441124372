// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

//
// Components
//
import ServicePropertiesPane from '../pane/ServicePropertiesPane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        // take width of the content
        width: '1px',
        whiteSpace: 'nowrap',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },

    sku: {
        color: theme.palette.text.secondary,
    },

    extIcon: {
        marginLeft: theme.spacing(1),
    },
}));

//
// Props
//
type Props = {
    //
    // Callbacks
    //
    onClick?: () => void,

    //
    // Data
    //
    row: any,
};

//
// Component
//
const createServiceRowWithPropertiesCellRenderer = (cfg: any): any => (props: any): any => {
    const service = (props.row) ? props.row : {}
    const code = (service.code) ? service.code : '';
    const name = (service.name && service.name.bg) ? service.name.bg : '';

    return(
        <Grid container direction='row'>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid item container direction='row'>
                    <Grid item>
                        <Typography variant='subtitle2' display='block'>
                            {code}
                        </Typography>
                        <Typography variant='caption' display='block'>
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
                
                <Grid 
                    item 
                    container 
                    spacing={2}
                    direction='column'
                >                        
                    <Grid item>
                        <ServicePropertiesPane
                            service={service}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const createServiceRowWithPropertiesTableColumn = (cfg: any): any => { 
    return {
        flex: true,
        width: 200,

        renderCell: createServiceRowWithPropertiesCellRenderer({
            ...cfg.cellRendererFactoryProps,
        })
    }
};
export default createServiceRowWithPropertiesTableColumn;
