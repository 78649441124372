// @flow

//
// React
//
import React from 'react';

//
// Redux
//
import { useDispatch, useSelector } from 'react-redux';

//
// Trix Middleware
//
import {
    type Error,
    formatError,
} from '../../../../../../../lib/trix-web-data-commons';

//
// Ducks (Note: not exactly ducks. Need to be wraped up into duck and then used here)
//
import {
    closeErrorNotification,
    createAppNotificationsSelectors,
} from '../../../../../../../lib/trix-web-data-reducers';

//
// Ducks
//
import ReducerActions from '../../../../../../../lib/redux-fantasy-reducers/actions/Actions';
import { appSelector } from '../../../../../state/ducks';
import { Selectors } from '../../../../../state/ducks/app/reducer';

//
// Material UI
//
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//
// Props
//
type Props = {};

//
// Selectors (TODO: export into shell ducks)
//
const appNotificationsSelectors = createAppNotificationsSelectors(
    state => state['trix-web-shell-classic'].notifications
);

//
// Component
//
const ErrorNotificationSnackbar = (props: Props): any => {
    //
    // Hooks
    //
    const dispatch = useDispatch();

    const isOpen = useSelector(
        appNotificationsSelectors.error.isOpen
    );
    const error = useSelector(
        appNotificationsSelectors.error.getError
    );

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={isOpen}
            autoHideDuration={20000}
            onClose={(event: any, reason: string) => {
                if (reason === 'timeout') {
                    dispatch(closeErrorNotification());
                }
            }}
        >
            <Alert
                onClose={() => dispatch(closeErrorNotification())}
                severity='error'
            >
                {formatError(error)}
            </Alert>
        </Snackbar>
    );
};
export default ErrorNotificationSnackbar;
