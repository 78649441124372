// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    TextFloatPaneField,
} from '../../../../lib/trix-web-components-pane';

//
// Props
//
type Props = {
    //
    // State
    //
    label: string,

    //
    // Data
    //
    data: any,
}; // End of Props

//
// Component
//
const ServiceFloatPaneField = (props: Props): Node => {
    // Hooks
    // const classes = useStyles();
    // End Hooks

    const serviceCode = (props.data && props.data.code) ? props.data.code : '';
    const serviceName = (props.data && props.data.name) ? props.data.name.bg : '';

    return (
        <TextFloatPaneField
            label={props.label}
            value={`${serviceCode} / ${serviceName}`}
        />
    );
};
export default ServiceFloatPaneField;
