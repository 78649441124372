// @flow 

//
// Duck Name
//
export const DUCK_NAME = 'entities';

//
// Reducer
//
export { reducer } from './reducer';

//
// Actions
//
export {
    default as Actions,
    ActionTypes,
} from './actions';

//
// Selectors
//
export { default as Selectors } from './selectors';
