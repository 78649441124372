// @flow

//
// React
//
import React, { useLayoutEffect, useMemo, type Node } from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

//
// Props
//
import { type HalFormsContainerRowProps } from './HalFormsContainerRowProps';

//
// Component
//
const HalFormsContainerRowPaper = (props: HalFormsContainerRowProps): Node => {
    let itemSx = {
        width: '100%'
    };
    if (!props.isLast) {
        itemSx = {
            ...itemSx,
            paddingBottom: (theme: any) => theme.spacing(2)
        }
    }

    return (
        <Grid
            item
            sx={itemSx}
        >
            <React.Fragment>
                {(props.title) ? 
                    (
                        <Typography 
                            variant='subtitle1' 
                            sx={{
                                fontWeight: 200,
                                lineHeight: 2,
                            }}
                        >
                            {props.title}
                        </Typography>
                    ) : null
                }
            
                <Paper>
                    {props.children}
                </Paper>
            </React.Fragment>
        </Grid>
    );
}
export default HalFormsContainerRowPaper;