// @flow

//
// React
//
import React from 'react';

//
// UI
//
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

//
// Images
//
const logo = require('../../../../../images/logo.png');

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        height: 24,
    },
}));

//
// Props
//
type Props = {
    //
    // Classname
    //
    className?: any,

    //
    // Callbacks
    //
    onClick?: () => void;
}

//
// Component
//
const TopbarLogoItem = (props: Props): any => {
    const classes = useStyles();

    return (
        <img 
            src={logo}
            className={clsx(classes.root, props.className)} 
        />
    );
}
export default TopbarLogoItem;
