// @flow

//
// React
//
import React from 'react';

//
// Components
//
import {
    TextFloatPaneField,
} from '../../../trix-web-components-pane';

//
// Props
//
type Props = {
    //
    // State
    //
    label: string,

    //
    // Data
    //
    data: any,
}; // End of Props

//
// Component
//
const WarehouseStorageLocationFloatPaneField = (props: Props) => {
    const warehouseStorageLocationName = (props.data && props.data.name) ? props.data.name : '';

    return (
        <TextFloatPaneField
            label={props.label}
            value={warehouseStorageLocationName}
        />
    );
};
export default WarehouseStorageLocationFloatPaneField;
