// @flow

//
// State
//
export type State = any;

//
// Configuration
//
type Configuration = {
    operationActionsTypes: {
        OPERATION_REQUESTED: string,
        OPERATION_EXECUTED_SUCCESS: string,
        OPERATION_EXECUTED_FAILURE: string,
    },
};

//
// Reducer factory
//
export const createHttpRequestReducer = (cfg: Configuration): any => (
    state: State = {
    },
    action: any
): State => {
    if (!cfg.operationActionsTypes) {
        return state;
    }

    switch (action.type) {
        case cfg.operationActionsTypes.OPERATION_REQUESTED:
            return reduceOperationRequested(state, action);
    }

    return state;
};

//
// Composite reducers
//
const reduceOperationRequested = (state: State, action: any) => {
    return (action.payload && action.payload) ? action.payload : {};
};
