// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';

// import { makeStyles } from '@mui/styles';
// import clsx from 'clsx';

//
// Styles
//
// const useStyles = makeStyles(theme => ({
//     root: {
//         marginBottom: theme.spacing(1),
//         padding: theme.spacing(1),
//     },

//     labelCell: {
//         paddingRight: theme.spacing(1),
//     },

//     fieldCell: {},

//     label: {
//         fontSize: '0.875rem',
//     },

//     description: {
//         fontSize: '0.875rem',
//     },
// }));

//
// Props
//
type Props = {
    //
    // Visual
    //
    // className?: any,

    //
    // State
    //
    // label: string,
    // description: string,

    //
    // Children
    //
    children: any,
}; // End of Props

//
// Component
//
const PlaceholderForFormPaneField = (props: Props): any => {
    // const classes = useStyles();

    // const col1Width = (props.label || props.description) ? 6 : 0;
    // const col2Width = (props.label || props.description) ? 6 : 12;

    return (
        <Grid
            item
            container
            direction='row'
            // className={clsx(classes.root, props.className)}
            xs={12}
        >
            {/* {(col1Width > 0) ? (
                <Grid item className={classes.labelCell} xs={col1Width}>
                    <Typography
                        variant='body1'
                        color='textPrimary'
                        className={classes.label}
                    >
                        {props.label}
                    </Typography>
                    <Typography
                        variant='body2'
                        color='textSecondary'
                        className={classes.description}
                    >
                        {props.description}
                    </Typography>
                </Grid>
            ) : null} */}
            <Grid 
                item
                // className={classes.fieldCell} 
                xs={12}
            >
                {props.children}
            </Grid>
        </Grid>
    );
};
export default PlaceholderForFormPaneField;