// @flow

import * as numeral from 'numeral';

import {
    type Quantity,
} from '../domain/Quantity';

export function fmtAmount(amount: number, fmt: string): string {
    return numeral(amount).format(fmt);
}

export default function fmtQuantity(quantity?: Quantity): string {
    const amount = (quantity && quantity.amount) ? quantity.amount : 0;
    const unit = (quantity && quantity.unit) ? quantity.unit : ' - ';
    return `${fmtAmount(amount, '0,0.00')} ${unit}`;
}