// @flow

//
// React
//
import * as React from 'react';

//
// Props
//
type Props = {
    __TYPE: string,

    //
    // children
    //
    children: any,
}

//
// Component
//
const PageLayoutHeader = (props: Props): any => {
    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
}

PageLayoutHeader.defaultProps = {
    __TYPE: 'PageLayoutHeader'
}

export default PageLayoutHeader;
