// @flow

//
// React
//
import React, { type Node } from 'react';

import {
    createItemSubItemCellRenderer,
} from '../../../trix-web-components-table';

const createEmployeeTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: true,
        width: 200,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            getItem: (props: any) => {
                if (props.value && props.value.name) {
                    return `${props.value.name}`;
                } else if (props.value && props.value.username) {
                    return props.value.username;
                } else {
                    return (<i>Not defined</i>);
                }
            },

            getSubItem: (props: any) => {
                return (props.value && props.value.position) ? props.value.position : '';
            }
        })
    }
}
export default createEmployeeTableColumn;