// @flow

//
// Lodash
//
import merge from 'lodash/merge';
import get from 'lodash/get';

//
// FP
//
import S, { F } from '../../trix-fp-fantasy';

//
// Redux Fantasy
//
import {
    O,
} from '../../redux-fantasy-middleware';

//
// Trix Data
//
import {
    networkError,
} from '../../trix-web-data-commons';

//
// Configuration
//
type Configuration = {
    actions: {
        // requestOperation :: data -> action
        requestOperation: (data: any) => any;

        // operationExecuted_Success :: data -> action
        operationExecuted_Success: (data: any) => any;

        // operationExecuted_Failure :: data -> action
        operationExecuted_Failure: (error: any) => any;
    },

    // findApi :: (action, reject, resolve) -> void
    findApi: (action: any, reject: any, resolve: any) => void,

    // onSuccess :: Action -> Array Action
    onSuccess?: (a: any) => any[];

    // onFailure :: Action -> Array Action
    onFailure?: (a: any) => any[],

    // response page path
    responsePagePath?: string,

    // response data path
    responseDataPath?: string,
}

const createFindOperation = (cfg: Configuration): any => 
            (actionPredicate: ((a: any) => Boolean)) => {
    // api :: Action -> Future Error Data
    const callFindApi = (action: any) => {
        return new F.Future((reject, resolve) => {
            cfg.findApi(action, reject, resolve);

            // TODO: fix after migration to Axious
            return () => null;
        });
    };

    // findFailure :: action -> error -> action
    const findFailure = (action: any) => (error: any) => {
        const actions = [];

        const failureAction = merge({}, cfg.actions.operationExecuted_Failure(error), {
            meta: {
                ...action.meta,
            },
        });
        actions.push(failureAction);

        if (cfg.onFailure) {
            actions.push(...cfg.onFailure(failureAction));
        }

        return actions;
    }

    // findSuccess :: action -> data -> Array action
    const findSuccess = (action: any) => (data: any) => {
        const actions = [];

        let items: any = [];
        if (cfg.responseDataPath) {
            items = get(data.body, cfg.responseDataPath);
        } else if (data.body._embedded) {
            const entityType = Object.keys(data.body._embedded)[0];
            items = data.body._embedded[entityType];
        }

        let page = {};
        if (cfg.responsePagePath) {
            page = get(data.body, cfg.responsePagePath);
        } else {
            page = data.body.page;
        }

        // enrich with initial action's meta
        const successAction = merge({},
            cfg.actions.operationExecuted_Success({
                // data 
                items,

                // page
                page,

                // links
                links: data.body._links,

                // meta
                meta: {
                    lastModified: (data.headers && data.headers['last-modified']) ? 
                            data.headers['last-modified'] : '',
                },
            }), {
                meta: {
                    ...action.meta,
                }
            });
        actions.push(successAction);

        if (cfg.onSuccess) {
            actions.push(...cfg.onSuccess(successAction));
        }

        return actions;
    }

    // findTask :: Action -> Future Error Data
    const findTask = (action: any) => S.pipe([
        // Future () Maybe Action -> Future Error Data
        callFindApi,

        // Timeout if API call takes more than 5 sec
        // TODO: use FP style of composition. Find the math !!!
        // Future Error Data -> Future Error Data
        F.race (F.rejectAfter (90000) (networkError('Connection timeout'))),

        // TODO: 
        // Cancel API call on CANCEL action
        // Future Error Data -> Future Error Data
        // F.race (rejectOnAction (a => a.type === 'CANCEL') ('Operation canceled')),

        // Future Error Data -> Future Action<Error> Action<Page>
        F.bimap (findFailure (action)) (findSuccess (action)),
    ]) (action);

    return O.takeAllAndFork (actionPredicate) (findTask);
}
export default createFindOperation;