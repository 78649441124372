// @flow

//
// Types
//
type Configuration = {
    reducerKey: string;
}

type Reducer = (state: any, action: any) => any;

/**
 * `node` reducer factory
 */
const node = (reducer: Reducer): ((cfg: any) => any) => (cfg: Configuration) => {
    return (state: any = {
                _meta: {
                    reducerKey: cfg.reducerKey,
                },
                ...reducer(undefined, '@@INIT'),
            }, action: any) => {
        if (!action.meta) {
            return state;
        }

        if (state._meta.reducerKey !== action.meta.reducerKey) {
            return state;
        }

        return {
            _meta: state._meta,
            ...reducer(state, action)
        };
    }
}
export default node;