// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../trix-fp-fantasy';

//
// React
//
import React, {
    useEffect,
    useMemo,
    useCallback,
    type Node, 
    type ComponentType,
} from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
    useDispatchLocal,
} from '../../../redux-fantasy-reducers';

//
// Hal forms
//
import withHalFormsAction from '../../extender/withHalFormsAction';
import {
    getHalFormsComponent,
} from '../../tools/halFormsTemplate';
import {
	getSelector,
	createAction,
} from '../../tools/halFormsRedux';

//
// Extenders
//
import withHalFormsDataSource from '../../extender/withHalFormsDataSource';

//
// Material UI
//
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    withComponentsRegistry,
} from '../../../trix-web-components-registry';

//
// Components
//
import {
    DataTable,
} from '../../../trix-web-components-table';

import createHalFormsReportingTableColumn from './halformsreportingtable/halFormsReportingTableColumnFactory';
import HalFormsReportingTableToolbarButtons from './halformsreportingtable/HalFormsReportingTableToolbarButtons';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {},
}));

const createToolbarButtons = (cfg: {
    halFormsTemplate: any,
    context: any,
    ducks: {
        selectors: any,
        actions: any,
    },
    container: any,
    data: any,
    getDataSourceType: () => string,
    loadData: (params: any) => void,
}) => (props: any) => {
    return (
        <HalFormsReportingTableToolbarButtons 
            __halFormsTemplate={cfg.halFormsTemplate}
            __ducks={cfg.ducks}
            __context={cfg.context}
            getDataSourceType={cfg.getDataSourceType}
            loadData={cfg.loadData}
            container={cfg.container}
            data={cfg.data}
            {...props}
        />
    );
};

// const handleFilterChange = (cfg: {
//     halFormsTemplate: any,
//     ducks: any,
//     loadData: (request: any) => void,
//     dispatchLocal: (action: any) => void, 
// }) => (request: {
//     page: number,
//     size: number,
//     filter: any,
// }) => {
//     // set filter into redux store
//     const action = S.maybeToNullable (
//         createAction 
//             (cfg.ducks) 
//             (cfg.halFormsTemplate.id) 
//             ('component.changeFilter') 
//             (request.filter)
//     );
//     if (action) {
//         cfg.dispatchLocal(action);
//     }

//     // execute data source with new filter
//     cfg.loadData(request);
// }

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Action Executor Extender props
    //
    __halFormsActionExtender: {
        executeHalFormsAction: (halFormsAction: any, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
        executeHalFormsActionByName: (halFormsActionName: string, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
    },

    //
    // Data Source Extender props
    //
    __dataSourceExtender: {
        dataSource: any,
        getDataSourceType: () => string,
        loadData: (params: any) => void,
        isDataLoadNeeded: (params: any) => boolean,
        isLoadingData: boolean,
        data: any,
        params: any,
    },

    //
    // Components registry
    //
    __componentsRegistry: {
        getHalFormsCustomizer: (customizer: string, halFormsTemplate: any, target?: string) => any,
    },

    //
    // Hal forms events dispatcher
    //
    setClientEventListener: (listener: any) => void,

    //
    // Context
    //
    __context: any,

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,
} // End of Props

//
// Component
//
const HalFormsReportingTable = (props: Props) => {
    //
    // Hooks
    //
    const dispatchLocal = useDispatchLocal();

    const columns = useLocalSelector (
        getSelector 
            (props.__ducks)
            (props.__halFormsTemplate.id) 
            ('component.getColumns'));
    useEffect(() => {
        if (columns && columns.length > 0) {
            return;
        }

        const initializeActionMaybe = S.pipe([
            // Name -> Maybe Component
            getHalFormsComponent (props.__halFormsTemplate),

            // Maybe Component -> Maybe ActionData
            S.map (columnsSet => {
                let a: any = [];

                if (columnsSet && columnsSet.components) {
                    a = [ ...a, ...columnsSet.components ]; 
                }

                return a
                    .map(o => {
                        const halFormsTableColumn = createHalFormsReportingTableColumn({
                            __halFormsTemplate: props.__halFormsTemplate,
                            __halFormsActionExtender: props.__halFormsActionExtender,
                            __componentsRegistry: props.__componentsRegistry,
                            column: o,
                            // data container
                            container: props.data,
                        });

                        let r = {
                            id: o.id,
                            name: o.name,
                            dataType: o.dataType,
                            label: o.label,
                            description: o.description,
                            enabled: true,
                        }
                        if (halFormsTableColumn) {
                            r = { ...r, ...halFormsTableColumn };
                        }
                        return r;
                    });
            }),

            // Maybe ActionData -> Maybe Action
            S.chain (createAction 
                (props.__ducks) 
                (props.__halFormsTemplate.id) 
                ('component.initializeTable'))
        ]) ('columns');
        if (S.isJust (initializeActionMaybe)) {
            dispatchLocal(S.fromMaybe ({}) (initializeActionMaybe));
        }
    }, [props.__halFormsTemplate.id]);

    const filter = useLocalSelector (
        getSelector
            (props.__ducks)
            (props.__halFormsTemplate.id)
            ('component.getFilter'));

    // const selectedItems = useLocalSelector (
    //     getSelector
    //         (props.__ducks)
    //         (props.__halFormsTemplate.id)
    //         ('component.getSelectedItems')
    // );

    const page = (props.__dataSourceExtender.data) ? props.__dataSourceExtender.data.page : {
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0,
    };
    const items = (props.__dataSourceExtender.data) ? props.__dataSourceExtender.data.items : [];

    const dataSource = (props.__dataSourceExtender) ? 
        props.__dataSourceExtender.dataSource : null;
    useEffect(() => {
        // skip initial data load in case of no data source specified for the container
        if (!dataSource) {
            return;
        }

        const params = {
            page: page.number,
            size: page.size,
            filter,
        }

        if (props.__dataSourceExtender && 
                !props.__dataSourceExtender.isDataLoadNeeded(params)) {
            return;
        }

        props.__dataSourceExtender.loadData(params);
    }, [dataSource]);

    const pageInstanceState = useLocalSelector (s => s);

    const classes = useStyles();

    //
    // prepare table configuration
    //
    // default configuration
    const defaultConfiguration = {
        enableToolbar: false,
        enableToolbarSearchField: false,
        enableHeader: true,
        enableItemSelection: false,
        enableRowExtension: false,
        enablePagination: true,
    };

    const customConfigurationFactory = props.__componentsRegistry.getHalFormsCustomizer(
        'tableConfiguration', props.__halFormsTemplate);
    const customConfiguration = (customConfigurationFactory) ? customConfigurationFactory() : {};

    // merge default and custom configuration
    const configuration = {
        ...defaultConfiguration,
        ...customConfiguration
    };

    const handleChangeFilter = useCallback((filter: any) => {
        const action = S.maybeToNullable (
            createAction 
                (props.__ducks) 
                (props.__halFormsTemplate.id) 
                ('component.changeFilter') 
                (filter)
        );
        if (action) {
            dispatchLocal(action);

            props.__dataSourceExtender.loadData({
                page: page.number,
                size: page.size,
                filter
            });
        }
    }, [page]);
    
    // back reference data source
    if (props.__dataSourcesRef && dataSource && dataSource.id) {
        props.__dataSourcesRef.current = {
            ...props.__dataSourcesRef.current,
            [dataSource.id]: {
                reload: () => props.__dataSourceExtender.loadData({
                    page: page.number || 0,
                    size: page.size || 10,
                    filter
                })
            }
        };
    }

    // TODO: filter
    // fetch filter components from the registry
    // const DataTableFilter = useMemo(() => props.__componentsRegistry.getHalFormsCustomizer(
    //         'tableFilter', props.__halFormsTemplate, 'filter'), [ props.__halFormsTemplate.id ]);

    // const DataTableToolbarButtons = useMemo(() => createToolbarButtons({
    //     halFormsTemplate: props.__halFormsTemplate,
    //     ducks: props.__ducks,
    //     context: {},
    //     container: props.container,
    //     data: props.data,
    //     getDataSourceType: props.__dataSourceExtender.getDataSourceType,
    //     loadData: props.__dataSourceExtender.loadData,
    // }), [ props.__halFormsTemplate.id ]);

    //
    // Handlers
    //
    const handleChangePage = useCallback((pageNumber: number) => {
        props.__dataSourceExtender.loadData({
            page: pageNumber,
            size: page.size || 10,
            filter
        })
    }, [page, filter]);

    const handleChangeRowsPerPage = useCallback((rowsPerPage: number) => {
        props.__dataSourceExtender.loadData({
            page: page.number || 0,
            size: rowsPerPage,
            filter
        })
    }, [page, filter]);

    const handleClickOnRow = useCallback((item: any) => {
        props.__halFormsActionExtender.executeHalFormsActionByName ('onRowClick', 
                item, props.container);
    }, [props.container]);

    // Add hal forms event dispatcher listener
    useEffect(() => {
        if (props.setClientEventListener) {
            props.setClientEventListener({
                identification: props.__halFormsTemplate.name,
                events: { 'RELOAD': true },
                onEvent: (event: any) => {
                    // build filter fields
                    const filter = {
                        expressions: Object.values(event.payload)
                    };

                    // TODO: keep filter into the store
                    handleChangeFilter(filter);

                    // props.__dataSourceExtender.loadData({
                    //     page: 0,
                    //     size: page.size || 10,
                    //     filter
                    // });
                }
            });
        }
    }, [page]);

    return (
        <DataTable
            //
            // Components
            //
            // DataTableToolbarButtons={DataTableToolbarButtons}
            // DataTableFilter={DataTableFilter}

            //
            // Configuration
            //
            // enableToolbar={configuration.enableToolbar}
            // enableToolbarSearchField={configuration.enableToolbarSearchField}
            enableToolbar={true}
            enableToolbarSearchField={false}
            enableToolbarExportField={true}
            enableHeader={configuration.enableHeader}
            enableItemSelection={configuration.enableItemSelection}
            enablePagination={configuration.enablePagination}

            //
            // State
            //
            isLoading={props.__dataSourceExtender.isLoadingData}
            
            columns={columns}

            //
            // Data
            //
            items={items}

            //
            // Pagination
            //
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}

            //
            // Selection
            //
            // selectedItems={selectedItems}
            // onChangeSelectedItems={handleChangeSelectedItems}
            
            //
            // Filter
            //
            filter={filter}
            onFilterChange={handleChangeFilter}
            
            //
            // Handlers
            //
            onRowClick={handleClickOnRow}
        ></DataTable>
    );
};
export default withComponentsRegistry (
    withHalFormsAction ({ }) (
        withHalFormsDataSource (HalFormsReportingTable)
    )
);