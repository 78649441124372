// @flow

//
// React
//
import React, { useState, useEffect, useCallback } from 'react';

//
// Trix Middleware
//
import { 
    get as httpGet,
} from '../../../../../lib/trix-web-middleware-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import CircularProgress  from '@mui/material/CircularProgress';

import { makeStyles } from '@mui/styles';

//
// Trix Data
//
import {
    buildUrl,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    TextFormPaneField,
    I8nTextFormPaneField,
    RadioGroupFormPaneField,
    ComboBoxFormPaneField,
    CheckboxFormPaneField,
    type FormPaneContentProps,
} from '../../../../../lib/trix-web-components-pane';

//
// Other
//
import {
    PropertyValueFormPaneField,
    resolvePropertyValueSource,
    resolvePropertyValueType,
} from '../../../../../lib/trix-web-components-property';

//
// Styles
//
const useStyles = makeStyles((theme) => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    data: any,

    //
    // Container
    //
    container: any,

    //
    // Error
    //
    error: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => any,
};

//
// Component
//
const CreateUpdateProductVariationForm = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();

    const [variationProperties, setVariationProperties] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const getProductGroupUrl = (props.container && 
            props.container._links && 
            props.container._links.getGroup) ? props.container._links.getGroup.href : '';
    useEffect(() => {
        setLoading(true);
        httpGet(
            // url
            getProductGroupUrl,

            // options
            null,
            
            // reject callback
            (error) => {
                setLoading(false);
                console.error('Error fetching product group:', error);
            },
            
            // resolve callback
            (data) => {
                setLoading(false);

                const productGroup = (data && data.body) ? data.body : {};
                let variationProperties = (productGroup && productGroup.variationProperties) ?
                        productGroup.variationProperties : [];
                setVariationProperties(variationProperties);
            }
        );
    }, [getProductGroupUrl]);

    const changeHandler = useCallback(
        (value, meta) => {
            // PATCH: 
            if (value.valueType) {
                props.onChange({
                    code: '',
                    value: value
                }, meta);    
            } else {
                props.onChange({
                    code: value.code,
                    value: value.value
                }, meta);
            }
        }, []);
    // End hooks

    if (isLoading) {
        return (
            <Grid item style={{ textAlign: 'center' }}>
                <CircularProgress />
            </Grid>
        );
    }

    if (null == variationProperties) {
        return null;
    }

    if (!variationProperties || variationProperties.length <= 0) {
        return (
            <div>Продуктовата група не дефинира вариационни характеристики.</div>
        );
    }

console.log('VAR DATA PROPS:', props.data);

    return (
        <Grid 
            container 
            direction='column' 
            className={classes.root}
        >
            {variationProperties.map((p) => {
                let value = (props.data.properties && props.data.properties[p.code]) ?
                        props.data.properties[p.code] : null;
                return (
                    <PropertyValueFormPaneField
                        key={p.code}
                        id={p.code}
                        valueType={p.valueType}
                        valueSource={p.valueSource}
                        code={p.code}
                        name={p.name}

                        value={value}
                        error={props.error}

                        onChange={changeHandler}
                        
                        dataPath={`properties.${p.code}`}
                    />
                );
            })}
        </Grid>
    );
};
export default CreateUpdateProductVariationForm;
