// @flow

import CompanyCellRenderer from './components/table/CompanyCellRenderer';
import DocumentTypeCellRenderer from './components/table/DocumentTypeCellRenderer';

import CompanyFloatPaneField from './components/form/CompanyFloatPaneField';
import DocumentTypeFloatPaneField from './components/form/DocumentTypeFloatPaneField';

export const components = {
    'tableCellRenderer:company': CompanyCellRenderer,
    'tableCellRenderer:documentType': DocumentTypeCellRenderer,

    'floatPaneField:company': CompanyFloatPaneField,
    'floatPaneField:documentType': DocumentTypeFloatPaneField,
}
