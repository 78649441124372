// @flow

//
// Formatter
//
import {
    fmtNumber,
    fmtDate,
} from '../../../../../lib/trix-web-data-commons';

const getConfiguration = (props: any) => {
    return {
        onGetOptionLabel: (option: any) => {
            return `${fmtNumber(option.documentNo, '0000000000')} / ${fmtDate(option.documentDate, 'LL')}`
        }
    }
}
export default getConfiguration;