// @flow

//
// Helper functions
//
type Meta = {
    path: string
}

export const mergeInNew = (path: any[], value: any): any => (data: any) => {
    let leg = path[0];

    // parse leg path and determine if this leg is an array or object
    // NOTE: at the time we don't support nested array (array of arrays, etc)
    const legTokens = leg.match('^(.*)\\[([0-9]+)\\]$');

    // leg value is an array
    if (legTokens && legTokens.length === 3) {
        leg = legTokens[1];
        const position = legTokens[2];

        // leg value is an array
        let legArray = (data[leg]) ? data[leg] : [];
        legArray[position] = (path.length > 1) ? 
            mergeInNew (path.slice(1), value) (legArray[position]) : value;
        return {
            ...data,
            [leg]: legArray
        };
    
    // leg value is not an array
    } else {
        const legValue = (path.length > 1) ? 
            mergeInNew (path.slice(1), value) (data[leg] || {}) : value;

        return {
            ...data,
            [leg]: legValue
        };
    }
}

export const createSetDataInState = (setDataHandler: (prevState: any => any) => void): any => 
        (value: any, meta: Meta) => {
    setDataHandler(prevState => {
        const dataPath = (meta && meta.path) ? meta.path : '';
        return mergeInNew (dataPath.split('.'), value) (prevState);
    });
}
