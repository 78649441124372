// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React from 'react';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../types/CellProps';

//
// Helper
//
import { renderAsLink } from './tools';

const formatI8nText = (i8nText: string) => {
    return get(i8nText, 'bg', '');
}

//
// Component
//
const createI8nTextCellRenderer = (cfg: CellRendererFactoryProps): any => (props: CellProps): any => {
    return (
        <div>
            <div>
                {renderAsLink(cfg.onClick, props.row)(formatI8nText(props.value))}
            </div>
        </div>
    );
};
export default createI8nTextCellRenderer;
