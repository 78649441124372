// ofTypeWithReducerKey :: t -> p -> a -> Boolean
export const ofTypeWithReducerKey = (type) => (reducerKeyPattern) => (action) => {
    // match type
    if (action.type !== type) {
        return false;
    }

    // match reducer key
    if (!action.meta || !action.meta.reducerKey) {
        return false;
    }
    let isApplicableRegEx = new RegExp(reducerKeyPattern);
    const isApplicable = isApplicableRegEx.test(action.meta.reducerKey);
    if (!isApplicable) {
        return false;
    }

    return true;
}

// ofTypeWithReducerKeyAnd :: t -> p -> a -> Boolean
export const ofTypeWithReducerKeyAndMeta = (type) => (reducerKeyPattern) => 
        (metaField, metaValue) => (action) => {
    // match type
    if (action.type !== type) {
        return false;
    }

    // match meta field value
    if (!action.meta || action.meta[metaField] !== metaValue) {
        return false;
    }

    // match reducer key
    if (!action.meta || !action.meta.reducerKey) {
        return false;
    }
    let isApplicableRegEx = new RegExp(reducerKeyPattern);
    const isApplicable = isApplicableRegEx.test(action.meta.reducerKey);
    if (!isApplicable) {
        return false;
    }

    return true;
}
