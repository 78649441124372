// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React from 'react';

//
// Components
//
import FloatPaneField from './FloatPaneField';

//
// Props
//
type Props = {
    //
    // Functions
    //
    render?: (text: string) => string,

    //
    // State
    //
    label: string,

    //
    // Data
    //
    value: string,
}; // End of Props

//
// Component
//
const I8nTextFloatPaneField = (props: Props): any => {
    const value = get(props.value, 'bg', '');
    const text = (props.render) ? props.render(value) : value;

    return(
        <FloatPaneField
            label={props.label}
            value={text}
        />
    );
};
export default I8nTextFloatPaneField;
