// @flow

//
// FP
//
import S from '../../trix-fp-fantasy';

//
// React
//
import React, {
    useEffect,
    useMemo,
    useCallback,
} from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
    useDispatchLocal,
} from '../../redux-fantasy-reducers';

//
// Hal forms
//
import withHalFormsAction from '../extender/withHalFormsAction';
import { getHalFormsComponent } from '../tools/halFormsTemplate';
import {
	getSelector,
	createAction,
} from '../tools/halFormsRedux';
import createHalFormsTableColumn from './halformstable/halFormsTableColumnFactory';

//
// Extenders
//
import withHalFormsDataSource from '../extender/withHalFormsDataSource';

//
// Components
//
import {
    withComponentsRegistry,
} from '../../trix-web-components-registry';

//
// Components
//
import {
    DataTable,
} from '../../trix-web-components-table';

import HalFormsTableToolbarButtons from './halformstable/HalFormsTableToolbarButtons';

//
// Styles
//
const createToolbarButtons = (cfg: {
    halFormsTemplate: any,
    context: any,
    ducks: {
        selectors: any,
        actions: any,
    },
    dataSourcesRef: any,
    container: any,
    data: any,
    getDataSourceType: () => string,
    loadData: (params: any) => void,
    onClose: () => void,
}) => (props: any) => {
    return (
        <HalFormsTableToolbarButtons 
            __halFormsTemplate={cfg.halFormsTemplate}
            __ducks={cfg.ducks}
            __dataSourcesRef={cfg.dataSourcesRef}
            __context={cfg.context}
            getDataSourceType={cfg.getDataSourceType}
            loadData={cfg.loadData}
            container={cfg.container}
            data={cfg.data}
            onClose={cfg.onClose}
            {...props}
        />
    );
};

// const createRowExtension = (cfg: {
//     getHalFormsCustomizer: (customizer: string, halFormsTemplate: any, target: string) => any,
//     halFormsTemplate: any,
// }) => {
//     return cfg.getHalFormsCustomizer('tableRowExtension', cfg.halFormsTemplate, '');
// }

// const handleFilterChange = (cfg: {
//     halFormsTemplate: any,
//     ducks: any,
//     loadData: (request: any) => void,
//     dispatchLocal: (action: any) => void, 
// }) => (request: {
//     page: number,
//     size: number,
//     filter: any,
// }) => {
//     // set filter into redux store
//     const action = S.maybeToNullable (
//         createAction 
//             (cfg.ducks) 
//             (cfg.halFormsTemplate.id) 
//             ('component.changeFilter') 
//             (request.filter)
//     );
//     if (action) {
//         cfg.dispatchLocal(action);
//     }

//     // execute data source with new filter
//     cfg.loadData(request);
// }

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Action Executor Extender props
    //
    __halFormsActionExtender: {
        executeHalFormsAction: (halFormsAction: any, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
        executeHalFormsActionByName: (halFormsActionName: string, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
    },

    //
    // Data Source Extender props
    //
    __dataSourceExtender: {
        dataSource: any,
        getDataSourceType: () => string,
        loadData: (params: any) => void,
        isDataLoadNeeded: (params: any) => boolean,
        isLoadingData: boolean,
        data: any,
        params: any,
    },

    //
    // Components registry
    //
    __componentsRegistry: {
        getHalFormsCustomizer: (customizer: string, halFormsTemplate: any, target?: string) => any,
    },

    //
    // Hal forms events dispatcher
    //
    setClientEventListener: (listener: any) => void,

    //
    // Context
    //
    __context: any,

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,

    //
    // Callbacks
    //
    onClose: () => void,
} // End of Props

//
// Component
//
const HalFormsTable = (props: Props) => {
    //
    // Hooks
    //
    const dispatchLocal = useDispatchLocal();

    const columns = useLocalSelector (
        getSelector 
            (props.__ducks)
            (props.__halFormsTemplate.id) 
            ('component.getColumns'));
    useEffect(() => {
        if (columns && columns.length > 0) {
            return;
        }

        const initializeActionMaybe = S.pipe([
            // Name -> Maybe Component
            getHalFormsComponent (props.__halFormsTemplate),

            // Maybe Component -> Maybe ActionData
            S.map (columnsSet => {
                let a: any = [];

                if (columnsSet && columnsSet.components) {
                    a = [ ...a, ...columnsSet.components ]; 
                }

                return a
                    .map(o => {
                        const halFormsTableColumn = createHalFormsTableColumn({
                            __halFormsTemplate: props.__halFormsTemplate,
                            __halFormsActionExtender: props.__halFormsActionExtender,
                            __componentsRegistry: props.__componentsRegistry,
                            column: o,
                            // data container
                            container: props.data,
                        });

                        let r = {
                            id: o.id,
                            name: o.name,
                            dataType: o.dataType,
                            label: o.label,
                            description: o.description,
                            enabled: true,
                        }
                        if (halFormsTableColumn) {
                            r = { ...r, ...halFormsTableColumn };
                        }
                        return r;
                    });
            }),

            // Maybe ActionData -> Maybe Action
            S.chain (createAction
                (props.__ducks)
                (props.__halFormsTemplate.id)
                ('component.initializeTable'))
        ]) ('columns');
        if (S.isJust (initializeActionMaybe)) {
            dispatchLocal(S.fromMaybe ({}) (initializeActionMaybe));
        }
    }, [props.__halFormsTemplate.id]);

    const getActions = useMemo(() => {
        const itemHalFormsActions = ((props.__halFormsTemplate && props.__halFormsTemplate.actions) ? 
            props.__halFormsTemplate.actions : [])
                    .filter(a => a.target === 'item');
        if (itemHalFormsActions && itemHalFormsActions.length > 0) {
            return (rowProps: any) => {
                const execute = (action: any): void => {
                    // execute action
                    props.__halFormsActionExtender.executeHalFormsAction(
                        action, rowProps.row, props.data, {
                            // context: props.__context,
                            // onSuccess: (data: any) => {}
                        }
                    );
                }

                const defaultActionFactory = (action: any) => {
                    return {
                        icon: (<></>),
                        label: action.title,
                        onClick: () => execute(action),
                        showInMenu: true
                    }
                }
                
                let customActionFacory;
                let createCustomActionFactory = props.__componentsRegistry.getHalFormsCustomizer(
                    'tableRowActionsFactory', props.__halFormsTemplate);
                if (createCustomActionFactory) {
                    customActionFacory = (action: any) => createCustomActionFactory({
                        rowProps,
                        execute: () => execute(action),
                    }) (action);
                }
                
                return itemHalFormsActions
                    .filter(action => {
                        if (action.createIfRel && 
                                !(rowProps.row && rowProps.row._links && 
                                    rowProps.row._links[action.createIfRel])) {
                            return false;
                        }
                        return true;
                    })
                    .map(action => {
                        let r;
                        if (customActionFacory) {
                            r = customActionFacory(action);
                        }
                        if (!r) {
                            r = defaultActionFactory(action);
                        }
                        return r;
                    })
            }
        } else {
            return undefined;
        }
    }, [props.__halFormsTemplate, props.data]);

    const filter = useLocalSelector (
        getSelector
            (props.__ducks)
            (props.__halFormsTemplate.id)
            ('component.getFilter'));

    const selectedItems = useLocalSelector (
        getSelector
            (props.__ducks)
            (props.__halFormsTemplate.id)
            ('component.getSelectedItems')
    );

    const page = (props.__dataSourceExtender.data) ? props.__dataSourceExtender.data.page : {
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0,
    };
    const items = (props.__dataSourceExtender.data) ? props.__dataSourceExtender.data.items : [];

    const dataSource = (props.__dataSourceExtender) ? 
        props.__dataSourceExtender.dataSource : null;
    useEffect(() => {
        // skip initial data load in case of no data source specified for the container
        if (!dataSource) {
            return;
        }

        const params = {
            page: page.number,
            size: page.size,
            ...filter,
        }

        if (props.__dataSourceExtender && 
                !props.__dataSourceExtender.isDataLoadNeeded(params)) {
            return;
        }

        props.__dataSourceExtender.loadData(params);
    }, [dataSource, props.data]);

    const pageInstanceState = useLocalSelector (s => s);

    // Add hal forms event dispatcher listener
    useEffect(() => {
        if (props.setClientEventListener) {
            props.setClientEventListener({
                identification: props.__halFormsTemplate.name,
                events: { 'RELOAD': true },
                onEvent: (event: any) => {
                    // build filter fields
                    const filter = {
                        expressions: Object.values(event.payload)
                    };

                    // TODO: keep filter into the store
                    handleChangeFilter({ filter });
                }
            });
        }
    }, [page]);
    // End hooks

    //
    // prepare table configuration
    //
    // default configuration
    const defaultConfiguration = {
        enableToolbar: true,
        enableToolbarSearchField: true,
        enableHeader: true,
        enableItemSelection: ((props.__halFormsTemplate && props.__halFormsTemplate.actions) ? 
                props.__halFormsTemplate.actions : [])
                    .filter(a => a.target === 'bulkItems')
                    .length > 0,
        enableRowExtension: false,
        enablePagination: page.size > 0,
    };

    const customConfigurationFactory = props.__componentsRegistry.getHalFormsCustomizer(
            'tableConfiguration', props.__halFormsTemplate);
    const customConfiguration = (customConfigurationFactory) ? customConfigurationFactory() : {};

    // merge default and custom configuration
    const configuration = {
        ...defaultConfiguration,
        ...customConfiguration
    };

    const handleChangeFilter = useCallback((filter: any) => {
        const action = S.maybeToNullable (
            createAction 
                (props.__ducks) 
                (props.__halFormsTemplate.id) 
                ('component.changeFilter') 
                (filter)
        );
        if (action) {
            dispatchLocal(action);

            props.__dataSourceExtender.loadData({
                page: page.number,
                size: page.size,
                ...filter
            });
        }
    }, [page, filter]);

    const handleChangeSelectedItems = useCallback((ids: string[]) => {
        const action = S.maybeToNullable (
            createAction 
                (props.__ducks) 
                (props.__halFormsTemplate.id) 
                ('component.changeSelectedItems') 
                (ids)
        );
        if (action) {
            dispatchLocal(action);
        }
    }, []);

    // determine if any operation is in progress
    let isOperationInProgress = false;
    let actions = (props.__halFormsTemplate.actions || []);
    let actionsExecutions = {};
    for (let i=0; i<actions.length; i++) {
        const isInProgressSelector = getSelector 
            (props.__ducks)
            (actions[i].id)
            ('isInProgress');
        isOperationInProgress = isOperationInProgress || 
                isInProgressSelector (pageInstanceState);

        const executionsSelector = getSelector 
            (props.__ducks)
            (actions[i].id)
            ('getExecutions');
        const executions = executionsSelector(pageInstanceState);
        if (executions) {
            actionsExecutions = {
                ...actionsExecutions,
                [actions[i].name]: executions
            }
        }
    }
    
    // back reference data source
    if (props.__dataSourcesRef && dataSource && dataSource.id) {
        props.__dataSourcesRef.current = {
            ...props.__dataSourcesRef.current,
            [dataSource.id]: {
                reload: () => props.__dataSourceExtender.loadData({
                    page: page.number || 0,
                    size: page.size || 10,
                    ...filter
                })
            }
        };
    }

    // fetch filter components from the registry
    const DataTableFilter = useMemo(() => props.__componentsRegistry.getHalFormsCustomizer(
            'tableFilter', props.__halFormsTemplate, 'filter'), [ props.__halFormsTemplate.id ]);

    const DataTableToolbarButtons = useMemo(() => createToolbarButtons({
        halFormsTemplate: props.__halFormsTemplate,
        ducks: props.__ducks,
        dataSourcesRef: props.__dataSourcesRef,
        context: {},
        container: props.container,
        data: props.data,
        getDataSourceType: props.__dataSourceExtender.getDataSourceType,
        loadData: props.__dataSourceExtender.loadData,

        onClose: props.onClose
    }), [ props.__halFormsTemplate.id, props.container, props.data ]);

    //
    // Handlers
    //
    const handleChangePage = useCallback((pageNumber: number) => {
        props.__dataSourceExtender.loadData({
            page: pageNumber,
            size: page.size || 10,
            ...filter
        })
    }, [page, filter]);

    const handleChangeRowsPerPage = useCallback((rowsPerPage: number) => {
        props.__dataSourceExtender.loadData({
            page: page.number || 0,
            size: rowsPerPage,
            ...filter
        })
    }, [page, filter]);

    const handleClickOnRow = useCallback((item: any) => {
        props.__halFormsActionExtender.executeHalFormsActionByName ('onRowClick', 
                item, props.data);
    }, [props.data]);

    // TODO: progress bar should be separate component, reused from all hal forms components, and not a propery 
    // passed to DataTable or FormPane for example.
    // It will be good to make it global anstatiated insied root page and controlled by the inner components

    return (
        <DataTable
            //
            // Components
            //
            DataTableToolbarButtons={DataTableToolbarButtons}
            // DataTableBody={DataTableBody}            
            // DataTableRowExtension={DataTableRowExtension}
            DataTableFilter={DataTableFilter}

            //
            // Configuration
            //
            enableToolbar={configuration.enableToolbar}
            enableToolbarSearchField={configuration.enableToolbarSearchField}
            enableHeader={configuration.enableHeader}
            enableItemSelection={configuration.enableItemSelection}
            // enableRowExtension={configuration.enableRowExtension}
            enablePagination={configuration.enablePagination}

            getRowId={configuration.getRowId}

            //
            // State
            //
            isLoading={props.__dataSourceExtender.isLoadingData}
            inProgress={isOperationInProgress}
            // links={links}

            columns={columns}

            //
            // Data
            //
            items={items}

            //
            // Pagination
            //
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}

            //
            // Selection
            //
            selectedItems={selectedItems}
            onChangeSelectedItems={handleChangeSelectedItems}
            
            //
            // Filter
            //
            filter={filter}
            onFilterChange={handleChangeFilter}
            
            //
            // Handlers
            //
            onRowClick={handleClickOnRow}

            //
            // Actions
            //
            getActions={getActions}
        ></DataTable>
    );
};
export default withComponentsRegistry (
    withHalFormsAction ({ }) (
        withHalFormsDataSource (HalFormsTable)
    )
);