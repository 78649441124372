// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';

import Image from 'mui-image';

import { makeStyles } from '@mui/styles';

//
// Components
//
import StockPropertiesPane from '../pane/StockPropertiesPane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        // take width of the content
        width: '1px',
        whiteSpace: 'nowrap',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },

    sku: {
        color: theme.palette.text.secondary,
    },

    extIcon: {
        marginLeft: theme.spacing(1),
    },
}));

//
// Props
//
type Props = {
    //
    // Callbacks
    //
    onClick?: () => void,

    //
    // Data
    //
    value: any,
};

//
// Component
//
const createStockRowWithPropertiesCellRenderer = (cfg: any): any => (props: any): any => {
    const stock = (props.row) ? props.row : {}
    const sku = (stock.sku) ? stock.sku : '';
    const name = (stock.name && stock.name.bg) ? stock.name.bg : '';

    // PATCH: 
    const imageUrl = `http://79.124.17.213/rp/${sku.substring(0, sku.length - 2)}_1.jpg`;
    // const imageUrl = 'https://img.tendenz.bg/2d0f648eb7b42b1bc5e429268d58b9dd0137269.jpg';

    return(
        <Grid container direction='row'>
            <Grid 
                item
                container
                style={{ justifyContent: 'center', alignItems: 'center' }}
                xs={12} sm={4} md={4} lg={4} xl={4}
            >
                <div style={{ width: '150px', height: '150px' }}>
                    <Image
                        src={imageUrl}
                        // onClick={() => console.log('onClick')}
                        // aspectRatio={(16/9)}
                        // disableSpinner
                    />
                </div>
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid item container direction='row'>
                    <Grid item>
                        <Typography variant='subtitle2' display='block'>
                            {sku}
                        </Typography>
                        <Typography variant='caption' display='block'>
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
                
                <Grid 
                    item 
                    container 
                    spacing={2}
                    direction='column'
                >                        
                    <Grid item>
                        <StockPropertiesPane
                            stock={stock}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const createStockRowWithPropertiesTableColumn = (cfg: any): any => { 
    return {
        flex: true,
        width: 200,

        renderCell: createStockRowWithPropertiesCellRenderer({
            ...cfg.cellRendererFactoryProps,
        })
    }
};
export default createStockRowWithPropertiesTableColumn;
