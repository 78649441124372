// @flow

//
// Redux
//
import { combineReducers } from 'redux';

//
// Shell
//
import { SHELL_NAME } from '../../shell';

//
// Ducks
//
import appReducer, {
    DUCK_NAME as APP_DUCK_NAME,
} from './app';

import {
    reducer as notificationsReducer,
} from '../../../../lib/trix-web-data-reducers/reducers/component/app/notifications/reducer';

//
// Reducer
//
// TODO: refactor reducer here. export appReducer into trix-web-data-reducers
// TODO2: rename trix-web-data-reducers to trix-web-reducers (or something better)
export const reducer = combineReducers({
    [SHELL_NAME]: combineReducers({
        [APP_DUCK_NAME]: appReducer,

        'notifications': notificationsReducer,
    }),
});

//
// Selectors
//
export const appSelector = (store: any) => store[SHELL_NAME][APP_DUCK_NAME];

//
// Operations
//
export const operations = [
    // End of Ducks operations
];
