// @flow

//
// React
//
import React from 'react';

//
// Tenant
//
import {
    useNavigateTo,
} from '../../../../../lib/trix-web-components-tenant';

//
// Formatters
//
import {
    fmtDate,
    fmtNumber
} from '../../../../../lib/trix-web-data-commons';

//
// Material UI
//
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '0.875rem',
    },

    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    item: any,
}

//
// Component
//
const TradeTimelineItem = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    const navigateTo = useNavigateTo();
    // End hooks

    let link = '';
    if (props.item.type === 'DOCUMENT') {
        if (props.item.documentType === 'SUPPLY') {
            link = `/wms/supply/supplies/${props.item.id}`;
        }
        if (props.item.documentType === 'SALE_ORDER') {
            link = `/wms/saleorder/saleOrders/${props.item.id}`;
        }
        if (props.item.documentType === 'SALE') {
            link = `/wms/sale/sales/${props.item.id}`;
        }
        if (props.item.documentType === 'SHIPMENT') {
            link = `/wms/shipment/shipments/${props.item.id}`;
        }
        if (props.item.documentType === 'SALE_RETURN') {
            link = `/wms/saleReturn/saleReturns/${props.item.id}`;
        }
        if (props.item.documentType === 'SALE_INVOICE') {
            link = `/fms/saleInvoice/saleInvoices/${props.item.id}`;
        }
        if (props.item.documentType === 'CREDIT_NOTE') {
            link = `/fms/saleInvoice/creditNotes/${props.item.id}`;
        }
    }

    if (link) {
        return(
            <div 
                className={clsx(classes.root, classes.link)}
                onClick={() => navigateTo(link)}
            >
                {fmtNumber(props.item.documentNo, '0000000000')} / {fmtDate(props.item.documentDate)}
            </div>
        );
    } else {
        return(
            <div className={classes.root}>
                {fmtNumber(props.item.documentNo, '0000000000')} / {fmtDate(props.item.documentDate)}
            </div>
        );
    }
};
export default TradeTimelineItem;
