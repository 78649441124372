// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React, { 
    useState,
    useCallback,
} from 'react';

//
// Trix Middleware
//
import {
    get as httpGet,
} from '../../../trix-web-middleware-commons';

//
// Trix Data
//
import {
    buildUrl,
    fmtOmni,
} from '../../../trix-web-data-commons';

//
// Material UI
//
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import AddCircle from '@mui/icons-material/AddCircle';

import { makeStyles } from '@mui/styles';

//
// Component
//
import {
    FloatPane,
    PlaceholderForFormPaneField,
    type FloatPaneContentProps,
} from '../../../trix-web-components-pane';
import { 
    ComboBox,
} from '../../../trix-web-components-combo';

//
// Helpers
//
export const getFindPropertyTypesUrl = (params: any): any => {
    return buildUrl(
        '/api/v1/core/property/propertyTypes/search/byFreeText?text={text}&page={page}&size={size}',
        params
    );
};

const SelectorAddForm = (props: {
    autoFocus: boolean,

    onAddSelectorItem: (selectorItem: any) => void,
}) => {
    const [selectorItem, setSelectorItem] = useState({
        property: undefined,
        value: undefined,
    });

    const loadPropertyValuesHandler = useCallback((value: any, reject: any, resolve: any) => {
        httpGet(
            // url
            buildUrl(
                get(selectorItem.property, '_links.findValues.href'),
                {
                    text: value,
                    page: 0,
                    size: 20
                }
            ),
            // options
            null,
            // reject callback
            reject,
            // resolve callback
            (data) =>
                resolve(
                    get(data, 'body._embedded.propertyTypeValues', [])
                )
        )
    }, [selectorItem.property]);
    // TODO: useCallback for all other ComboBoxes handlers

    return (
        <Grid
            container 
            direction='row' 
            alignItems='stretch'
            spacing={1}
            style={{ padding: '0.5em' }}
        >
            <Grid 
                item
                style={{ flexGrow: 1 }}
            >
                <ComboBox
                    autoFocus={props.autoFocus}
                    fullWidth={true}
                    variant='filled'
                    onLoadData={(value, reject, resolve) => {
                        httpGet(
                            // url
                            getFindPropertyTypesUrl({
                                // filter param
                                text: value,
                                // paging
                                page: 0,
                                size: 20,
                            }),
                            // options
                            null,
                            // reject callback
                            reject,
                            // resolve callback
                            (data) =>
                                resolve(
                                    get(data, 'body._embedded.propertyTypes', [])
                                )
                        )
                    }}
                    onGetOptionLabel={(option) =>
                        (option && option.code) ? `${option.code} / ${option.name}` : ''
                    }
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    onChange={value => {
                        setSelectorItem({
                            property: value,
                            value: undefined
                        })
                    }}
                    label='Х-ка'
                    value={selectorItem.property}
                />
            </Grid>

            <Grid 
                item
                style={{ flexGrow: 1 }}
            >
                <ComboBox
                    autoFocus={false}
                    fullWidth={true}
                    variant='filled'
                    onLoadData={loadPropertyValuesHandler}
                    onGetOptionLabel={(option) =>
                        (option && option.code) ? `${option.code} / ${fmtOmni(option.value)}` : ''
                    }
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    onChange={value => setSelectorItem({
                        property: selectorItem.property,
                        value: value,
                    })}
                    label='Ст-т'
                    value={selectorItem.value}
                    disabled={!get(selectorItem.property, '_links.findValues')}
                />
            </Grid>

            <Grid item>
                <IconButton
                    color='primary'
                    onClick={() => {
                        props.onAddSelectorItem({
                            code: get(selectorItem, 'property.code'),
                            name: get(selectorItem, 'property.name'),
                            value: get(selectorItem, 'value')
                        });

                        // celanup add form
                        setSelectorItem({
                            property: undefined,
                            value: undefined
                        });
                    }}
                    size="large">
                    <AddCircle />
                </IconButton>
            </Grid>
        </Grid>
    );
};

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },

    labelCell: {
        paddingRight: theme.spacing(1),
    },

    label: {
        fontSize: '0.875rem',
    },

    description: {
        fontSize: '0.875rem',
    },
}));

//
// Props
//
type Props = {
    //
    // Action handlers
    //
    onChange: (value: any, meta: any) => void,

    //
    // State
    //
    autoFocus: boolean,
    disabled?: boolean,
    label: string,
    description: string,
    error: boolean,
    helperText: string,

    required?: boolean,

    //
    // Data
    //
    value: any,

    dataPath: string,
}; // End of Props

//
// Component
//
const PropertiesSelectorFormPaneField = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    return (
        <Grid
            item
            container
            direction='row'
            // className={clsx(classes.root, props.className)}
        >
            <Grid item className={classes.labelCell}>
                <Typography
                    variant='body1'
                    color='textPrimary'
                    className={classes.label}
                >
                    {props.label}
                </Typography>
                <Typography
                    variant='body2'
                    color='textSecondary'
                    className={classes.description}
                >
                    {props.description}
                </Typography>
            </Grid>

            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <SelectorAddForm
                        autoFocus={props.autoFocus}

                        onAddSelectorItem={(selectorItem) => {
                            props.onChange([
                                ...get(props.value, 'items', []),
                                selectorItem
                            ], { path: `${props.dataPath}.items` })
                        }}
                    />
                </Grid>

                <Grid item container>
                    <TableContainer 
                        // component={Paper}
                    >
                        <Table className={classes.table} size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Характеристика</TableCell>
                                    <TableCell align="right">Стойност</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {get(props.value, 'items', []).map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell component='th' scope='row'>
                                        {row.code} / {row.name}
                                    </TableCell>
                                    <TableCell align='right'>{(row.value) ? row.value.code : '*'} / {(row.value) ? fmtOmni(get(row.value, 'value', {})) : '*'}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default PropertiesSelectorFormPaneField;
