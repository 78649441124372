// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Components
//
import { SearchField } from '../../../../../lib/trix-web-components-search';

//
// Styles
//
const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
    },
}));

//
// Props
//
type Props = {
    //
    // Handlers
    //
    onFilterChange: (filter: any) => void,

    //
    // Data
    //
    filter: any,
};

//
// Component
//
const TableFilter = (props: Props): Node => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    return (
        <div className={classes.root}>
            <SearchField
                placeholder='Търси стока'
                value={(props.filter && props.filter.text) ? props.filter.text : ''}
                onChange={value => props.onFilterChange({
                    text: value
                })}
            />
        </div>
    )
}
export default TableFilter;