// @flow

//
// Trix Middleware
//
import {
    type Error,
} from '../../../../trix-web-data-commons';

//
// Types
//
type Configuration = {
    ns: string;
    data: string;
}

type ActionTypes = {
    DATA_SET: string;
    DATA_CHANGED: string;
}

//
// Action types factory
//
export const createDataRecordActionsTypes = (cfg: Configuration): any => {
    return {
        DATA_SET: `@@${cfg.ns}/${cfg.data}_SET`,
        DATA_CHANGED: `@@${cfg.ns}/${cfg.data}_CHANGED`,
    };
}

//
// Actions factory
//
export const createDataRecordActions = (actionTypes: ActionTypes): any => {
    //
    // Action creators
    //
    const setData = (data: any) => {
        return {
            type: actionTypes.DATA_SET,
            payload: data,
        }
    };

    const changeData = (data: any) => {
        return {
            type: actionTypes.DATA_CHANGED,
            payload: data,
        }
    };

    return {
        setData,
        changeData,
    };
}

