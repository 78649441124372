// @flow

//
// Lodash
//
import merge from 'lodash/merge';

//
// Types
//
import { type Error } from '../../../../../trix-web-data-commons';

import { ActionsTypes } from './actions';

//
// State
//
export type State = {
    success: {
        isOpen: boolean,
        message: string,
    },
    error: {
        isOpen: boolean,
        error: Error,
    },
};

//
// Reducer factory
//
export const reducer = (
    state: State = {
        success: {
            isOpen: false,
            message: '',
        },
        error: {
            isOpen: false,
            error: { causes: [] },
        },
    },
    action: any
): State => {
    switch (action.type) {
        case ActionsTypes.SUCCESS_NOTIFICATION_OPENED:
            return reduceSuccessNotificationOpened(state, action);
        case ActionsTypes.SUCCESS_NOTIFICATION_CLOSED:
            return reduceSuccessNotificationClosed(state, action);

        case ActionsTypes.ERROR_NOTIFICATION_OPENED:
            return reduceErrorNotificationOpened(state, action);
        case ActionsTypes.ERROR_NOTIFICATION_CLOSED:
            return reduceErrorNotificationClosed(state, action);

        default:
            return state;
    }
};

//
// Composite reducers
//
const reduceSuccessNotificationOpened = (state: State, action: any) => {
    return merge({}, state, {
        success: {
            isOpen: true,
            message: action.payload.message,
        },
    });
};

const reduceSuccessNotificationClosed = (state: State, action: any) => {
    return merge({}, state, {
        success: {
            isOpen: false,
            message: '',
        },
    });
};

const reduceErrorNotificationOpened = (state: State, action: any) => {
    return merge({}, state, {
        error: {
            isOpen: true,
            error: action.payload.error,
        },
    });
};

const reduceErrorNotificationClosed = (state: State, action: any) => {
    return merge({}, state, {
        error: {
            isOpen: false,
            message: {},
        },
    });
};
