// @flow

///
// React
//
import React from 'react';

//
// Formatter
//
import { fmtQuantity } from '../../../../../lib/trix-web-data-commons';

//
// Cell renderer
//
import {
    createItemSubItemCellRenderer,
} from '../../../../../lib/trix-web-components-table';

const createSaleReturnLineQuantitiesTableColumn = (cfg: any): any => {
    return {
        align: 'right',
        flex: false,
        width: 120,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            align: 'right',

            getItem: (props: any) => {
                return fmtQuantity((props.row && props.row.quantity) ? 
                        props.row.quantity : undefined);
            },

            getSubItem: (props: any) => {
                return fmtQuantity((props.row && props.row.storedQuantity) ? 
                        props.row.storedQuantity : undefined);
            }
        })
    }
}
export default createSaleReturnLineQuantitiesTableColumn;