// @flow

import {
    PersonComboBox,
    CompanyComboBox,
    SupplierComboBox,
    CustomerComboBox,

    FloatPaneSupplierField,
    FloatPaneCustomerField,

    createDocumentNoTableColumn,
    createDocumentDateTableColumn,
    createDocumentDateTimeTableColumn,

    createPersonTableColumn,
    createCompanyTableColumn,
    createCustomerTableColumn,
    createSupplierTableColumn,
    createEmployeeTableColumn,
} from '../../../lib/trix-web-components-domain-core';

import {
    WarehouseComboBox,

    StockComboBoxFormPaneField,
    StockQuantityFormPaneField,
    StockMainQuantityFormPaneField,
    ShippingRecipientFormPaneField,

    StockFloatPaneField,
    WarehouseFloatPaneField,
    WarehouseStorageLocationFloatPaneField,

    createStockTableColumn,
    createStockWithPropertiesTableColumn,
    createStockRowWithPropertiesTableColumn,
    createWarehouseTableColumn,
    createWarehouseStorageLocationTableColumn,
    createStockQuantityTableColumn,
    createStockInventoryTableColumn,
    createStockDataAndInventoryTableColumn,
    createPriceListTableColumn,
} from '../../../lib/trix-web-components-domain-wms';

import {
    createServiceRowWithPropertiesTableColumn,
} from '../../../lib/trix-web-components-domain-service';

import {
    createProseTableColumn,
} from '../../../lib/trix-web-components-domain-prose';

// TODO: move into commons-domain-property
import { 
    createPropertyCodeTableColumn,
    createPropertyCodeNameTableColumn,
    createPropertyValueTableColumn,
    createEntityPropertyTypeTableColumn,
    createEntityPropertyCodeTableColumn,
    createEntityPropertyValueTableColumn,
    createPropertyMandatoryTableColumn,
    createPropertyValueSourceTableColumn,
    createPropertyValueTypeTableColumn,
} from '../../../lib/trix-web-components-domain-property';

import addItemFormListStocksTableConfiguration from './components/configuration/addItemFormListStocksTableConfiguration';
import AddItemFormListStocksTableFilter from './components/table/AddItemFormListStocksTableFilter';
import createAddItemFormListStocksTableRowActionsFactory from './components/table/AddItemFormListStocksTableRowActionsFactory';

import addItemFormListServicesTableConfiguration from './components/configuration/addItemFormListServicesTableConfiguration';
import AddItemFormListServicesTableFilter from './components/table/AddItemFormListServicesTableFilter';
import createAddItemFormListServicesTableRowActionsFactory from './components/table/AddItemFormListServicesTableRowActionsFactory';

export const components = {
    'tableColumn:documentNo': createDocumentNoTableColumn,
    'tableColumn:documentDate': createDocumentDateTableColumn,
    'tableColumn:documentDateTime': createDocumentDateTimeTableColumn,

    'tableColumn:person': createPersonTableColumn,
    'tableColumn:company': createCompanyTableColumn,
    'tableColumn:customer': createCustomerTableColumn,
    'tableColumn:supplier': createSupplierTableColumn,
    'tableColumn:employee': createEmployeeTableColumn,

    'floatPaneField:warehouse': WarehouseFloatPaneField,
    'floatPaneField:warehouseStorageLocation': WarehouseStorageLocationFloatPaneField,
    'floatPaneField:supplier': FloatPaneSupplierField,
    'floatPaneField:customer': FloatPaneCustomerField,

    'formPaneField:stock': StockComboBoxFormPaneField,
    'formPaneField:stockQuantity': StockQuantityFormPaneField,
    'formPaneField:stockMainQuantity': StockMainQuantityFormPaneField,

    'formPaneField:person': PersonComboBox,
    'formPaneField:company': CompanyComboBox,
    'formPaneField:supplier': SupplierComboBox,
    'formPaneField:customer': CustomerComboBox,
    'formPaneField:warehouse': WarehouseComboBox,

    'formPaneField:shippingRecipient': ShippingRecipientFormPaneField,

    'tableColumn:stock': createStockTableColumn,
    'tableColumn:stockWithProperties': createStockWithPropertiesTableColumn,
    'tableColumn:stockRowWithProperties': createStockRowWithPropertiesTableColumn,
    'tableColumn:warehouse': createWarehouseTableColumn,
    'tableColumn:warehouseStorageLocation': createWarehouseStorageLocationTableColumn,
    'tableColumn:stockQuantity': createStockQuantityTableColumn,
    'tableColumn:stockInventory': createStockInventoryTableColumn,
    'tableColumn:stockDataAndInventory': createStockDataAndInventoryTableColumn,
    'tableColumn:priceList': createPriceListTableColumn,

    'tableColumn:serviceRowWithProperties': createServiceRowWithPropertiesTableColumn,

    'tableColumn:prose': createProseTableColumn,

    // properties
    'tableColumn:propertyCode': createPropertyCodeTableColumn,
    'tableColumn:propertyCodeName': createPropertyCodeNameTableColumn,
    'tableColumn:propertyValue': createPropertyValueTableColumn,
    'tableColumn:entityPropertyType': createEntityPropertyTypeTableColumn,
    'tableColumn:entityPropertyCode': createEntityPropertyCodeTableColumn,
    'tableColumn:entityPropertyValue': createEntityPropertyValueTableColumn,
    'tableColumn:propertyMandatory': createPropertyMandatoryTableColumn,
    'tableColumn:propertyValueSource': createPropertyValueSourceTableColumn,
    'tableColumn:propertyValueType': createPropertyValueTypeTableColumn,

    // add item form - list stocks table
    'tableConfiguration:addItemFormListStocksTable': addItemFormListStocksTableConfiguration,
    'tableFilter:addItemFormListStocksTable': AddItemFormListStocksTableFilter,
    'tableRowActionsFactory:addItemFormListStocksTable': createAddItemFormListStocksTableRowActionsFactory,

    // add item form - list services table
    'tableConfiguration:addItemFormListServicesTable': addItemFormListServicesTableConfiguration,
    'tableFilter:addItemFormListServicesTable': AddItemFormListServicesTableFilter,
    'tableRowActionsFactory:addItemFormListServicesTable': createAddItemFormListServicesTableRowActionsFactory,
}

