// @flow

//
// React
//
import React, { type Node, useState } from 'react';

//
// Formatter
//
import {
    fmtOmni,
} from '../../../../lib/trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.secondary.main,
    },
    
    propertiesTable: {
    },
    propertiesTableLastRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    propertiesText: {
        fontSize: '.625rem',
    },
    
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    title?: string,
    stock: any,
};

//
// Component
//
const StockPropertiesPane = (props: Props): Node => {
    //
    // Hooks
    //
    const classes = useStyles();

    const [isMainPropertiesVisible, setMainPropertiesVisible] = useState(false);
    // End Hooks

    return (
        <>
            {(props.title) ? 
                (
                    <Typography 
                        className={classes.title}
                        variant='caption' 
                        display='block'
                    >
                        {props.title}
                    </Typography>
                ) : null
            }
            <Typography 
                variant='caption' 
                display='block' 
                className={clsx(classes.link, classes.propertiesText)}
            >
                <div
                    onClick={() => setMainPropertiesVisible(!isMainPropertiesVisible)}
                >
                    {isMainPropertiesVisible ? 
                        (<>Скрий основни характеристики</>) : (<>Покажи основни характеристики</>)
                    }
                </div>
            </Typography>

            <Table 
                className={classes.propertiesTable}
                size='small' 
            >
                <TableBody>
                    {isMainPropertiesVisible ? 
                            ((props.stock && props.stock.mainProperties) ? props.stock.mainProperties : []).map((row, index, array) => (
                                <TableRow 
                                    key={index}
                                    className={(index === array.length - 1) ? classes.propertiesTableLastRow : null}
                                >
                                    <TableCell
                                        component='th'
                                        size='small'
                                        className={classes.propertiesText}
                                    >
                                        {row.code} {row.name}
                                    </TableCell>
                                    <TableCell 
                                        size='small'
                                        align='right'
                                        className={classes.propertiesText}
                                    >
                                        {fmtOmni(row.value)}
                                    </TableCell>
                                </TableRow>
                            )
                    ) : null}

                    {((props.stock && props.stock.variationProperties) ? props.stock.variationProperties : []).map((row, index, array) => (
                        <TableRow 
                            key={index}
                            className={(index === array.length - 1) ? classes.propertiesTableLastRow : null}
                        >
                            <TableCell
                                component='th'
                                size='small'
                                className={classes.propertiesText}
                            >
                                {row.code} {row.name}
                            </TableCell>
                            <TableCell 
                                size='small'
                                align='right'
                                className={classes.propertiesText}
                            >
                                {fmtOmni(row.value)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}
export default StockPropertiesPane;
