// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

// getActionFactory :: Ducks -> TemplateId -> ActionPath -> Maybe (StrMap -> Action)
export const getActionFactory = (ducks: any): any => (templateId: string) => (path: string) => {
    return S.gets (S.is ($.AnyFunction)) ([ 'actions', templateId, ...path.split('.') ]) (ducks);
}

// createAction :: Ducks -> TemplateId -> ActionPath -> Data -> Maybe Action
export const createAction = (ducks: any): any => (templateId: string) => (actionPath: string) => (data: any, meta?: any) => {
    return S.pipe ([
        // Path -> Maybe (StrMap -> Action)
        getActionFactory (ducks) (templateId),

        // Maybe (StrMap -> Action) -> Maybe Action 
        S.map (f => f(data, meta))
    ]) (actionPath);
}

// getSelector :: Ducks -> TemplateId -> SelectorPath -> Selector
export const getSelector = (ducks: any): any => (templateId: string) => (path: string) => {
    // Sanctuary FP implementation
    // const selectorMaybe = S.gets (S.is ($.AnyFunction)) ([ 'selectors', templateId, ...path.split('.') ]) (ducks);
    // if (S.isNothing (selectorMaybe)) {
    //     return (state: any) => undefined;
    // }
    // return S.fromMaybe ((state: any) => { return {}; }) (selectorMaybe);
    
    // Native JS implementation
    // Note: native JS implementation is something like 10% faster, than Sanctuary without type checking
    const keys = [ 'selectors', templateId, ...path.split('.') ];
    let selector = ducks;
    for (let i=0; i<keys.length; i++) {
        const key = keys[i];
        if (selector[key] == null) {
            selector = null;
            break;
        }
        selector = selector[key];
    }
    return (selector != null) ? selector : (state: any) => undefined;
}
