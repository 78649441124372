// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Trix Data
//
import {
    buildUrl,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    type FormPaneContentProps,
} from '../../../../../lib/trix-web-components-pane';

import { PropertyValueFormPaneField } from '../../../../../lib/trix-web-components-property';

//
// Props
//
type Props = FormPaneContentProps & {
    //
    // Data
    //
    data: any,

    //
    // Error
    //
    error: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => any,
};

//
// Component
//
const SupplyStockInstancePropertiesFormPaneField = (props: Props): Node => {
    return (
        <React.Fragment>
            {(props.data.stock && props.data.stock.instanceProperties
                ? props.data.stock.instanceProperties
                : []
            ).map((p) => (
                <PropertyValueFormPaneField
                    key={p.code}
                    id={p.code}
                    valueType={p.valueType}
                    valueSource={p.valueSource}
                    code={p.code}
                    name={p.name}
                    description={p.description}
                    mandatory={p.mandatory}
                    
                    value={(props.data && props.data.instanceProperties) ? 
                        props.data.instanceProperties[p.code] : null}
                    error={props.error}
                    
                    onChange={props.onChange}

                    dataPath={`instanceProperties.${p.code}`}
                />
            ))}
        </React.Fragment>
    );
};
export default SupplyStockInstancePropertiesFormPaneField;
