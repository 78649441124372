// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

//
// React
//
import React, {
    useMemo,
    type ComponentType,
} from 'react';

//
// Redux Fantasy
//
import {
    useDispatchLocal,
} from '../../redux-fantasy-reducers';

//
// Tenant
//
import {
    useNavigateTo,
} from '../../trix-web-components-tenant';

//
// Context
//
import HalFormsContext from '../context/HalFormsContext';

//
// Data
//
import {
    buildUrl,
} from '../../trix-web-data-commons';

import {
    getHalFormsAction,
} from '../tools/halFormsTemplate';
import {
    createAction
} from '../tools/halFormsRedux';

import {
    evaluateCondition
} from '../tools/halFormsCondition';

//
// Helper functions
//
const setValueInObject: any = (path: any[], value: any) => (data: any) => {
    const leg = path[0];
    return (path.length > 1) ? {
            ...data,
            [leg]: setValueInObject (path.slice(1), value) (data[leg] || {})
        } : {
            ...data,
            [leg]: value
        };
}

const buildPayloadFromActionFields = (actionFields: any[]) => (data: any) => {
    const payload = S.reduce (xs => x => {
        //
        // [Deprecated] Expression based field value
        //
        if (x.expression) {
            const valueMaybe = S.gets (S.is ($.Any)) (x.expression.split('.')) ({
                data,
            });
            const value = S.maybeToNullable (valueMaybe);
            return (value) ? setValueInObject (x.field.split('.'), value) (xs) : xs;
        }

        //
        // Constant value
        //
        if (x.value && x.destinationPath) {
            return setValueInObject (x.destinationPath.split('.'), x.value) (xs);
        }

        //
        // Fetch value from data to destination
        //
        if (x.sourcePath && x.destinationPath) {
            const valueMaybe = S.gets (S.is ($.Any)) (x.sourcePath.split('.')) ({
                data,
            });
            const value = S.maybeToNullable (valueMaybe);
            return setValueInObject (x.destinationPath.split('.'), value) (xs);
        }

        return xs;
    }) ({}) (actionFields || []);
    return payload;
}

//
// Executor functions
//
type Env = {
    dispatchLocal: (action: any) => void,
    navigateTo: (path: string) => void,
    onOpenModal: (halFormsTemplate: any, ducks: any, data: any, container: any, meta?: any) => void,

    halFormsTemplate: any,
    dataSourcesRef: any,
    ducks: any,

    onClose: () => void,

    sendClientEvent: (destination: string, event: any) => void,
};

type Meta = {
    onSuccess?: (data: any) => void,
};

const executeHalFormsActionByName = (env: Env) => (halFormsActionName: string, data: any, 
        container: any, meta?: Meta) => {
    const halFormsActionMaybe = getHalFormsAction (env.halFormsTemplate) (halFormsActionName);
    if (S.isNothing (halFormsActionMaybe)) {
        console.error('[withHalFormsActionExecutor]: Can\'t find action by name: ' + halFormsActionName);
        return;
    }
    const halFormsAction = S.fromMaybe ({}) (halFormsActionMaybe);

    return executeHalFormsAction (env) (halFormsAction, data, container, meta);
}

const executeHalFormsAction = (env: Env) => (halFormsAction: any, data: any, container: any, meta?: Meta) => {
    if (!halFormsAction || !halFormsAction.name) {
        console.error('[withHalFormsActionExecutor]: Invalid hal forms action passed. ', halFormsAction);
        return;
    }

    if (halFormsAction.type === 'navigate') {
        let paramsJar = {};
        if (halFormsAction.context) {
            const rowJar = {
                data,
                parent: container,
                // TODO: maybe add params 
            };
            for(var n in halFormsAction.context) {
                paramsJar = {
                    ...paramsJar,
                    [n]: S.fromMaybe ('') (S.gets (S.is ($.String)) (halFormsAction.context[n].split('.')) (rowJar))
                }
            }
        } else {
            paramsJar = data;
        }

        // single path navigation target
        if (halFormsAction.path) {
            const to = buildUrl(halFormsAction.path, paramsJar);
            env.navigateTo(to);
        }

        // resolve target from conditional routing table
        if (halFormsAction.routes) {
            for (var r in halFormsAction.routes) {
                if (halFormsAction.routes[r].navigateIf && 
                        evaluateCondition(halFormsAction.routes[r].navigateIf, { container, data })) {
                    const to = buildUrl(halFormsAction.routes[r].path, paramsJar);
                    env.navigateTo(to);
                    return;
                }
            }
            
        }

        return;
    }

    if (halFormsAction.type === 'openLink') {
        // resolve url
        let url = '';
        if (halFormsAction.link && halFormsAction.link.href) {
            url = halFormsAction.link.href;
        }
        if (halFormsAction.rel) {
            let o = {};
            if (halFormsAction.relTarget === 'item') {
                // rel target is 'item'
                o = data;
            } else {
                // rel target is 'container'
                o = container;
            }

            if (o && o._links && o._links[halFormsAction.rel]) {
                url = o._links[halFormsAction.rel].href;
            }
        }
        if (!url) {
            console.error('Could not determine http post url for action: ', halFormsAction, ', env:', env, 
                    'data:', data, ', container:', container);
            return;
        }
        window.open(url, '_blank');        
        return;
    }

    if (halFormsAction.type === 'modal') {
        let modalData = data;
        let modalContainer = container;

        if (halFormsAction.dataDisposition === 'container') {
            modalData = {};
            modalContainer = data;
        }

        env.onOpenModal(halFormsAction.component, env.ducks, modalData, modalContainer, meta);
        return;
    }

    if (halFormsAction.type === 'httpPost') {
        // resolve url
        let url = '';
        if (halFormsAction.link && halFormsAction.link.href) {
            url = halFormsAction.link.href;
        }
        if (halFormsAction.rel) {
            let o = {};
            if (halFormsAction.relTarget === 'item') {
                // rel target is 'item'
                o = data;
            } else {
                // rel target is 'container'
                o = container;
            }

            if (o && o._links && o._links[halFormsAction.rel]) {
                url = o._links[halFormsAction.rel].href;
            }
        }
        if (!url) {
            console.error('Could not determine http post url for action: ', halFormsAction, ', env:', env, 
                    'data:', data, ', container:', container);
            return;
        }

        // resolve request data
        const requestBody = (halFormsAction.payloadFields) ? 
            buildPayloadFromActionFields (halFormsAction.payloadFields) (data) : data;

        //
        // build request
        //
        const request = {
            url,
            data: requestBody,
        }

        //
        // build request meta data
        //
        let requestMeta = {};

        if (data && data.id) {
            requestMeta = {
                ...requestMeta,
                _id: data.id,
            }
        }

        const onSuccess = (data: any) => {
            if (halFormsAction.postExecutionEffects) {
                for (let i=0; i<halFormsAction.postExecutionEffects.length; i++) {
                    if (halFormsAction.postExecutionEffects[i].operation === 'navigate') {
                        const to = buildUrl(halFormsAction.postExecutionEffects[i].path, data);
                        env.navigateTo(to);
                    }

                    if (halFormsAction.postExecutionEffects[i].operation === 'close') {
                        if (env.onClose) {
                            env.onClose();
                        }
                    }

                    if (halFormsAction.postExecutionEffects[i].operation === 'reload' && 
                            halFormsAction.postExecutionEffects[i].dataSourceId) {
                        const targetDataSourceId = halFormsAction.postExecutionEffects[i].dataSourceId;
                        if (env.dataSourcesRef && 
                                env.dataSourcesRef.current && 
                                env.dataSourcesRef.current[targetDataSourceId] && 
                                env.dataSourcesRef.current[targetDataSourceId].reload) {
                            env.dataSourcesRef.current[targetDataSourceId].reload();
                        }
                    }

                    // NOTE: other types of post execution effects are implemented into reducers.
                }
            }

            if (meta && meta.onSuccess) {
                meta.onSuccess(data);
            }
        }
        requestMeta = {
            ...requestMeta, 
            onSuccess: onSuccess,
        };

        const actionMaybe = createAction
            (env.ducks) 
            (halFormsAction.id) 
            ('requestOperation') 
            (request, requestMeta);
        const action = S.maybeToNullable (actionMaybe);
        if (action) {
            env.dispatchLocal(action);
        }

        return;
    }

    if (halFormsAction.type === 'clientEvent') {
        const eventPayload = (halFormsAction.payloadFields) ? 
            buildPayloadFromActionFields (halFormsAction.payloadFields) (data) : data;
        env.sendClientEvent(halFormsAction.eventDestination, {
            'name': halFormsAction.eventName,
            'payload': eventPayload
        });

        return;
    }

    // TODO: execute other types of actions

    console.error('[withHalFormsActionExecutor]: Unkown hal forms action type. ', halFormsAction);
}

//
// Props
//
type Props = {
    //
    // Actions
    //
    __halFormsAction?: any,
    __halFormsActions?: any[],
    
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Data sources refs
    //
    __dataSourcesRef: any,

    //
    // Container
    //
    // container: any,

    //
    // Data
    //
    data: any,

    //
    // Handlers
    //
    onClose: () => void,
} // End of Props

type WrapperProps = {
    //
    // Actions
    //
    __halFormsAction?: any,
    __halFormsActions?: any[],

    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Data source refs
    //
    __dataSourcesRef: any,

    //
    // Data
    //
    data: any,

    //
    // Handlers
    //
    onClose: () => void,

    WrappedComponent: ComponentType<any>,
    getHalFormsActions?: (halFormsTemplate: any) => any[],
    
    onOpenModal: () => void,
    onCloseModal: () => void,

    onClose: () => void,

    setClientEventListener: (listener: any) => void,
    sendClientEvent: (destination: string, event: any) => void,
} 

//
// Wapper component. Utilize useMemo to optimize components rendering on change
//
const Wrapper = (props: WrapperProps) => {
    const { 
        WrappedComponent,
        getHalFormsActions,

        onOpenModal,
        onCloseModal,

        sendClientEvent,

        onClose,

        ...componentProps
    } = props;

    //
    // Hooks
    //
    const dispatchLocal = useDispatchLocal();
    const navigateTo = useNavigateTo();
    // End hooks
    
    const halFormsActionExtender = useMemo(() => {
        // const propsCheckSum = ((props.halFormsAction) ? 1 : 0) + ((props.halFormsActions) ? 1 : 0);
        // if (propsCheckSum === 0) {
        //     console.error('[withHalFormsActionExecutor]: No <halFormsAction> or <halFormsActions> property passed. Don\'t know what to execute!');
        // } else if (propsCheckSum > 1) {
        //     console.error('[withHalFormsActionExecutor]: Extender requires only <halFormsAction> or <halFormsActions> property to be passed. Behaviour is undetermined when both are provided');
        // }

        // NOTE: seem like, halFormsActions are not needed
        // let halFormsActions: any = [];
        // if (props.__halFormsActions) {
        //     halFormsActions = props.__halFormsActions;
        // }
        // if (props.__halFormsAction) {
        //     halFormsActions = [ props.__halFormsAction ];
        // }

        // // check if more actions are specified to be handled by this component
        // if (getHalFormsActions) {
        //     const additionalHalFormsActions = getHalFormsActions(props.__halFormsTemplate);
        //     halFormsActions = [ ...halFormsActions, ...additionalHalFormsActions ];
        // }

        const handleExecuteHalFormsAction = (cfg: {
            handleModalOpen: (data: any, container: any, meta: any) => void,
            sendClientEvent: (destination: string, event: any) => void,
            onClose: () => void,
        }) => executeHalFormsAction({
                dispatchLocal,
                navigateTo,
                
                onOpenModal: cfg.handleModalOpen,
                onClose: cfg.onClose,

                sendClientEvent: cfg.sendClientEvent,
                
                halFormsTemplate: props.__halFormsTemplate,
                ducks: props.__ducks,
                dataSourcesRef: props.__dataSourcesRef,
        });
        const handleExecuteHalFormsActionByName = (cfg: {
            handleModalOpen: () => void,
            sendClientEvent: (destination: string, event: any) => void, 
            onClose: () => void,
        }) => executeHalFormsActionByName({
                dispatchLocal,
                navigateTo,

                onOpenModal: cfg.handleModalOpen,
                onClose: cfg.onClose,

                sendClientEvent: cfg.sendClientEvent,
                
                halFormsTemplate: props.__halFormsTemplate,
                ducks: props.__ducks,
                dataSourcesRef: props.__dataSourcesRef,
            }
        );
        
        return {
            executeHalFormsAction: handleExecuteHalFormsAction({
                handleModalOpen: onOpenModal,
                sendClientEvent: sendClientEvent,
                onClose: props.onClose
            }),
            executeHalFormsActionByName: handleExecuteHalFormsActionByName({
                handleModalOpen: onOpenModal,
                sendClientEvent: sendClientEvent,
                onClose: props.onClose
            }),
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onOpenModal, props.__halFormsTemplate, props.__ducks]);

    return (
        <WrappedComponent
            {...componentProps}

            __halFormsActionExtender={halFormsActionExtender}

            onClose={props.onClose}
        />
    );
}

//
// Extender
//
const withHalFormsAction = (cfg: {
    getHalFormsActions?: (halFormsTemplate: any) => any[],
}): any => (WrappedComponent: ComponentType<any>) => (props: Props) => {
    return (
        <HalFormsContext.Consumer>
            {halFormsContext => {
                return (
                    <Wrapper
                        {...props}

                        WrappedComponent={WrappedComponent}
                        getHalFormsActions={cfg.getHalFormsActions}

                        // TODO: rename action events (no need of that 'on' prefix - 'onXxx')
                        onOpenModal={halFormsContext.onOpenModal}
                        onCloseModal={halFormsContext.onCloseModal}

                        onClose={props.onClose}

                        // events dispatcher
                        setClientEventListener={halFormsContext.setClientEventListener}
                        sendClientEvent={halFormsContext.sendClientEvent}
                    />
                );
            }}
        </HalFormsContext.Consumer>
    );
};
export default withHalFormsAction;
