// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../lib/trix-fp-fantasy';

//
// React
//
import { combineReducers } from 'redux';
import concatenateReducers from 'redux-concatenate-reducers';

//
// Trix Reducers
//
import {
    createOperationSelectors,
    createHttpRequestSelectors,
    createDataListSelectors,
    createDataRecordSelectors,
    createComponentTableSelectors,
} from '../../trix-web-data-reducers';

// createSelectors :: StrMap a -> Array t -> r
export const createSelectors = (actionsTypes: any) => (templates: any) => {
    // group templates by plugin:page
    const mergedSelectors = S.reduce (xs => x => {
        if (x.type !== 'page') {
            return xs;
        }

        const key = `${x.plugin}__${x.name}`;
        const selectorsSection = createSelectorsSection (actionsTypes) (x);
        return S.concat (xs) (selectorsSection);
    }) ({}) (templates);
    return mergedSelectors;
}

// createSelectorsSection :: StrMap a -> Object t -> StrMap r
export const createSelectorsSection = (actionsTypes: any) => (t: any) => {
    let selectorsSection = {};

    // create appropriate selector for the template type
    if (t.type === 'container') {
        selectorsSection = S.concat (selectorsSection) (createContainerSelectors (actionsTypes[t.id]) (t));
    }
    if (t.type === 'table') {
        selectorsSection = S.concat (selectorsSection) (createTableSelectors (actionsTypes[t.id]) (t));
    }
    if (t.type === 'timeline') {
        selectorsSection = S.concat (selectorsSection) (createTimelineSelectors (actionsTypes[t.id]) (t));
    }

    if (t.type === 'reportingTable') {
        selectorsSection = S.concat (selectorsSection) (createReportingSelectors (actionsTypes[t.id]) (t));
    }

    // drill down components, that needs selectors sections to be created 
    if (t.components) {
        const subSelectors = S.reduce(srs => c => {
            const s = createSelectorsSection (actionsTypes) (c);
            return S.concat (srs) (s);
        }) ({}) (t.components);
        selectorsSection = S.concat (selectorsSection) (subSelectors);
    }

    // drill down into actions
    if (t.actions) {
        for (let i=0; i<t.actions.length; i++) {
            const a = t.actions[i];

            if (a.type === 'modal' && a.component) {
                const subSelectors = createSelectorsSection (actionsTypes) (a.component);
                selectorsSection = S.concat (selectorsSection) (subSelectors);
            }

            if (a.type === 'httpPost') {
                selectorsSection = S.concat (selectorsSection) (createHttpOperationSelectors (actionsTypes[a.id]) (a));
            }
        }
    }

    return (selectorsSection);
}

// createContainerSelectors :: StrMap a -> ActionsTypes t -> StrMap
const createContainerSelectors = (actionsTypes: any) => (t: any) => {
    //
    // create data selectors
    //
    const dataSelectors = createDataRecordSelectors(state => {
        return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'data' ]) (state));
    });

    //
    // create operations selectors
    //
    let operationsSelectors = {};
    let httpRequestSelectors = null;
    if (actionsTypes.operations) {
        if (actionsTypes.operations.load) {
            operationsSelectors = S.concat (operationsSelectors) ({
                load: createOperationSelectors(state => {
                    return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'operations', 'load' ])(state));
                }),
            });

            httpRequestSelectors = createHttpRequestSelectors(state => {
                return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'httpRequest' ])(state));
            });
        }
    }
  
    return {
        [t.id]: {
            data: dataSelectors,
            operations: operationsSelectors,
            httpRequest: httpRequestSelectors,
        }
    };
}

// createTableSelectors :: StrMap a -> ActionsTypes t -> StrMap
const createTableSelectors = (actionsTypes: any) => (t: any) => {
    //
    // create data selectors
    //
    const dataSelectors = createDataListSelectors(state => {
        return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'data' ]) (state));
    });

    //
    // create component selectors
    //
    const componentSelectors = createComponentTableSelectors(state => state[t.sid].component);

    //
    // create operations selectors
    //
    let operationsSelectors = {};
    let httpRequestSelectors = null;
    if (actionsTypes.operations) {
        if (actionsTypes.operations.load) {
            operationsSelectors = S.concat (operationsSelectors) ({
                load: createOperationSelectors(state => {
                    return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'operations', 'load' ])(state));
                }),
            });
        }

        // TODO:
        // post: createOperationSelectors(
        //             state => state.dataListCompaniesList.operations.post),

        httpRequestSelectors = createHttpRequestSelectors(state => {
            return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'httpRequest' ])(state));
        });
    }

    return {
        [t.id]: {
            data: dataSelectors,
            component: componentSelectors,
            operations: operationsSelectors,
            httpRequest: httpRequestSelectors,
        }
    };
}

// createTimelineSelectors :: StrMap a -> ActionsTypes t -> StrMap
const createTimelineSelectors = (actionsTypes: any) => (t: any) => {
    //
    // create data selectors
    //
    const dataSelectors = createDataListSelectors(state => {
        return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'data' ]) (state));
    });

    //
    // create operations selectors
    //
    let operationsSelectors = {};
    let httpRequestSelectors = null;
    if (actionsTypes.operations) {
        if (actionsTypes.operations.load) {
            operationsSelectors = S.concat (operationsSelectors) ({
                load: createOperationSelectors(state => {
                    return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'operations', 'load' ])(state));
                }),
            });

            httpRequestSelectors = createHttpRequestSelectors(state => {
                return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'httpRequest' ])(state));
            });
        }
    }

    return {
        [t.id]: {
            data: dataSelectors,
            operations: operationsSelectors,
            httpRequest: httpRequestSelectors,
        }
    };
}

// createReportingSelectors :: StrMap a -> ActionsTypes t -> StrMap
const createReportingSelectors = (actionsTypes: any) => (t: any) => {
    //
    // create data selectors
    //
    const dataSelectors = createDataListSelectors(state => {
        return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'data' ]) (state));
    });

    //
    // create component selectors
    //
    const componentSelectors = createComponentTableSelectors(state => state[t.sid].component);

    //
    // create operations selectors
    //
    let operationsSelectors = {};
    let httpRequestSelectors = null;
    if (actionsTypes.operations) {
        if (actionsTypes.operations.load) {
            operationsSelectors = S.concat (operationsSelectors) ({
                load: createOperationSelectors(state => {
                    return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'operations', 'load' ])(state));
                }),
            });
        }

        // TODO:
        // post: createOperationSelectors(
        //             state => state.dataListCompaniesList.operations.post),

        httpRequestSelectors = createHttpRequestSelectors(state => {
            return S.fromMaybe ({}) (S.gets (S.is ($.Object)) ([ t.sid, 'httpRequest' ])(state));
        });
    }

    return {
        [t.id]: {
            data: dataSelectors,
            component: componentSelectors,
            operations: operationsSelectors,
            httpRequest: httpRequestSelectors,
        }
    };
}


// createHttpOperationSelectors :: StrMap a -> ActionsTypes t -> StrMap
const createHttpOperationSelectors = (actionsTypes: any) => (t: any) => {
    const selectors = createOperationSelectors(state => {
        return S.fromMaybe ({}) (S.get (S.is ($.Object)) (t.sid)(state));
    })

    return {
        [t.id]: selectors
    };
}
