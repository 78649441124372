// @flow

//
// React
//
import React, {
    useState,
    useCallback,
    useMemo,
} from 'react';

//
// Trix Middleware
//
import { get as httpGet } from '../../../../../lib/trix-web-middleware-commons';

//
// Material UI
//
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

//
// Trix Data
//
import {
    hasErrorCausesForLocation,
    formatErrorsForLocation,
    fmtOmni,
} from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    TextFormPaneField,
    RadioGroupFormPaneField,
    CheckboxFormPaneField,
    I8nTextFormPaneField,
    ComboBoxFormPaneField,
    type FormPaneContentProps,
} from '../../../../../lib/trix-web-components-pane';

//
// Commons
//
import {
    resolvePropertyValueSource,
    resolvePropertyValueType,
} from '../../../utils/property';

import { buildUrl } from '../../../../../lib/trix-web-data-commons';

export const getFindPropertiesTypesUrl = (params: any) => {
    return buildUrl(
        '/api/v1/core/property/propertyTypes/search/byFreeText?text={text}&page={page}&size={size}',
        params
    );
};

export const getFindPropertyTypeValuesUrl = (url: string) => (params: any) => {
    return buildUrl(
        url,
        params
    );
};

//
// Component factories
//
const ContentForPredefinedProperty = (props: {
    data: any,
    error: any,
    onChange: (data: any, meta: any) => void,
}) => {
    const propertyTypeValuesHref = (props.data && props.data.propertyType && 
            props.data.propertyType._links && props.data.propertyType._links.findValues) ?
                    props.data.propertyType._links.findValues.href : null;

    const loadPropertyTypesHandler = useCallback(
        (value, reject, resolve) =>
            httpGet(
                // url
                getFindPropertiesTypesUrl({
                    text: value,
                    page: 0,
                    size: 20,
                }),
                // options
                null,
                // reject callback
                reject,
                // resolve callback
                (data) =>
                    resolve(
                        (data && data.body && data.body._embedded && data.body._embedded.propertyTypes) ?
                                data.body._embedded.propertyTypes : []
                    )
            ), []);
    const getPropertyTypeOptionLabelHandler = useCallback(
        (option) => `${option.code} ${option.name}`, []);
    const getPropertyTypeOptionSelectedHandler = useCallback(
        (option, value) => {
            return option.id === value.id;
        }, []);

    const changePropertyTypeHandler = useCallback(
        (value, meta) => {
            props.onChange(value, { path: 'propertyType' });
            props.onChange(value.id, { path: 'propertyTypeId' });
            props.onChange(value ? value.code : '', { path: 'code' });
            props.onChange(value ? value.name : '', { path: 'name' });
            props.onChange(value ? value.description : {}, { path: 'description' });
            props.onChange(value ? value.valueType : '', { path: 'valueType' });
            props.onChange(value ? value.valueType : '', { path: 'valueType' });
        }, []);

    const loadPropertyTypeValuesHandler = useCallback(
        (value, reject, resolve) => {
            if (null != propertyTypeValuesHref) {
                httpGet(
                    // url
                    getFindPropertyTypeValuesUrl(propertyTypeValuesHref)({
                        // filter param
                        text: value,
                        // paging
                        page: 0,
                        size: 20,
                    }),
                    // options
                    null,
                    // reject callback
                    reject,
                    // resolve callback
                    (data) => {
                        resolve(
                            (data && data.body && data.body._embedded && 
                                data.body._embedded.propertyTypeValues) ?
                                    data.body._embedded.propertyTypeValues : []
                        );
                    }
                )
            }
        }, [propertyTypeValuesHref]);
    const getPropertyTypeValueOptionLabelHandler = useCallback(
        (option) => `${option.code} ${fmtOmni(option.value)}`, []);
    const getPropertyTypeValueOptionSelectedHandler = useCallback(
        (option, value) => {
            return option.id == value.id;
        }, []);
    
    const changePropertyTypeValueHandler = useCallback(
        (value, meta) => {
            props.onChange(value, { path: '_value' });
            props.onChange(value.code, { path: 'valueCode' });
            props.onChange(value.value, { path: 'value' });
        }, []);
            
    return (
        <React.Fragment>
            <ComboBoxFormPaneField
                label='Тип характеристика'
                description='Избор на тип характеристика'

                value={props.data.propertyType}
                error={props.error}

                onLoadData={loadPropertyTypesHandler}
                onGetOptionLabel={getPropertyTypeOptionLabelHandler}
                isOptionEqualToValue={getPropertyTypeOptionSelectedHandler}

                onChange={changePropertyTypeHandler}

                dataPath='propertyType'
            />

            {(propertyTypeValuesHref) ? (
                <ComboBoxFormPaneField
                    label='Стойност характеристика'
                    description='Избор на стойност характеристика'
                    
                    value={props.data._value}
                    error={props.error}

                    onLoadData={loadPropertyTypeValuesHandler}
                    onGetOptionLabel={getPropertyTypeValueOptionLabelHandler}
                    isOptionEqualToValue={getPropertyTypeValueOptionSelectedHandler}
                    onChange={changePropertyTypeValueHandler}

                    dataPath='value'
                />) : null
            }
        </React.Fragment>
    );
};

const ContentForManualProperty = (props: {
    data: any,
    error: any,
    onChange: (data: any, meta: any) => void,
}) => {
    const changeValueHandler = useCallback(
        (value, meta) => {
            props.onChange('', { path: 'valueCode'});
            props.onChange(value, { path: 'value'});
    }, []);

    const valueTypeOptions = useMemo(() => [
        { value: 'TEXT', label: resolvePropertyValueType('TEXT') },
        { value: 'I8N_TEXT', label: resolvePropertyValueType('I8N_TEXT') },
        { value: 'INTEGER', label: resolvePropertyValueType('INTEGER') },
        { value: 'DOUBLE', label: resolvePropertyValueType('DOUBLE') },
        { value: 'DATE', label: resolvePropertyValueType('DATE') },
        { value: 'BOOLEAN', label: resolvePropertyValueType('BOOLEAN') },
    ], []);

    let PropertyValueFormPaneField = null;
    if (props.data.valueType === 'TEXT') {
        PropertyValueFormPaneField = (
            <TextFormPaneField
                autoFocus={false}
                label='Стойност'
                description=''
                // required={props.mandatory}

                value={props.data.value}
                error={props.error}

                onChange={changeValueHandler}
                
                dataPath='value'
            />
        );
    }
    if (props.data.valueType === 'I8N_TEXT') {
        PropertyValueFormPaneField = (
            <I8nTextFormPaneField
                autoFocus={false}
                disabled={false}
                label='Стойност'
                description=''

                value={props.data.value}
                error={props.error}

                onChange={changeValueHandler}
                
                dataPath='value'
            />
        );
    }
    if (props.data.valueType === 'DATE') {
        PropertyValueFormPaneField = (
            <TextFormPaneField
                autoFocus={false}
                label='Стойност'
                description=''
                                
                value={props.data.value}
                error={props.error}

                onChange={changeValueHandler}
                
                dataPath='value'
            />
        );
    }
    if (props.data.valueType === 'BOOLEAN') {
        PropertyValueFormPaneField = (
            <CheckboxFormPaneField
                autoFocus={false}
                disabled={false}
                label='Стойност'
                description=''

                value={props.data.value}
                error={props.error}

                onChange={changeValueHandler}

                dataPath='value'                
            />
        );
    }

    return (
        <React.Fragment>
            <TextFormPaneField
                autoFocus={true}
                label='Код'
                description='Вътрешен за системата код на характеристика'

                value={props.data.code}
                error={props.error}

                onChange={props.onChange}

                dataPath='code'
            />

            <TextFormPaneField
                autoFocus={false}
                label='Наименование'
                description='Наименование характеристика'

                value={props.data.name}
                error={props.error}

                onChange={props.onChange}

                dataPath='name'
            />

            <I8nTextFormPaneField
                disabled={false}
                autoFocus={false}
                label='Описание'
                description='Описание характеристика'

                value={props.data.description}
                error={props.error}

                onChange={props.onChange}
                
                dataPath='description'
            />

            <RadioGroupFormPaneField
                autoFocus={false}
                label='Тип'
                description='Тип на стойностите, които ще бъдат създадени за тази характеристика'

                value={props.data.valueType}
                error={props.error}
                
                onChange={props.onChange}

                options={valueTypeOptions}
                
                dataPath='valueType'
            />

            {PropertyValueFormPaneField}
        </React.Fragment>
    );
}

//
// Styles
//
const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: theme.spacing(3),
    },
    selectedTabText: {
        fontWeight: 700,
    }
}));

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,
    __ducks: any,
    __context: any,

    //
    // Data
    //
    data: any,
    container: any,
    error: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => void,
} // End of Props

//
// Component
//
const AddUpdateEntityPropertyEditorForm = (props: Props) => {
    //
    // Hooks
    //
    const [ mode, setMode ] = useState(0); 
    const classes = useStyles();
    // End hooks

    let text1 = 'изберете';
    let text2 = 'създадете';
    let content = (<></>);

    if (mode == 0) {
        text1 = (<span className={classes.selectedTabText}>{text1}</span>);
        text2 = (<Button color='primary' onClick={() => setMode(1)}>{text2}</Button>);
        content = (
            <ContentForPredefinedProperty
                data={props.data}
                error={props.error}
                onChange={props.onChange}
            />
        );
    }

    if (mode == 1) {
        text1 = (<Button color='primary' onClick={() => setMode(0)}>{text1}</Button>);
        text2 = (<span className={classes.selectedTabText}>{text2}</span>);
        content = (
            <ContentForManualProperty
                data={props.data}
                error={props.error}
                onChange={props.onChange}
            />
        );
    }

    return (
        <>
            <Typography variant='body1'>Можете да {text1} или да {text2} характеристика</Typography>

            <Box className={classes.content}>
                {content}
            </Box>
        </>
    );
}
export default AddUpdateEntityPropertyEditorForm;