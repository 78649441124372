// @flow

//
// React
//
import React from 'react';

//
// Formatter
//
import { fmtQuantity } from '../../../../../lib/trix-web-data-commons';

import { createQuantityCellRenderer } from '../../../../../lib/trix-web-components-table';

//
// Components
//
import {
    createItemSubItemCellRenderer,
} from '../../../../../lib/trix-web-components-table';

//
// Component
//
const createShipmentLineItemQuantityTableColumn = (cfg: any): any => {
    return {
        align: 'right',
        flex: false,
        width: 200,

        renderCell: (props: any) => {
            if (props.row.type === 'PARCEL') {
                return createItemSubItemCellRenderer({
                    ...cfg.cellRendererFactoryProps,

                    align: 'right',
                    flex: true,
        
                    getItem: (props: any) => {
                        return fmtQuantity(props.row.weight);
                    },
        
                    getSubItem: (props: any) => {
                        const width = (props.row && props.row.size) ? props.row.size.width : { amount: 0, unit: '' };
                        const height = (props.row && props.row.size) ? props.row.size.height : { amount: 0, unit: '' };
                        const depth = (props.row && props.row.size) ? props.row.size.depth : { amount: 0, unit: '' };

                        console.log('[getSubItem] >>> PROPS:', props);
                        return `(${fmtQuantity(width)} x ${fmtQuantity(height)} x ${fmtQuantity(depth)})`;
                    }
                }) (props);
            }

            if (props.row.type === 'ITEM') {
                return createQuantityCellRenderer (cfg) ({
                    value: props.row.quantity
                });
            }

            return null;
        }
    }
};
export default createShipmentLineItemQuantityTableColumn;