// @flow

//
// Lodash
//
import omit from 'lodash/omit';

//
// Types
//
type Configuration = {
    reducerKey: string;
}

type ReducerFactory = (cfg: Configuration) => (state: any, action: any) => any;
 
/**
 * `instances` reducer factory
 */
const instances = (reducer: ReducerFactory): ((state: any, action: any) => any) => (cfg: Configuration) => {
    return (state: any = {
                _meta: {
                    reducerKey: cfg.reducerKey,
                },
                _instances: {},
            }, action: any) => {
        if (!action.meta) {
            return state;
        }

        // check if this is instance of the current reducer
        // TODO: optimize
        let reducerKeyPattern = state._meta.reducerKey;
        reducerKeyPattern = reducerKeyPattern.replace('[', '\\[');
        reducerKeyPattern = reducerKeyPattern.replace(']', '\\]');
        let instanceRegEx = new RegExp(`^${reducerKeyPattern}\\._instances\\[([\\w\\-]+)\\](.*)$`);
        const isInstance = instanceRegEx.test(action.meta.reducerKey);
        // console.log('*** isInstance: action.meta.reducerKey:', action.meta.reducerKey, ', instanceRegEx:', instanceRegEx, ', isInstance:', isInstance);
        if (!isInstance) {
            return state;
        }

        const r = instanceRegEx.exec(action.meta.reducerKey);
        if (null === r) {
            return state;
        }
        const instanceId = r[1];
        const doActionRelay = !!(r[2]);

        //
        // 1. in case Action targets me - process INITIALIZED and DESTROYED
        //
        if (!doActionRelay) {
            switch (action.type) {
                // initialize instance state
                case '@@redux-unicorn/reducer/INITIALIZED':
                    // instance already initialized
                    if (state._instances[instanceId]) {
                        return state;
                    }

                    // initialize instance
                    return {
                        ...state,
                        _instances: {
                            ...state._instances,
                            [instanceId]: reducer({
                                reducerKey: action.meta.reducerKey,
                            })(undefined, '@@INIT'),
                        }
                    }

                // cleanup instance state
                case '@@redux-unicorn/reducer/DESTROYED':
                    return {
                        ...state,
                        _instances: omit(state._instances, instanceId),
                    };
            }
        }

        //
        // 2. relay action to the next reducer down the tree
        //
        let instanceState = state._instances[instanceId];
        if (null === instanceState) {
            instanceState = reducer({
                reducerKey: action.meta.reducerKey,
            })(undefined, '@@INIT')
        }
        
        // reducer instance state
        const nextInstanceState = reducer({
            reducerKey: action.meta.reducerKey,
        })(instanceState, action);
        
        // no change - return original state
        if (nextInstanceState === state._instances[instanceId]) {
            return state;
        }

        // apply next instance state
        return {
            ...state,
            _instances: {
                ...state._instances,
                [instanceId]: nextInstanceState,
            }
        }
    }
}
export default instances;
