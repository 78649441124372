// @flow

//
// React
//
import React from 'react';

//
// Other
//
import { fmtOmni } from '../../../../../lib/trix-web-data-commons';

//
// Material UI
//
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const createVariationPropertiesNameValueTableColumn = (cfg: any): any => {
    return {
        align: 'center',
        flex: true,
        width: 300,

        renderCell: (props: any): any => {
            const properties = (props.value) ? props.value : [];
            return (
                <List>
                    {properties.map(
                        (p, i, a) => {
                            return (
                                <ListItem key={i}>
                                    <Typography
                                        component='span'
                                        color='textPrimary'
                                        sx={{
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {p.code} / {p.name}: <b>{fmtOmni(p.value)}</b>
                                    </Typography>
                                </ListItem>
                            );
                        }
                    )}
                </List>
            );
        }
    }
}
export default createVariationPropertiesNameValueTableColumn;