// @flow

//
// React
//
import React from 'react';

//
// Tenant
//
import { 
    useNavigateTo,
} from '../../../../../../../lib/trix-web-components-tenant';

//
// Material UI
//
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';

//
// Props
//
type Props = {
}

//
// Component
//
const HomeHeaderItem = (props: Props): any => {
    // Hooks
    const navigateTo = useNavigateTo();
    // End hooks
    
    return (
        <IconButton
            edge='end'
            onClick={() => navigateTo('/home')}
            color='inherit'
            size="large">
            <HomeIcon />
        </IconButton>
    );
}
export default HomeHeaderItem;
