// @flow

//
// Props
//
type Props = {
    __TYPE: string,

    //
    // children
    //
    children: any,
}

//
// Component
//
const PageLayoutContent = (props: Props): any => {
    return props.children;
}

PageLayoutContent.defaultProps = {
    __TYPE: 'PageLayoutContent'
}

export default PageLayoutContent;
