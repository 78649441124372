// @flow

//
// React
//
import React from 'react';

import AddCircle from '@mui/icons-material/AddCircle';

type Cfg = {
    rowProps: any,
    execute: () => void,
}

const createActionsFactory = (cfg: Cfg): any => (action: any): any => {
    if (action.name === 'onAddItem') {
        return {
            icon: (<AddCircle />),
            label: action.title,
            onClick: () => cfg.execute(),
            showInMenu: false
        }
    }
}
export default createActionsFactory;