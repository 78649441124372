// @flow

//
// React
//
import React from 'react';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../types/CellProps';

//
// Formatter
//
import { fmtQuantity } from '../../../../trix-web-data-commons';
//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//
// Helper
//
import { renderAsLink } from './tools';

//
// Component
//
const createItemSubItemCellRenderer = (cfg: any): any => (props: any): any => {
    let alignItems = 'start';
    if (cfg.align === 'right') {
        alignItems = 'end';
    }

    let item = '';

    if (cfg.getItem) {
        item = cfg.getItem(props);
    }
    
    return (
        <Grid container direction='column' alignItems={alignItems}>
            <Grid>
                {renderAsLink(cfg.onClick, props.row)(item)}
            </Grid>
        </Grid>
    );
};
export default createItemSubItemCellRenderer;

