// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//
// Components
//
import {
    type FloatPaneMenuProps,
} from '../../trix-web-components-pane';

//
// Extender
//
import withHalFormsAction from '../extender/withHalFormsAction';

//
// Props
//
type Props = FloatPaneMenuProps & {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Actions
    //
    __halFormsActions: any[],

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Context
    //
    __context: any,

    //
    // Action Executor Extender props
    //
    __halFormsActionExtender: {
        executeHalFormsAction: (halFormsAction: any, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
        executeHalFormsActionByName: (halFormsActionName: string, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
    },

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,
} // End of Props

//
// Component
//
const HalFormsMenu = (props: Props) => {
    if (!props.__halFormsActions) {
        return null;
    }

    return (
        <Menu
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.onCloseMenu}
        >
            {
                props.__halFormsActions
                    .filter(action => {
                        if (action.createIfRel && 
                                !(props.data && props.data._links && props.data._links[action.createIfRel])) {
                            return false;
                        }
                        return true;
                    })
                    .map((a, i) => {
                        return (
                            <MenuItem
                                key={i}
                                onClick={() => {
                                    // close menu
                                    props.onCloseMenu();

                                    // execute action
                                    props.__halFormsActionExtender.executeHalFormsAction(a, 
                                        props.data, props.container,
                                        {
                                            context: props.__context,
                                        }
                                    )
                                }}
                            >
                                {a.title || a.name}
                            </MenuItem>
                        );
                    })
            }
        </Menu>
    );
};
export default withHalFormsAction ({}) (HalFormsMenu);
