// @flow

//
// Lodash
//
import fp from 'lodash/fp';
import get from 'lodash/get';

//
// React
//
import React, { useRef } from 'react';

//
// Redux Fantasy
//
import {
    withReducer,
} from '../../../lib/redux-fantasy-reducers';

//
// Tenant
//
import {
    withNavigator,
} from '../../../lib/trix-web-components-tenant';

//
// Ducks
//

//
// Material UI
//
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

//
// Components
//
import { PageLayout } from '../../../lib/trix-web-components-layout';
import HalFormsHeader from './HalFormsHeader';
import HalFormsPageContent from './halformspage/HalFormsPageContent';

//
// Props
//
type Props = {
    //
    // 'title' comes from 'withReducer' enhancer
    //
    title: string,
    
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        actions: any,
        selectors: any,
    },

    //
    // Handlers
    //
    onClose: () => void,
} // End of Props

//
// Component
//
const HalFormsPage = (props: Props) => {
    //
    // Hooks
    //
    const dataSourcesRef = useRef({});
    // End hooks

    const isClosable = (props.__halFormsTemplate && props.__halFormsTemplate.closable !== undefined) ?
        props.__halFormsTemplate.closable : true;

    return (
        <React.Fragment>
            <PageLayout>
                <PageLayout.Header>
                    <HalFormsHeader
                        title={props.title}
                        onClose={(isClosable) ? props.onClose : null}
                    />
                </PageLayout.Header>
                <PageLayout.Content>
                    <Grid
                        container
                        direction='column'
                        wrap='nowrap'
                    >
                        {
                            (get(props.__halFormsTemplate, 'components', [])
                                .map((c, i) => {
                                    return (
                                        <Grid 
                                            key={`halFormsContainer_${i}`}
                                            item
                                        >
                                            <HalFormsPageContent
                                                __ducks={props.__ducks}
                                                __halFormsTemplate={c}
                                                __dataSourcesRef={dataSourcesRef}
                                                onClose={props.onClose}
                                            />
                                        </Grid>
                                    );
                                })
                            )
                        }
                    </Grid>
                </PageLayout.Content>
            </PageLayout>
        </React.Fragment>
    );
};

export default fp.compose(
    // provide 'navigateTo' function to the 'withReducer'
    // withNavigator(),
    // withPages(),
    withReducer({
        resolveTitle: (props: any) => {
            return get(props, '__halFormsTemplate.title', '???');
        },
        resolveStorePath: (props: any) => {
            return `system.${get(props, '__halFormsTemplate.plugin', '')}__${get(props, '__halFormsTemplate.name', '')}._instances`
        },
    })
)(HalFormsPage);
