// @flow

//
// React
//
import React, {
    useMemo,
    type ComponentType,
} from 'react';

//
// React Router
//
import { useLocation } from 'react-router-dom';

//
// Trix middleware
//
import { setHttpOptions } from '../../trix-web-middleware-commons';

//
// Tenant content
//
import TenantContext from '../context/TenantContext';

//
// Props
//
type Props = {
}

//
// Wapper component. Utilize useMemo to optimize components rendering on change
//
const Wrapper = (props: {
    WrappedComponent: ComponentType<any>,
}) => {
    //
    // Hooks
    //
    const location = useLocation();
    // End hooks

    const tenantIdMatch = location.pathname.match('^/([0-9]*).*$');
    const tenantId = (tenantIdMatch && tenantIdMatch.length > 1 && parseInt(tenantIdMatch[1]) > 0) ? 
            parseInt(tenantIdMatch[1]) : 0;
    // const tenantId = 0;

    // configure global http options, to be used for all API requests
    setHttpOptions({
        headers: {
            'X-Tenant': tenantId,
        }
    });

    const { 
        WrappedComponent,
        ...componentProps
    } = props;

    return (
        <TenantContext.Provider value={tenantId}>
            <WrappedComponent
                {...componentProps}
            />
        </TenantContext.Provider>
    );
}

//
// Setup tenant content 
//
const withTenantProvider = (WrappedComponent: any): any => {
    const Component = (props: Props) => {
        // Renders the wrapped component with the context
        return (
            <Wrapper
                {...props}
                WrappedComponent={WrappedComponent}
            />
        );
    };
    return Component;
};
export default withTenantProvider;