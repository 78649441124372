// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Chip from '@mui/material/Chip';

//
// Components
//
import { resolvePropertyValueType } from '../../../../../lib/trix-web-components-property';

const createPropertyValueTypeTableColumn = (cfg: any): any => {
    return {
        align: 'center',
        flex: false,
        width: 50,

        renderCell: (props: any): any => {
            const value = resolvePropertyValueType(props.value);
            return (
                <Chip 
                    label={value} 
                />
            );
        }
    }
}
export default createPropertyValueTypeTableColumn;

