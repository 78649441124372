//
// React
//
import React, { useContext } from 'react';

//
// Tanant
//
import TenantContext from '../context/TenantContext';

//
// Tools
//
import { getLocationPreffix } from '../tools/location';

const useLocationPreffix = () => {
    const tenantId = useContext(TenantContext);
    return getLocationPreffix(tenantId);
}
export default useLocationPreffix;