// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

//
// Components
//
import Copyright from '../Copyright';
import TenantSelector from './tenantselectorpage/TenantSelector';

//
// Props
//
type Props = {
    //
    // Handlers
    //
    onTenantSelected: (tenantId: number) => void
};

//
// Components
//
const TenantSelectorPage = (props: Props): any => {
    return (
        <Container maxWidth='xs'>
            <TenantSelector
                onTenantSelected={props.onTenantSelected}
            />

            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};
export default TenantSelectorPage;
