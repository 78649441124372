// @flow

import supplyLinesTableConfiguration from './components/configuration/supplyLinesTableConfiguration';
import createSupplyLineQuantitiesTableColumn from './components/table/SupplyLineQuantitiesTableColumn';
import createSupplyLineDiscountTableColumn from './components/table/SupplyLineDiscountTableColumn';
import addSupplyLineModalConfiguration from './components/configuration/addSupplyLineModalConfiguration';

import TradeTimelineItem from '../../trix-web-plugin-wms-trade/web/components/timeline/TradeTimelineItem';

import onStoreStockActionHandlers from './components/handlers/onStoreStockActionHandlers';

import SupplyStockInstancePropertiesFormPaneField from './components/form/SupplyStockInstancePropertiesFormPaneField';

export const components = {
    'tableColumn:supplyLineQuantities': createSupplyLineQuantitiesTableColumn,
    'tableColumn:supplyLineDiscount': createSupplyLineDiscountTableColumn,

    'configuration:trix-web-plugin-wms-supply.manageSupplyPage.container.supplyLinesTable.table': supplyLinesTableConfiguration,
    'configuration:trix-web-plugin-wms-supply.manageSupplyPage.container.supplyLinesTable.action.onAddItem.editor.modal': addSupplyLineModalConfiguration,

    'timelineItem:trix-web-plugin-wms-supply.manageSupplyPage.container.trade': TradeTimelineItem,

    'actionHanders:trix-web-plugin-wms-supply.manageSupplyPage.container.supplyLinesTable.action.onStoreStock.storeStockBySku.action.onStoreStock': onStoreStockActionHandlers,

    'formPaneField:supplyStockInstanceProperties': SupplyStockInstancePropertiesFormPaneField,
}
