// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: theme.spacing(3),
    },
    selectedTabText: {
        fontWeight: 700,
    }
}));

//
// Props
//
type Props = {
    __currentHalFormTabIndex: number,
    __renderHalFormTab: (index: number) => void,
    __halFormTabs: any[],

    children: any,
} // End of Props

//
// Component
//
const CompanyCustomerFormLayout = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    let text1 = 'изберете';
    let text2 = 'създадете';

    if (props.__currentHalFormTabIndex == 0) {
        text1 = (<span className={classes.selectedTabText}>{text1}</span>);
        text2 = (<Button color='primary' onClick={() => props.__renderHalFormTab(1)}>{text2}</Button>);
    }

    if (props.__currentHalFormTabIndex == 1) {
        text1 = (<Button color='primary' onClick={() => props.__renderHalFormTab(0)}>{text1}</Button>);
        text2 = (<span className={classes.selectedTabText}>{text2}</span>);
    }

    return (
        <>
            <Typography variant='body1'>Можете да {text1} или да {text2} юридическо лице за новия клиент</Typography>

            <Box className={classes.content}>
                {props.children}
            </Box>
        </>
    );
}
export default CompanyCustomerFormLayout;