// @flow

import PriceListComboBox from './components/form/PriceListComboBox';
import PriceListCellRenderer from './components/table/PriceListCellRenderer';
import PriceListItemsTableRowExtension from './components/table/PriceListItemsTableRowExtension';

import priceListItemsTableContainerConfiguration from './components/configuration/priceListItemsTableContainerConfiguration';
import priceListRulesTableContainerConfiguration from './components/configuration/priceListRulesTableContainerConfiguration';
import priceListRulesTableConfiguration from './components/configuration/priceListItemsTableConfiguration';

export const components = {
    'formPaneField:priceList': PriceListComboBox,

    'tableCellRenderer:priceList': PriceListCellRenderer,

    'configuration:trix-web-plugin-wms-price-list.managePriceListPage.container.priceListItemsTable': priceListItemsTableContainerConfiguration,

    'configuration:trix-web-plugin-wms-price-list.managePriceListPage.container.priceListRulesTable': priceListRulesTableContainerConfiguration,

    'configuration:trix-web-plugin-wms-price-list.managePriceListPage.container.priceListItemsTable.table': priceListRulesTableConfiguration,
    'tableRowExtension:trix-web-plugin-wms-price-list.managePriceListPage.container.priceListItemsTable': PriceListItemsTableRowExtension,

    
}

