// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

//
// Formatter
//
import { fmtMoney, type Money } from '../../../trix-web-data-commons';

//
// Types
//
type Item = {
    label: string,
    value: Money,
};

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },

    itemLabel: {
        fontSize: '0.750rem',
        textAlign: 'right',
        paddingRight: theme.spacing(2),
    },

    itemValue: {
        fontSize: '0.750rem',
        textAlign: 'right',
    },

    totalLabel: {
        fontSize: '0.875rem',
        textAlign: 'right',
        paddingRight: '8px',
    },

    totalValue: {
        fontSize: '0.875rem',
        textAlign: 'right',
    },
}));

//
// Props
//
type Props = {
    //
    // Visual
    //
    className?: any,

    //
    // Data
    //
    items: Item[],
    total: Money,
}; // End of Props

//
// Component
//
const TotalsPane = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    return (
        <table className={classes.root}>
            <tbody>
                {(props.items || []).map((item, i) => (
                    <tr key={i}>
                        <td>
                            <Typography
                                variant='body1'
                                color='textSecondary'
                                className={classes.itemLabel}
                            >
                                {item.label}
                            </Typography>
                        </td>
                        <td>
                            <Typography
                                variant='body2'
                                color='textSecondary'
                                className={classes.itemValue}
                            >
                                {fmtMoney(item.value)}
                            </Typography>
                        </td>
                    </tr>
                ))}

                <tr>
                    <td>
                        <Typography
                            variant='body1'
                            color='textPrimary'
                            className={classes.totalLabel}
                        >
                            Тотал
                        </Typography>
                    </td>
                    <td>
                        <Typography
                            variant='body2'
                            color='textPrimary'
                            className={classes.totalValue}
                        >
                            {fmtMoney(props.total)}
                        </Typography>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
export default TotalsPane;
