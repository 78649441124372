// @flow

import inventoryItemsTableConfiguration from './components/table/inventoryItemsTableConfiguration';
import InventoryItemsTableFilter from './components/table/InventoryItemsTableFilter';

export const components = {
    'tableConfiguration:trix-web-plugin-wms-inventory.listInventoryItemsPage.container.table': inventoryItemsTableConfiguration,
    'tableFilter:trix-web-plugin-wms-inventory.listInventoryItemsPage.container.table.filter': InventoryItemsTableFilter,
}

