// @flow

//
// Trix Middleware
//
import {
    type Error,
} from '../../../../trix-web-data-commons';

//
// Types
//
type Configuration = {
    ns: string;
    data: string;
}

type ActionTypes = {
    DATA_SET: string;
    ITEM_ADDED: string;
    ITEM_CHANGED: string;
    ITEM_DELETED: string;
}

//
// Action types factory
//
export const createDataListActionsTypes = (cfg: Configuration): any => {
    return {
        DATA_SET: `@@${cfg.ns}/${cfg.data}_SET`,
        ITEM_ADDED: `@@${cfg.ns}/${cfg.data}_ITEM_ADDED`,
        ITEM_CHANGED: `@@${cfg.ns}/${cfg.data}_ITEM_CHANGED`,
        ITEM_DELETED: `@@${cfg.ns}/${cfg.data}_ITEM_DELETED`,
    };
}

//
// Actions factory
//
export const createDataListActions = (actionTypes: ActionTypes): any => {
    //
    // Action creators
    //
    const setData = (data: any) => {
        return {
            type: actionTypes.DATA_SET,
            payload: data,
        }
    };

    const addItem = (data: any) => {
        return {
            type: actionTypes.ITEM_ADDED,
            payload: data,
        }
    };

    const changeItem = (data: any) => {
        return {
            type: actionTypes.ITEM_CHANGED,
            payload: data,
        }
    };

    const deleteItem = (data: any) => {
        return {
            type: actionTypes.ITEM_DELETED,
            payload: data,
        }
    };

    return {
        setData,
        addItem,
        changeItem,
        deleteItem,
    };
}

