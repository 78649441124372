// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import PersonIcon from '@mui/icons-material/Person';

//
// Props
//
type Props = {
    value: string;
    onChange: (value: string) => void;
    error: boolean;
    helperText: string;
};

//
// Component
//
const UsernameTextField = (props: Props): any => {
    return (
        <TextField
            value={props.value}
            onChange={(event) => props.onChange(event.target.value)}
            error={props.error}
            helperText={props.helperText}
            variant='standard'
            fullWidth
            placeholder='Потребител'
            autoComplete='username'
            autoFocus
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <PersonIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};
export default UsernameTextField;
