// @flow

//
// React
//
import React from 'react';

//
// Tenant
//
import {
    useNavigateTo,
} from '../../../../../../../lib/trix-web-components-tenant';

import { makeStyles } from '@mui/styles';

//
// Components
//
import AppMenu from '../../../app/AppMenu';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
}));

//
// Properties
//
export type Props = {
    menus: any[],
};

//
// Components
//
const Settings = (props: Props): any => {
    // hooks
    const navigateTo = useNavigateTo();
    const classes = useStyles();
    // end hooks

    return (
        <div
            style={{
                padding: '16px',
            }}
        >
            <AppMenu
                menus={props.menus}
            />
        </div>
    );
};
export default Settings;
