// @flow

//
// Types
//
import { 
    type ActionState,
} from '../../../../../lib/trix-web-data-commons';

//
// Domain
//
import {
    type User,
} from '../../domain';

//
// Actions
//
import { type SessionState } from './types';
import {
    ActionTypes,

    type SessionInitializedAction,

    type UserLoggedOutAction,
    type UserLoggedOutAction_Success,
    type UserLoggedOutAction_Failure,
} from './actions';

//
// State
//
export type Action = 
    SessionInitializedAction |
    
    UserLoggedOutAction |
    UserLoggedOutAction_Success |
    UserLoggedOutAction_Failure;

export type State = {
    //
    // Data
    //
    user: User;

    //
    // State
    //
    sessionState: SessionState;

    //
    // Actions
    //
    actionLogOut: ActionState;
}

//
// Reducer
//
export const reducer = (state: State = {
    user: {
        username: "",

        name: "",
        email: "",

        tenantId: [],
        authorities: [],
        features: [],
    },
    
    sessionState: 'START',

    actionLogOut: {
        inProgress: false,
        error: null,
        executions: [],
    },
}, action: Action): State => {
    switch (action.type) {
        case ActionTypes.SESSION_INITIALIZED:
            return reduceSessionInitialized(state, action);
        
        case ActionTypes.USER_LOGGEDOUT:
            return reduceUserLoggedOut(state, action);
        case ActionTypes.USER_LOGGEDOUT_SUCCESS:
            return reduceUserLoggedOut_Success(state, action)
        case ActionTypes.USER_LOGGEDOUT_FAILURE:
            return reduceUserLoggedOut_Failure(state, action);

        default:
            return state;
    }
}

//
// Composite reducers
//
const reduceSessionInitialized = (state: State, 
        action: SessionInitializedAction) => {
    return {
        ...state,

        // Data
        user: action.payload.user,

        // State
        sessionState: 'INITIALIZED',
    };
}

const reduceUserLoggedOut = (state: State, 
        action: UserLoggedOutAction): State => {
    return {
        ...state,
        
        // State
        sessionState: 'LOGGING_OUT',

        // Action
        actionLogOut: {
            inProgress: true,
            error: null,
            executions: [],
        },
    };
}

const reduceUserLoggedOut_Success = (state: State, 
        action: UserLoggedOutAction_Success): State => {
    return {
        ...state,

        // Data
        user: {
            username: "",

            name: "",
            email: "",

            tenantId: [],
            authorities: [],
            features: [],
        },
        
        // State
        sessionState: 'LOGGED_OUT',

        // Action
        actionLogOut: {
            inProgress: false,
            error: null,
            executions: [],
        },
    };
}

const reduceUserLoggedOut_Failure = (state: State, 
        action: UserLoggedOutAction_Failure): State => {
    return {
        ...state,

        // Action
        actionLogOut: {
            inProgress: false,
            error: action.payload.error,
            executions: [],
        },
    };
}
