// @flow

import * as numeral from 'numeral';

export function fmtNumber(value: number, fmt?: string): string {
    const currentFmt = (fmt) ? fmt : '0,0.00';
    return (value !== undefined && value !== null) ?
        numeral(value).format(currentFmt) : numeral(0).format(currentFmt);
}

export function fmtDocumentNumber(value: number, fmt?: string): string {
    const currentFmt = (fmt) ? fmt : '0000000000';
    return (value !== undefined && value !== null) ?
        numeral(value).format(currentFmt) : numeral(0).format(currentFmt);
}