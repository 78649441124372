// @flow

//
// React
//
import React, { useCallback } from 'react';

//
// API
//
import { 
    authenticate,
} from '../../../state/api';

//
// Types
//
import {
    type Credentials,
} from '../../../state/domain';

//
// Material UI
//
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

//
// Components
//
import Copyright from '../Copyright';
import LoginForm from './loginpage/LoginForm';

//
// Props
//
type Props = {
    //
    // Handlers
    //
    onSuccessfullyAuthenticated: (user: any) => void
};

//
// Components
//
const LoginPage = (props: Props): any => {
    // Hooks
    const authenticateHandler = useCallback(
        (credentials: Credentials, reject: (error: any) => void, resolve: (user: any) => void) => {
            authenticate(
                credentials,

                // reject
                (error: any) => {
                    reject(error);
                },

                // resolve
                (user: any) => {
                    resolve(user);
                    props.onSuccessfullyAuthenticated(user);
                }
            )
        }, []
    );
    // end hooks

    return (
        <Container
            sx={{
                width: '100%',
                height: '100%',
                overflowY: 'scroll'
            }}
        >
            <Container
                maxWidth='xs'
                sx = {{
                    marginTop: theme => theme.spacing(8),
                    marginBottom: theme => theme.spacing(8),
                }}
            >
                <LoginForm
                    onAuthenticate={authenticateHandler}
                />
            </Container>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};
export default LoginPage;
