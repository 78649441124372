// @flow

//
// React
//
import React from 'react';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../types/CellProps';

//
// Material UI
//
import Typography from '@mui/material/Typography';

//
// Formatter
//
import { fmtDate, fmtTime } from '../../../../trix-web-data-commons';

//
// Helper
//
import { renderAsLink } from './tools';

//
// Component
//
const createDateTimeCellRenderer = (cfg: CellRendererFactoryProps): any => (props: CellProps): any => {
    const dateValue = fmtDate(props.value, 'll');
    const timeValue = fmtTime(props.value, 'LT');

    return(
        <div>
            <Typography 
                variant='body2'
                display='block'
                sx={{
                    fontSize: '0.875rem'
                }}
            >
                {renderAsLink(cfg.onClick, props.row)(dateValue)}
            </Typography>
            <Typography 
                variant='body2' 
                display='block' 
                sx={{
                    fontSize: '0.75rem'
                }}
            >
                {renderAsLink(cfg.onClick, props.row)(timeValue)}
            </Typography>
        </div>
    );
};
export default createDateTimeCellRenderer;
