// @flow

//
// Trix Data Commons
//
import { buildUrl } from '../../../../lib/trix-web-data-commons';

export const getFindCompanyByTenantIdUrl = (params: any): string => {
    return buildUrl(
        '/api/v1/tenant/company/companies/search/byTenant?tenantId={tenantId}',
        params
    );
};

