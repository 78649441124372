// @flow

//
// React
//
import React, { useState } from 'react';

//
// Hal forms
//
import HalFormsMenu from '../../HalFormsMenu';

//
// Material UI
//
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Actions
    //
    __halFormsActions: any[],

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Context
    //
    __context: any,

    //
    // Data sources ref
    //
    __dataSourcesRef: any,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,

    //
    // Handlers
    //
    onClose: () => void,
};

//
// Component
//
const HalFormsTableToolbarMenu = (props: Props): any => {
    //
    // Hooks
    //
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    // End hooks

    const handleMenuOpen = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    if (!props.__halFormsActions || props.__halFormsActions.length === 0) {
        return null;
    }

    return <>
        <IconButton onClick={handleMenuOpen} size='large'>
            <MoreVertIcon fontSize='small' />
        </IconButton>

        <HalFormsMenu
            __ducks={props.__ducks}
            __halFormsTemplate={props.__halFormsTemplate}
            __halFormsActions={props.__halFormsActions}
            __context={props.__context}
            __dataSourcesRef={props.__dataSourcesRef}
            container={props.container}
            data={props.data}
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onCloseMenu={handleMenuClose}

            onClose={props.onClose}
        />
    </>;
}
export default HalFormsTableToolbarMenu;