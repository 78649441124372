// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';

import { makeStyles } from '@mui/styles';

//
// Trix Data
//
import {
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    QuantityFormPaneField
} from '../../../../../lib/trix-web-components-pane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    value: any;

    //
    // Error
    //
    error: any;

    //
    // Actions
    //
    onChange: (value: any) => any;
};

//
// Component
//
const ShipmentParcelSizeField = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    const size = (props.value) ? props.value : {};

    return (
        <Grid container direction='column' className={classes.root}>
            <QuantityFormPaneField
                dimension='LENGTH'

                autoFocus={false}
                label='Ширина'
                description='Ширина на пакета'
                
                value={size.width}
                error={props.error}

                onChange={value => props.onChange({
                    ...size,
                    width: value,
                })}
                
                dataPath='width'
            />

            <QuantityFormPaneField
                dimension='LENGTH'

                autoFocus={false}
                label='Височина'
                description='Височина на пакета'

                value={size.height}
                error={props.error}
                
                onChange={value => props.onChange({
                    ...size,
                    height: value,
                })}
                
                dataPath='height'
            />

            <QuantityFormPaneField
                dimension='LENGTH'

                autoFocus={false}
                label='Дълбочина'
                description='Дълбочина на пакета'

                value={size.depth}
                error={props.error}
                
                onChange={value => props.onChange({
                    ...size,
                    depth: value,
                })}

                dataPath='depth'
            /> 
            
        </Grid>
    );
};
export default ShipmentParcelSizeField;
