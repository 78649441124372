// @flow

//
// React
//
import React from 'react';

//
// Lodash
//
import get from 'lodash/get';

//
// Material UI
//
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const createPropertyMandatoryTableColumn = (cfg: any): any => {
    return {
        align: 'center',
        flex: false,
        width: 50,

        renderCell: (props: any): any => {
            const isMandatory = props.value;
            return (
                <div>
                    {(isMandatory) ? 
                        (
                            <CheckCircleOutlineOutlinedIcon />
                        ) :
                        (
                            <></>
                        ) 
                    }
                </div>
            );
        }
    }
}
export default createPropertyMandatoryTableColumn;

