// @flow

//
// Trix Middleware
//
import {
    type Error,
} from '../../../../../lib/trix-web-data-commons';

//
// Types
//
import {
    type Credentials,
    type User,
} from '../../domain';

//
// Action types
//
export const ActionTypes = {
    SESSION_INITIALIZED:            '@@trix-web-plugin-security-auth/session/SESSION_INITIALIZED',

    USER_LOGGEDOUT:                 '@@trix-web-plugin-security-auth/session/USER_LOGGEDOUT',
    USER_LOGGEDOUT_SUCCESS:         '@@trix-web-plugin-security-auth/session/USER_LOGGEDOUT::SUCCESS',
    USER_LOGGEDOUT_FAILURE:         '@@trix-web-plugin-security-auth/session/USER_LOGGEDOUT::FAILURE',
};

//
// Actions
//
export type SessionInitializedAction = {
    type: '@@trix-web-plugin-security-auth/session/SESSION_INITIALIZED', 
    payload: {
        user: User,
    }
};

export type UserLoggedOutAction = {
    type: '@@trix-web-plugin-security-auth/session/USER_LOGGEDOUT',
    payload: {
    },
    meta: {
        onSuccess: () => void,
    }
};
export type UserLoggedOutAction_Success = {
    type: '@@trix-web-plugin-security-auth/session/USER_LOGGEDOUT::SUCCESS', 
    payload: {
    }
};
export type UserLoggedOutAction_Failure = {
    type: '@@trix-web-plugin-security-auth/session/USER_LOGGEDOUT::FAILURE',
    payload: {
        error: Error,
    }
};

//
// Action creators
//
const initializeSession = (user: User): SessionInitializedAction => {
    return {
        type: '@@trix-web-plugin-security-auth/session/SESSION_INITIALIZED',
        payload: {
            user,
        },
    }
};

const logOutUser = (onSuccess: () => void): UserLoggedOutAction => {
    return {
        type: '@@trix-web-plugin-security-auth/session/USER_LOGGEDOUT',
        payload: {
        },
        meta: {
            onSuccess,
        }
    }
};
const logOutUser_Success = (): UserLoggedOutAction_Success => {
    return {
        type: '@@trix-web-plugin-security-auth/session/USER_LOGGEDOUT::SUCCESS',
        payload: {
        }
    }
};
const logOutUser_Failure = (error: Error): UserLoggedOutAction_Failure => {
    return {
        type: '@@trix-web-plugin-security-auth/session/USER_LOGGEDOUT::FAILURE',
        payload: {
            error,
        }
    }
};

const Actions = {
    initializeSession,
    
    logOutUser,
    logOutUser_Success,
    logOutUser_Failure,
};
export default Actions;
