// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

//
// React
//
import React, {
    useMemo,
    type ComponentType,
} from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
    useDispatchLocal,
    // useOperationsError,
} from '../../redux-fantasy-reducers';

//
// Hal forms
//
import {
	getSelector,
	createAction,
} from '../tools/halFormsRedux';

//
// Data
//
import {
    buildUrl,
    buildJsonBody,
} from '../../trix-web-data-commons';

import { shallowEqual } from '../tools/halFormsObject';

//
// Executor functions
//
type Env = {
    dispatchLocal: (action: any) => void,

    halFormsTemplate: any,
    ducks: any,
    dataSource: any,

    container: any,
    data: any,
};

const getDataSourceType = (env: {
    dataSource: any,
}) => () => {
    if (env.dataSource && env.dataSource.type) {
        return env.dataSource.type;
    }
    return 'unknown';
}

const buildRemoteDataSourceUrl = (dataSource: any, data: any, container: any, params: any) => {
    let url = '';

    // build url from direct link provided
    if (dataSource && dataSource.link && dataSource.link.href) {
        url = buildUrl(dataSource.link.href, params);
    }

    // resolve link from rel
    if (dataSource && dataSource.rel) {
        let o = {};
        if (dataSource.relTarget === 'item') {
            // rel target is 'item'
            o = data;
        } else {
            // rel target is 'container'
            o = container;
        }
        if (o._links && o._links[dataSource.rel]) {
            url = buildUrl(o._links[dataSource.rel].href, params);
        }
    }

    return url;
}

const isDataLoadNeeded = (env: {
    dataSource: any,
    data: any,
    container: any,
    lastHttpRequest: any,
}) => (params: any) => {
    const dataSourceType = getDataSourceType({ dataSource: env.dataSource }) ();

    if (dataSourceType === 'remote') {
        if (!env.lastHttpRequest) {
            return true;
        }

        let url = buildRemoteDataSourceUrl(env.dataSource, env.data, env.container, params);
        if (env.lastHttpRequest.url === url && shallowEqual(env.lastHttpRequest.params, params)) {
            return false;
        }
    }

    return true;
}

const loadData = (env: Env) => (params: any) => {
    const dataSourceType = getDataSourceType({ dataSource: env.dataSource }) ();

    //
    // Remote data source 
    //
    if (dataSourceType === 'remote') {
        let postBody = {};
        if (env.dataSource.method === 'POST') {
            postBody = buildJsonBody(env.dataSource.postBody, params, env.dataSource.defaultParamsValues);
        }

        let url = buildRemoteDataSourceUrl(env.dataSource, env.data, env.container, params);
        if (url) {
            const actionMaybe = createAction 
                (env.ducks) 
                (env.halFormsTemplate.id) 
                ('operations.load.requestOperation') 
                ({
                    url,
                    // Note: post body is build and used, only if data source fetch method is POST
                    data: postBody,
                    params,
                });
            if (S.isJust (actionMaybe)) {
                env.dispatchLocal (S.fromMaybe ({}) (actionMaybe));
            }
        }

        return;
    }

    //
    // Inline data source
    //
    if (dataSourceType === 'inline') {
        // nothng to load
        return;
    }

    //
    // Simple data source
    //
    if (dataSourceType === 'simple') {
        // nothng to load
        return;
    }

    console.error('Dont know how to load data, using following data source:', env.dataSource);
}

//
// Props
//
type Props = {
    //
    // Actions
    //
    __halFormsAction?: any,
    __halFormsActions?: any[],
    
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,
} // End of Props

type WrapperProps = {
    //
    // Actions
    //
    __halFormsAction?: any,
    __halFormsActions?: any[],
    
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,

    WrappedComponent: ComponentType<any>,
    dataSource: any,
} // End of Props

//
// Wapper component. Utilize useMemo to optimize components rendering on change
//
const Wrapper = (props: WrapperProps) => {
    const { 
        WrappedComponent,
        dataSource,

        ...componentProps
    } = props;

    //
    // Hooks
    //
    const dispatchLocal = useDispatchLocal();
    const pageInstanceState = useLocalSelector (s => s);
    
    const dataSourceType = getDataSourceType({ dataSource }) ();

    let data = {};
    let isLoadingData = false;
    let lastHttpRequest = null;

    if (dataSourceType === 'remote') {
        // check if data source data has been already loaded
        const getDataSelector = getSelector
                (props.__ducks)
                (props.__halFormsTemplate.id)
                ('data.getData');
        data = (getDataSelector) ? getDataSelector(pageInstanceState) : {};

        // check for http request
        const getHttpRequestSelector = getSelector
                (props.__ducks)
                (props.__halFormsTemplate.id)
                ('httpRequest.getHttpRequest');
        lastHttpRequest = (getHttpRequestSelector) ? getHttpRequestSelector(pageInstanceState) : null;

        const isLoadingDataSelector = getSelector
                (props.__ducks)
                (props.__halFormsTemplate.id)
                ('operations.load.isInProgress');
        isLoadingData = isLoadingDataSelector && isLoadingDataSelector(pageInstanceState);
    }

    if (dataSourceType === 'inline') {
        // let items = [];
        if (dataSource.itemsPathFromContainer) {
            const items = S.fromMaybe ([]) (S.gets 
                    (S.is ($.Array ($.Object))) 
                    (dataSource.itemsPathFromContainer.split('.')) 
                    (props.container));
            data = {
                items,
                page: {
                    size: '',
                    totalElements: items.length,
                    totalPages: 1,
                    number: 0,
                }
            }
        }
        if (dataSource.itemsPathFromData) {
            const items = S.fromMaybe ([]) (S.gets 
                    (S.is ($.Array ($.Object))) 
                    (dataSource.itemsPathFromData.split('.')) 
                    (props.data));
            data = {
                items,
                page: {
                    size: '',
                    totalElements: items.length,
                    totalPages: 1,
                    number: 0,
                }
            }
        }

        if (dataSource.itemPathFromContainer) {
            data = S.fromMaybe ({}) (S.gets 
                    (S.is ($.Any)) 
                    (dataSource.itemPathFromContainer.split('.')) 
                    (props.container));
        }
        if (dataSource.itemPathFromData) {
            data = S.fromMaybe ({}) (S.gets 
                    (S.is ($.Any)) 
                    (dataSource.itemPathFromData.split('.')) 
                    (props.data));
        }
    }

    if (dataSourceType === 'simple') {
        const getDataSelector = getSelector
                    (props.__ducks)
                    (props.__halFormsTemplate.id)
                    ('data.getData');
        data = (getDataSelector) ? getDataSelector(pageInstanceState) : {};
    }

    const dataSourceExtender = useMemo(() => {
        return {
            dataSource,
            getDataSourceType: getDataSourceType({
                dataSource
            }),
            loadData: loadData({
                dispatchLocal,
                halFormsTemplate: props.__halFormsTemplate,
                ducks: props.__ducks,
                dataSource,
                container: props.container,
                data: props.data
            }),
            isDataLoadNeeded: isDataLoadNeeded({
                dataSource,
                container: props.container,
                data: props.data,
                lastHttpRequest,
            }),
            isLoadingData,
            data
        }
    }, [props.data, dataSource, data, isLoadingData, lastHttpRequest]);

    return (
        <WrappedComponent
            {...componentProps}

            __dataSourceExtender={dataSourceExtender}
        />
    );
}

//
// Extender
//
const withHalFormsDataSource = (WrappedComponent: ComponentType<any>): any => (props: Props) => {
    const dataSource = props.__halFormsTemplate.dataSource;
    if (!dataSource) {
        return (
            <WrappedComponent
                {...props}
            />
        );
    }

    return (
        <Wrapper
            {...props}

            WrappedComponent={WrappedComponent}
            dataSource={dataSource}
        />
    );
};
export default withHalFormsDataSource;