// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';

import { makeStyles } from '@mui/styles';

//
// Component
//
import {
    TextFloatPaneField,
} from '../../../trix-web-components-pane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // Main
    //
    label: string,

    //
    // Data
    //
    data: any,
}; // End of Props

//
// Component
//
const AddressFloatPaneField = (props: Props): any => {
    const clases = useStyles();

    return(
        <Grid 
            container
            direction='column'
        >
            <Grid
                item
                container
                direction='row'
            >
                <TextFloatPaneField
                    label='Държава'
                    value={(props.data && props.data.country && props.data.country.name) ? props.data.country.name : ''}
                />
                <TextFloatPaneField
                    label='Пощенски номер'
                    value={(props.data && props.data.postalCode) ? props.data.postalCode : ''}
                />
                <TextFloatPaneField
                    label='Град'
                    value={(props.data && props.data.city && props.data.city.name) ? props.data.city.name : ''}
                />
            </Grid>

            <Grid
                item
                container
                direction='row'
            >
                {(props.data && props.data.neighbourhood && props.data.neighbourhood.name) ? (
                    <TextFloatPaneField
                        label='Квартал'
                        value={(props.data && props.data.neighbourhood && props.data.neighbourhood.name) ? props.data.neighbourhood.name : ''}
                    />
                ) : null}
                
                {(props.data && props.data.street && props.data.street.name) ? (
                    <TextFloatPaneField
                        label='Улица'
                        value={(props.data && props.data.street && props.data.street.name) ? props.data.street.name : ''}
                    />
                ) : null}

                {(props.data && props.data.houseNumber) ? (
                    <TextFloatPaneField
                        label='Номер'
                        value={(props.data && props.data.houseNumber) ? props.data.houseNumber : ''}
                    />
                ) : null}
            </Grid>

            <Grid
                item
                container
                direction='row'
            >
                {(props.data && props.data.address1) ? (
                    <TextFloatPaneField
                        label='Адрес 1'
                        value={(props.data && props.data.address1) ? props.data.address1 : ''}
                    />
                ) : null}
            </Grid>

            <Grid
                item
                container
                direction='row'
            >
                {(props.data && props.data.address2) ? (
                    <TextFloatPaneField
                        label='Адрес 2'
                        value={(props.data && props.data.address2) ? props.data.address2 : ''}
                    />
                ) : null}
            </Grid>

            <Grid
                item
                container
                direction='row'
            >
                {(props.data && props.data.details) ? (
                    <TextFloatPaneField
                        label='Детайли'
                        value={(props.data && props.data.details) ? props.data.details : ''}
                    />
                ) : null}
            </Grid>

        </Grid>
    );
};
export default AddressFloatPaneField;
