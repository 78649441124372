// @flow

//
// React
//
import React, { useState } from 'react';

//
// Material UI
//
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

//
// Trix Web Components
//
import { 
    ActionProgressBar, 
    InlineErrorMessage 
} from '../../../../../../lib/trix-web-components-notifications';

import UsernameTextField from './loginform/UsernameTextField';
import PasswordTextField from './loginform/PasswordTextField';

//
// Trix Web Commons
//
import {
    emptyError,
    hasErrorCausesForParameter,
    formatErrorsForParameter,
} from '../../../../../../lib/trix-web-data-commons';

import {
    type Credentials
} from '../../../../state/domain';

//
// Props
//
type Props = {
    //
    // Handlers
    //
    onAuthenticate: (
        // data
        credentials: Credentials,
        
        // reject
        (error: any) => void,

        // resolve
        (data: any) => void
    ) => void,
};

//
// Component
//
const LoginForm = (props: Props): any => {
    // hooks
    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });
    
    const [actionLogIn, setActionLogIn] = useState({
        inProgress: false,
        error: emptyError()
    });
    // End hooks

    return (
        <Paper>
            <ActionProgressBar
                inProgress={actionLogIn.inProgress}
            />

            <Grid 
                container 
                direction='column' 
                alignItems='center'
            >
                <InlineErrorMessage
                    sx={{
                        margin: theme => theme.spacing(2)
                    }}
                    error={actionLogIn.error}
                />

                <Avatar 
                    sx={{
                        margin: theme => theme.spacing(3, 0, 1),
                        backgroundColor: theme => theme.palette.secondary.main,
                    }}
                >
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component='h1' variant='h5'>
                    Вход
                </Typography>

                <Grid item 
                    sx={{
                        margin: theme => theme.spacing(5),
                    }}
                >
                    <Grid container direction='column' rowSpacing={5}>
                        <Grid item>
                            <UsernameTextField
                                value={credentials.username}
                                onChange={value =>
                                    setCredentials(credentials => ({
                                        ...credentials,
                                        username: value,
                                    }))
                                }
                                error={hasErrorCausesForParameter(actionLogIn.error, 'username')}
                                helperText={formatErrorsForParameter(actionLogIn.error, 'username')}
                            />
                        </Grid>

                        <Grid item>
                            <PasswordTextField
                                value={credentials.password}
                                onChange={value =>
                                    setCredentials(credentials => ({
                                        ...credentials,
                                        password: value,
                                    }))
                                }
                                error={hasErrorCausesForParameter(actionLogIn.error, 'password')}
                                helperText={formatErrorsForParameter(actionLogIn.error, 'password')}
                            />
                        </Grid>

                        <Grid item >
                            <Button
                                sx={{
                                    margin: theme => theme.spacing(3, 0, 2),
                                }}
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    setActionLogIn(actionLogIn => ({
                                        error: emptyError(),
                                        inProgress: true,
                                    }));

                                    props.onAuthenticate(
                                        credentials,

                                        (error: any) => {
                                            setActionLogIn(actionLogIn => ({
                                                error,
                                                inProgress: false,
                                            }));
                                        },

                                        (user: any) => {
                                            setActionLogIn(actionLogIn => ({
                                                error: emptyError(),
                                                inProgress: false,
                                            }));
                                        }
                                    )
                                }}
                                disabled={actionLogIn.inProgress}
                            >
                                Вход
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
export default LoginForm;