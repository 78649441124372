// @flow

//
// Redux
//
import { combineReducers } from 'redux';

//
// Plugin
//
import { LIB_NAME } from '../../lib';

//
// Ducks
//
import {
    DUCK_NAME as ENTITIES_DUCK_NAME,
    reducer as entitiesReducer,
    Selectors as EntitiesSelectors,
} from './entities';

//
// Reducer
//
export const reducer = combineReducers({
    [LIB_NAME]: combineReducers({
        [ENTITIES_DUCK_NAME]: entitiesReducer,
    }),
});

//
// Selectors
//
const entitiesDuck = (store: any) => store[LIB_NAME][ENTITIES_DUCK_NAME];

export const Selectors = {
    getEntity: (store: any) => EntitiesSelectors.getEntity(entitiesDuck(store)),
    getEntities: (store: any) => EntitiesSelectors.getEntities(entitiesDuck(store)),
}

//
// Combined operations
//
export const operations = [];
