// @flow

import transferLinesTableConfiguration from './components/configuration/transferLinesTableConfiguration';
import addTransferLineModalConfiguration from './components/configuration/addTransferLineModalConfiguration';
// import transferSourcesTableConfiguration from './components/configuration/transferSourcesTableConfiguration';
// import transferOrderSelectorFieldConfiguration from './components/configuration/transferOrderSelectorFieldConfiguration';
// import transferSourceSelectorFieldConfiguration from './components/configuration/transferSourceSelectorFieldConfiguration';
// import transferSourceItemSelectorFieldConfiguration from './components/configuration/transferSourceItemSelectorFieldConfiguration';

import createTransferLineQuantitiesTableColumn from './components/table/TransferLineQuantitiesTableColumn';
import TransferLinesTableRowExtension from './components/table/TransferLinesTableRowExtension';

import PickTransferStockBarcodeField from './components/form/PickTransferStockBarcodeField';

import TradeTimelineItem from '../../trix-web-plugin-wms-trade/web/components/timeline/TradeTimelineItem';

export const components = {
    // 'configuration:trix-web-plugin-wms-transfer.manageTransferPage.container.transferSourcesTable.table': transferSourcesTableConfiguration,

    // 'configuration:trix-web-plugin-wms-transfer.manageTransferPage.container.transferLinesTable.action.onAddItemByReservation.editor._.transferSource': transferSourceSelectorFieldConfiguration,
    // 'configuration:trix-web-plugin-wms-transfer.manageTransferPage.container.transferLinesTable.action.onAddItemByReservation.editor._.transferSourceItem': transferSourceItemSelectorFieldConfiguration,

    'tableColumn:transferLineQuantities': createTransferLineQuantitiesTableColumn,
    'tableRowExtension:trix-web-plugin-wms-transfer.manageTransferPage.container.transferLinesTable': TransferLinesTableRowExtension,

    'configuration:trix-web-plugin-wms-transfer.manageTransferPage.container.transferLinesTable.table': transferLinesTableConfiguration,
    'configuration:trix-web-plugin-wms-transfer.manageTransferPage.container.transferLinesTable.action.onAddItem.editor.modal': addTransferLineModalConfiguration,

    'formPaneField:pickTransferStockBarcode': PickTransferStockBarcodeField,

    'timelineItem:trix-web-plugin-wms-transfer.manageTransferPage.container.trade': TradeTimelineItem,
}
