// @flow

//
// Lodash
//
import fp from 'lodash/fp';

//
// Duck
//
import { type State } from './reducer';

import {
    Selectors as EntitySelectors,
} from '../../../../trix-web-lib-core-entities';

//
// Selectors
//
const getData = (state: State) => state;
const getItems = (state: State) => state.items;
const getPage = (state: State) => state.page;
const getLinks = (state: State) => state.links || {};

export const createDataListSelectors = (localState: (state: any) => State): any => {
    return {
        getItems: fp.compose(getItems, localState),
        getPage: fp.compose(getPage, localState),
        getLinks: fp.compose(getLinks, localState),
        getData: fp.compose(getData, localState),
    };
};
