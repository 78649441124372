// @flow

//
// Name
//
import { PLUGIN_NAME } from './plugin';

//
// Components
//
import { components } from './web';

//
// Plugin Definition
//
const plugin = {
    name: PLUGIN_NAME,

    //
    // State
    //
    state: {
    },

    //
    // Boot functions
    //
    boot: {
        load: (tenantId: number, reject: (error: any) => void, resolve: (data: any) => void) => {
            resolve({});
            // get(
            //     // url
            //     '/api/v1/reporting/report/index', 

            //     // options
            //     {
            //         headers: {
            //             'X-Tenant': tenantId
            //         }
            //     },

            //     // reject callback
            //     reject, 

            //     // resolve callback
            //     (data) => resolve(data.body)
            // );
        },
    },

    //
    // Web
    //
    web: {
        // public components
        components
    },
}
export default plugin;