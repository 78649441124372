// @flow

import createHumanTaskStatusTableColumn from './components/table/HumanTaskStatusTableColumn';
import createHumanTaskNameTableColumn from './components/table/HumanTaskNameCellRenderer';

// import HumanTasksTableRowExtension from './components/table/HumanTasksTableRowExtension';

import ViewHumanTaskDetailsPane from './components/pane/ViewHumanTaskDetailsPane';
import ListHumanTaskPropertiesPane from './components/pane/ListHumanTaskPropertiesPane';

export const components = {
    'tableColumn:humanTaskStatus': createHumanTaskStatusTableColumn,
    'tableColumn:humanTaskName': createHumanTaskNameTableColumn,

    // 'tableRowExtension:trix-web-plugin-core-human-task.listHumanTasksPage.container.table': HumanTasksTableRowExtension,

    'customPane:viewHumanTaskDetails': ViewHumanTaskDetailsPane,
    'customPane:listHumanTaskProperties': ListHumanTaskPropertiesPane,
}
