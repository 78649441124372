// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Box from '@mui/material/Box';

//
// Components
//
import { SearchField } from '../../../../../lib/trix-web-components-search';

//
// Props
//
type Props = {
    //
    // Handlers
    //
    onFilterChange: (filter: any) => void,

    //
    // Data
    //
    filter: any,
};

//
// Component
//
const InventoryItemsTableFilter = (props: Props) => {
    return (
        <Box
            sx={{
                paddingBottom: (theme) => theme.spacing(2),
            }}
        >
            <SearchField
                placeholder='Търси'
                value={(props.filter && props.filter.text) ? props.filter.text : ''}
                onChange={value => props.onFilterChange({
                    text: value
                })}
            />
        </Box>
    )
}
export default InventoryItemsTableFilter;