// @flow

//
// React
//
import React, { useMemo } from 'react';

//
// Formatter
//
import { fmtOmni } from '../../../../../lib/trix-web-data-commons';

//
// Material UI
//
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    FloatPane
} from '../../../../../lib/trix-web-components-pane';

//
// Component factories
//
const Content = (props: any) => {
    const classes = useStyles();

    return (
        <Table
            size='small' 
        >
            <TableBody>
                {((props.data && props.data.properties) ? props.data.properties : [])
                        .filter(p => !p.system)
                        .map((p, index, array) => {
                    return (
                        <TableRow 
                            key={index}
                            className={(index === array.length - 1) ? classes.tableLastRow : null}
                        >
                            <TableCell
                                component='th'
                                size='small'
                                // className={classes.propertyText}
                            >
                                <div>{p.code} / {p.name}</div>
                                <div>
                                    <Typography
                                        variant='body1'
                                        color='textSecondary'
                                        className={classes.listItemSecondary}
                                    >
                                        {(p.description) ? p.description.bg : ''}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell
                                component='th'
                                size='small'
                                // className={classes.propertyText}
                            >
                                {fmtOmni(p.value)}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },

    tableLastRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },

    listItemSecondary: {
        fontSize: '0.8rem',
    },
}));

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Hal forms actions
    //
    __halFormsActions: any,

    //
    // Ducks
    //
    __ducks: any,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,
}

//
// Component
//
const ListHumanTaskPropertiesPane = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    return(
        <FloatPane
            Content={Content}
            isLoading={false}
            inProgress={false}
            title={props.__halFormsTemplate.title}
            container={null}
            data={props.data}
        />
    );
};
export default ListHumanTaskPropertiesPane;
