// @flow

//
// React
//
import React, { useState, useEffect } from 'react';

//
// Material UI
//
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

//
// Trix Middleware
//
import { get as httpGet } from '../../../../../lib/trix-web-middleware-commons';

//
// Trix Data
//
import {
    buildUrl,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    TextFormPaneField,
    ComboBoxFormPaneField,
    type FormPaneContentProps,
} from '../../../../../lib/trix-web-components-pane';

import { PropertyValueFormPaneField } from '../../../../../lib/trix-web-components-property';

export const getFindProductGroupUrl = (params: any) => {
    return buildUrl(
        '/api/v1/core/product/productGroups/{id}',
        params
    );
};

//
// Props
//
type Props = FormPaneContentProps & {
    //
    // Data
    //
    data: any,

    //
    // Error
    //
    error: any,

    //
    // Actions
    //
    onChange: (value: any, meta: any) => any,
};

//
// Component
//
const UpdateProductForm = (props: Props) => {
    //
    // Hooks
    //
    const [productGroup, setProductGroup] = useState({
        code: '',
        name: '',
    });

    useEffect(() => {
        if (props.data && props.data._links && props.data._links.getGroup) {
            // setCustomer(props.data);

            httpGet(
                // url
                props.data._links.getGroup.href,

                // options
                null,

                // reject callback
                (error) => { console.log(error); },

                // resolve callback
                (data) => {
                    setProductGroup(data.body);

                    // rebuild product properties array to a properties map
                    let properties = {};
                    (props.data.properties || []).forEach(p => {
                        properties[p.code] = {
                            // dummy id, that wll be used by the combo box to compare selected item
                            id: p.valueCode,
                            code: p.valueCode,
                            valueType: p.valueType,
                            value: p.value
                        };
                    });
                    
                    props.onChange(properties, { path: 'properties' });
                }
            );
        }
    }, [props.data.group]);

    // End hooks

    return (
        <React.Fragment>
            <TextFormPaneField
                autoFocus={false}
                required={true}
                disabled={true}
                label={'Продуктова група'}
                description={''}

                value={`${(props.data.group) ? props.data.group.code : ''} / ${(props.data.group && props.data.group.name) ? props.data.group.name.bg : ''}`}
                error={props.error}
                
                onChange={props.onChange}

                dataPath='group'
            />

            {(productGroup && productGroup.mainProperties
                ? productGroup.mainProperties
                : []
            ).map((p) => (
                <PropertyValueFormPaneField
                    key={p.id}
                    id={p.id}
                    valueType={p.valueType}
                    valueSource={p.valueSource}
                    code={p.code}
                    name={p.name}
                    description={p.description}
                    mandatory={p.mandatory}
                    
                    value={(props.data && props.data.properties) ? props.data.properties[p.code] : null}
                    error={props.error}
                    
                    onChange={props.onChange}

                    dataPath={`properties.${p.code}`}
                />
            ))}
        </React.Fragment>
    );
};
export default UpdateProductForm;
