// @flow

//
// Plugins
//
import securityAuthPlugin from './plugins/trix-web-plugin-security-auth';
import securityApiKeyPlugin from './plugins/trix-web-plugin-security-apikey';

import tenantCompanyPlugin from './plugins/trix-web-plugin-tenant-company';
import tenantUserPlugin from './plugins/trix-web-plugin-tenant-user';

import coreAuditLogPlugin from './plugins/trix-web-plugin-core-auditlog';
// import coreBusinessPremisePlugin from './plugins/trix-web-plugin-core-business-premise';
import coreEntityPlugin from './plugins/trix-web-plugin-core-entity';
import corePropertyPlugin from './plugins/trix-web-plugin-core-property';
import coreProductPlugin from './plugins/trix-web-plugin-core-product';
import coreServicePlugin from './plugins/trix-web-plugin-core-service';
import coreCompanyPlugin from './plugins/trix-web-plugin-core-company';
import corePersonPlugin from './plugins/trix-web-plugin-core-person';
import coreCustomerPlugin from './plugins/trix-web-plugin-core-customer';
import coreSupplierPlugin from './plugins/trix-web-plugin-core-supplier';
import payPaymentAccountPlugin from './plugins/trix-web-plugin-pay-paymentaccount';
import payPaymentDuePlugin from './plugins/trix-web-plugin-pay-paymentdue';

import commPlugin from './plugins/trix-web-plugin-comm';
import commChatPlugin from './plugins/trix-web-plugin-comm-chat';

import bpmHumanTaskPlugin from './plugins/trix-web-plugin-bpm-humantask';

import wmsPlugin from './plugins/trix-web-plugin-wms';
import wmsSettingsPlugin from './plugins/trix-web-plugin-wms-settings';
import wmsStockPlugin from './plugins/trix-web-plugin-wms-stock';
import wmsParcelPlugin from './plugins/trix-web-plugin-wms-parcel';
import wmsInventoryPlugin from './plugins/trix-web-plugin-wms-inventory';
import wmsWarehousePlugin from './plugins/trix-web-plugin-wms-warehouse';
import wmsPriceListPlugin from './plugins/trix-web-plugin-wms-pricelist';
import wmsSupplyPlugin from './plugins/trix-web-plugin-wms-supply';
import wmsSaleOrderPlugin from './plugins/trix-web-plugin-wms-saleorder';
import wmsPickingPlugin from './plugins/trix-web-plugin-wms-picking';
import wmsSalePlugin from './plugins/trix-web-plugin-wms-sale';
import wmsSaleReturnPlugin from './plugins/trix-web-plugin-wms-salereturn';
import wmsAssemblyPlugin from './plugins/trix-web-plugin-wms-assembly';
import wmsTransferPlugin from './plugins/trix-web-plugin-wms-transfer';
import wmsReceiptPlugin from './plugins/trix-web-plugin-wms-receipt';
import wmsShipmentPlugin from './plugins/trix-web-plugin-wms-shipment';
import wmsWavePlugin from './plugins/trix-web-plugin-wms-wave';
import wmsTradePlugin from './plugins/trix-web-plugin-wms-trade';

import emsOperatingExpensePlugin from './plugins/trix-web-plugin-ems-operatingexpense';

import fmsSaleInvoicePlugin from './plugins/trix-web-plugin-fms-saleinvoice';

import dialogueHostPlugin from './plugins/trix-web-plugin-dialogue-host';
import tendenzPlugin from './plugins/trix-web-plugin-tendenz';
// import coreReportPlugin from './lib/trix-web-plugin-core-report';

import clmContractPlugin from './plugins/trix-web-plugin-clm-contract';
import clmContractFfpSpartySaleOrderPlugin from './plugins/trix-web-plugin-clm-contract-ffp-sparty-saleorder';

import ffpWpartyRegisterPlugin from './plugins/trix-web-plugin-ffp-wparty-register';
import ffpSpartyRegisterPlugin from './plugins/trix-web-plugin-ffp-sparty-register';

import ffpWpartyConnectionsPlugin from './plugins/trix-web-plugin-ffp-wparty-connections';
import ffpSpartyConnectionsPlugin from './plugins/trix-web-plugin-ffp-sparty-connections';
import ffpSaleOrderPlugin from './plugins/trix-web-plugin-ffp-saleorder';

import reportingReportPlugin from './plugins/trix-web-plugin-reporting-report';

//
// Shell
//
import classicShell from './shell/trix-web-shell-classic';
import openShell from './shell/trix-web-shell-open';

//
// Init
//
import {
    bootPublicApp,
    bootMultitenantPrivateApp
} from './init/loader';

import { history } from './init/route';

//
// MAIN ROUTES TO APPLICATIONS
// Note: compose and routing should be dynamically loaded from server
//

//
// Register ffp partner warehoues app
//
const pathname = (history.location && history.location.pathname) ? history.location.pathname : '';

if (pathname === '/registerWarehouseParty') {
    // TODO:
    // bootPublicApp(openShell, ['REGISTER_FFP_WAREHOUSE_PARTY'], 
    //     (name: string) => {
    //         if (name === 'REGISTER_FFP_WAREHOUSE_PARTY') {
    //             return ffpWpartyRegisterPlugin;
    //         }
    //         console.error(`Can't load plugin '${name}'`);
    //         return null;
    //     }
    // );

//
// Register ffp partner eshop app
//
} else if (pathname === '/registerShopParty') {
    // bootPublicApp(openShell, ['REGISTER_FFP_SHOP_PARTY'], 
    //     (name: string) => {
    //         if (name === 'REGISTER_FFP_SHOP_PARTY') {
    //             return ffpSpartyRegisterPlugin;
    //         }
    //         console.error(`Can't load plugin '${name}'`);
    //         return null;
    //     }
    // );

//
// Main app
//
} else {
    bootMultitenantPrivateApp(classicShell, ['SECURITY_AUTH', 'TENANT_USER', 'WMS', 'COMM', 'CORE_ENTITY'], 
        (name: string) => {
            if (name === 'SECURITY_AUTH') {
                return securityAuthPlugin;
            }
        
            if (name === 'TENANT_COMPANY') {
                return tenantCompanyPlugin;
            }
            if (name === 'TENANT_USER') {
                return tenantUserPlugin;
            }

            // if (name === 'CORE_BUSINESS_PREMISE') {
            //     return coreBusinessPremisePlugin;
            // }

            if (name === 'SECURITY_API_KEY') {
                return securityApiKeyPlugin;
            }
            
            if (name === 'CORE_AUDIT_LOG') {
                return coreAuditLogPlugin;
            }
            if (name === 'CORE_COMPANY') {
                return coreCompanyPlugin;
            }
            if (name === 'CORE_PERSON') {
                return corePersonPlugin;
            }
            if (name === 'CORE_CUSTOMER') {
                return coreCustomerPlugin;
            }
            if (name === 'CORE_SUPPLIER') {
                return coreSupplierPlugin;
            }

            if (name === 'CORE_PROPERTY') {
                return corePropertyPlugin;
            }
            if (name === 'CORE_ENTITY') {
                return coreEntityPlugin;
            }

            if (name === 'CORE_PRODUCT') {
                return coreProductPlugin;
            }
            if (name === 'CORE_SERVICE') {
                return coreServicePlugin;
            }

            if (name === 'COMM') {
                return commPlugin;
            }
            if (name === 'COMM_CHAT') {
                return commChatPlugin;
            }

            if (name === 'PAY_PAYMENT_ACCOUNT') {
                return payPaymentAccountPlugin;
            }
            if (name === 'PAY_PAYMENT_DUE') {
                return payPaymentDuePlugin;
            }

            if (name === 'BPM_HUMAN_TASK') {
                return bpmHumanTaskPlugin;
            }

            if (name === 'WMS') {
                return wmsPlugin;
            }
            if (name === 'WMS_SETTINGS') {
                return wmsSettingsPlugin;
            }
            if (name === 'WMS_STOCK') {
                return wmsStockPlugin;
            }
            if (name === 'WMS_PARCEL') {
                return wmsParcelPlugin;
            }
            if (name === 'WMS_ASSEMBLY') {
                return wmsAssemblyPlugin;
            }
            if (name === 'WMS_INVENTORY') {
                return wmsInventoryPlugin;
            }
            if (name === 'WMS_WAREHOUSE') {
                return wmsWarehousePlugin;
            }
            if (name === 'WMS_PRICE_LIST') {
                return wmsPriceListPlugin;
            }
            if (name === 'WMS_SUPPLY') {
                return wmsSupplyPlugin;
            }
            if (name === 'WMS_SALE_ORDER') {
                return wmsSaleOrderPlugin;
            }
            if (name === 'WMS_PICKING') {
                return wmsPickingPlugin;
            }
            if (name === 'WMS_SALE') {
                return wmsSalePlugin;
            }
            if (name === 'WMS_SALERETURN') {
                return wmsSaleReturnPlugin;
            }
            if (name === 'WMS_RECEIPT') {
                return wmsReceiptPlugin;
            }
            if (name === 'WMS_SHIPMENT') {
                return wmsShipmentPlugin;
            }
            if (name === 'WMS_TRADE') {
                return wmsTradePlugin;
            }
            if (name === 'WMS_TRANSFER') {
                return wmsTransferPlugin;
            }
            if (name === 'WMS_WAVE') {
                return wmsWavePlugin;
            }

            if (name === 'EMS_OPERATING_EXPENSE') {
                return emsOperatingExpensePlugin;
            }

            if (name === 'FMS_SALE_INVOICE') {
                return fmsSaleInvoicePlugin;
            }

            if (name === 'CLM_CONTRACT') {
                return clmContractPlugin;
            }
            if (name === 'CLM_CONTRACT_FFP_SPARTY_SALEORDER') {
                return clmContractFfpSpartySaleOrderPlugin;
            }

            if (name === 'DIALOGUE') {
                return dialogueHostPlugin;
            }

            if (name === 'TENDENZ') {
                return tendenzPlugin;
            }

            // // TODO: kill this and replace with FFP_ESHOP
            // if (name === 'REPORT_WMS_FULFILLMENT') {
            //     return reportWmsFulfillmentPlugin;
            // }

            // if (name === 'FFP_WPARTY_CONNECTIONS') {
            //     return ffpWpartyConnectionsPlugin;
            // }
            // if (name === 'FFP_SPARTY_CONNECTIONS') {
            //     return ffpSpartyConnectionsPlugin;
            // }
                        
            // if (name === 'FFP_SALEORDER') {
            //     return ffpSaleOrderPlugin;
            // }

            if (name === 'REPORTING_REPORT') {
                return reportingReportPlugin;
            }

            console.error(`Can't load plugin '${name}'`);
            return null;
        }
    );
}
