// @flow

//
// Types
//
import {
    type ActionState,
} from '../../../../trix-web-data-commons';

import assign from 'lodash/assign'; 

//
// Actions
//
import {
    ActionTypes,

    type StoreEntityRequestedAction,
} from './actions';

//
// State
//
type Action = 
    StoreEntityRequestedAction;

type EntityTypeStorage = { [id: string]: any };

export type State = {
    //
    // Data
    //
    entities: { [type: string]: EntityTypeStorage },
}

//
// Reducer
//
export const reducer = (state: State = {
    entities: {},
}, action: Action): State => {
    switch (action.type) {
        case ActionTypes.STORE_ENTITIES_REQUESTED:
            return reduceStoreEntityRequested(state, action);

        default:
            return state;
    }
}

//
// Composite reducers
//
const reduceStoreEntityRequested = (state: State,
        action: StoreEntityRequestedAction) => {
    return {
        ...state,

        entities: {
            ...state.entities,
            [action.payload.type]: assign({}, 
                    state.entities[action.payload.type], 
                    action.payload.entities),
        }
    };
}
