// @flow

//
// React
//
import React, {
    useMemo, 
    type ComponentType,
} from 'react';

//
// Trix Data
//
import { type Error } from '../../../trix-web-data-commons';

//
// Material UI
//
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';

import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Components
//
import DataDialogHeader from './DataDialogHeader';
import { type DataDialogContentProps } from './types/DataDialogContentProps';
import { type DataDialogButtonsProps } from './types/DataDialogButtonsProps';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey['100'],
    },

    floatRoot: {
        width: '700px',
        maxWidth: '700px',
        height: '700px',
    },

    progress: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
}));

//
// Props
//
type Props = {
    //
    // Configuration
    //
    enableHeader?: boolean,
    enableButtons?: boolean,

    //
    // Components factories
    //
    DataDialogContent?: ComponentType<DataDialogContentProps>,
    DataDialogButtons?: ComponentType<DataDialogButtonsProps>,

    //
    // Actions
    //
    onClose: () => void,

    //
    // Visual
    //
    className?: any,

    //
    // State
    //
    isOpen: boolean,
    inProgress?: boolean,

    //
    // Header
    //
    title: string,

    //
    // Data
    //
    error?: Error,
    data?: any,
}; // End of Props

//
// Component
//
const DataDialog = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const dataDialogHeader = useMemo(() => {
        return (props.enableHeader) ? (
                <DataDialogHeader 
                    onClose={props.onClose} 
                    title={props.title} 
                />
            ) : null
    }, [props.enableHeader, props.title]);

    const dataDialogContent = useMemo(() => {
        return (props.DataDialogContent) ? (
            <DialogContent dividers={props.enableHeader}>
                <props.DataDialogContent
                    inProgress={props.inProgress}
                    data={props.data}
                    error={props.error}
                />
            </DialogContent>
        ) : null
    }, [props.data, props.error, props.inProgress, props.enableHeader]);

    const dataDialogActions = useMemo(() => {
        return (props.enableButtons && props.DataDialogButtons) ? (
            <DialogActions>
                <props.DataDialogButtons
                    onClose={props.onClose}
                    inProgress={props.inProgress}
                    data={props.data}
                />
            </DialogActions>
        ) : null
    }, [props.data, props.enableButtons, props.inProgress]);

    const paperProps = useMemo(() => {
        return {
            className: (fullScreen) ? clsx(classes.root) : clsx(classes.root, classes.floatRoot),
        }
    }, [fullScreen]);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.isOpen}
            onClose={props.onClose}
            PaperProps={paperProps}
        >
            <div className={classes.progress}>
                {props.inProgress ? (
                    <LinearProgress variant='indeterminate' />
                ) : null}
            </div>

            {dataDialogHeader}

            {dataDialogContent}

            {dataDialogActions}
        </Dialog>
    );
};
export default DataDialog;
