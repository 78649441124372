// @flow

//
// Shell
//
import { SHELL_NAME } from './shell';

//
// Ducks
//
import {
    reducer,
    operations,
} from './state/ducks';

//
// Components
//
import { IndexRoute } from './web/index';
import Root from './web/components/Root';

//
// Plugin Definition
//
const shell = {
    name: SHELL_NAME,

    //
    // State
    //
    state: {
        operations,
        reducer,
    },

    //
    // Components
    //
    web: {
        indexRoute: IndexRoute,

        components: {
            Root,
        }
    },
}
export default shell;
