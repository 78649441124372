// @flow

//
// React
//
import React, { 
    type ComponentType,
} from 'react';

//
// Trix Data
//
import { type Error } from '../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Types
//
import { type FormPaneContentProps } from './type/FormPaneContentProps';
import { type FormPaneButtonsProps } from './type/FormPaneButtonsProps';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        width: '100%',
    },

    form: {
        position: 'relative',
        padding: theme.spacing(1),
    },

    formRow: {
        padding: theme.spacing(1),
    },

    progress: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
}));

//
// Props
//
type Props = {
    //
    // Components factories
    //
    FormPaneContent?: ComponentType<FormPaneContentProps>,
    FormPaneButtons?: ComponentType<FormPaneButtonsProps>,

    //
    // Visual
    //
    component?: Node,
    className?: any,

    //
    // State
    //
    inProgress: boolean,

    //
    // Data
    //
    error?: Error,
    data: any,
}; // End of Props

//
// Component
//
const FormPane = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    const FormPaneContent = (props.FormPaneContent) ? (
        <props.FormPaneContent
            inProgress={props.inProgress}
            data={props.data}
            error={props.error}
        />
    ) : null;

    const FormPaneButtons = (props.FormPaneButtons) ? (
        <props.FormPaneButtons
            inProgress={props.inProgress}
            data={props.data}
            error={props.error}
        />
    ) : null;

    return (
        <div className={clsx(classes.root, props.className)}>
            <Grid
                container
                direction='column'
                wrap='nowrap'
                component={props.component}
                className={classes.form}
                spacing={2}
            >
                <div className={classes.progress}>
                    {props.inProgress ? (
                        <LinearProgress variant='indeterminate' />
                    ) : null}
                </div>

                <Grid item>
                    {FormPaneContent}
                </Grid>

                <Grid item>
                    {FormPaneButtons}
                </Grid>
            </Grid>
        </div>
    );
};
export default FormPane;
