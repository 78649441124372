// @flow

//
// Lodash
//
import debounce from 'lodash/debounce';

//
// React
//
import React, { 

    useCallback,
    useMemo
} from 'react';

//
// Material UI
//
import Button from '@mui/material/Button';
// import SearchIcon from '@mui/icons-material/Search';

import {
    useGridApiContext,
} from '@mui/x-data-grid';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    input: {
        fontSize: '12px',
    },
}));

//
// Props
//
type Props = {
    //
    // Value
    //
    // value: string,

    //
    // Handlers
    //
    // onChange: (text: string) => void,
};

//
// Component
//
const ExportField = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    const apiRef = useGridApiContext();

    //
    // Debounced load data handler
    //
    // const debouncedChangeHandler = debounce(value => {
    //     if (props.onChange) {
    //         props.onChange(value);
    //     }
    // }, 500);

    // const handleTextChange = useCallback((e: any) => {
    //     debouncedChangeHandler(e.target.value);
    // }, []);

    // const inputProps = useMemo(() => {
    //     return {
    //             classes: classes,
    //             startAdornment: (
    //                 <InputAdornment position='start'>
    //                     <SearchIcon color='disabled' />
    //                 </InputAdornment>
    //             ),
    //         }
    //     }, []);

    return (
        <Button
            color='primary'
            size='small'
            // disabled={destinationType === 'address'}
            onClick={(event) => {
                console.log('API REF:', apiRef);
                apiRef.current.exportDataAsCsv();
                // changeHandler('address', { path: `ref1` });
            }}
        >CSV</Button>
    );
};
export default ExportField;