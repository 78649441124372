// @flow

//
// React
//
import React from 'react';

//
// Formatter
//
import { fmtQuantity } from '../../../../../lib/trix-web-data-commons';

//
// Cell renderer
//
import {
    createItemSubItemCellRenderer,
} from '../../../../../lib/trix-web-components-table';

const createTransferLineQuantitiesTableColumn = (cfg: any): any => {
    return {
        align: 'right',
        flex: false,
        width: 120,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            align: 'right',

            getItem: (props: any) => {
                return fmtQuantity((props.row && props.row.quantity) ? 
                        props.row.quantity : undefined);
            },

            getSubItem: (props: any) => {
                const pickedQuantity = (props.row && props.row.pickedQuantity) ? props.row.pickedQuantity : {
                    amount: 0.0, 
                    unit: '-'
                };
                const cancelledQuantity = (props.row && props.row.cancelledQuantity) ? props.row.cancelledQuantity : {
                    amount: 0.0, 
                    unit: '-'
                };
                const sendQuantity = (props.row && props.row.shippedQuantity) ? props.row.shippedQuantity : {
                    amount: 0.0, 
                    unit: '-'
                };
                const receivedQuantity = (props.row && props.row.storedQuantity) ? props.row.storedQuantity : {
                    amount: 0.0, 
                    unit: '-'
                };

                return `${fmtQuantity(pickedQuantity)} (${fmtQuantity(cancelledQuantity)}) / ${fmtQuantity(sendQuantity)} / ${fmtQuantity(receivedQuantity)}`;
            }
        })
    }
}
export default createTransferLineQuantitiesTableColumn;