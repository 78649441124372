// @flow

//
// React
//
import React, { useEffect } from 'react';

//
// Trix Middleware
//
import { get as httpGet } from '../../../../../lib/trix-web-middleware-commons';

//
// Trix Data
//
import {
    buildUrl,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Components
//
import { PropertyValueFormPaneField } from '../../../../../lib/trix-web-components-property';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    }
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    data: any,

    //
    // Error
    //
    error: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => any,
};

//
// Component
//
const CreateDialogueAgentPropertiesEditor = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();

    useEffect(() => {
        if (props.data.domain && 
                props.data.domain._links && 
                props.data.domain._links.getConfig) {
            const getConfigUrl = buildUrl(props.data.domain._links.getConfig.href, {
                data: props.data
            });

            httpGet(
                // url
                getConfigUrl,

                // options
                null,

                // reject callback
                (error) => {
                    console.error("Error load dialogue domain config. Error: ", error)
                },

                // resolve callback
                (data) => {
                    props.onChange(data && data.body && data.body.properties ?
                            data.body.properties : [], { path: 'properties' })
                }
            )
        }
    }, [props.data.domain, props.data.connector])
    // End hooks

    if (!props.data || !props.data.properties) {
        return null;
    }

    return (
        <React.Fragment>
            {(props.data && props.data.properties
                ? props.data.properties
                : []
            ).map((p, i) => (
                <PropertyValueFormPaneField
                    key={i}
                    id={p.code}
                    valueType={p.valueType}
                    valueSource={p.valueSource}
                    code={p.code}
                    name={p.name}
                    description={p.description}
                    mandatory={p.mandatory}
                    
                    value={(props.data && props.data.values) ? props.data.values[p.code] : null}
                    // value={p.value}
                    error={props.error}
                    
                    onChange={props.onChange}

                    // dataPath={`properties[${i}].value`}
                    dataPath={`values.${p.code}`}
                />
            ))}
        </React.Fragment>
    );
};
export default CreateDialogueAgentPropertiesEditor;
