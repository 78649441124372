//
// Functional Programming
//
import { F } from '../trix-fp-fantasy';

//
// Observable
//
import {
    default as createObservable,

    take,
    takeAll
} from './observable/observable';

//
// Predicates
//
import { ofType } from './actions/predicates';
export const P = {
    ofType,
}

//
// Action Feed Observable
//
const actionFeed = createObservable();

//
// Effects
//

// put :: dispatch -> (a -> a) -> a -> void
const put = (dispatch) => (f) => (a) => {
    dispatch(f(a));
}

// forkAndPut :: dispatch -> (a -> Future b a) -> a -> void
const forkAndPut = (dispatch) => (f) => (a) => {
    const ftr = f(a);

    if (!F.isFuture(ftr)) {
        console.error('[forkAndPut]: Expected Future, but got:', f);
        return;
    }

    // handle array of actions or single action
    F.fork
        // reject
        (
            (action) => {
                if (action.constructor === Array) {
                    action.forEach(dispatch);
                } else {
                    dispatch(action);
                }
            }
        )

        // resolve 
        (
            (action) => {
                if (action.constructor === Array) {
                    action.forEach(dispatch);
                } else {
                    dispatch(action);
                }
            }
        )

        // future
        (ftr);
}

//
// Observer Factories
//
export const O = {
    // take :: p -> (a -> a) -> b -> (a -> void)
    take: (p) => (fn) => (actionFeed) => (dispatch) => {
        take (actionFeed) (p) (put (dispatch) (fn));
    },

    // takeAll :: p -> (a -> a) -> b -> (a -> void)
    takeAll: (p) => (fn) => (actionFeed) => (dispatch) => {
        takeAll (actionFeed) (p) (put (dispatch) (fn));
    },

    // takeAndFork :: p -> (a -> Future) -> b -> (a -> void)
    takeAndFork: (p) => (fn) => (actionFeed) => (dispatch) => {
        take (actionFeed) (p) (forkAndPut (dispatch) (fn));
    },

    // takeAllAndFork :: p -> (a -> Future) -> b -> (a -> void)
    takeAllAndFork: (p) => (fn) => (actionFeed) => (dispatch) => {
        takeAll (actionFeed) (p) (forkAndPut (dispatch) (fn));
    },
}

//
// Middleware
//
// configureMiddleware :: Array <(actionFeed -> dispatch -> void)> -> (ctx -> (next -> action -> void))
export function configureMiddleware(operationFactories) {
    function createMiddleware({ dispatch, getState }) {
        // setup action feed oberverers
        operationFactories.forEach(f => f (actionFeed) (dispatch));

        return next => action => {
            // hit reducers with this action
            next(action);

            // feed observable with action
            actionFeed.emit(action);
        };
    }
    return createMiddleware;
};
