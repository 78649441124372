// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Typography from '@mui/material/Typography';

//
// Formatter
//
import { fmtQuantity } from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    createItemSubItemCellRenderer,
} from '../../../../../lib/trix-web-components-table';

//
// Component
//
const createReceiptLineParcelStockTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: true,
        width: 200,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            getItem: (props: any) => {
                if (props.row.type === 'PARCEL') {
                    return (
                        <Typography
                            variant='h6' 
                            color='textSecondary'
                        >
                            {props.row.description}
                        </Typography>
                    );
                }

                if (props.row.type === 'ITEM') {
                    return (props.row && props.row.stock && props.row.stock.sku) ? 
                        props.row.stock.sku : '';
                }

                return null;
            },

            getSubItem: (props: any) => {
                if (props.row.type === 'ITEM') {
                    if (props.row && props.row.stock && props.row.stock.name) {
                        return props.row.stock.name.bg;
                    } else {
                        return (<i>Not defined</i>);
                    }
                }

                return null;
            }
        })
    }
};
export default createReceiptLineParcelStockTableColumn;
