// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

//
// React
//
import React, { useLayoutEffect, useMemo, type Node, type ComponentType } from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
    useDispatchLocal,
} from '../../redux-fantasy-reducers';

//
// Extenders
//
import withHalFormsDataSource from '../extender/withHalFormsDataSource';
import {
    withComponentsRegistry,
} from '../../trix-web-components-registry';

//
// Material UI
//
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

//
// Components
//
import HalFormsTable from './HalFormsTable';
import HalFormsViewOnlyForm from './HalFormsViewOnlyForm';
import HalFormsEditableForm from './HalFormsEditableForm';
import HalFormsTimeline from './HalFormsTimeline';
import HalFormsEmbedded from './HalFormsEmbedded';

import HalFormsReportingTable from './halformsreporting/HalFormsReportingTable';

import HalFormsContainerRowPlain from './halformscontainer/HalFormsContainerRowPlain';
import HalFormsContainerRowPaper from './halformscontainer/HalFormsContainerRowPaper';
import { type HalFormsContainerRowProps } from './halformscontainer/HalFormsContainerRowProps';

import {
	getActionFactory,
	createAction,
} from '../tools/halFormsRedux';

//
// Props
//
type Props = {
    //
    // Components
    //
    HalFormsContainerRow: ComponentType<HalFormsContainerRowProps>,

    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        actions: any,
        selectors: any,
    },

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // Components registry
    //
    __componentsRegistry: any,

    //
    // Data Source Extender props (in case data source set)
    //
    __dataSourceExtender: {
        dataSource: any,
        loadData: (params: any) => void,
        isDataLoadNeeded: (params: any) => boolean,
        isLoadingData: boolean,
        params: any,
        data: any,
    },

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => void,
    onClose: () => void,
} // End of Props

//
// Component
//
const HalFormsContainer = (props: Props): Node => {
    //
    // Hooks
    //
    const dispatchLocal = useDispatchLocal();
    
    // initially load data
    const dataSource = (props.__dataSourceExtender) ? props.__dataSourceExtender.dataSource : null;
    useLayoutEffect(() => {
        // skip initial data load in case of no data source specified for the container
        if (!dataSource) {
            return;
        }

        if (props.__dataSourceExtender && 
                !props.__dataSourceExtender.isDataLoadNeeded(props.data)) {
            return;
        }
        props.__dataSourceExtender.loadData(props.data);
    }, [dataSource, props.data]);
    // End hooks

    // render progress
    if (props.__dataSourceExtender && props.__dataSourceExtender.isLoadingData) {
        return (
            <Box
                display='flex'
                width='100%'
                height='100%' 
            >
                <Box m='auto'>
                    <CircularProgress />
                </Box>
            </Box>
        );
    }

    let container = null;
    let data = null;
    if (dataSource) {
        // container = (props.__dataSourceExtender) ? props.__dataSourceExtender.data : props.data;
        container = props.container;
        data = (props.__dataSourceExtender) ? props.__dataSourceExtender.data : null;
    } else {
        container = props.container;
        data = props.data;
    }

    return (
        <Grid
            container
            direction='column'
        >
            {(props.__halFormsTemplate.components || []).map((c, i, a) => {
                const componentConfigurationId = `configuration:${c.id}`;
                let getConfiguration = props.__componentsRegistry.getComponent(componentConfigurationId);
                const configuration = (!getConfiguration) ? {
                    visible: true,
                } : getConfiguration({
                    ...props,
                    data,
                });

                if (!configuration.visible) {
                    return null;
                }

                if (c.type === 'container') {
                    return (
                        <props.HalFormsContainerRow 
                            key={i}
                            isLast={i == a.length - 1}
                            title={c.title}
                        >
                            <HalFormsContainerExt
                                HalFormsContainerRow={HalFormsContainerRowPlain}

                                __halFormsTemplate={c}
                                __ducks={props.__ducks}
                                __dataSourcesRef={props.__dataSourcesRef}
                                                                
                                container={container}
                                data={data}

                                meta={null}

                                onChange={props.onChange}
                                onClose={props.onClose}
                            />
                        </props.HalFormsContainerRow>
                    );
                }

                if (c.type === 'form') {
                    if (c.editable) {
                        return (
                            <props.HalFormsContainerRow 
                                key={i}
                                isLast={i == a.length - 1}
                                title={c.title}
                            >
                                <HalFormsEditableForm
                                    __componentsRegistry={props.__componentsRegistry}

                                    __halFormsTemplate={c}
                                    __halFormsActions={c.actions}
                                    __ducks={props.__ducks}
                                    __dataSourcesRef={props.__dataSourcesRef}
                                    __halFormsDataContainerId={props.__halFormsTemplate.id}

                                    container={container}
                                    data={data}

                                    meta={null}

                                    onChange={props.onChange}
                                    onClose={props.onClose}
                                />
                            </props.HalFormsContainerRow>
                        );
                    } else {
                        return (
                            <props.HalFormsContainerRow 
                                key={i}
                                isLast={i == a.length - 1}
                                title={c.title}
                            >
                                <HalFormsViewOnlyForm
                                    __halFormsTemplate={c}
                                    __halFormsActions={c.actions}
                                    __ducks={props.__ducks}
                                    __dataSourcesRef={props.__dataSourcesRef}
                                    
                                    container={container}
                                    data={data}

                                    onClose={props.onClose}
                                />
                            </props.HalFormsContainerRow>
                        );
                    }
                }

                if (c.type === 'table') {
                    return (
                        <props.HalFormsContainerRow 
                            key={i}
                            isLast={i == a.length - 1}
                            title={c.title}
                        >
                            <HalFormsTable
                                __halFormsTemplate={c}
                                __halFormsActions={c.actions}
                                __ducks={props.__ducks}
                                __dataSourcesRef={props.__dataSourcesRef}
                                
                                container={container}
                                data={data}

                                onClose={props.onClose}
                            />
                        </props.HalFormsContainerRow>
                    );
                }

                if (c.type === 'timeline') {
                    return (
                        <props.HalFormsContainerRow 
                            key={i}
                            isLast={i == a.length - 1}
                            title={c.title}
                        >
                            <HalFormsTimeline
                                __halFormsTemplate={c}
                                __halFormsActions={c.actions}
                                __ducks={props.__ducks}
                                __dataSourcesRef={props.__dataSourcesRef}

                                container={container}
                                data={data}

                                onClose={props.onClose}
                            />
                        </props.HalFormsContainerRow>
                    );
                }

                if (c.type === 'embeddedContainer') {
                    return (
                        <props.HalFormsContainerRow 
                            key={i}
                            isLast={i == a.length - 1}
                            title={c.title}
                        >
                            <HalFormsEmbedded
                                __halFormsTemplate={c}
                                __halFormsActions={c.actions}
                                __ducks={props.__ducks}
                                __dataSourcesRef={props.__dataSourcesRef}
                                
                                container={container}
                                data={data}

                                onChange={props.onChange}
                                onClose={props.onClose}
                            />
                        </props.HalFormsContainerRow>
                    );
                }

                //
                // Reporting
                //
                if (c.type === 'reportingTable') {
                    return(
                        <props.HalFormsContainerRow 
                            key={i}
                            isLast={i == a.length - 1}
                            title={c.title}
                        >
                            <HalFormsReportingTable
                                __halFormsTemplate={c}
                                __halFormsActions={c.actions}
                                __ducks={props.__ducks}
                                __dataSourcesRef={props.__dataSourcesRef}
                                
                                container={container}
                                data={data}

                                onClose={props.onClose}
                            />
                        </props.HalFormsContainerRow>
                    );
                }

                const customPaneTypeId = `customPane:${c.name}`;
                let CustomPane = props.__componentsRegistry.getComponent(customPaneTypeId);
                // console.log('Trying to resolve CustomPane by id:[' + customPaneTypeId + ']');
                if (CustomPane) {
                    return (
                        <props.HalFormsContainerRow 
                            key={i}
                            isLast={i == a.length - 1}
                            title={c.title}
                        >
                            <CustomPane
                                __halFormsTemplate={c}
                                __halFormsActions={c.actions}
                                __ducks={props.__ducks}
                                __dataSourcesRef={props.__dataSourcesRef}
                                
                                container={container}
                                data={data}

                                onChange={props.onChange}
                                onClose={props.onClose}
                            />
                        </props.HalFormsContainerRow>
                    );
                }

                return (null);
            })}
        </Grid>
    );
}
const HalFormsContainerExt = withComponentsRegistry (withHalFormsDataSource (HalFormsContainer));

export default HalFormsContainerExt;


