// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Data Source
//
import { loadData } from '../../../extender/dataSource';

//
// Components
//
import {
    ShippingAddressFormPaneField,
} from '../../../../trix-web-components-domain-geo';

//
// Props
//
type Props = {
    //
    // Hal Forms Template
    //
    __halFormsTemplate: any,
    container: any,

    data: any,
    
    name: string,
    label: string,

    value: any,
    error: Error,

    onChange: (data: any, meta: any) => void,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const HalFormsShippingAddressFormPaneField = (props: Props): any => {
    // Hooks
    // const classes = useStyles();
    // End Hooks

    let onLoadCountries: any = undefined;
    if (props.__halFormsTemplate.countriesDataSource) {
        onLoadCountries = (value: string, reject: (error: any) => void, resolve: (data: any) => void) => {
            const env: any = {
                dataSource: props.__halFormsTemplate.countriesDataSource,
                data: props.data,
                container: props.container,
            };

            const params = {
                text: value,
                data: props.data
            };

            loadData (env) (params, reject, (data: any) => {
                const embedded = (data && data.body && data.body._embedded) ?
                    data.body._embedded : {};
                const values = Object.values(embedded);
                const items = (values && values.length > 0) ? (values[0]) : [];
                // $FlowFixMe
                resolve(items);
            });
        }
    }

    let onLoadCities: any = undefined;
    if (props.__halFormsTemplate.citiesDataSource) {
        onLoadCities = (value: string, reject: (error: any) => void, resolve: (data: any) => void) => {
            const env: any = {
                dataSource: props.__halFormsTemplate.citiesDataSource,
                data: props.data,
                container: props.container,
            };

            const params = {
                text: value,
                data: props.data
            };

            loadData (env) (params, reject, (data: any) => {
                const embedded = (data && data.body && data.body._embedded) ?
                    data.body._embedded : {};
                const values = Object.values(embedded);
                const items = (values && values.length > 0) ? (values[0]) : [];
                // $FlowFixMe
                resolve(items);
            });
        }
    }

    let onLoadNeighbourhoods: any = undefined;
    if (props.__halFormsTemplate.neighbourhoodsDataSource) {
        onLoadNeighbourhoods = (value: string, reject: (error: any) => void, resolve: (data: any) => void) => {
            const env: any = {
                dataSource: props.__halFormsTemplate.neighbourhoodsDataSource,
                data: props.data,
                container: props.container,
            };

            const params = {
                text: value,
                data: props.data
            };

            loadData (env) (params, reject, (data: any) => {
                const embedded = (data && data.body && data.body._embedded) ?
                    data.body._embedded : {};
                const values = Object.values(embedded);
                const items = (values && values.length > 0) ? (values[0]) : [];
                // $FlowFixMe
                resolve(items);
            });
        }
    }

    let onLoadStreets: any = undefined;
    if (props.__halFormsTemplate.streetsDataSource) {
        onLoadStreets = (value: string, reject: (error: any) => void, resolve: (data: any) => void) => {
            const env: any = {
                dataSource: props.__halFormsTemplate.streetsDataSource,
                data: props.data,
                container: props.container,
            };

            const params = {
                text: value,
                data: props.data
            };

            loadData (env) (params, reject, (data: any) => {
                const embedded = (data && data.body && data.body._embedded) ?
                    data.body._embedded : {};
                const values = Object.values(embedded);
                const items = (values && values.length > 0) ? (values[0]) : [];
                // $FlowFixMe
                resolve(items);
            });
        }
    }

    let onLoadPlaces: any = undefined;
    if (props.__halFormsTemplate.placesDataSource) {
        onLoadPlaces = (value: string, reject: (error: any) => void, resolve: (data: any) => void) => {
            const env: any = {
                dataSource: props.__halFormsTemplate.placesDataSource,
                data: props.data,
                container: props.container,
            };

            const params = {
                text: value,
                data: props.data
            };

            loadData (env) (params, reject, (data: any) => {
                const embedded = (data && data.body && data.body._embedded) ?
                    data.body._embedded : {};
                const values = Object.values(embedded);
                const items = (values && values.length > 0) ? (values[0]) : [];
                // $FlowFixMe
                resolve(items);
            });
        }
    }

    return (
        <ShippingAddressFormPaneField
            name={props.name}
            label={props.label}
            
            data={props.value}
            error={props.error}
            
            onChange={props.onChange}

            // loaders callbacks 
            onLoadCountries={onLoadCountries}
            onLoadCities={onLoadCities}
            onLoadNeighbourhoods={onLoadNeighbourhoods}
            onLoadStreets={onLoadStreets}
            onLoadPlaces={onLoadPlaces}

            dataPath={props.dataPath}
        />
    );
};
export default HalFormsShippingAddressFormPaneField;
