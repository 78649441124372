// @flow

//
// React
//
import React from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
} from '../../../redux-fantasy-reducers';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

//
// Extenders
//
import withHalFormsAction from '../../extender/withHalFormsAction';

//
// Hal forms
//
import {
	getSelector,
} from '../../tools/halFormsRedux';

//
// Props
//
type Props = {
    //
    // Components registry
    //
    __componentsRegistry: any,

    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // Context
    //
    __context: any,

    //
    // Data
    //
    data: any,

    //
    // Container
    //
    container: any,

    //
    // Action Executor Extender props
    //
    __halFormsActionExtender: {
        executeHalFormsAction: (halFormsAction: any, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
        executeHalFormsActionByName: (halFormsActionName: string, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
    },

    //
    // Handlers
    //
    onClose: () => void,
};

//
// Component
//
const HalFormsModalButtons = (props: Props) => {
    //
    // Hooks
    //

    // fetch page instance state
    const pageInstanceState = useLocalSelector (s => s);

    // End hooks

    return (
        <Grid container>
            <Grid item style={{ flexGrow: 1 }}></Grid>
            <Grid item>
                <Button
                    color='primary'
                    size='small'
                    onClick={props.onClose}
                >
                    Затвори
                </Button>
            </Grid>

            {
                (props.__halFormsTemplate.actions || [])
                    .slice(0)
                    .reverse()
                    .map((a, i, array) => {
                        const actionHandersId = `actionHanders:${a.id}`;

                        let actionHandlers = props.__componentsRegistry.getComponent(actionHandersId);
                        // console.log('Action handlers: actionHandersId:[' + actionHandersId + '], actionHandlers:', actionHandlers);

                        const inProgress = getSelector (props.__ducks) (a.id) ('isInProgress') (pageInstanceState);
                        return (
                            <Grid key={i} item>
                                <Button
                                    variant='contained'
                                    color={(i === array.length - 1) ? 'primary' : 'default'}
                                    disabled={inProgress}
                                    size='small'
                                    onClick={() => {
                                        props.__halFormsActionExtender.executeHalFormsAction(a, 
                                            props.data, props.container, {
                                                onSuccess: (data: any) => {
                                                    if (actionHandlers && actionHandlers.onSuccess) {
                                                        actionHandlers.onSuccess({
                                                            data: props.data,
                                                            onClose: props.onClose,
                                                        });
                                                    }
                                                }
                                            }
                                        );
                                    }}
                                >
                                    {a.title}
                                </Button>
                            </Grid>
                        );
                    }
                )
            }
        </Grid>
    );
}
export default withHalFormsAction ({}) (HalFormsModalButtons);
