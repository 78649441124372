// @flow

//
// Trix Middleware
//
import {
    type Error,
} from '../../../trix-web-data-commons';

//
// Types
//
type Configuration = {
    ns: string;
    operation: string;
}

type ActionTypes = {
    OPERATION_REQUESTED: string;
    OPERATION_EXECUTED_SUCCESS: string;
    OPERATION_EXECUTED_FAILURE: string;
}

//
// Action types factory
//
export const createActionsTypes = (cfg: Configuration): any => {
    return {
        OPERATION_REQUESTED: `@@${cfg.ns}/${cfg.operation}_REQUESTED`,
        OPERATION_EXECUTED_SUCCESS: `@@${cfg.ns}/${cfg.operation}_EXECUTED::SUCCESS`,
        OPERATION_EXECUTED_FAILURE: `@@${cfg.ns}/${cfg.operation}_EXECUTED::FAILURE`,
    };
}

//
// Actions factory
//
export const createActions = (actionTypes: ActionTypes): any => {
    //
    // Action creators
    //
    const requestOperation = (payload: any, meta?: any) => {
        // generate unique execution id. this id will be used to identify and track 
        // operation's status when multiple operations are executed asynchronously 
        // in parallel   
        const executionId = uuidv4();

        return {
            type: actionTypes.OPERATION_REQUESTED,
            payload,
            meta: {
                ...meta,
                executionId
            },
        }
    };
    const operationExecuted_Success = (data: any) => {
        return {
            type: actionTypes.OPERATION_EXECUTED_SUCCESS,
            payload: data,
        }
    };
    const operationExecuted_Failure = (error: Error) => {
        return {
            type: actionTypes.OPERATION_EXECUTED_FAILURE,
            payload: error,
        }
    };

    return {
        requestOperation,
        operationExecuted_Success,
        operationExecuted_Failure,
    };
}

//
// Helpers
//
const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};