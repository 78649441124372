// @flow

//
// Lodash
//
import fp from 'lodash/fp';
import has from 'lodash/has';

//
// React
//
import React, { useEffect } from 'react';

//
// Redux
//
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

//
// Reducer Context
//
import ReducerContext from './ReducerContext';

//
// Connectors
//
import connectAction from '../connectors/connectAction';
import connectSelector from '../connectors/connectSelector';

// Actions
import Actions from '../actions/Actions';

//
// Helpers
//
const getUrlParameter = (queryString: string, name: string) => {
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(queryString);
    return results === null ? '' : results[1].replace(/\+/g, ' ');
};

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

/**
 * Higher order component, which generates 'reducerKey' property and pass it
 * to the wrapped component.
 *
 * 'reducerKey' is composed by:
 *  - configured storePath
 *  - instanceId
 *
 * 'instanceId' is generated on first page access. Resolving later this exact page
 * instance requires passing 'i' url query parameter with the value of the 'instanceId'.
 *
 */
type WithReducerKeyProps = {
    // router location object, used to access url query params
    // location: any,
    history: {
        location: {
            pathname: string,
            search: string,
        }
    }
};
const withReducerKey = (cfg: { storePath?: string, resolveStorePath?: (props: any) => string }) => (
    WrappedComponent: any
) => {
    class WithReducerKey extends React.Component<WithReducerKeyProps> {
        constructor(props: WithReducerKeyProps) {
            super(props);            
        }

        render(): any {
            // retrieve or generate instance id
            let instanceId = getUrlParameter(this.props.history.location.search, 'i');
            if ('' === instanceId) {
                instanceId = uuidv4();
            }

            const storePath = cfg.storePath || (cfg.resolveStorePath ? cfg.resolveStorePath(this.props) : '')

            const reducerKey = `${storePath}[${instanceId}]`;
            return (
                <WrappedComponent
                    {...this.props}
                    instanceId={instanceId}
                    reducerKey={reducerKey}
                />
            );
        }
    }
    return WithReducerKey;
};

/**
 * Higher order component, which manages redux store initialization for the
 * specified 'reducerKey'. When a store is initialized, 'reducerKey' is
 * put into a react context available for all the child components down the tree.
 *
 */
type WithReducerProp = {
    // instance id
    instanceId: string,

    // title
    title?: string,

    // reducer key
    reducerKey: string,

    // expect to be passed by HOC, normally withNavigator
    inTenantNavigateTo: (path: string) => void,

    // from react router
    history: {
        push: (url: string) => void,
        location: {
            pathname: string,
            search: string,
        },
    },
    match: any;

    // state
    initialized: boolean,

    // handlers
    initialize: (data: any) => void,
    destroy: (data: any) => void,
};
const withReducer = (cfg: { title?: string, resolveTitle?: (props: any) => string }) => (WrappedComponent: any) => {
    const getTitle = (props: any): string => {
        return cfg.title || (cfg.resolveTitle ? cfg.resolveTitle(props) : '');
    }

    const doInitialize = (props: any) => {
        // initialize reducer
        if (!props.initialized) {
            const url = `${props.history.location.pathname}?i=${props.instanceId}`;

            props.initialize({
                instanceId: props.instanceId,
                reducerKey: props.reducerKey,
                title: getTitle(props),
                url,
            });

            // redirect to the full url of the page
            props.history.push(url);
        }
    }
    
    const WithReducer = (props: any) => {
        const {
            initialize,
            destroy,
            initialized,
            inTenantNavigateTo,
            match,
            ...other
        } = props;
        
        // Hooks
        useEffect(() => {
            if (!props.initialized) {
                doInitialize(props);
            }
        }, [props]);

        // TODO: show some waiting screen (configurable) until
        // reducer gets initialized
        if (!props.initialized) {           
            return (
                <div
                    style={{
                        padding: '1rem',
                    }}
                >
                    Момент...
                </div>
            );
        }

        return (
            <ReducerContext.Provider
                value={{
                    reducerKey: props.reducerKey,
                }}
            >
                <WrappedComponent
                    {...other}
                    reducerKey={props.reducerKey}
                    title={getTitle(props)}
                />
            </ReducerContext.Provider>
        );
    }

    return WithReducer;
};

export default (cfg: { 
    title?: string, 
    storePath?: string,

    resolveTitle?: (props: any) => string,
    resolveStorePath?: (props: any) => string,
}): any => 
    fp.compose(
        withReducerKey({
            storePath: cfg.storePath,
            resolveStorePath: cfg.resolveStorePath
        }),
        connect(
            // map state to props
            (store: any, props: any) => {
                return {
                    initialized: has(store, props.reducerKey),
                };
            },

            // bind action creators
            (dispatch: any, props: any) => {
                // const reducerKey = generateReducerKey(cfg, props);
                return bindActionCreators(
                    {
                        initialize: connectAction(props.reducerKey)(
                            Actions.initialize
                        ),
                        destroy: connectAction(props.reducerKey)(
                            Actions.destroy
                        ),
                    },
                    dispatch
                );
            }
        ),
        withReducer({
            title: cfg.title,
            resolveTitle: cfg.resolveTitle
         }),
    );