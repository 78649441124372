// @flow

import {
    ServiceComboBox,
    ServiceFloatPaneField,
    ServiceCellRenderer,
    ServiceCodeNamePropertiesCellRenderer,
} from '../../../lib/trix-web-components-domain-service';

import ServiceGroupNameField from './components/form/ServiceGroupNameField';
import CreateUpdateServiceGroupPropertyForm from './components/form/CreateUpdateServiceGroupPropertyForm';
import CreateServiceForm from './components/form/CreateServiceForm';
import UpdateServiceForm from './components/form/UpdateServiceForm';

import PropertyValueSourceCellRenderer from './components/table/PropertyValueSourceCellRenderer';
import PropertyValueTypeCellRenderer from './components/table/PropertyValueTypeCellRenderer';
import PropertyMandatoryCellRenderer from './components/table/PropertyMandatoryCellRenderer';
import PropertyCodeNameDescriptionCellRenderer from './components/table/PropertyCodeNameDescriptionCellRenderer';

import getServiceMainPropertiesTableConfiguration from './components/configuration/serviceMainPropertiesTableConfiguration';

export const components = {
    //
    // Common service type components
    //
    'formPaneField:service': ServiceComboBox,
    // 'formPaneField:serviceQuantity': ServiceQuantityFormPaneField,
    'floatPaneField:service': ServiceFloatPaneField,
    'tableCellRenderer:service': ServiceCellRenderer,
    // 'tableCellRenderer:serviceCodeNameProperties': ServiceCodeNamePropertiesCellRenderer,

    'form:trix-web-plugin-core-service.manageServiceGroupPage.container.serviceGroupMainProperties.action.onAddItem.editor': CreateUpdateServiceGroupPropertyForm,
    'form:trix-web-plugin-core-service.manageServiceGroupPage.container.serviceGroupMainProperties.action.onRowClick.editor': CreateUpdateServiceGroupPropertyForm,

    'form:trix-web-plugin-core-service.listServicesPage.container.table.action.onAddItem.createServiceForm': CreateServiceForm,
    'form:trix-web-plugin-core-service.manageServicePage.container.form.action.onEdit.updateServiceForm': UpdateServiceForm,

    'formPaneField:SERVICE_GROUP_NAME': ServiceGroupNameField,

    'tableCellRenderer:PROPERTY_VALUE_SOURCE': PropertyValueSourceCellRenderer,
    'tableCellRenderer:PROPERTY_VALUE_TYPE': PropertyValueTypeCellRenderer,
    'tableCellRenderer:PROPERTY_MANDATORY': PropertyMandatoryCellRenderer,
    'tableCellRenderer:PROPERTY_CODE_NAME_DESCRIPTION': PropertyCodeNameDescriptionCellRenderer,

    'configuration:trix-web-plugin-core-service.manageServicePage.container.serviceMainProperties.table': getServiceMainPropertiesTableConfiguration,
}

