// @flow

import get from 'lodash/get';

//
// React
//
import React, { type Node } from 'react';

//
// Type
//
import { type CellRendererProps } from '../../../trix-web-components-table/components/table/types/CellRendererProps';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.text.primary,
    },
    code: {
        color: theme.palette.text.secondary,
    },
}));


//
// Props
//
type Props = CellRendererProps & {
    service: {
        code: string,
        name: any,
    },
};

//
// Component
//
const ServiceHeaderRendererPane = (props: Props): Node => {
    //
    // Hooks
    //
    const classes = useStyles();

    // End Hooks

    return (
        <>
            <Typography variant='subtitle2' display='block' className={classes.title}>
                {get(props.service, 'name.bg', '')}
            </Typography>
            <Typography variant='caption' display='block' className={classes.code}>
                {get(props.service, 'code', '')}
            </Typography>
        </>
    );
}
export default ServiceHeaderRendererPane;
