// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

//
// Props
//
type Props = {
    //
    // Handlers
    //
    onToggleNavigationMenu: () => void,
}

//
// Component
//
const NavigationMenuHeaderItem = (props: Props): any => {
    return (
        <IconButton
            edge='start'
            onClick={props.onToggleNavigationMenu}
            color='inherit'
            size="large">
            <MenuIcon />
        </IconButton>
    );
}
export default NavigationMenuHeaderItem;
