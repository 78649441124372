// @flow

//
// React
//
import React from 'react';

//
// Type
//
import { type CellRendererProps } from '../../../../../lib/trix-web-components-table';

//
// Material UI
//
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

//
// Formatter
//
import {
    fmtNumber,
    fmtDate
} from '../../../../../lib/trix-web-data-commons';

const renderAsLink = (onClick?: (item: any) => void, classes: any, item: any) => (
    value: string
) => {
    if (!onClick) {
        return value;
    }

    if (item.status === 'IN_PROGRESS') {
        const id = (item && item.id) ? item.id : '';
        const type = (item && item.type) ? item.type : '';
        
        return (
            <div 
                className={classes.link}
                onClick={(event) => {
                    onClick(item);
                    
                    // don't propagate the event to the document
                    if (event.stopPropagation) {
                        // W3C model
                        event.stopPropagation();
                    } else {
                        // IE model
                        event.cancelBubble = true;
                    }
                }}
            >
                {value}
            </div>
        );
    } else {
        return value;
    }
};

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },

    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },

    propertiesTable: {
    },

    propertiesTableLastRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    propertiesText: {
    },
}));

//
// Props
//
type Props = CellRendererProps & {
    //
    // Callbacks
    //
    onClick?: () => void,

    //
    // Data
    //
    data: any,
};

//
// Component
//
const PackSaleOrderHumanTaskInfo = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End Hooks

    const subject = (props.item && props.item.subject) ? props.item.subject : '';

    let saleOrderNo = '';
    let saleOrderDate = '';
    let skus = [];
    if (props.data && props.data.properties) {
        for (let i = 0; i < props.data.properties.length; i++) {
            if (props.data.properties[i].name === 'saleOrderNo') {
                saleOrderNo = fmtNumber(props.data.properties[i].value.textValue, '0000000000')
            }
            if (props.data.properties[i].name === 'saleOrderDate') {
                saleOrderDate = fmtDate(props.data.properties[i].value.dateValue, 'LL');   
            }

            if (props.data.properties[i].name === 'saleOrderSku') {
                skus = [
                    ...skus,
                    props.data.properties[i].value.textValue
                ];
            }
        }
    }

    return (
        <TableCell padding="normal" className={classes.root}>
            <Grid 
                container
                direction='column'
                spacing={1}
                wrap='nowrap'
            >
                <Grid item>
                    {renderAsLink(props.onClick, classes, props.item)(subject)}
                </Grid>

                <Grid item>
                    <Divider />
                </Grid>

                <Grid
                    item
                    container
                    direction='row'
                    spacing={3}
                    wrap='nowrap'
                >
                    <Grid item align='center'>
                        <Typography variant='subtitle2' display='block' >
                            {saleOrderNo}
                        </Typography>
                        <Typography variant='caption' display='block' >
                            {saleOrderDate}
                        </Typography>
                    </Grid>

                    <Grid 
                        item 
                        container
                    >
                        <Typography variant='h4' display='block' >
                            {skus.length} поз
                        </Typography>
                        {/* {(skus).map((sku, index, array) => (
                            <Grid
                                key={index}
                                container
                                direction='column'
                                wrap='nowrap'
                            >
                                <Grid item>
                                    {sku}
                                </Grid>
                            </Grid>
                        ))} */}
                    </Grid>
                </Grid>
            </Grid>
        </TableCell>
    );
};
export default PackSaleOrderHumanTaskInfo;