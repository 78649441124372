// @flow

//
// React
//
import React from 'react';

//
// Trix Middleware
//
import { get as httpGet } from '../../../../../lib/trix-web-middleware-commons';

//
// Trix Data
//
import {
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Trix Data Commons
//
import { buildUrl } from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    ComboBoxFormPaneField,
    type FormPaneContentProps,
} from '../../../../../lib/trix-web-components-pane';

export const getFindUrl = (params: any) => {
    return buildUrl(
        '/api/v1/wms/pricelist/priceLists/search/byFreeText?text={text}&page={page}&size={size}',
        params
    );
};

//
// Props
//
type Props = {
    name: string,
    label: string,
    description: string,
    helperText: string,

    value: any,
    error: any,

    onChange: (data: any) => void,
}

//
// Component
//
const Content = (props: Props) => {
    //
    // Hooks
    //
    // const dispatchLocal = useDispatchLocal();
    // End hooks

    return (
        <ComboBoxFormPaneField
            label={props.label}
            description={props.description}
            
            value={props.value}
            error={props.error}

            onLoadData={(value, reject, resolve) =>
                httpGet(
                    // url
                    getFindUrl({
                        // filter param
                        text: value,

                        // paging
                        page: 0,
                        size: 20,
                    }),

                    // options
                    null,

                    // reject callback
                    reject,

                    // resolve callback
                    (data) =>
                        resolve(
                            (data && data.body && data.body._embedded && data.body._embedded.priceLists) ? 
                                    data.body._embedded.priceLists : []
                        )
                )
            }
            onGetOptionLabel={(option) => (option) ? `${option.name} (${option.type})` : ''}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            
            onChange={props.onChange}
            
            dataPath={props.name}
        />
    );
};
export default Content;
