// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    TextFloatPaneField,
} from '../../../../../lib/trix-web-components-pane';

//
// Props
//
type Props = {
    //
    // State
    //
    label: string,

    //
    // Data
    //
    data: any,
}; // End of Props

//
// Component
//
const DocumentTypeFloatPaneField = (props: Props) => {
    // Hooks
    // const classes = useStyles();
    // End Hooks

    const name = (props.data && props.data.name) ? props.data.name : '';

    return (
        <TextFloatPaneField
            label={props.label}
            value={`${name}`}
        />
    );
};
export default DocumentTypeFloatPaneField ;
