// @flow

//
// Lodas
//
import fp from 'lodash/fp';

//
// React
//
import React, { type ComponentType } from 'react';

//
// Redux
//
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

//
// Duck
//
import { sessionSelector } from '../state/ducks';
import { Actions, Selectors } from '../state/ducks/session';

/**
 * Higher order component. Set user and tenant into store and redirects to
 * url path, which starts with tenant id.
 *
 */
type InitializerProps = {
    history: any,

    user: any,
    tenantId: any,

    sessionState: string,

    initializeSession: (user: any) => void,
};
const initializer = () => (
    WrappedComponent: ComponentType<any>
) => {
    class Initializer extends React.Component<InitializerProps> {
        componentDidMount() {
            if (this.props.sessionState === 'START') {
                this.props.initializeSession(this.props.user);
            }
        }

        render(): any {
            const { sessionState, initializeSession, ...other } = this.props;
            return <WrappedComponent {...other} />;
        }
    }
    return Initializer;
};

export default (cfg: { /* LoginPage: ComponentType<any> */ }): any =>
    fp.compose(
        connect(
            store => {
                const sessionDuck = sessionSelector(store);
                return {
                    sessionState: Selectors.sessionState(sessionDuck),
                };
            },

            // bind action creators
            dispatch => {
                return bindActionCreators(
                    {
                        initializeSession: Actions.initializeSession,
                    },
                    dispatch
                );
            }
        ),
        initializer()
    );
