// @flow

//
// React
//
import React from 'react';

//
// Trix Middleware
//
import {
    type Error,
    isOK,
    findAllErrorCauses,
    formatErrorCauses,
} from '../../trix-web-data-commons';

//
// Material UI
//
import Box from '@mui/material/Box';
import SnackbarContent from '@mui/material/SnackbarContent';
import ErrorIcon from '@mui/icons-material/Error';

//
// Interface
//
type Props = {
    //
    // Visual
    //
    sx?: any,

    //
    // Data
    //
    error?: Error,
};

//
// Component
//
const InlineErrorMessage = (props: Props): any => {
    if (!props.error || isOK(props.error)) {
        return null;
    }

    const causes = findAllErrorCauses(props.error).filter(
        c =>
            c.location.type === 'SERVER' ||
            c.location.type === 'NETWORK' ||
            c.location.type === 'CLIENT'
    );
    if (causes.length === 0) {
        return null;
    }

    return (
        <SnackbarContent
            sx={[
                {
                    backgroundColor: theme => theme.palette.error.dark,
                },
                ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
            ]}
            message={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <ErrorIcon 
                        sx={{
                            fontSize: '1.25rem',
                            opacity: 0.9,
                            marginRight: theme => theme.spacing(1),
                        }}
                    />
                    {formatErrorCauses(causes)}
                </Box>
            }
        />
    );
};
export default InlineErrorMessage;
