// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Type
//
import { type CellRendererProps } from '../../../trix-web-components-table';

//
// Material UI
//
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        // take width of the content
        width: '1px',
        whiteSpace: 'nowrap',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },

    name: {
        color: theme.palette.text.secondary,
    }
}));

//
// Props
//
type Props = CellRendererProps & {
    //
    // Callbacks
    //
    onClick?: () => void,

    //
    // Data
    //
    value: any,
};

//
// Component
//
const ServiceCellRenderer = (props: Props): Node => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End Hooks

    const item = (props.item) ? props.item : {};

    const code = (item && item.service && item.service.sku) ? item.service.sku : '';
    const name = (item && item.service && item.service.name && item.service.name.bg) ?
            item.service.name.bg : '';

    return (
        <TableCell 
            padding="normal" 
            className={classes.root}
        >
            <Typography variant='subtitle2' display='block' className={classes.title}>
                {code}
            </Typography>
            <Typography variant='caption' display='block' className={classes.name}>
                {name}
            </Typography>
        </TableCell>
    );
};
export default ServiceCellRenderer;
