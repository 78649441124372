// @flow

import get from 'lodash/get';

//
// React
//
import React, { useState } from 'react';

//
// Formatter
//
import {
    fmtOmni,
} from '../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.secondary.main,
    },
    
    selectorItemsTable: {
    },
    selectorItemsTableLastRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    selectorItemsText: {
        fontSize: '.625rem',
    },
    
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    title?: string,
    selector: any,
};

//
// Component
//
const SelectorItemsRendererPane = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End Hooks

    return (
        <>
            {(props.title) ? 
                (
                    <Typography 
                        className={classes.title}
                        variant='caption' 
                        display='block'
                    >
                        {props.title}
                    </Typography>
                ) : null
            }
            <Table 
                className={classes.selectorItemsTable}
                size='small' 
            >
                <TableBody>
                    {get(props.selector, 'items', []).map((row, index, array) => (
                        <TableRow 
                            key={index}
                            className={(index === array.length - 1) ? classes.selectorItemsTableLastRow : null}
                        >
                            <TableCell
                                component='th'
                                size='small'
                                className={classes.selectorItemsText}
                            >
                                {row.code} {row.name}:
                            </TableCell>
                            <TableCell 
                                size='small'
                                align='left'
                                className={classes.selectorItemsText}
                            >
                                {get(row, 'value.code')} {fmtOmni(get(row, 'value.value', {}))}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}
export default SelectorItemsRendererPane;
