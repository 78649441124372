//
// Redux
//
import {
    applyMiddleware,
    compose,
    createStore,
} from 'redux';

//
// Redux thunk
//
import reduxThunk from 'redux-thunk';

//
// Redux Logger
//
import { createLogger } from 'redux-logger';

/**
 * Configure store
 */
export function configureStore(reducer, middlewares) {
    // in development mode connect to redux devtools browser extension
    const composeEnhancers = (
        process.env.NODE_ENV !== 'production' && 
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) || compose;

    // development mode middlewares
    if (process.env.NODE_ENV === 'development') {
        const logger = createLogger();
        middlewares.push(logger);
    }

    // compose shell enhancers
    const storeEnhancers = composeEnhancers(
        applyMiddleware(
            // other middlewares
            ...middlewares,

            // redux thunk
            reduxThunk,
        ),
    );

    // initial state. use to resume application from a state saved in 
    // local storage
    const initialState = {};
    const store = createStore(
        // reducer
        reducer,

        // initial state 
        initialState,

        // store enhancers
        storeEnhancers,
    );

    return store;
}
