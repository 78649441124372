// Reducer key pattern
const ReducerKeyPattern = {
    concat: (...tokens: string[]) => {
        return '^' + tokens.join('.');
    },

    instance: (key?: string) => {
        return (key) ? key + '._instances\\[[\\w\\-]+\\]' : '_instances\\[[\\w\\-]+\\]';
    },
}
export default ReducerKeyPattern;