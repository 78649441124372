// @flow

//
// React
//
import React, { type Component } from 'react';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    type CellProps,

    createTextCellRenderer,
    createI8nTextCellRenderer,
    createMultiCellRenderer,
    createQuantityCellRenderer,
    createMoneyCellRenderer,
    createNumberCellRenderer,
    createPercentCellRenderer,
    createDateCellRenderer,
    createDateInMsCellRenderer,
    createDateTimeCellRenderer,
    createCountryCellRenderer,
} from '../../../trix-web-components-table';

import {
    createSelectorCellRenderer,
} from '../../../trix-web-components-property';

//
// Type
//
type HalFormsTableColumn = {
    align: string,

    flex: boolean,
    width: any,

    renderCell: any,
}

//
// Configuration
//
type Cfg = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Action Executor Extender props
    //
    __halFormsActionExtender: {
        executeHalFormsAction: (halFormsAction: any, data: any, container: any,meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
        executeHalFormsActionByName: (halFormsActionName: string, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
    },

    //
    // Components registry
    //
    __componentsRegistry: any,

    //
    // Column component
    //
    column: {
        id: string,
        plugin: string,
        sid: string,

        dataType: string,
        name: string,
        label: string,
        description: string,

        actions?: any[],
    },

    //
    // Container
    //
    container: any,
} // End of Props

const createHalFormsTableColumn = (cfg: Cfg): HalFormsTableColumn => {
    // retrieve action (if any specified)
    let onClick = undefined;
    const action = (cfg.column.actions && cfg.column.actions.length > 0) ? 
            cfg.column.actions[0] : null;
    if (action) {
        onClick = (item: any) => {
            cfg.__halFormsActionExtender.executeHalFormsAction(action, item, cfg.container, {
                // onSuccess: (data: any) => props.onClose()
            });
        };
    }

    if (cfg.column.dataType === 'text' || cfg.column.dataType === 'TEXT') {
        return {
            align: 'left',
            flex: true,
            width: 150,

            renderCell: createTextCellRenderer({
                onClick
            })
        };
    }

    if (cfg.column.dataType === 'number') {
        return {
            align: 'right',
            flex: false,
            width: 100,

            renderCell: createNumberCellRenderer({
                onClick,
                fmt: '0000000000'
            })
        };
    }

    if (cfg.column.dataType === 'double') {
        return {
            align: 'right',
            flex: false,
            width: 100,

            renderCell: createNumberCellRenderer({
                onClick,
                fmt: '0.00'
            })
        };
    }

    if (cfg.column.dataType === 'integer') {
        return {
            align: 'right',
            flex: false,
            width: 100,

            renderCell: createNumberCellRenderer({
                onClick,
                fmt: '0'
            })
        };
    }

    if (cfg.column.dataType === 'i8nText' || cfg.column.dataType === 'I8N_TEXT') {
        return {
            align: 'left',
            flex: true,
            width: 150,

            renderCell: createI8nTextCellRenderer({
                onClick
            })
        };
    }

    if (cfg.column.dataType === 'multi' || cfg.column.dataType === 'MULTI') {
        return {
            align: 'left',
            flex: false,
            width: 150,

            renderCell: createMultiCellRenderer({
                onClick
            })
        };
    }

    if (cfg.column.dataType === 'quantity') {
        return {
            align: 'right',
            flex: false,
            width: 100,

            renderCell: createQuantityCellRenderer({
                onClick
            })
        };
    }

    if (cfg.column.dataType === 'date') {
        return {
            align: 'left',
            flex: false,
            width: 100,

            renderCell: createDateCellRenderer({
                onClick
            })
        };
    }
    if (cfg.column.dataType === 'dateInMs') {
        return {
            align: 'right',
            flex: false,
            width: 150,

            renderCell: createDateInMsCellRenderer({
                onClick
            })
        };
    }

    if (cfg.column.dataType === 'dateTime') {
        return {
            align: 'left',
            flex: false,
            width: 100,

            renderCell: createDateTimeCellRenderer({
                onClick
            })
        };
    }

    if (cfg.column.dataType === 'percent') {
        return {
            align: 'right',
            flex: false,
            width: 90,

            renderCell: createPercentCellRenderer({
                onClick,
            })
        };
    }

    if (cfg.column.dataType === 'boolean') {
        return {
            align: 'center',
            flex: false,
            width: 90,

            renderCell: createTextCellRenderer({
                onClick
            })
        };
    }

    if (cfg.column.dataType === 'money') {
        return {
            align: 'right',
            flex: false,
            width: 130,

            renderCell: createMoneyCellRenderer({
                onClick
            })
        };
    }

    if (cfg.column.dataType === 'country') {
        return {
            align: 'left',
            flex: false,
            width: 150,

            renderCell: createCountryCellRenderer({
                onClick
            })
        };
    }

    if (cfg.column.dataType === 'propertySelector') {
        return {
            align: 'left',
            flex: true,
            width: 250,

            renderCell: createSelectorCellRenderer({
                onClick
            })
        };
    }

    // check if custom cell renderer is provided
    const tableColumnId = `tableColumn:${cfg.column.dataType}`;
    const tableColumnFactory = cfg.__componentsRegistry.getComponent(tableColumnId);
    if (tableColumnFactory) {
        return tableColumnFactory({
            cellRendererFactoryProps: {
                onClick
            }
        });
    }

    // default column def
    return {
        align: 'left',
        flex: false,
        width: 150,
        renderCell: null
    };
}
export default createHalFormsTableColumn;
