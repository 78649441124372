// @flow

//
// Lodash
//
import fp from 'lodash/fp';

//
// Duck
//
import { type State } from './reducer';

//
// Selectors
//
const isSuccessNotificationOpen = (state: State) => state.success.isOpen;
const getSuccessNotificationMessage = (state: State) => state.success.message;

const isErrorNotificationOpen = (state: State) => state.error.isOpen;
const getErrorNotificationError = (state: State) => state.error.error;

export const createSelectors = (localState: (state: any) => State): any => {
    return {
        success: {
            isOpen: fp.compose(isSuccessNotificationOpen, localState),
            getMessage: fp.compose(getSuccessNotificationMessage, localState),
        },
        error: {
            isOpen: fp.compose(isErrorNotificationOpen, localState),
            getError: fp.compose(getErrorNotificationError, localState),
        },
    };
};
