// @flow

//
// React
//
import React from 'react';

//
// Tenant
//
import { 
    useNavigateTo,
} from '../../../../../../../../lib/trix-web-components-tenant';

//
// Material UI
//
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
}));

//
// Properties
//
export type Props = {
    menus: any[],
};

//
// Components
//
const Settings = (props: Props): any => {
    // hooks
    const navigateTo = useNavigateTo();
    const classes = useStyles();
    // end hooks

    return (
        <Grid container direction='column' spacing={2} className={classes.root}>
            <Grid item>
                <Typography variant='h5'>Настройки</Typography>
            </Grid>
            <Grid item container spacing={2}>
                {(props.menus || []).map((menuSection, i) => (
                    <List
                        key={i}
                        subheader={
                            <ListSubheader>{menuSection.title}</ListSubheader>
                        }
                        dense={true}
                        disablePadding={true}
                    >
                        {menuSection.items.map((menuItem, j) => (
                            <ListItem
                                key={j}
                                button
                                onClick={() => {
                                    navigateTo(menuItem.path);
                                }}
                            >
                                <ListItemText
                                    primary={menuItem.title}
                                    primaryTypographyProps={{
                                        color: 'primary',
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                ))}
            </Grid>
        </Grid>
    );
};
export default Settings;
