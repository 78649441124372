// @flow

//
// React
//
import React from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
} from '../../../../redux-fantasy-reducers';

//
// Hal forms
//
import {
	getSelector,
} from '../../../tools/halFormsRedux';

//
// Material UI
//
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DetailsIcon from '@mui/icons-material/Details';

import { makeStyles } from '@mui/styles';

//
// Hal Forms
//
import withHalFormsAction from '../../../extender/withHalFormsAction';

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Context
    //
    __context: any,

    //
    // Action
    //
    __halFormsAction: any,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    selectedItems: any[],

    //
    // Action Executor Extender props
    //
    __halFormsActionExtender: {
        executeHalFormsAction: (halFormsAction: any, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
        executeHalFormsActionByName: (halFormsActionName: string, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
    },

    //
    // Hadlers
    //
    onClose: () => void,
};

//
// Component
//
const HalFormsTableToolbarIconButton = (props: Props) => {
    //
    // Hooks
    //
    const inProgress = useLocalSelector (
        getSelector 
                (props.__ducks) 
                (props.__halFormsAction.id) 
                ('isInProgress'));
    // End hooks

    let icon = null;
    if (props.__halFormsAction.name.includes('Add')) {
        icon = (<AddIcon fontSize='small' />);
    } else if (props.__halFormsAction.name.includes('Delete')) {
        icon = (<DeleteIcon fontSize='small' />);
    } else {
        icon = (<DetailsIcon fontSize='small' />);                        
    }

    return (
        <Tooltip 
            title={props.__halFormsAction.title}
        >
            <IconButton
                disabled={inProgress}
                onClick={() => {
                    (props.selectedItems || []).forEach(data => {
                        props.__halFormsActionExtender.executeHalFormsAction(
                            props.__halFormsAction, data, props.container, {
                                context: props.__context,
                            }
                        );
                    });
                }}
                size='large'>
                {icon}
            </IconButton>
        </Tooltip>
    );
}
export default withHalFormsAction ({}) (HalFormsTableToolbarIconButton);
