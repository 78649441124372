//
// Sanctuary
//
import S from '../../trix-fp-fantasy';

// validate :: Array Checker -> Action -> Either Error Action
export const validate = (checkers) => (action) => {
    const causes = S.reduce(
        (a) => (f) => {
            return S.concat(a)
                (S.maybe([])(e => e.causes)(f(action)));
        })
        ([])
        (checkers);
    return causes.length === 0 ? S.Right(action) : S.Left({ causes });
};
