// @flow

//
// React
//
import React from 'react';

//
// Components
//
import FloatPaneField from './FloatPaneField';

//
// Props
//
type Props = {
    //
    // State
    //
    label: string,

    //
    // Data
    //
    value: string,

    //
    // Actions
    //
    onClick?: () => void;
}; // End of Props

//
// Component
//
const TextFloatPaneField = (props: Props): any => {
    return(
        <FloatPaneField
            label={props.label}
            value={props.value}
            onClick={props.onClick}
        />
    );
};
export default TextFloatPaneField;
