// @flow

//
// React
//
import React from 'react';

//
// Cell renderer
//
import {
    createTextCellRenderer,
} from '../../../trix-web-components-table';

const createPropertyCodeTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: false,
        width: 120,

        renderCell: createTextCellRenderer(cfg)
    }
}
export default createPropertyCodeTableColumn;