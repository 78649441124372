// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React, { useMemo } from 'react';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../types/CellProps';

//
// Components
//
import createTextCellRenderer from './TextCellRenderer';
import createI8nTextCellRenderer from './I8nTextCellRenderer';
import createNumberCellRenderer from './NumberCellRenderer';
import createDateCellRenderer from './DateCellRenderer';

//
// Component
//
const createMultiCellRenderer = (cfg: CellRendererFactoryProps): any => (props: CellProps): any => {
    const renderProps = useMemo(() => {
        let value = '';
        
        if (props.value != null && props.value.valueType === 'TEXT') {
            value = get(props.value, 'textValue');
        }
        if (props.value != null && props.value.valueType === 'I8N_TEXT') {
            value = get(props.value, 'i8nTextValue');
        }
        if (props.value != null && props.value.valueType === 'INTEGER') {
            value = get(props.value, 'integerValue');
        }
        if (props.value != null && props.value.valueType === 'LONG') {
            value = get(props.value, 'longValue');
        }
        if (props.value != null && props.value.valueType === 'DOUBLE') {
            value = get(props.value, 'doubleValue');
            // const currentFmt = (options && options.fmt) ? options.fmt : '0,0.00';
            // return (value && value.doubleValue) ?
            //     numeral(value.doubleValue).format(currentFmt) : numeral(0).format(currentFmt);
        }
        if (props.value != null && props.value.valueType === 'DATE') {
            value = get(props.value, 'dateValue');
        }
        if (props.value != null && props.value.valueType === 'TIME') {
            value = get(props.value, 'dateValue');
        }
        if (props.value != null && props.value.valueType === 'DATE_TIME') {
            value = get(props.value, 'dateValue');
        }
        if (props.value != null && props.value.valueType === 'BOOLEAN') {
            value = get(props.value, 'booleanValue') ? 'Да' : 'Не';
        }
        if (props.value != null && props.value.valueType === 'QUANTITY') {
            // value = get(props.value, 'quantityValue');
            value = 'TODO';
        }
        if (props.value != null && props.value.valueType === 'MONEY') {
            // value = get(props.value, 'moneyValue');
            value = 'TODO';
        }
        if (props.value != null && props.value.valueType === 'PERSON') {
            // TODO: common formatter (person renderer)
            const firstName = (props.value && props.value.personValue) ? props.value.personValue.firstName : '';
            const middleName = (props.value && props.value.personValue) ? props.value.personValue.middleName : '';
            const lastName = (props.value && props.value.personValue) ? props.value.personValue.lastName : '';
            value = `${firstName} ${middleName} ${lastName}`;
        }
        if (props.value != null && props.value.valueType === 'COMPANY') {
            // TODO: common formatter (company renderer)
            const value = (props.value && props.value.companyValue) ? props.value.companyValue.name : '';
        }

        return {
            ...props,
            value,
        };
    }, [cfg, props]);

    if (!props.value) {
        return;
    }

    if (props.value.valueType === 'TEXT') {
        return createTextCellRenderer(cfg)(renderProps);
    }
    if (props.value.valueType === 'I8N_TEXT') {
        return createI8nTextCellRenderer(cfg)(renderProps);
    }
    if (props.value.valueType === 'INTEGER') {
        return createNumberCellRenderer(cfg)(renderProps);
    }
    if (props.value.valueType === 'LONG') {
        return createNumberCellRenderer(cfg)(renderProps);
    }
    if (props.value.valueType === 'DOUBLE') {
        return createNumberCellRenderer(cfg)(renderProps);
    }
    if (props.value.valueType === 'DATE') {
        return createDateCellRenderer(cfg)(renderProps);
    }
    if (props.value.valueType === 'BOOLEAN') {
        return createTextCellRenderer(cfg)(renderProps);
    };

    if (props.value.valueType === 'PERSON') {
        return createTextCellRenderer(cfg)(renderProps);
    };

    if (props.value.valueType === 'COMPANY') {
        return createTextCellRenderer(cfg)(renderProps);
    };

    return null;
};
export default createMultiCellRenderer;
