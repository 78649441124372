//
// History / Router
//
import { createBrowserHistory } from 'history';

import { createReduxHistoryContext, reachify } from "redux-first-history";

const history = createBrowserHistory();

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history });

export { history, createReduxHistory, routerReducer, routerMiddleware };
