// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../lib/trix-fp-fantasy';

//
// React
//
import React from 'react';

//
// React Router
//
import { Routes, Route } from 'react-router-dom';

//
// Components
//
import HalFormsPage from './HalFormsPage';

//
// Props
//
type Props = {
    //
    // HAL
    //
    __halFormsTemplates: any[],
    __ducks: any,

    //
    // Routing
    //
    history: {
        push: (url: string) => void,
    },

    //
    // Handlers
    //
    onClosePage: () => void,
} // End of Props

//
// Component
//
export const getHalFormsRoutes = (props: Props): any[] => {
    //
    // Hooks
    //
    // End hooks

    const __halFormsTemplates = props.__halFormsTemplates;

    // Array c -> Array r
    const allRoutes = S.pipe ([
        // Array c -> Array c
        S.filter (c => c.type === 'page'),

        // Array c -> Array r
        S.map (c => {
            return {
                path: c.path,
                template: c,
            };
        })
    ]) (__halFormsTemplates);

    return allRoutes.map((r, i) => {
        const path = r.path
            .replace(/^\//, '')
            .replace(/\{(\w+)\}/ig, ':$1');
        return(
            <Route
                key={`route_${i}`}
                path={path}
                element={
                    <HalFormsPage
                        __ducks={props.__ducks}
                        __halFormsTemplate={r.template}

                        history={props.history}

                        onClose={props.onClosePage}
                    />
                }
            />
        ); 
    });
};
export default getHalFormsRoutes;