// @flow

//
// Formatter
//
import {
    fmtQuantity,
} from '../../../../../lib/trix-web-data-commons';

const getConfiguration = (props: any) => {
    return {
        onGetOptionLabel: (option: any) => {
            let label = '';
            if (option.prose) {
                label = option.prose.code;
                if (option.prose.name.bg) {
                    label = label + ' ' + option.prose.name.bg;
                }
                if (option.quantity) {
                    label = label + ' (' + fmtQuantity(option.quantity) + ')';
                }
            } else {
                label = 'Unknown';
            }
            return label;
        }
    }
}
export default getConfiguration;