// @flow

//
// FP
//
import S, { F } from '../lib/trix-fp-fantasy';

//
// React
//
import React from 'react';
import { createRoot } from 'react-dom/client';

//
// Redux
//
import { combineReducers } from 'redux';
import concatenateReducers from 'redux-concatenate-reducers';

//
// History / Router
//
import { HistoryRouter } from "redux-first-history/rr6";

//
// React Redux integration
//
import { Provider } from 'react-redux';

//
// Redux Fantasy Reducers
//
import {
    compose,
    instances,
    node,
} from '../lib/redux-fantasy-reducers';

//
// Moment
//
import moment from 'moment';

//
// Import Roboto fonts family
//
// import '../css/roboto.css';

//
// Material UI
//
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

//
// Bundle
//
import Kernel from '../lib/trix-web-bundle';

import { ComponentsRegistryContext } from '../lib/trix-web-components-registry';

//
// Builder
//
import { rewriteHalFormsTemplates } from '../lib/trix-web-hal-forms/tools/halFormsTemplate';
import buildDucks, { setHalFormsIds } from '../lib/trix-web-hal-forms/duck';
import buildMenus from '../lib/trix-web-hal-forms/menu';

//
// Init API and Components
//
import { 
    loadMe,
    LoginPage,
    TenantSelectorPage,
} from '../plugins/trix-web-plugin-security-auth';
import { configureStore } from '../init/store';
import { history, routerReducer, routerMiddleware, createReduxHistory } from '../init/route';

//
// Configure middlewares
//
import { configureMiddleware as configureReduxFantasyMiddleware } from '../lib/redux-fantasy-middleware';

//
// Libs
//
import coreEntitiesLib from '../lib/trix-web-lib-core-entities';

//
// Root
//
const root = createRoot(document.getElementById('root')); 

//
// Functions
//
export function bootPublicApp(shell: any, features: string[], loadPlugin: (name: string) => any) {
    const user = null;
    const tenantId = 0;

    renderApp(user, tenantId, shell, features, loadPlugin);
}

export function bootMultitenantPrivateApp(shell: any, features: string[], loadPlugin: (name: string) => any) {
    init();

    renderWithTheme(<div>Loading...</div>);

    const pathname = (history.location && history.location.pathname) ? history.location.pathname : '';
    const tenantIdMatch = pathname.match('^/([0-9]*).*$');
    const tenantId = (tenantIdMatch && tenantIdMatch.length > 1 && parseInt(tenantIdMatch[1]) > 0) ? 
            parseInt(tenantIdMatch[1]) : 0;

    loadMe(
        tenantId,
        (error) => {
            renderLogin(shell, features, loadPlugin);
        }, 
        (user) => {
            if (tenantId > 0) {
                renderApp(user, parseInt(tenantId), shell, features, loadPlugin);
            } else {
                // ne tenant selected yet - ask user to select
                renderTenantSelector(shell, features, loadPlugin);
            }
        }
    );
}

//
// Private functions
//
function init() {
    // setup moment locale
    moment.updateLocale('bg', {
        week: {
            dow: 1, // Monday is the first day of the week.
            doy: 4, // Used to determine first week of the year.
        },
    });
    moment.locale('bg');
}
    
function setupTheme() {
    const theme = createTheme({
        shape: {
            borderRadius: 0,
        },
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#009fda',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },

            background: {
                default: '#fafafa'
            }
        },
        status: {
            // danger: "orange",
        },
    });
    // console.log('THEME:', theme);
    return theme;
}

function createStore(kernel: any, pluginsReducers: any[], pluginsOperations: any[]) {
    //
    // configure redux middlewares
    //
    const middlewares = [
        // router middleware for dispatching history actions
        routerMiddleware,

        configureReduxFantasyMiddleware(kernel.operations(pluginsOperations))
    ];
    
    //
    // Configure third party reducers
    //
    const otherReducers = combineReducers({
        router: routerReducer,
    });

    // configure store
    const store = configureStore(
        kernel.reducer(pluginsReducers, otherReducers),
        middlewares
    );
    return store;
}

function renderWithTheme(component: any) {
    const theme = setupTheme();

    // Use StyledEngineProvider to Inject Emotion before JSS 
    root.render(
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <React.Fragment>
                        {/* 
                            Setup Material-UI CssBaseline (https://material-ui.com/components/css-baseline/) 
                        */}
                        <CssBaseline />
                        {component}
                    </React.Fragment>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

function renderLogin(shell: any, features: string[], loadPlugin: (name: string) => any) {
    renderWithTheme(
        <LoginPage 
            onSuccessfullyAuthenticated={(user: any) => {
                renderTenantSelector(shell, features, loadPlugin);
            }}
        />
    )
}

function renderTenantSelector(shell: any, features: string[], loadPlugin: (name: string) => any) {
    //
    // create history
    //
    renderWithTheme(
        <TenantSelectorPage
            onTenantSelected={(tenantId: number) => {
                loadMe(
                    tenantId,
                    (error) => {
                        renderLogin(shell, features, loadPlugin);
                    }, 
                    (user) => {
                        // redirect to url path, which start with tenantId
                        history.push('/' + tenantId);

                        // boot app
                        renderApp(user, tenantId, shell, features, loadPlugin);
                    }
                );
            }}
        />
    )
}

function renderApp(user: any, tenantId: number, shell: any, features: string[],
        loadPlugin: (name: string) => any) {
console.log('**** BOOT APP: user:', user);
    //
    // Build Kernel
    //
    const kernel = new Kernel();
    kernel.registerShell(shell);
    kernel.registerLib(coreEntitiesLib);

    let allFeatures = features;
    if (user && user.features) {
        allFeatures = [
            ...allFeatures,
            ...user.features
        ];
    }

    for (let feature of allFeatures) {
        const plugin = loadPlugin(feature);
        if (null != plugin) {
            kernel.registerPlugin(plugin);
        }
    }

    const bootTask = F.fork 
    // reject
    (
        () => {
            // reject is not used, 'F.parallel' load plugins always 'resolves's with Either
        }
    )

    // resolve :: Array Either error data -> void
    (
        (a) => {
            // Build plugins' ducks
            // 
            // Ducks consists of action type, actions, system reducer and selectors
            // 
            // 'system reducer' is reducer for all ui components, that are going to be build 
            // dynamically by the hal-forms

            // merge embedded components into elmbedded contianers
            const plugins = rewriteHalFormsTemplates (a);
console.log('**** PLUGINS WITH MERGED EMBEDDED CONTAINERS: ', plugins);
// if (true) { return; }

            // set ids to templates
            const templates = setHalFormsIds(plugins);
console.log('**** TEMPLATES WITH IDS: ', templates);
// if (true) { return; }

            // build menus
            const menus = buildMenus(plugins);
console.log('**** MENUS: ', menus);
// if (true) { return; }

            // build ducks
            const ducks = buildDucks(templates);
console.log('*** DUCKS:', ducks);
// if (true) { return; }

            // build plugins' static reducer
            // 'static reducer' is reducer that is coded and exported by plugins. some plugins
            // implements specific non hal-forms generated functionality, which is supported by 
            // those 'static reducer'
            const staticReducers = a
                .map ((x) => {
                    return (x.plugin && x.plugin.state && x.plugin.state.reducer) ? 
                            x.plugin.state.reducer : null;
                })
                .filter (e => e != null);
// if (true) { return; }

            //
            // Create store
            //
            const store = createStore(kernel, S.concat (staticReducers) ( [ducks.reducer] ), ducks.operations);

            //
            // Register menus into kernel
            //
            kernel.registerMenus(menus);

            //
            // Create theme
            //
            const theme = createTheme();

            const componentsRegistryContext = {
                components: kernel.components(),
            };

            //
            // Render root
            //
            renderWithTheme(
                <Provider store={store}>
                    <ComponentsRegistryContext.Provider
                        value={componentsRegistryContext}
                    >
                        <HistoryRouter history={createReduxHistory(store)}>
                            <shell.web.components.Root
                                __halFormsTemplates={templates}
                                __ducks={ducks}

                                history={history}

                                tenantId={tenantId}
                                user={user}
                                menus={menus}
                            />
                        </HistoryRouter>
                    </ComponentsRegistryContext.Provider>
                </Provider>
            );
        }
    )
    
    // Future
    (
        F.parallel (5) (loadPluginsToFutures (tenantId) (kernel.plugins))
    );
}

//
// Booting functions
//

// loadPluginsToFutures :: Int -> Array Plugin -> Array Future Error Data
const loadPluginsToFutures = (tenantId: number) => S.pipe([
    // Array Plugin -> Array Plugin
    S.filter (p => !!(p.boot && p.boot.load)),

    // Array Plugin -> Array Future Error Data
    S.map (p => F.Future((reject, resolve) => {
        p.boot.load(
            // tenant
            tenantId,

            // reject
            error => resolve({
                plugin: p,
            }), 
            
            // resolve
            data => resolve({
                plugin: p,
                ...data,
            })
        );
        return () => null;
    })),
]);
