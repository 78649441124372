// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';

import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    FloatPane,
    TextFloatPaneField,
    DateFloatPaneField,
    type FloatPaneContentProps,
} from '../../../../../lib/trix-web-components-pane';

//
// Component factories
//
const Content = (props: any) => {
    return (
        <Grid container direction='row' spacing={2}>
            <Grid item>
                <TextFloatPaneField
                    label='Задача'
                    value={(props.data && props.data.subject) ? props.data.subject : ''}
                />
            </Grid>

            <Grid item>
                <TextFloatPaneField
                    label='Описание'
                    value={(props.data && props.data.description) ? props.data.description : ''}
                />
            </Grid>

            <Grid item>
                <DateFloatPaneField 
                    label='Дата на създаване'
                    value={(props.data && props.data.createdAt) ? props.data.createdAt : new Date()}
                />
            </Grid>
        </Grid>
    );
}

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },

    tableLastRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
}));

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Hal forms actions
    //
    __halFormsActions: any,

    //
    // Ducks
    //
    __ducks: any,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,
}

//
// Component
//
const ViewHumanTaskDetailsPane = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    return(
        <FloatPane
            Content={Content}
            isLoading={false}
            inProgress={false}
            title={props.__halFormsTemplate.title}
            container={null}
            data={props.data}
        />
    );
};
export default ViewHumanTaskDetailsPane;
