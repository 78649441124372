export const reduceAction = (
    actionType: string,
    reducer: (state: any, action: any) => any
) => (state: any, action: any) => {
    if (action.type !== actionType) {
        return state;
    }
    return reducer(state, action);
};

export type OperationExecution = {
    target: string,
    operation: string,
    label: string,
}
