// @flow

//
// React
//
import React, { type Node } from 'react';

//
// Components
//
import {
    TextFloatPaneField,
} from '../../../trix-web-components-pane';

//
// Props
//
type Props = {
    //
    // State
    //
    label: string,

    //
    // Data
    //
    data: any,
}; // End of Props

//
// Component
//
const FloatPaneSupplierField = (props: Props): Node => {
    const code = (props.data && props.data.code) ? props.data.code : '';
    const name = (props.data && props.data.name) ? props.data.name : '';

    return (
        <TextFloatPaneField
            label={props.label}
            value={`${code} / ${name}`}
        />
    );
};
export default FloatPaneSupplierField;
