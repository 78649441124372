// @flow

import shipmentLinesTableConfiguration from './components/configuration/shipmentLinesTableConfiguration';
import shipmentSourceDocumentsTableConfiguration from './components/configuration/shipmentSourceDocumentsTableConfiguration';
import saleSelectorFieldConfiguration from './components/configuration/saleSelectorFieldConfiguration';
import transferOutSelectorFieldConfiguration from './components/configuration/transferOutSelectorFieldConfiguration';
import shipmentSourceDocumentSelectorFieldConfiguration from './components/configuration/shipmentSourceDocumentSelectorFieldConfiguration';
import shipmentSourceDocumentItemSelectorFieldConfiguration from './components/configuration/shipmentSourceDocumentItemSelectorFieldConfiguration';

import ShipmentLinesTableRowExtension from './components/table/ShipmentLinesTableRowExtension';
import ShipmentParcelSizeField from './components/form/ShipmentParcelSizeField';
import createShipmentLineParcelStockTableColumn from './components/table/ShipmentLineParcelStockCellRenderer';
import createShipmentLineItemQuantityTableColumn from './components/table/ShipmentLineItemQuantityCellRenderer';
// import ShipmentLineItemQuantityCellRenderer from './components/table/ShipmentLineItemQuantityCellRenderer';
import ShipmentAgentConfigurationField from './components/form/ShipmentAgentConfigurationField';
import ShippingStockQuantityField from './components/form/ShippingStockQuantityField';

import TradeTimelineItem from '../../trix-web-plugin-wms-trade/web/components/timeline/TradeTimelineItem';

export const components = {
    'configuration:trix-web-plugin-wms-shipment.manageShipmentPage.container.shipmentLinesTable.table': shipmentLinesTableConfiguration,
    'configuration:trix-web-plugin-wms-shipment.manageShipmentPage.container.shipmentSourcesTable.table': shipmentSourceDocumentsTableConfiguration,
    'configuration:trix-web-plugin-wms-shipment.manageShipmentPage.container.shipmentSourcesTable.action.onAttachSale.editor._.sale': saleSelectorFieldConfiguration,
    'configuration:trix-web-plugin-wms-shipment.manageShipmentPage.container.shipmentSourcesTable.action.onAttachTransferOut.editor._.transferOut': transferOutSelectorFieldConfiguration,

    'tableRowExtension:trix-web-plugin-wms-shipment.manageShipmentPage.container.shipmentLinesTable': ShipmentLinesTableRowExtension,
    'floatPaneField:shipmentAgentConfiguration': ShipmentAgentConfigurationField,
    'formPaneField:shipmentParcelSize': ShipmentParcelSizeField,

    'tableColumn:shipmentLineParcelStock': createShipmentLineParcelStockTableColumn,
    'tableColumn:shipmentLineItemQuantity': createShipmentLineItemQuantityTableColumn,
    // 'tableCellRenderer:shipmentLineParcelStock': ShipmentLineParcelStockCellRenderer,
    // 'tableCellRenderer:shipmentLineItemQuantity': ShipmentLineItemQuantityCellRenderer,

    'configuration:shipmentSourceDocument': shipmentSourceDocumentSelectorFieldConfiguration,
    'configuration:shipmentSourceDocumentItem': shipmentSourceDocumentItemSelectorFieldConfiguration,
    'formPaneField:shippingStockQuantity': ShippingStockQuantityField,

    'timelineItem:trix-web-plugin-wms-shipment.manageShipmentPage.container.trade': TradeTimelineItem,
}
