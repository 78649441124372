// @flow

//
// FP
//
import S, { F } from '../../../../../../lib/trix-fp-fantasy';

//
// Redux Fantasy
//
import {
    O, P,
} from '../../../../../../lib/redux-fantasy-middleware';

//
// Trix Middleware
//
import {
    type Error,
    networkError,
    requiredFieldError,
} from '../../../../../../lib/trix-web-data-commons';
import {
    validate,
} from '../../../../../../lib/trix-web-middleware-commons';

//
// Plugin
//
import {
    deleteAllCookies,
    logout,
} from '../../../api';

//
// Duck
//
import Actions, { 
    ActionTypes,
    type UserLoggedOutAction,
} from '../actions';

// callLogout :: a -> Future Error User
const callLogout = (action: UserLoggedOutAction) => {
    return new F.Future((reject, resolve) => {
        logout(reject, () => {
            if (action.meta && action.meta.onSuccess) {
                deleteAllCookies();
                action.meta.onSuccess();
            }
            resolve();
        });

        // TODO: fix after migration to Axious
        return () => null;
    });
};

// logOut :: Action -> Future Action<Error> Action<>
const logOut = S.pipe ([
    // Action -> Future () Action
    F.resolve,

    // Future Error Action -> Future Error User
    S.chain (callLogout),

    // Timeout if API call takes more than 5 sec
    // TODO: use FP style of composition. Find the math !!!
    // Future Error Person -> Future Error Person
    F.race (F.rejectAfter (30000) (networkError('Connection timeout'))),

    // TODO: 
    // Cancel API call on CANCEL action
    // Future Error Person -> Future Error Person
    // F.race (rejectOnAction (a => a.type === 'LOGIN_CANCEL') ('Authentication canceled')),

    // Future Error Void -> Future Action<Error> Action<>
    F.bimap (Actions.logOutUser_Failure) (Actions.logOutUser_Success),
]);

export default O.takeAllAndFork (P.ofType(ActionTypes.USER_LOGGEDOUT)) (logOut);
