// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../lib/trix-fp-fantasy';

//
// Redux Fantasy Reducers
//
import {
    ofTypeWithReducerKey,
    ReducerKeyPattern,
} from '../../redux-fantasy-reducers';

//
// Trix Reducers
//
import {
    createFindOperation,
    createReadOperation,
    createPostOperation,
    createDeleteOperation,

    showSuccessNotification,
    showErrorNotification,
} from '../../trix-web-data-reducers';

//
// Trix Middleware
//
import { 
    get as httpGet, 
    post as httpPost,
} from '../../trix-web-middleware-commons';

// createOperations :: Array t -> Array o
export const createOperations = (actionsTypes: any) => (actions: any) => 
    S.reduce (xs => x => {
        const templateKey = `${x.plugin}__${x.name}`;
        const templateOperations = createSectionOperations (actionsTypes) (actions) (templateKey) (x);
        return S.concat (xs) (templateOperations);
    }) ([]);

// createSectionOperations :: Array t -> Array o
export const createSectionOperations = (actionsTypes: any) => (actions: any) => (templateKey: string) => (x: any) => {
    let operations = [];
    
    // create appropriate operations for the template type
    if (x.type === 'container') {
        operations = S.concat (operations) (createContainerOperations (actionsTypes) (actions) (templateKey) (x));
    }
    if (x.type === 'table') {
        operations = S.concat (operations) (createTableOperations (actionsTypes) (actions) (templateKey) (x));
    }
    if (x.type === 'timeline') {
        operations = S.concat (operations) (createTimelineOperations (actionsTypes) (actions) (templateKey) (x));
    }

    if (x.type === 'reportingTable') {
        operations = S.concat (operations) (createTableOperations (actionsTypes) (actions) (templateKey) (x));
    }

    // drill down components, that needs selectors operations to be created
    if (x.components) {
        const subOperations = S.reduce(sas => c => {
            const componentOperations = createSectionOperations (actionsTypes) (actions) (templateKey) (c);
            return S.concat (sas) (componentOperations);
        }) ([]) (x.components);
        operations = S.concat (operations) (subOperations);
    }

    // drill down into actions
    if (x.actions) {
        for (let i=0; i<x.actions.length; i++) {
            const a = x.actions[i];
            
            if (a.type === 'modal' && a.component) {
                const modalOperations = createSectionOperations (actionsTypes) (actions) (templateKey) (a.component);
                operations = S.concat (operations) (modalOperations);
            }

            if (a.type === 'httpPost') {
                operations = S.concat (operations) (createHttpPostOperation (actionsTypes) (actions) (templateKey) (a));
            }
        }
    }
    

    return operations;
}

// createContainerOperations :: Object -> a
const createContainerOperations = (actionsTypes: any) => (actions: any) => (templateKey: string) => (t: any) => {
    let operations = [];

    // get load operation action
    if (actions[t.id].operations && actions[t.id].operations.load) {
        // create load operation if data source is remote, i.e we have a loadOperation action
        const createOperation = createReadOperation ({
            actions: actions[t.id].operations.load,

            readApi: (action, reject, resolve) => {
                if (t.dataSource && t.dataSource.method === 'POST') {
                    httpPost(action.payload.url, action.payload.data, reject, resolve);
                    return;
                } else {
                    // if (t.dataSource && t.dataSource.method === 'GET') {
                    httpGet(action.payload.url, action.payload.data, reject, resolve);
                    return;
                }
                // console.error('Invalid HTTP request method for data source. ', t);
            },

            onFailure: (action: any) => {
                return [
                    showErrorNotification({
                        error: action.payload
                    })
                ];
            },
        });

        const reducerKey = ReducerKeyPattern.concat (
            'system',
            templateKey,
            ReducerKeyPattern.instance ()
        );

        const operation = createOperation (
            ofTypeWithReducerKey
                (actionsTypes[t.id].operations.load.OPERATION_REQUESTED)
                (reducerKey)
        );

        operations = S.append (operation) (operations);
    }

    return operations;
}

// createTableOperations :: Object -> a
const createTableOperations = (actionsTypes: any) => (actions: any) => (templateKey: string) => (t: any) => {
    let operations = [];

    // get load operation action
    if (actions[t.id].operations && actions[t.id].operations.load) {
        // create load operation if data source is remote, i.e we have a loadOperation action
        const createOperation = createFindOperation ({
            actions: actions[t.id].operations.load,

            findApi: (action, reject, resolve) => {
                if (t.dataSource && t.dataSource.method === 'POST') {
                    httpPost(action.payload.url, action.payload.data, reject, resolve);
                    return;
                } else {
                    // if (t.dataSource && t.dataSource.method === 'GET') {
                    httpGet(action.payload.url, action.payload.data, reject, resolve);
                    return;
                }
                // console.error('Invalid HTTP request method for data source. ', t);
            },

            onFailure: (action: any) => {
                return [
                    showErrorNotification({
                        error: action.payload
                    })
                ];
            },

            responsePagePath: (t.dataSource && t.dataSource.responsePagePath) ? t.dataSource.responsePagePath : undefined,

            responseDataPath: (t.dataSource && t.dataSource.responseDataPath) ? t.dataSource.responseDataPath : undefined,
        });

        const reducerKey = ReducerKeyPattern.concat (
            'system',
            templateKey,
            ReducerKeyPattern.instance ()
        );

        const operation = createOperation (
            ofTypeWithReducerKey
                (actionsTypes[t.id].operations.load.OPERATION_REQUESTED)
                (reducerKey)
        );

        operations = S.append (operation) (operations);
    }

    return operations;
}

// createTimelineOperations :: Object -> a
const createTimelineOperations = (actionsTypes: any) => (actions: any) => (templateKey: string) => (t: any) => {
    let operations = [];

    // get load operation action
    if (actions[t.id].operations && actions[t.id].operations.load) {
        // create load operation if data source is remote, i.e we have a loadOperation action
        const createOperation = createFindOperation ({
            actions: actions[t.id].operations.load,

            findApi: (action, reject, resolve) => {
                if (t.dataSource && t.dataSource.method === 'POST') {
                    httpPost(action.payload.url, action.payload.data, reject, resolve);
                    return;
                } else {
                    // if (t.dataSource && t.dataSource.method === 'GET') {
                    httpGet(action.payload.url, action.payload.data, reject, resolve);
                    return;
                }
                // console.error('Invalid HTTP request method for data source. ', t);
            },

            onFailure: (action: any) => {
                return [
                    showErrorNotification({
                        error: action.payload
                    })
                ];
            },
        });

        const reducerKey = ReducerKeyPattern.concat (
            'system',
            templateKey,
            ReducerKeyPattern.instance ()
        );

        const operation = createOperation (
            ofTypeWithReducerKey
                (actionsTypes[t.id].operations.load.OPERATION_REQUESTED)
                (reducerKey)
        );

        operations = S.append (operation) (operations);
    }

    return operations;
}

// createHttpPostOperation :: Object -> a
const createHttpPostOperation = (actionsTypes: any) => (actions: any) => (templateKey: string) => (t: any) => {
    let operations = [];

    // get action
    if (actions[t.id]) {
        const createOperation = createPostOperation ({
            actions: actions[t.id],

            validators: [
            ], // End of operation's validators

            postApi: (action, reject, resolve) => {
                httpPost(action.payload.url, action.payload.data, reject, resolve);
            },

            onSuccess: (action: any) => {
                return [
                    showSuccessNotification({
                        message: 'Операцията беше изпълнена успешно'
                    })
                ];
            },

            onFailure: (action: any) => {
                return [
                    showErrorNotification({
                        error: action.payload
                    })
                ];
            },
        });

        const reducerKey = ReducerKeyPattern.concat (
            'system',
            templateKey,
            ReducerKeyPattern.instance ()
        );

        const operation = createOperation (
            ofTypeWithReducerKey
                (actionsTypes[t.id].OPERATION_REQUESTED)
                (reducerKey)
        );

        operations = S.append (operation) (operations);
    }

    return operations;
}


