// @flow

//
// Trix Middleware
//
import {
    type Error,
} from '../../../../trix-web-data-commons';

//
// Action types
//
export const ActionTypes = {
    STORE_ENTITIES_REQUESTED:   '@@trix-web-lib-core-entities/entities/STORE_ENTITIES_REQUESTED',
};

//
// Actions
//
export type StoreEntityRequestedAction = {
    type: '@@trix-web-lib-core-entities/entities/STORE_ENTITIES_REQUESTED',
    payload: {
        type: string;
        entities: { [id: string]: any };
    }
};

//
// Action creators
//
const storeEntities = (type: string, entities: { [id: string]: any }): StoreEntityRequestedAction => {
    return {
        type: '@@trix-web-lib-core-entities/entities/STORE_ENTITIES_REQUESTED',
        payload: {
            type,
            entities,
        }
    }
};

const Actions = {
    storeEntities,
};
export default Actions;
