// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

//
// React
//
import React, {
    useLayoutEffect, 
    useState,
    type ComponentType,
} from 'react';

//
// React router
//
import { useParams } from 'react-router-dom';

//
// DataSource
//
import {
    getDataSourceType,
    loadData
} from './dataSource';

//
// Props
//
type Props = {
    //
    // Actions
    //
    // __halFormsAction?: any,
    // __halFormsActions?: any[],
    
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    // __ducks: {
    //     selectors: any,
    //     actions: any,
    // },

    //
    // Data
    //
    data: any,

    //
    // Container
    //
    container: any,
} // End of Props

//
// Extender
//
const withHalFormsOptions = (Component: ComponentType<any>): any => (props: Props) => {
    //
    // Hooks
    //
    const params = useParams();
    // End hooks

    let dataSource = props.__halFormsTemplate.options;
    if (!dataSource) {
        return (
            <Component
                {...props}
            />
        );
    }

    const dataSourceType = getDataSourceType({ dataSource }) ();

    let data = {};
    let isLoadingData = false;

    if (dataSourceType === 'remote') {
        // check if data source data has been already loaded
        // const getDataSelector = getSelector
        //         (props.__ducks)
        //         (props.__halFormsTemplate.id)
        //         ('data.getData');
        // data = (getDataSelector) ? getDataSelector(pageInstanceState) : null;

        // const isLoadingDataSelector = getSelector
        //         (props.__ducks)
        //         (props.__halFormsTemplate.id)
        //         ('operations.load.isInProgress');
        // isLoadingData = isLoadingDataSelector && isLoadingDataSelector(pageInstanceState);
    }

    if (dataSourceType === 'inline') {
        let items: any = [];
        if (dataSource.itemsPathFromContainer) {
            items = S.fromMaybe ([]) (S.gets 
                    (S.is ($.Array ($.Object))) 
                    (dataSource.itemsPathFromContainer.split('.')) 
                    (props.container));
        }
        if (dataSource.itemsPathFromData) {
            items = S.fromMaybe ([]) (S.gets 
                    (S.is ($.Array ($.Object))) 
                    (dataSource.itemsPathFromData.split('.')) 
                    (props.data));
        }

        if (dataSource.inline) {
            items = dataSource.inline;
        }

        data = {
            items,
            page: {
                size: '',
                totalElements: items.length,
                totalPages: 1,
                number: 0,
            }
        }
    }

    // TODO: select error (if any) on data loading and pass to the upstream

    return (
        <Component
            {...props}

            //
            // Our props
            //
            __dataSourceExtender={{
                dataSource,
                getDataSourceType: getDataSourceType({
                    dataSource
                }),
                loadData: loadData({
                    // dispatchLocal,
                    halFormsTemplate: props.__halFormsTemplate,
                    // ducks: props.__ducks,
                    dataSource,
                    container: props.container,
                    data: props.data,
                }),
                isLoadingData,
                data
            }}
        />
    );
};
export default withHalFormsOptions;