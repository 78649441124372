// @flow

//
// Formatter
//
import {
    fmtNumber,
    fmtDate,
} from '../../../../../lib/trix-web-data-commons';

const getConfiguration = (props: any) => {
    return {
        onGetOptionLabel: (option: any) => {
            let documentType = 'Unknown'; 
            if (option.documentType === 'SALE') {
                documentType = 'Продажба';
            }
            if (option.documentType === 'TRANSFER') {
                documentType = 'Трансфер';
            }
            return `${documentType} - ${fmtNumber(option.documentNo, '0000000000')} / ${fmtDate(option.documentDate, 'LL')}`;
        }
    }
}
export default getConfiguration;