// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../trix-fp-fantasy';

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

import {
    evaluateCondition,
} from '../../tools/halFormsCondition';

//
// Components
//
import {
    withComponentsRegistry,
} from '../../../trix-web-components-registry';
import {
    TextFloatPaneField,
    I8nTextFloatPaneField,
    NumberFloatPaneField,
    DateFloatPaneField,
    MoneyFloatPaneField,
    QuantityFloatPaneField,
    PercentFloatPaneField,
} from '../../../trix-web-components-pane';
import {
    AddressFloatPaneField,
    ShippingAddressFloatPaneField,
} from '../../../trix-web-components-domain-geo';

//
// Functions
//
const createFields = (props: {
    getComponent: (name: string) => any,

    ducks: {
        selectors: any,
        actions: any,
    },

    classes: any,
    components: any[],
    data: any,
    error: any,
}) => {
    // wrap up components into sections
    const components = props.components || [];
    let sections = [];
    for (let i = 0; i < components.length; i++) {
        if (components[i].fieldType === 'section') {
            sections.push(components[i]);
            continue;
        }
        if (sections.length === 0) {
            sections.push({
                fieldType: 'section',
                components: [],
            })
        }
        sections[sections.length - 1].components.push(components[i]);
    }

    const conditionContext = {
        container: {},
        data: props.data
    };

    return (
        <Grid container direction='column'>
            {
                sections.map((s, si, array) => {
                    if (s.visibleIf) {
                        const isVisible = evaluateCondition(s.visibleIf, conditionContext);
                        if (!isVisible) {
                            return null;
                        }
                    }

                    return (
                        <React.Fragment key={`${si}-fragment`}>
                            {
                                (si > 0) ? (
                                    <Grid
                                        key={`${si}-divider`} 
                                        item
                                    >
                                        <Divider/>
                                    </Grid>
                                ) : null
                            }
                            <Grid
                                key={si} 
                                item 
                                container 
                                spacing={2}
                                className={props.classes.fieldsBlock}
                                direction='row'
                            >
                                {
                                    (s.components || []).map((c, ci) => {
                                        if (c.visibleIf) {
                                            const isVisible = evaluateCondition(c.visibleIf, conditionContext);
                                            if (!isVisible) {
                                                return null;
                                            }
                                        }

                                        if (c.type !== 'field') {
                                            return null;
                                        }

                                        if (c.fieldType === 'section') {
                                            return (
                                                createFields ({
                                                    getComponent: props.getComponent,

                                                    classes: props.classes,
                                                    components: c.components,
                                                    ducks: props.ducks,
                                                    data: props.data,
                                                    error: props.error,
                                                })
                                            );
                                        }

                                        if (c.fieldType === 'text' || c.fieldType === 'TEXT') {
                                            return (
                                                <Grid item key={ci}>
                                                    <TextFloatPaneField
                                                        label={c.title}
                                                        value={S.fromMaybe ('') (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data))}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'i8nText' || c.fieldType === 'i8n_text') {
                                            return (
                                                <Grid item key={ci}>
                                                    <I8nTextFloatPaneField
                                                        label={c.title}
                                                        value={S.fromMaybe ({}) (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data))}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'documentNo') {
                                            return (
                                                <Grid item key={ci}>
                                                    <NumberFloatPaneField
                                                        label={c.title}
                                                        value={S.fromMaybe (0) (S.gets (S.is ($.Integer)) (c.name.split('.')) (props.data))}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'documentDate') {
                                            return (
                                                <Grid item key={ci}>
                                                    <DateFloatPaneField
                                                        label={c.title}
                                                        value={S.fromMaybe ('') (S.gets (S.is ($.String)) (c.name.split('.')) (props.data))}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'percent') {
                                            const value = S.maybeToNullable (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                            
                                            return (
                                                <Grid item key={ci}>
                                                    <PercentFloatPaneField 
                                                        label={c.title}
                                                        value={(value) ? value : 0.0}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'number') {
                                            const value = S.maybeToNullable (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                            
                                            return (
                                                <Grid item key={ci}>
                                                    <NumberFloatPaneField 
                                                        label={c.title}
                                                        value={(value) ? value : 0.0}
                                                        fmt='0.0'
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'checkbox') {
                                            const value = S.maybeToNullable (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                            
                                            return (
                                                <Grid item key={ci}>
                                                    <TextFloatPaneField
                                                        label={c.title}
                                                        value={value ? 'Да' : 'Не'}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'money') {
                                            const value = S.maybeToNullable (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                            
                                            return (
                                                <Grid item key={ci}>
                                                    <MoneyFloatPaneField 
                                                        label={c.title}
                                                        value={(value) ? value : { amount: 0.0, currency: '-' }}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'quantity') {
                                            const value = S.maybeToNullable (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                            
                                            return (
                                                <Grid item key={ci}>
                                                    <QuantityFloatPaneField 
                                                        label={c.title}
                                                        value={(value) ? value : { amount: 0.0, unit: '-' }}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'address') {
                                            const value = S.maybeToNullable (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));

                                            return (
                                                <Grid item key={ci}>
                                                    <AddressFloatPaneField
                                                        label={c.title}
                                                        data={value}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'shipping_address') {
                                            const value = S.maybeToNullable (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));

                                            return (
                                                <Grid item key={ci}>
                                                    <ShippingAddressFloatPaneField
                                                        label={c.title}
                                                        data={value}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'country') {
                                            const value = S.fromMaybe ({}) (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));

                                            return (
                                                <Grid item key={ci}>
                                                    <TextFloatPaneField
                                                        label={c.title}
                                                        value={(value) ? `${value.code} / ${value.name}` : ''}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        if (c.fieldType === 'link' || c.fieldType === 'LINK') {
                                            return (
                                                <Button 
                                                    variant='outlined'
                                                    onClick={() => {
                                                        window.open(S.fromMaybe ('') (S.gets (S.is ($.String)) (c.name.split('.')) (props.data)), '_blank');
                                                    }}
                                                >PDF</Button>
                                            );
                                        }

                                        // try to fetch components from the registry
                                        const Component = props.getComponent(`floatPaneField:${c.fieldType}`);
                                        if (null != Component) {
                                            let value = S.maybeToNullable 
                                                (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                            return (
                                                <Grid key={ci} item>
                                                    <Component
                                                        __halFormsTemplate={c}
                                                        __ducks={props.ducks}
                                                        container={{
                                                            data: props.data
                                                        }}

                                                        name={c.name}
                                                        label={c.title}
                                                        description={c.description}
                                                        data={value}
                                                    />
                                                </Grid>
                                            );
                                        }

                                        return null;
                                    })
                                }
                            </Grid>
                        </React.Fragment>
                    )
                })
            }
        </Grid>
    );
};

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {},

    fieldsBlock: {
        padding: theme.spacing(1),
    },
}));

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Components registry
    //
    __componentsRegistry: any,

    //
    // Data
    //
    data: any,

    //
    // Error
    //
    error: any,
} // End of Props

//
// Component
//
const HalFormsFloatPaneContent = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    return createFields ({
        classes,
        getComponent: props.__componentsRegistry.getComponent,
        components: props.__halFormsTemplate.components,
        ducks: props.__ducks,
        data: props.data,
        error: props.error
    });
}
export default withComponentsRegistry(HalFormsFloatPaneContent);