// @flow

//
// React
//
import React from 'react';

//
// Formatter
//
import { fmtDate } from '../../../../trix-web-data-commons';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../types/CellProps';

//
// Helper
//
import { renderAsLink } from './tools';

//
// Component
//
const createDateInMsCellRenderer = (cfg: CellRendererFactoryProps): any => (props: CellProps): any => {
    if (!props.value) {
        return (<i>Not defined</i>);
    }

    const value = fmtDate(props.value);
    return (
        <div>
            {renderAsLink(cfg.onClick, props.row)(value)}
        </div>
    );
};
export default createDateInMsCellRenderer;
