// @flow

//
// React
//
import React from 'react';

//
// Formatter
//
import { fmtQuantity } from '../../../../../lib/trix-web-data-commons';

//
// Cell renderer
//
import {
    createItemSubItemCellRenderer,
} from '../../../../../lib/trix-web-components-table';

const createSaleOrderLineQuantitiesTableColumn = (cfg: any): any => {
    return {
        align: 'right',
        flex: false,
        width: 120,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            align: 'right',

            getItem: (props: any) => {
                return fmtQuantity((props.row && props.row.quantity) ? 
                        props.row.quantity : undefined);
            },

            getSubItem: (props: any) => {
                const reservedQuantity = (props.row && props.row.reservedQuantity) ? props.row.reservedQuantity : {
                    amount: 0.0, 
                    unit: '-'
                };
                const soldQuantity = (props.row && props.row.soldQuantity) ? props.row.soldQuantity : {
                    amount: 0.0, 
                    unit: '-'
                };
                const returnedQuantity = (props.row && props.row.returnedQuantity) ? props.row.returnedQuantity : {
                    amount: 0.0, 
                    unit: '-'
                };
                return `${fmtQuantity(reservedQuantity)} / ${fmtQuantity(soldQuantity)} (${fmtQuantity(soldQuantity)})`;
            }
        })
    }
}
export default createSaleOrderLineQuantitiesTableColumn;