// @flow

//
// Types
//
type Configuration = {
    ns: string;
    data: string;
}

type ActionTypes = {
    TABLE_INITIALIZED: string;
    COLUMN_STATE_CHANGED: string;
    FILTER_CHANGED: string;
    PAGE_NUMBER_CHANGED: string;
    ROWS_PER_PAGE_CHANGED: string;
    SELECTED_ITEMS_CHANGED: string;
}

//
// Action types factory
//
export const createComponentTableActionsTypes = (cfg: Configuration): any => {
    return {
        TABLE_INITIALIZED: `@@${cfg.ns}/${cfg.data}_TABLE_INITIALIZED`,
        COLUMN_STATE_CHANGED: `@@${cfg.ns}/${cfg.data}_COLUMN_STATE_CHANGED`,
        FILTER_CHANGED: `@@${cfg.ns}/${cfg.data}_FILTER_CHANGED`,
        PAGE_NUMBER_CHANGED: `@@${cfg.ns}${cfg.data}_PAGE_NUMBER_CHANGED`,
        ROWS_PER_PAGE_CHANGED: `@@${cfg.ns}${cfg.data}_ROWS_PER_PAGE_CHANGED`,
        SELECTED_ITEMS_CHANGED: `@@${cfg.ns}/${cfg.data}_SELECTED_ITEMS_CHANGED`,
    };
}

//
// Actions factory
//
export const createComponentTableActions = (actionTypes: ActionTypes): any => {
    //
    // Action creators
    //
    const initializeTable = (columns: Array<any>) => {
        return {
            type: actionTypes.TABLE_INITIALIZED,
            payload: {
                columns,
            }
        }
    };

    const enableColumn = (id: string) => {
        return {
            type: actionTypes.COLUMN_STATE_CHANGED,
            payload: {
                id,
                enabled: true,
            }
        }
    };

    const disableColumn = (id: string) => {
        return {
            type: actionTypes.COLUMN_STATE_CHANGED,
            payload: {
                id,
                enabled: false,
            }
        }
    };

    // const changeFilter = (property: string, op: string, value: any) => {
    //     return {
    //         type: actionTypes.FILTER_CHANGED,
    //         payload: {
    //             property,
    //             op,
    //             value,
    //         }
    //     }
    // };
    const changeFilter = (filter: any) => {
        return {
            type: actionTypes.FILTER_CHANGED,
            payload: filter
        }
    };

    const changePageNumber = (pageNumber: number) => {
        return {
            type: actionTypes.PAGE_NUMBER_CHANGED,
            payload: {
                pageNumber,
            }
        }
    };

    const changeRowsPerPage = (rowsPerPage: number) => {
        return {
            type: actionTypes.ROWS_PER_PAGE_CHANGED,
            payload: {
                rowsPerPage,
            }
        }
    };

    const changeSelectedItems = (items: string[]) => {
        return {
            type: actionTypes.SELECTED_ITEMS_CHANGED,
            items,
        }
    }

    return {
        initializeTable,
        enableColumn,
        disableColumn,

        changeFilter,
        changeRowsPerPage,

        changeSelectedItems,
    };
}