// @flow

//
// FP
//
import S, { F } from '../../trix-fp-fantasy';

//
// Lodash
//
import merge from 'lodash/merge';

//
// Redux Fantasy
//
import {
    O,
} from '../../redux-fantasy-middleware';

//
// Trix Data
//
import {
    networkError,
} from '../../trix-web-data-commons';

//
// Trix Middleware
//
import {
    validate,
} from '../../trix-web-middleware-commons';

//
// Configuration
//
type Configuration = {
    // actions
    actions: {
        // requestOperation :: data -> action
        requestOperation: (data: any) => any;

        // operationExecuted_Success :: data -> action
        operationExecuted_Success: (data: any) => any;

        // operationExecuted_Failure :: data -> action
        operationExecuted_Failure: (error: any) => any;
    },

    // Array (Action -> Maybe Error)
    validators?: any,

    // (action, reject, resolve) -> void
    postApi: (action: any, reject: any, resolve: any) => void,

    // transitionOnSuccess :: (prevState, nextState, action) -> transitions
    transitionOnSuccess?: (prevState: any, nextState: any, action: any) => any;

    // onSuccess :: Action -> Array Action
    onSuccess?: (a: any) => any[],

    // onFailure :: Action -> Array Action
    onFailure?: (a: any) => any[],
}

const createPostOperation = (cfg: Configuration): any => 
            (actionPredicate: ((a: any) => Boolean)) => {
    // api :: Action -> Future Error Data
    const callApi = (action: any) => {
        return new F.Future((reject, resolve) => {
            cfg.postApi(action, 
                // reject,
                (error) => {
                    // execute onsuccess callback (if specified)
                    if (action.meta && action.meta.onFailure) {
                        action.meta.onFailure(error);
                    }
                    // call reject callback
                    reject(error);
                },

                // resolve
                (data) => {
                    // execute onsuccess callback (if specified)
                    if (action.meta && action.meta.onSuccess) {
                        action.meta.onSuccess(data.body);
                    }
                    // call resolve callback
                    resolve(data);
                });

            // TODO: fix after migration to Axious
            return () => null;
        });
    };

    // postDataFailure :: action -> error -> action
    const postDataFailure = (action: any) => (error: any) => {
        const actions = [];

        const failureAction = merge({}, cfg.actions.operationExecuted_Failure(error), {
            meta: {
                ...action.meta,
            },
        });
        actions.push(failureAction);

        if (cfg.onFailure) {
            actions.push(...cfg.onFailure(failureAction));
        }

        return actions;
    }

    // postDataSuccess :: action -> data -> Array action
    const postDataSuccess = (action: any) => (data: any) => {
        const actions = [];

        const transition = (cfg.transitionOnSuccess) ? {
            transition: cfg.transitionOnSuccess
        } : {};

        // enrich with initial action's meta
        const successAction = merge(
            {},
            cfg.actions.operationExecuted_Success(data.body),
            {
                meta: {
                    ...action.meta,
                    ...transition,
                },
            }
        );
        actions.push(successAction);

        if (cfg.onSuccess) {
            actions.push(...cfg.onSuccess(successAction));
        }

        return actions;
    }

    // postTask :: Action -> Future Error Data
    const postTask = (action: any) => S.pipe([
        // Action -> Future () Action
        F.resolve,

        // Future () Action -> Future () Either Error Action 
        S.map (validate(cfg.validators || [])),

        // Future () Either Error Action -> Future Error Action
        S.chain (S.either (F.reject) (F.resolve)),

        // Future () Maybe Action -> Future Error Data
        S.chain (callApi),

        // Timeout if API call takes more than 5 sec
        // TODO: use FP style of composition. Find the math !!!
        // Future Error Data -> Future Error Data
        F.race (F.rejectAfter (60000) (networkError('Connection timeout'))),

        // TODO: 
        // Cancel API call on CANCEL action
        // Future Error Data -> Future Error Data
        // F.race (rejectOnAction (a => a.type === 'CANCEL') ('Operation canceled')),

        // Future Error Data -> Future Action<Error> Action<Page>
        F.bimap (postDataFailure (action)) (postDataSuccess (action)),
    ]) (action);

    return O.takeAllAndFork (actionPredicate) (postTask);
}
export default createPostOperation;