// @flow

//
// React
//
import React from 'react';

//
// Cell renderer
//
import {
    createItemSubItemCellRenderer,
} from '../../../trix-web-components-table';

const createPropertyCodeNameTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: true,
        width: 300,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            getItem: (props: any) => {
                return (props.value) ? `${props.value.code} / ${props.value.name}` : '';
            },

            getSubItem: (props: any) => {
                if (props.value && props.value.description) {
                    return props.value.description.description.bg;
                } else {
                    return (<i>Not defined</i>);
                }
            }
        })
    }
}
export default createPropertyCodeNameTableColumn;