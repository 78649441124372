// @flow

//
// React
//
import React from 'react';

//
// Type
//
import { type CellRendererProps } from '../../../../../lib/trix-web-components-table';

//
// Material UI
//
import TableCell from '@mui/material/TableCell';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        // take width of the content
        width: '1px',
        whiteSpace: 'nowrap',
    },
}));

//
// Props
//
type Props = CellRendererProps & {
    //
    // Data
    //
    value: any,
};

//
// Component
//
const DocumentTypeCellRenderer = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End Hooks

    const name = (props.value && props.value.name) ? props.value.name : 'N/A';

    return (
        <TableCell 
            padding="normal" 
            className={classes.root}
        >
            {name}
        </TableCell>
    );
};
export default DocumentTypeCellRenderer;
