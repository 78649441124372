// @flow 

//
// Redux
//
import { combineReducers } from 'redux';

// Namespace
const NS = 'shell/app';

//
// Actions
//
export const ActionTypes = {
    LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
    ORGANIZATION_SET: '@@trix-web-shell/reducer/ORGANIZATION_SET',
};

export type LocationChangeAction = {
    type: '@@router/LOCATION_CHANGE',
    payload: any,
}

export type OrganizationSetAction = {
    type: '@@trix-web-shell/reducer/ORGANIZATION_SET',
    payload: any,
};

// End Actions

//
// Action creators
//
const setOrganization = (organization: any): OrganizationSetAction => {
    return {
        type: '@@trix-web-shell/reducer/ORGANIZATION_SET',
        payload: organization,
    };
};

export const Actions = {
    setOrganization,
}
