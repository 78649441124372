// @flow

//
// React
//
import React from 'react';

//
// Components
//
import { HumanTaskStatusChip } from '../../../../../lib/trix-web-components-domain-human-task';

//
// Component
//
const createHumanTaskStatusTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: false,
        width: 150,

        renderCell: (props: any): any => {
            return(
                <HumanTaskStatusChip
                    humanTaskStatus={props.value}
                />
            );
        }
    }
};
export default createHumanTaskStatusTableColumn;

