// @flow

//
// React
//
import React from 'react';

import {
    createItemSubItemCellRenderer,
} from '../../../trix-web-components-table';

const createChatParticipantTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: false,
        width: 150,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            getItem: (props: any) => {
                return (props.value) ? props.value.name : '';
            },

            getSubItem: (props: any) => {
                if (props.value) {
                    return props.value.email;
                } else {
                    return (<i>Not defined</i>);
                }
            }
        })
    }
}
export default createChatParticipantTableColumn;