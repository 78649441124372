// @flow

export const getLocationPreffix = (tenantId: string): string => {
    return (tenantId != undefined && tenantId != null) ? `/${tenantId}` : '';
}

export const buildLocationPath = (tenantId: string): any => (path: string) => {
    return (tenantId != undefined && tenantId != null) ? `/${tenantId}${path}` : path;
}

export const navigateTo = (navigate: (string: any) => void, tenantId: string): any => (path: string) => {
    navigate(buildLocationPath(tenantId)(path));
}
