// @flow

//
// Lodash
//
import debounce from 'lodash/debounce';

//
// React
//
import React, { 
    useState,
    useCallback,
    useEffect,
} from 'react';

//
// Material UI
//
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {},
}));

//
// Props
//
type Props = {
    //
    // Visual
    //
    variant?: string,
    fullWidth?: boolean,

    //
    // Action handlers
    //
    onLoadData: (
        value: string,
        reject: (error: any) => void,
        resolve: (options: Array<any>) => void
    ) => void,
    onGetOptionLabel: (option: any) => any,
    isOptionEqualToValue: (option: any, value: any) => boolean,

    onChange?: (value: any) => void,
    onInputChange?: (value: string) => void,

    //
    // State
    //
    autoFocus?: boolean,
    error?: boolean,
    helperText?: string,
    required?: boolean,
    label?: string,
    disabled?: boolean,
    freeSolo?: boolean,

    //
    // Data
    //
    value?: any,
}; // End of Props

//
// Component
//
const ComboBox = (props: Props): any => {
    if (!props.onLoadData) {
        console.warn('onLoadData handler is not specified.');
    }
    if (!props.onGetOptionLabel) {
        console.warn('onGetOptionLabel handler is not specified.');
    }

    //
    // Hooks
    //
    const classes = useStyles();

    const [options, setOptions] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [inputText, setInputText] = useState('');
    const [isLoadedOnOpen, setLoadedOnOpen] = useState(false);

    // Show value set, in case no options are still loaded
    useEffect(() => {
        if (props.value && (!options || options.length <= 0)) {
            setOptions([props.value]);
            setInputText(props.onGetOptionLabel(props.value));
        }
    }, [props.value]);

    //
    // Debounced load data handler
    //
    const doLoadData = (value: any, reject: any, resolve: any) => {
        if (!props.onLoadData) {
            console.warn('onLoadData handler was not specified.');
            resolve([]);
            return;
        }

        setLoading(true);
        props.onLoadData(
            value,
            options => {
                setLoading(false);
                reject(options);
            },
            error => {
                setLoading(false);
                resolve(error);
            }
        );
    };
    const debouncedLoadData = debounce(doLoadData, 500);

    const inputChangeHandler = useCallback((event: Object, value: string, reason: string) => {
        if (reason === 'input') {
            setInputText(value);
            if (props.freeSolo && props.onInputChange) {
                props.onInputChange(value);
            }
            debouncedLoadData(
                value,
                error => {
                    console.log('Error loading combo box data: ', error);
                },
                setOptions
            );
        }

        if (reason === 'clear') {
            setInputText(value);
            if (props.freeSolo && props.onInputChange) {
                props.onInputChange(value);
            }
            if (props.onChange) {
                props.onChange(null);
            }
        }

        if (reason === 'reset') {
            setInputText(value);
        }
    }, [props.onChange, props.onInputChange]);

    const changeHandler = useCallback((event: Object, value: any, reason: string) => {
        if (reason === 'createOption') {
            if (props.freeSolo && props.onInputChange) {
                props.onInputChange(value);
            }
        }

        if (reason === 'selectOption') {
            if (props.onChange) {
                props.onChange(value);
            }
        }
    }, [props.onChange, props.onInputChange]);

    // stop the invocation of debounced function after unmounting
    useEffect(() => {
        return () => {
            debouncedLoadData.cancel();
        }
    }, []);

    const openHandler = useCallback(() => {
        setOpen(true);
        if (!isLoadedOnOpen) {
            setLoadedOnOpen(true);
            doLoadData(
                inputText,
                error => {
                    console.log('Error loading combo box data: ', error);
                },
                setOptions
            );
        }
    }, [inputText, props.onLoadData]);

    const closeHandler = useCallback(() => setOpen(false), []);

    const renderInput = useCallback((params: any) => {
        return (
            <TextField
                {...params}
                label={props.label}
                variant='filled'
                autoFocus={props.autoFocus}
                fullWidth={props.fullWidth}
                error={props.error}
                helperText={props.helperText}
            />
        )
    }, [props.error, props.helperText, props.label]);

    return (
        <Autocomplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys

            freeSolo={props.freeSolo}
            openOnFocus={(props.freeSolo) ? false : true}
            
            // TODO: build-in loading indication is irritating. need to implement some progress indicator
            // outside of the dropdown menu
            // loading={isLoading}
            // loadingText='Зарежда...'
            size='small'
            options={options}
            getOptionLabel={props.onGetOptionLabel}
            isOptionEqualToValue={props.isOptionEqualToValue}
            onOpen={openHandler}
            onClose={closeHandler}
            onInputChange={inputChangeHandler}
            onChange={changeHandler}

            inputValue={inputText}

            value={props.value}

            required={props.required}
            disabled={props.disabled}

            renderInput={renderInput}
        />
    );
};
export default ComboBox;
