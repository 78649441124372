// @flow

//
// Lodash
//
import fp from 'lodash/fp';

//
// React
//
import React from 'react';

//
// Tenant
//
import { withTenantProvider } from '../../../../lib/trix-web-components-tenant';

//
// Bundle
//
import initializer from '../../../../plugins/trix-web-plugin-security-auth/enhancers/initializer';

//
// Components
//
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';

import withPages from '../../enhancers/withPages';

// create index page
export default fp.compose(
    // exec 'authenticated' enhancer provided by an auth plugin
    // all application's component needs to be authenticated
    // authenticated({ LoginPage }),
    initializer({ }),

    // provide tenant context
    withTenantProvider,

    // provide shell pages management
    withPages
)(MainPage);