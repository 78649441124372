// @flow

//
// Trix Middleware
//
import { get } from '../../lib/trix-web-middleware-commons';

//
// Name
//
import { PLUGIN_NAME } from './plugin';

import { components } from './web';

//
// Constants
//
const MENU_ITEMS_POSITION = 3;

//
// Plugin Definition
//
const plugin = {
    name: PLUGIN_NAME,

    //
    // State
    //
    state: {
    },

    //
    // Boot functions
    //
    boot: {
        load: (tenantId: number, reject: (error: any) => void, resolve: (data: any) => void) => {
            get(
                // url
                '/api/v1/core/company/index', 

                // options
                {
                    headers: {
                        // On server disable X-Tenant header for 'index' request (index is same for all tenants)
                        'X-Tenant': 0
                    }
                },

                // reject callback
                reject, 

                // resolve callback
                (data) => resolve({
                    templates: data.body.templates,
                    menus: data.body.menus.map(m => {
                        // set menu items position
                        for (let i=0; i<m.items.length; i++) {
                            m.items[i].position = MENU_ITEMS_POSITION;
                        }
                        return m;
                    })
                })
            );
        },
    },

    //
    // Web
    //
    web: {
        // public components
        components
    },
}
export default plugin;