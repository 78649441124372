// @flow

//
// React
//
import React from 'react';

//
// Cell renderer
//
import {
    createMultiCellRenderer,
} from '../../../trix-web-components-table';

const createEntityPropertyValueTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: true,
        width: 150,

        renderCell: createMultiCellRenderer(cfg)
    }
}
export default createEntityPropertyValueTableColumn;