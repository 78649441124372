// @flow

//
// React
//
import React from 'react';

// //
// // Type
// //
// import {
//     type CellRendererFactoryProps,
//     type CellProps,
// } from '../../../../../lib/trix-web-components-table';

// //
// // Material UI
// //
// import { makeStyles } from '@mui/styles';

// import {
//     createQuantityQuantityCellRenderer,
// } from '../../../../../lib/trix-web-components-table';

// //
// // Component
// //
// const createPickingLineQuantityAndPickedQuantityCellRenderer = (cfg: CellRendererFactoryProps): any => (props: CellProps): any => {
//     // const value = (props.item && props.item.quantity) ? props.item.quantity : {};
//     // const secondaryValue = (props.item && props.item.pickedQuantity) ? props.item.pickedQuantity : {};

//     // return (
//     //     <QuantityQuantityCellRenderer
//     //         {...props}

//     //         value={value}
//     //         secondaryValue={secondaryValue}
//     //     />
//     // );
//     return null;
// };
// export default createPickingLineQuantityAndPickedQuantityCellRenderer;

//
// Formatter
//
import { fmtQuantity } from '../../../../../lib/trix-web-data-commons';

//
// Cell renderer
//
import {
    createItemSubItemCellRenderer,
} from '../../../../../lib/trix-web-components-table';

const createPickingLineQuantityAndPickedQuantityTableColumn = (cfg: any): any => {
    return {
        align: 'right',
        flex: false,
        width: 120,

        renderCell: createItemSubItemCellRenderer({
            ...cfg.cellRendererFactoryProps,

            align: 'right',

            getItem: (props: any) => {
                return fmtQuantity((props.row && props.row.quantity) ? 
                        props.row.quantity : undefined);
            },

            getSubItem: (props: any) => {
                const pickedQuantity = (props.row && props.row.pickedQuantity) ? 
                    props.row.pickedQuantity : {
                        amount: 0.0, 
                        unit: '-'
                    };
                return fmtQuantity(pickedQuantity);
            }
        })
    }
}
export default createPickingLineQuantityAndPickedQuantityTableColumn;