// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../lib/trix-fp-fantasy';

//
// Builders
//
import { createActionsTypes, createActions } from './actionFn';
import { createReducer } from './reducerFn';
import { createOperations } from './operationFn';
import { createSelectors } from './selectorsFn';

// buildDucks :: Array StrMap -> r
const buildDucks = (templates: any): any => {
    console.log('[buildDucks].1 INPUT:', templates);

    // build actions
    const actionsTypes = createActionsTypes (templates);
    console.log('[buildDucks].2 ACTIONS_TYPES: ', actionsTypes);

    const actions = createActions (actionsTypes) (templates);
    console.log('[buildDucks].3 ACTIONS: ', actions);

    // build reducer
    const reducer = createReducer (actionsTypes) (templates);
    console.log('[buildDucks].4 REDUCER: ', reducer);

    // build selectors
    const selectors = createSelectors (actionsTypes) (templates);
    console.log('[buildDucks].5 SELECTORS: ', selectors);

    // build operations
    const operations = createOperations (actionsTypes) (actions) (templates);
    console.log('[buildDucks].6 OPERATIONS: ', operations);

    return {
        actionsTypes,
        actions,
        reducer,
        selectors,
        operations
    };
}
export default buildDucks;

// setHalFormsIds :: Array d -> Array c
export const setHalFormsIds: (any[] => any[]) = 
    S.reduce (ps => p => {
        const plugin = (p && p.plugin) ? p.plugin.name : '';
        const templates = (p && p.templates) ? p.templates : [];
        if (templates.length === 0) {
            return ps;
        }

        console.log('[setHalFormsIds] >> plugin:[' + p.plugin.name + '], templates: ', p.templates);

        const templatesWithIds = S.reduce (ts => t => {
            const pageName = t.name;
            const id = `${plugin}.${pageName}`;

            return S.append ({
                ...t,

                // global unique id
                id,

                // section id
                sid: '',

                plugin,

                components: setHalFormsComponentsIds (plugin) (pageName) (S.Pair ('') (t.components)),
                actions: setHalFormActionsIds (plugin) (pageName) (S.Pair ('') (t.actions)),
            }) (ts);
        }) ([]) (templates);

        return S.concat (ps) (templatesWithIds);
    }) ([]);

// setHalFormsComponentsIds :: String -> String -> Pair String Array p -> Array p
const setHalFormsComponentsIds = (plugin: string): (string => any) => (page: string) => (p: any) => {
    const parent = S.fst (p);
    const components = S.snd (p);

    if (!components) {
        return [];
    }

    return S.map (c => {
        const name = (c.name) ? c.name : '_';
        const id = (parent) ? `${plugin}.${page}.${parent}.${name}` : `${plugin}.${page}.${name}`;

        const sid = (parent) ? `${parent}.${name}` : name;

        let subComponents = c.components;
        if (subComponents) {
            subComponents = setHalFormsComponentsIds (plugin) (page) (S.Pair (sid) (subComponents));
        }

        let component = {
            ...c,

            // global id
            id,

            // section id
            sid,
            
            // plugin name
            plugin,
            
            // components with applied ids
            components: subComponents,
        };

        if (c.actions) {
            const componentActions = setHalFormActionsIds (plugin) (page) (S.Pair (sid) (c.actions));
            component = {
                ...component,

                // actions with applied ids
                actions: componentActions,
            }
        }

        if (c.dataSource) {
            const componentDataSource = setHalFormDataSourceIds (plugin) (page) (c.dataSource);
            component = {
                ...component,

                // data source with applied ids
                dataSource: componentDataSource,
            }
        }

        return component;
    }) (components);
}

// setHalFormActionsIds :: String -> String -> Pair String Array p -> Array p
const setHalFormActionsIds = (plugin: string): (string => any) => (page: string) => (p: any) => {
    const parent = S.fst (p);
    const actions = S.snd (p);

    if (!actions) {
        return [];
    }

    return S.map (a => {
        const id = (parent) ? `${plugin}.${page}.${parent}.action.${a.name}` : `${plugin}.${page}.action.${a.name}`;
        const sid = (parent) ? `${parent}.action.${a.name}` : `action.${a.name}`;

        let action = {
            ...a,

            // global id
            id,

            // section id
            sid,
            
            // plugin name
            plugin,
        };

        if (a.component) {
            let actionComponents = setHalFormsComponentsIds (plugin) (page) (S.Pair (sid) ([ a.component ]));
            action = {
                ...action,

                // component with applied ids
                component: actionComponents[0]
            };
        }

        if (a.postExecutionEffects) {
            let actionPostExecutionEffects = S.map(e => {
                if (!e.dataSourceId) {
                    return e;
                }
                return {
                    ...e,
                    dataSourceId: buildHalFormsDataSourceId (plugin) (page) (e.dataSourceId)
                }
            }) (action.postExecutionEffects);
            action = {
                ...action,

                // post execution effect with applied data source id
                postExecutionEffects: actionPostExecutionEffects
            };
        }

        return action;
    }) (actions);
}

// setHalFormDataSourceIds :: String -> String -> dataSource -> dataSource
const setHalFormDataSourceIds = (plugin: string): (string => any) => (page: string) => (dataSource: any) => {
    return {
        ...dataSource,
        id: buildHalFormsDataSourceId (plugin) (page) (dataSource.id)
    }
}

// buildHalFormDataSourceId :: String -> String -> String -> String
const buildHalFormsDataSourceId = (plugin: string): (string => any) => (page: string) => (dataSourceId: string) => {
    return `${plugin}.${page}.${dataSourceId}`;
}