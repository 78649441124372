// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';

import { makeStyles } from '@mui/styles';

//
// Extenders
//
import withHalFormsAction from '../../../../../lib/trix-web-hal-forms/extender/withHalFormsAction';

//
// Trix Data
//
import {
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import {
    TextFormPaneField,
} from '../../../../../lib/trix-web-components-pane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // Hal Forms
    //
    __form: any,
    __halFormsTemplate: any,
    __halFormsActionExtender: any,

    //
    // Data
    //
    value: any,
    data: any,
    container: any,

    //
    // Error
    //
    error: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => any,
    onClose: () => any,
};

//
// Component
//
const PickPickingStockBarcodeField = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    return (
        <Grid container direction='column' className={classes.root}>
            <TextFormPaneField
                autoFocus={true}
                label={props.__halFormsTemplate.title}
                description={props.__halFormsTemplate.description}

                value={props.value}
                error={props.error}

                onChange={props.onChange}
                onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                        // find onComplete action
                        const action = props.__form.actions.find(a => a.name === 'onComplete');
                        props.__halFormsActionExtender.executeHalFormsAction(action,
                            props.data, props.container, {
                                onSuccess: (data: any) => {
                                    props.onChange('', {
                                        path: props.__halFormsTemplate.name
                                    });
                                }
                            }
                        );
                    }
                }}

                dataPath={props.__halFormsTemplate.name}
            />

        </Grid>
    );
};
export default withHalFormsAction ({}) (PickPickingStockBarcodeField);