// @flow

//
// Redux
//
import { combineReducers } from 'redux';

//
// Plugin
//
import { PLUGIN_NAME } from '../../plugin';

//
// Ducks
//
import {
    DUCK_NAME as SESSION_DUCK_NAME,
    reducer as sessionReducer,
    operations as sessionOperations,
} from './session';

//
// Reducer
//
export const reducer = combineReducers({
    [PLUGIN_NAME]: combineReducers({
        [SESSION_DUCK_NAME]: sessionReducer,
    }),
});

//
// Selectors
//
export const sessionSelector = (store: any): any => store[PLUGIN_NAME][SESSION_DUCK_NAME];

//
// Operations
//
export const operations = [
    ...sessionOperations,
];
