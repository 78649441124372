// @flow

//
// React
//
import React from 'react';

//
// Trix Data
//
import {
    type Error,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { makeStyles } from '@mui/styles';

//
// Component
//
import PlaceholderForFormPaneField from './PlaceholderForFormPaneField';

import { type FormPaneFieldMeta } from './formPaneTypes';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // State
    //
    autoFocus?: boolean,
    disabled?: boolean,
    required?: boolean,
    
    multiline?: boolean,

    label: string,
    description: string,

    hiddenLabel?: string,
    variant?: string,

    //
    // Action handlers
    //
    onChange: (value: string, meta: FormPaneFieldMeta) => void,
    onKeyDown?: (e: any) => void,

    //
    // Data
    //
    value: string,
    error: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const TextFormPaneField = (props: Props) => {
    const classes = useStyles();

    const hasError = (props.dataPath) ? hasErrorCausesForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : false;
    const helperText = (props.dataPath) ? formatErrorsForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : '';

    // determine default
    let hiddenLabel = (props.hiddenLabel != undefined) ? props.hiddenLabel : true;
    let variant = (props.variant != undefined) ? props.variant : 'filled';

    return (
        <PlaceholderForFormPaneField
        >
            <TextField
                margin='dense'
                disabled={props.disabled}
                size='small'
                fullWidth={true}

                multiline={props.multiline}

                hiddenLabel={hiddenLabel}
                variant={variant}

                label={`${props.label}${(props.required) ? ' *' : ''}`}
                description={props.description}
                
                value={props.value}
                onChange={event => {
                    props.onChange(event.target.value, {
                        path: props.dataPath
                    });
                }}
                error={props.error}
                helperText={helperText}
                autoFocus={props.autoFocus}
                onKeyDown={props.onKeyDown}
            />
        </PlaceholderForFormPaneField>
    );
};

//
// Use memo to create new component, which is sensible on just a several 
// important properties whose change is going to trigger re-render.
// This pattern is important performance optimization. As a side effect
// all other properties should be initialized on create and will not be 
// possible to be changed after component is created 
//
export default React.memo<Props>(TextFormPaneField,
    (prevProps: any, nextProps: any) => {
        return prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
		    prevProps.disabled === nextProps.disabled && 
            prevProps.autoFocus === nextProps.autoFocus && 
            prevProps.onChange === nextProps.onChange && 
            prevProps.onKeyDown === nextProps.onKeyDown;
    }
);
