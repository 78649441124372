// @flow

//
// React
//
import React, { useEffect } from 'react';

//
// Trix Middleware
//
import { get as httpGet } from '../../../../../lib/trix-web-middleware-commons';

//
// Trix Data
//
import {
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../../lib/trix-web-data-commons';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Components
//
import { PropertyValueFormPaneField } from '../../../../../lib/trix-web-components-property';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    }
}));

//
// Props
//
type Props = {
    // label: string,

    //
    // Data
    //
    data: any,

    //
    // Error
    //
    error: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => any,
};

//
// Component
//
const UpdateDialogueAgentPropertiesEditor = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles()

    useEffect(() => {
        if (props.data && 
                props.data._links && 
                props.data._links.getDomainConfig) {
            httpGet(
                // url
                `${props.data._links.getDomainConfig.href}?connector=${props.data.connector.id}`,

                // options
                null,

                // reject callback
                (error) => {
                    console.error("Error load dialogue domain config. Error: ", error)
                },

                // resolve callback
                (data) => {
                    // console.log('AAAA FETCH PROPS:', data);
                    // const propertiesConfig = (data && data.body && data.body.properties ?
                    //         data.body.properties : []).reduce((accumulator, value) => {
                    //     return {
                    //         ...accumulator,
                    //         [value.code]: value,
                    //     };
                    // }, {});
                    const propertiesConfig = (data && data.body && data.body.properties ?
                        data.body.properties : []).reduce((accumulator, value) => {
                        return [
                            ...accumulator,
                            value,
                        ];
                    }, []);
                    // console.log('AAAA PROPS CONFIG:', propertiesConfig);

                    // TODO: merge properties config with properties values from 
                    // const properties = props.data.properties.map(p => {
                    //     // find property config
                    //     return (propertiesConfig[p.code]) ? {
                    //         ...p,
                    //         ...propertiesConfig[p.code],
                    //     } : p
                    // });
                    const properties = propertiesConfig;

                    props.onChange(properties, { path: 'properties' })
                }
            )
        }
    }, [props.data._links])
    // End hooks

    if (!props.data || !props.data.properties) {
        return null;
    }

    return (
        <React.Fragment>
            {(props.data && props.data.properties
                ? props.data.properties
                : []
            ).map((p, i) => (
                <PropertyValueFormPaneField
                    key={i}
                    id={p.code}
                    valueType={p.valueType}
                    valueSource={p.valueSource}
                    code={p.code}
                    name={p.name}
                    description={p.description}
                    mandatory={p.mandatory}
                    
                    value={(props.data && props.data.values) ? props.data.values[p.code] : null}
                    // value={p.value}
                    error={props.error}
                    
                    onChange={props.onChange}

                    // dataPath={`properties[${i}].value`}
                    dataPath={`values.${p.code}`}
                />
            ))}
        </React.Fragment>
    );
};
export default UpdateDialogueAgentPropertiesEditor;
