// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

//
// React
//
import React, { type Node, useEffect, useMemo } from 'react';

//
// Components
//
import HalFormsContainer from './HalFormsContainer';
import HalFormsContainerRowPlain from './halformscontainer/HalFormsContainerRowPlain';

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Hal forms actions
    //
    __halFormsActions: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // Container
    //
    container: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => void,

    //
    // Data
    //
    data: any,
} // End of Props

//
// Component
//
const HalFormsEmbedded = (props: Props): any => {
    // Hooks
    const container = props.data;

    // End hooks

    // resolve child id
    let embeddedComponentId = '';
    if (props.__halFormsTemplate.childId) {
        embeddedComponentId = props.__halFormsTemplate.childId;
    }
    if (props.__halFormsTemplate.fetchChildIdExpression) {
        // return null in case data is not loaded yet
        if (!props.data || Object.keys(props.data).length === 0) {
            return null;
        }

        const context = {
            data: props.data,
        };
        embeddedComponentId = S.maybeToNullable (
            S.gets (S.is ($.Any)) 
            (props.__halFormsTemplate.fetchChildIdExpression.split('.')) 
            (context)
        );
    }

    // TODO: resolve 'embedded component' component by name
    let halFormsTemplate = null;
    let components = (props.__halFormsTemplate && props.__halFormsTemplate.components) ? 
            props.__halFormsTemplate.components : [];
    for (let i=0; i<components.length; i++) {
        if (components[i].name === embeddedComponentId) {
            halFormsTemplate = components[i];
            break;
        }
    }

    if (!halFormsTemplate) {
        console.error(`Embedded component with Id ${embeddedComponentId} was not found. Available embedded components:`, props.__halFormsTemplate.components);
        return null;
    }

    return (
        <HalFormsContainer
            key='halFormsEmbedded-0'

            HalFormsContainerRow={HalFormsContainerRowPlain}

            __halFormsTemplate={halFormsTemplate}
            __ducks={props.__ducks}
            __dataSourcesRef={props.__dataSourcesRef}
            
            container={container}
            data={props.data}

            onChange={props.onChange}
        />
    );
}
export default HalFormsEmbedded;
