// @flow

//
// React
//
import React from 'react';

//
// Trix Data
//
import {
    type Quantity,
} from '../../../../../lib/trix-web-data-commons';

//
// Components
//
import { StockQuantityFormPaneField } from '../../../../../lib/trix-web-components-domain-wms';

//
// Props
//
type Props = {
    //
    // State
    //
    autoFocus: boolean,
    required?: boolean,
    label: string,
    description: string,

    //
    // Action handlers
    //
    onChange: (value: Quantity, meta: any) => void,

    //
    // Data
    //
    data: any,
    value: Quantity,
    error: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const ShippingStockQuantityField = (props: Props) => {
    const stock = (props.data && 
            props.data.shipmentSourceItem && 
            props.data.shipmentSourceItem.stock) ? 
            props.data.shipmentSourceItem.stock : {};
    const data = {
        stock
    };
    
    return(
        <StockQuantityFormPaneField
            {...props}
            data={data}
        />
    );
}
export default ShippingStockQuantityField;