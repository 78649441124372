// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

//
// Trix Middleware
//
import { get as httpGet } from '../../trix-web-middleware-commons';

//
// Trix Data Commons
//
import { buildUrl } from '../../trix-web-data-commons';


export const getDataSourceType = (env: {
    dataSource: any,
}): (() => string) => () => {
    if (env.dataSource && env.dataSource.type) {
        return env.dataSource.type;
    }
    return 'unknown';
}

export const loadData = (env: {
    halFormsTemplate: any,
    dataSource: any,
    data: any,
    container: any
}): any => (params: any, reject: (error: any) => void, resolve: (data: any) => void) => {
    const dataSourceType = getDataSourceType({ dataSource: env.dataSource }) ();

    //
    // Remote data source 
    //
    if (dataSourceType === 'remote') {
        let url = '';
        const buildUrlParams = {
            data: env.data,
            ...params
        };

        // build url from direct link provided
        if (env.dataSource && env.dataSource.link && env.dataSource.link.href) {
            url = buildUrl(env.dataSource.link.href, buildUrlParams);
        }

        // resolve link from rel
        if (env.dataSource && env.dataSource.rel) {
            let o = {};
            if (env.dataSource.relTarget === 'item') {
                // rel target is 'item'
                o = env.data;
            } else {
                // rel target is 'container'
                o = env.container;
            }
            if (o._links && o._links[env.dataSource.rel]) {
                url = buildUrl(o._links[env.dataSource.rel].href, params);
            }
        }

        if (env.dataSource && env.dataSource.fetchLinkExpression) {
            const context = {
                data: env.data,
                parent: env.container
            };
            const hrefExpression = env.dataSource.fetchLinkExpression + '.href';
            let hrefTemplate = S.maybeToNullable (
                    S.gets (S.is ($.Any)) 
                    (hrefExpression.split('.')) 
                    (context)
            );
            url = buildUrl(hrefTemplate, buildUrlParams);
        }

        if (url) {
            httpGet(
                // url
                url,

                // options
                null,

                // reject callback
                reject,

                // resolve callback
                resolve
            )
        } else {
            console.error('Invalid data source url. env:', env);
        }

        return;
    }

    //
    // Inline data source
    //
    if (dataSourceType === 'inline') {    
        let items: any = [];
        if (env.dataSource.itemsPathFromContainer) {
            items = S.fromMaybe ([]) (S.gets 
                    (S.is ($.Array ($.Object))) 
                    (env.dataSource.itemsPathFromContainer.split('.')) 
                    (env.container));
        }
        if (env.dataSource.itemsPathFromData) {
            items = S.fromMaybe ([]) (S.gets 
                    (S.is ($.Array ($.Object))) 
                    (env.dataSource.itemsPathFromData.split('.')) 
                    (env.data));
        }

        if (env.dataSource.inline) {
            items = env.dataSource.inline;
        }

        resolve({
            body: {
                _embedded: {
                    items
                },
                page: {
                    size: '',
                    totalElements: items.length,
                    totalPages: 1,
                    number: 0,
                }
            }
        });
        return;
    }

    console.error('Dont know how to load data, using following data source:', env.dataSource);
}