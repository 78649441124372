// @flow

import createReceiptLineParcelStockTableColumn from './components/table/ReceiptLineParcelStockCellRenderer';
import createReceiptLineItemQuantityTableColumn from './components/table/ReceiptLineItemQuantityCellRenderer';

import receiptSourceDocumentSelectorFieldConfiguration from './components/configuration/receiptSourceDocumentSelectorFieldConfiguration';
import receiptSourceDocumentItemSelectorFieldConfiguration from './components/configuration/receiptSourceDocumentItemSelectorFieldConfiguration';

export const components = {
    'tableColumn:receiptLineParcelStock': createReceiptLineParcelStockTableColumn,
    'tableColumn:receiptLineItemQuantity': createReceiptLineItemQuantityTableColumn,

    'configuration:receiptSourceDocument': receiptSourceDocumentSelectorFieldConfiguration,
    'configuration:receiptSourceDocumentItem': receiptSourceDocumentItemSelectorFieldConfiguration,
}
