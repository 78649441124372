// @flow

import createPickingLineQuantitiesTableColumn from './components/table/PickingLineQuantitiesTableColumn';

import saleOrderSelectorFieldConfiguration from './components/configuration/saleOrderSelectorFieldConfiguration';
import transferSelectorFieldConfiguration from './components/configuration/transferSelectorFieldConfiguration';

import pickingSourceSelectorFieldConfiguration from './components/configuration/pickingSourceSelectorFieldConfiguration';
import pickingSourceItemSelectorFieldConfiguration from './components/configuration/pickingSourceItemSelectorFieldConfiguration';

export const components = {
    'tableColumn:pickingLineQuantities': createPickingLineQuantitiesTableColumn,

    'configuration:trix-web-plugin-wms-picking.managePickingPage.container.pickingLinesTable.action.onAddItemFromSource.editor._.pickingSource': pickingSourceSelectorFieldConfiguration,
    'configuration:trix-web-plugin-wms-picking.managePickingPage.container.pickingLinesTable.action.onAddItemFromSource.editor._.pickingSourceItem': pickingSourceItemSelectorFieldConfiguration,

    'configuration:trix-web-plugin-wms-picking.managePickingPage.container.pickingSourcesTable.action.onAttachSaleOrder.editor._.saleOrder': saleOrderSelectorFieldConfiguration,
    'configuration:trix-web-plugin-wms-picking.managePickingPage.container.pickingSourcesTable.action.onAttachTransfer.editor._.transfer': transferSelectorFieldConfiguration,
}
