// @flow

//
// Lodash
//
import transform from 'lodash/transform';

//
// React
//
import React from 'react';

//
// Redux
//
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

//
// Reducer Context
//
import ReducerContext from './ReducerContext';

//
// Connectors
//
import connectAction from '../connectors/connectAction';
import connectSelector from '../connectors/connectSelector';

//
// Types
//
type Selector = (store: any, props: any) => any;

type Action = () => void;

type ConnectReducerConfiguration = {
    reducerKey?: any, // string | (props) => any;

    selectors: { [key: string]: Selector },
    actions: { [key: string]: any },
};

//
// Helper methods
//
const generateReducerKey = (cfg: ConnectReducerConfiguration, props: any) => {
    // resolve sub - reducer key
    if (cfg.reducerKey) {
        let subReducerKey = '';
        if (cfg.reducerKey.constructor === Function) {
            subReducerKey = cfg.reducerKey(props);
        } else {
            subReducerKey = cfg.reducerKey;
        }
        return `${props.reducerKey}.${subReducerKey}`;
    }
    return props.reducerKey;
};

//
// Enhancer
//
const findReducer = (WrappedComponent: any) => {
    const FindReducer = (props: any) => {
        return (
            <ReducerContext.Consumer>
                {context => (
                    <WrappedComponent
                        {...props}
                        reducerKey={context.reducerKey}
                    />
                )}
            </ReducerContext.Consumer>
        );
    };
    return FindReducer;
};

const connectReducer = (cfg: ConnectReducerConfiguration): ((WrappedComponent: any) => any) => (
    WrappedComponent: any
) =>
    findReducer(
        connect(
            // map state to props
            (store: any, props: any) => {
                const reducerKey = generateReducerKey(cfg, props);

                // evaluate custom selectors in the context of current reducer's state
                const _connectSelector = connectSelector(reducerKey, props);
                const selectedValues = transform(
                    cfg.selectors,
                    (result, selector, key) => {
                        result[key] = _connectSelector(selector)(store);
                        return result;
                    },
                    {}
                );

                return {
                    ...selectedValues,
                };
            },

            // bind action creators
            (dispatch: any/*Dispatch*/, props: any) => {
                const reducerKey = generateReducerKey(cfg, props);

                // bind custom actions
                const _connectAction = connectAction(reducerKey);
                const actions = transform(
                    cfg.actions,
                    (result, value, key) => {
                        result[key] = _connectAction(value);
                        return result;
                    },
                    {}
                );

                return bindActionCreators(
                    {
                        ...actions,
                    },
                    dispatch
                );
            }
        )(WrappedComponent)
    );
export default connectReducer;
