// @flow

//
// React
//
import React from 'react';

//
// Trix Data
//
import {
    type Error,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import { makeStyles } from '@mui/styles';

//
// Component
//
import PlaceholderForFormPaneField from './PlaceholderForFormPaneField';

import { type FormPaneFieldMeta } from './formPaneTypes';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

const useFormControlLabelStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
    },

    label: {
        fontSize: '0.875rem',
    },
}));

//
// Props
//
type Option = {
    value: string;
    label: string;
}

type Props = {
    //
    // Styles
    //
    className?: any,

    //
    // State
    //
    autoFocus: boolean,
    label: string,
    description: string,
    required?: boolean,

    //
    // Action handlers
    //
    onChange: (value: string, meta: FormPaneFieldMeta) => void,

    //
    // Data
    //
    options: Option[];
    value: string,
    error: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const RadioGroupFormPaneField = (props: Props) => {
    const classes = useStyles();
    const formControlLabelStyles = useFormControlLabelStyles();

    const hasError = (props.dataPath) ? hasErrorCausesForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : false;
    const helperText = (props.dataPath) ? formatErrorsForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : '';

    console.log('[RadioGroupFormPaneField] >>> props:', props);

    return (
        <PlaceholderForFormPaneField
            className={props.className}
            // error={props.error}
        >
            <Grid container direction='row'>
                <Grid 
                    item
                    sx={{
                        width: '200px'
                    }}
                >
                    <Typography
                        variant='body1'
                        color='textPrimary'
                        // className={classes.label}
                        sx={{
                            fontSize: '0.875rem'
                        }}
                    >
                        {props.label}
                    </Typography>
                    <Typography
                        variant='body2'
                        color='textSecondary'
                        // className={classes.description}
                        sx={{
                            fontSize: '0.875rem'
                        }}
                    >
                        {props.description}
                    </Typography>
                </Grid>
                <Grid item>
                    <RadioGroup
                        // label={`${props.label}${(props.required) ? ' *' : ''}`}
                        // description={props.description}

                        value={props.value}
                        onChange={event => {
                            props.onChange(event.target.value, {
                                path: props.dataPath
                            })
                        }}
                    >
                        {
                            props.options.map(o => {
                                return (
                                    <FormControlLabel
                                        key={o.value}
                                        value={o.value}
                                        control={<Radio size='small'/>}
                                        label={o.label}
                                        classes={formControlLabelStyles}
                                    />
                                );
                            })
                        }
                        {
                            (hasError) ? (
                                <FormHelperText error={true}>{helperText}</FormHelperText>
                            ) : null
                        }
                    </RadioGroup>
                </Grid>
            </Grid>
        </PlaceholderForFormPaneField>
    );
};

//
// Use memo to create new component, which is sensible on just a several 
// important properties whose change is going to trigger re-render.
// This pattern is important performance optimization. As a side effect
// all other properties should be initialized on create and will not be 
// possible to be changed after component is created 
//
export default React.memo<Props>(RadioGroupFormPaneField,
    (prevProps: any, nextProps: any) => {
        return prevProps.value === nextProps.value &&
            prevProps.options === nextProps.options &&
            prevProps.error === nextProps.error &&
		    prevProps.disabled === nextProps.disabled &&
            prevProps.autoFocus === nextProps.autoFocus && 
            prevProps.onChange === nextProps.onChange;
    }
);
