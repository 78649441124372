// @flow

//
// Lodash
//
import set from 'lodash/set';

//
// React
//
import React, {
    useCallback,
} from 'react';

//
// Trix Data
//
import {
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

//
// Component
//
import { 
    TextFormPaneField,
    ComboBoxFormPaneField,
} from '../../../trix-web-components-pane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },

    header: {
        margin: '0.5rem',
    },
}));

//
// Props
//
type Props = {
    //
    // Main
    //
    name: string,
    label?: string,

    //
    // Action handlers
    //
    onChange: (value: any, meta: any) => void,

    //
    // State
    //
    error: any,

    //
    // Data
    //
    value: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const ShippingRecipientFormPaneField = (props: Props): any => {
    //
    // Hooks
    //
    const clases = useStyles();
    // End Hooks

    //
    // Change Handlers
    //
    const changeHandler = useCallback((value: any, meta: any) => {
        let newData = (props.data) ? props.data : {};
        newData = set(newData, meta.path, value);

        props.onChange(newData, {
            path: props.dataPath
        });
    }, [props.data]);

    //
    // Components
    //
    return (
        <Grid>
            {
                (props.label) ? (
                    <Typography 
                        className={clases.header}
                        variant='body2'
                        color='textSecondary'
                        align='left'
                    >
                        Получател
                    </Typography>
                ) : null
            }

            <TextFormPaneField
                label='Име'
                description=''

                value={(props.value) ? props.value.name : ''}
                error={props.error}

                onChange={changeHandler}

                dataPath='name'
            />

            <TextFormPaneField
                label='Телефон'
                description=''

                value={(props.value) ? props.value.phone : ''}
                error={props.error}

                onChange={changeHandler}

                dataPath='phone'
            />

            <TextFormPaneField
                label='Емайл'
                description=''

                value={(props.value) ? props.value.email : ''}
                error={props.error}

                onChange={changeHandler}

                dataPath='email'
            />            
        </Grid>
    );
};
export default ShippingRecipientFormPaneField;
