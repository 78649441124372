// @flow

//
// React
//
import React from 'react';

//
// React router
//
import { useNavigate } from 'react-router-dom';

//
// Redux
//
import { useDispatch, useSelector } from 'react-redux';

//
// Ducks
//
import { sessionSelector } from '../../../../../../../plugins/trix-web-plugin-security-auth/state/ducks';
import {
    Actions,
    Selectors,
} from '../../../../../../../plugins/trix-web-plugin-security-auth/state/ducks/session';

//
// Material UI
//
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

//
// Props
//
type Props = {};

//
// Component
//
const UserHeaderItem = (props: Props): any => {
    //
    // Hooks
    //
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useDispatch();

    const user = useSelector(state => Selectors.user(sessionSelector(state)));

    const navigate = useNavigate();

    const isMenuOpen = Boolean(menuAnchorEl);

    const openMenu = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Typography variant='subtitle1'>{user.name}</Typography>
            <IconButton edge='end' onClick={openMenu} color='inherit' size="large">
                <AccountCircleIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                keepMounted
                open={isMenuOpen}
                onClose={closeMenu}
            >
                <MenuItem onClick={() => {
                    window.location.href = '/';
                }}>
                    Смяна фирма
                </MenuItem>

                <Divider />

                <MenuItem onClick={() => {
                    dispatch(Actions.logOutUser(
                        () => {
                            window.location.href = '/';
                        }
                    ))
                }}>
                    Изход
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};
export default UserHeaderItem;
