// @flow

import createDialogueDomainCellRenderer from './components/renderer/DialogueDomainCellRenderer';
import createDialogueConnectorCellRenderer from './components/renderer/DialogueConnectorCellRenderer';
import DialogueDomainFloatPaneField from './components/float/DialogueDomainFloatPaneField';
import DialogueConnectorFloatPaneField from './components/float/DialogueConnectorFloatPaneField';

import CreateDialogueAgentPropertiesEditor from './components/form/CreateDialogueAgentPropertiesEditor';
import UpdateDialogueAgentPropertiesEditor from './components/form/UpdateDialogueAgentPropertiesEditor';

import PropertyCodeNameDescriptionCellRenderer from './components/table/PropertyCodeNameDescriptionCellRenderer';

export const components = {
    'tableColumn:dialogueDomain': createDialogueDomainCellRenderer,
    'tableColumn:dialogueConnector': createDialogueConnectorCellRenderer,
    'floatPaneField:dialogueDomain': DialogueDomainFloatPaneField,
    'floatPaneField:dialogueConnector': DialogueConnectorFloatPaneField,

    'formPaneField:createDialogueAgentPropertiesEditor': CreateDialogueAgentPropertiesEditor,
    'formPaneField:updateDialogueAgentPropertiesEditor': UpdateDialogueAgentPropertiesEditor,

    'tableCellRenderer:PROPERTY_CODE_NAME_DESCRIPTION': PropertyCodeNameDescriptionCellRenderer,
}
