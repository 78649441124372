// @flow

//
// Name
//
import { PLUGIN_NAME } from './plugin';

//
// API
//
import {
    loadMe,
} from './state/api';

//
// Pages
//
import LoginPage from './web/components/pages/LoginPage';
import TenantSelectorPage from './web/components/pages/TenantSelectorPage';

//
// Store
//
import {
    reducer,
    operations,

    sessionSelector,
} from './state/ducks';

import {
    Actions as SessionActions,
    Selectors as SessionSelectors,
} from './state/ducks/session';

//
// Enhancers
//
import initializer from './enhancers/initializer';

//
// Plugin Definition
//
const plugin = {
    name: PLUGIN_NAME,

    //
    // State
    //
    state: {
        operations,

        reducer,

        actions: {
            session: {
            },
        },

        selectors: {
            session: {
            }
        },
    },

    //
    // Boot functions
    //
    boot: {
        load: (tenantId: number, reject: (error: any) => void, resolve: (data: any) => void) => {
            reject({
                title: 'Index',
                error: 'N/A'
            });

            // get(
            //     // url
            //     '/api/v1/core/company/index', 

            //     // options
            //     {
            //         headers: {
            //             // TODO: put real Tenant here
            //             'X-Tenant': 0
            //         }
            //     },

            //     // reject callback
            //     reject, 

            //     // resolve callback
            //     (data) => resolve(data.body)
            // );
        },
    },

    //
    // Web
    //
    web: {
        // public components
        components: {
        },

        // public enhancers
        enhancers: {
            initializer,
        }
    },
}
export default plugin;

export {
    loadMe,

    LoginPage,
    TenantSelectorPage
};