// @flow

//
// React
//
import React from 'react';

//
// Type
//
import { type CellRendererProps } from '../../../../../lib/trix-web-components-table';

//
// Material UI
//
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        // take width of the content
        width: '1px',
        whiteSpace: 'nowrap',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    listItemSecondary: {
        fontSize: '0.8rem',
    },
}));

//
// Props
//
type Props = CellRendererProps & {
    //
    // Callbacks
    //
    onClick?: () => void,

    //
    // Data
    //
    value: any,
};

//
// Component
//
const PropertyCodeNameDescriptionCellRenderer = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End Hooks

    const item = (props.item) ? props.item : {};

    return (
        <TableCell 
            padding="normal" 
            className={classes.root}
        >
            <div>{item.code} / {item.name}</div>
            <div>
                <Typography
                    variant='body1'
                    color='textSecondary'
                    className={classes.listItemSecondary}
                >
                    {(item.description) ? item.description.bg : ''}
                </Typography>
            </div>
        </TableCell>
    );
};
export default PropertyCodeNameDescriptionCellRenderer;
