/**
 * Observable factory
 */
const createObservable = () => {
    let id = 0;
    let observers = [];

    const subscribe = (observerFn) => {
        // generate observer is
        const observerId = id++;

        // add observer into the list of observers
        observers = [ ...observers, {
            id: observerId,
            observerFn
        }];

        // return unsubscribe function
        return () => unsubscribe(observerId);
    }

    const unsubscribe = (id) => {
        observers = observers.filter(o => o.id !== id);
    }

    const emit = (a) => {
        observers.forEach(o => {
            o.observerFn(a);
        });
        // observers.forEach(o => o.observerFn(a));
    }

    return {
        subscribe,
        emit,
    }
}
export default createObservable;

//
// Take functions
//

/**
 * Setup observer and listens for actions, satisfying {@param predicate}.
 * Feed matched actions to the {@link observerFn} and unsubscirbe after first 
 * match.
 *
 * @param observable
 * @param predicate
 * @param observerFn
 * @return unsubscribe callback 
 */
export const take = (observable) => (predicate) => (observerFn) => {
    let unsubscribe;

    const cb = (a) => {
        if (predicate(a)) {
            observerFn(a);
            unsubscribe();
        }
    }
    unsubscribe = observable.subscribe(cb);

    return unsubscribe;
}

/**
 * Setup observer and listens for actions, satisfying {@param predicate}.
 * Feed matched actions to the {@link observerFn}.
 *
 * @param observable
 * @param predicate
 * @param observerFn
 * @return unsubscribe callback 
 */
export const takeAll = (observable) => (predicate) => (observerFn) => {
    let unsubscribe;

    const cb = (a) => {
        if (predicate(a)) {
            observerFn(a);
        }
    }
    unsubscribe = observable.subscribe(cb);

    return unsubscribe;
}