// @flow

//
// React
//
import React, { type Node, useCallback } from 'react';

//
// React Router
//
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// HAL forms
//
import getHalFormsRoutes from '../../../../../../lib/trix-web-hal-forms/component/HalFormsRoutes';

//
// Tenant
//
import {
    useLocationPreffix,
} from '../../../../../../lib/trix-web-components-tenant';

//
// Components
//
import Home from './content/Home';
import Settings from './content/Settings';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    
}));

//
// Props
//
type Props = {
    //
    // HAL forms
    //
    __halFormsTemplates: any,
    __ducks: any,

    //
    // Routing
    //
    history: {
        push: (url: string) => void,
    },

    //
    // Menus
    //
    menus: any,

    //
    // Handlers
    //
    onClosePage: (instanceId?: string) => void,
};

//
// Component
//
const Content = (props: Props): any => {
    //
    // Hooks
    //
    const locationPreffix = useLocationPreffix();
    const classes = useStyles();

    const closePage = useCallback(() => props.onClosePage(), [props.onClosePage]);
    // End hooks

    return (
        <React.Fragment>
            {/* Shell routes. Maybe let the kernel join them with other roots */}
            <Routes>
                <Route path={locationPreffix}>
                    <Route 
                        index
                        element={
                            <Home
                                menus={props.menus['home']}
                            />
                        }
                    />
                    <Route 
                        path='home'
                        element={
                            <Home
                                menus={props.menus['home']}
                            />
                        }
                    />
                    <Route 
                        path='settings'
                        element={
                            <Settings 
                                menus={props.menus['settings']}
                            />
                        }
                    />
                    {
                        getHalFormsRoutes({
                            __halFormsTemplates: props.__halFormsTemplates,
                            __ducks: props.__ducks,
                            history: props.history,
                            onClosePage: closePage,
                        })
                    }
                </Route>
            </Routes>
        </React.Fragment>
    );
};
export default Content;
