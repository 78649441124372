// @flow

//
// React
//
import React, { useMemo, useCallback } from 'react';

//
// Moment
//
import moment from 'moment';

//
// Trix Data
//
import {
    formatErrorsForLocation,
} from '../../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { makeStyles } from '@mui/styles';

//
// Components
//
import PlaceholderForFormPaneField from './PlaceholderForFormPaneField';

import { type FormPaneFieldMeta } from './formPaneTypes';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {},

    // textFieldCell: {
        // width: '80px',
    // }, 

    // selectRoot: {
        // marginRight: theme.spacing(4),
        // marginLeft: theme.spacing(1),
    // },
}));

//
// Props
//
type Props = {
    //
    // State
    //
    autoFocus: boolean,
    required?: boolean,
    label: string,
    description: string,
    
    //
    // Action handlers
    //
    onChange: (value: any, meta: FormPaneFieldMeta) => void,

    //
    // Data
    //
    value: any,
    error: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const DateRangeFormPaneField = (props: Props) => {
    //
    // Hooks
    //
    // const classes = useStyles();

    const fromDateLabel = useMemo(() => {
        return `От ${props.label}${(props.required) ? ' *' : ''}`;
    }, [props.label, props.required]);

    const toDateLabel = useMemo(() => {
        return `До ${props.label}${(props.required) ? ' *' : ''}`;
    }, [props.label, props.required]);

    // const hasError = (props.dataPath) ? hasErrorCausesForLocation(
    //     props.error,
    //     'PARAMETER',
    //     props.dataPath
    // ) : false;
    const helperText = (props.dataPath) ? formatErrorsForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : '';


    const fromDateMoment = useMemo(() => {
        return (props.value) ? moment(props.value.fromDate) : null;
    }, [props.value]);

    const onChangeFromDate = useCallback((value: any, context: any) => {
        props.onChange({
            ...props.value,
            fromDate: (value) ? value.valueOf() : -1
        }, {
            path: props.dataPath
        });
    }, [props.value]);

    const toDateMoment = useMemo(() => {
        return (props.value) ? moment(props.value.toDate) : null;
    }, [props.value]);

    const onChangeToDate = useCallback((value: any, context: any) => {
        props.onChange({
            ...props.value,
            toDate: (value) ? value.valueOf() : -1
        }, {
            path: props.dataPath
        });
    }, [props.value]);

    return (
        <PlaceholderForFormPaneField>
            <Grid container wrap='nowrap' direction='row' spacing={1}>
                <Grid item>
                    <DatePicker
                        margin='dense'
                        disabled={props.disabled}
                        size='small'
                        fullWidth={true}
                        hiddenLabel={true}
                        variant='filled'

                        label={fromDateLabel}
                        
                        value={fromDateMoment}
                        onChange={onChangeFromDate}

                        // error={props.error}
                        helperText={helperText}
                        autoFocus={props.autoFocus}
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        margin='dense'
                        disabled={props.disabled}
                        size='small'
                        fullWidth={true}
                        hiddenLabel={true}
                        variant='filled'

                        label={toDateLabel}
                        
                        value={toDateMoment}
                        onChange={onChangeToDate}

                        // error={props.error}
                        helperText={helperText}
                        autoFocus={false}
                    />
                </Grid>
            </Grid>
        </PlaceholderForFormPaneField>
    );
};

//
// Use memo to create new component, which is sensible on just a several 
// important properties whose change is going to trigger re-render.
// This pattern is important performance optimization. As a side effect
// all other properties should be initialized on create and will not be 
// possible to be changed after component is created 
//
export default React.memo<Props>(DateRangeFormPaneField,
    (prevProps: any, nextProps: any) => {
        return prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
		    prevProps.disabled === nextProps.disabled &&
            prevProps.autoFocus === nextProps.autoFocus && 
            prevProps.onChange === nextProps.onChange;
    }
);