//
// Constants
//
export const CSRF_COOKIE_NAME = "CSRF-TOKEN";
export const CSRF_HEADER_NAME = "X-CSRF-TOKEN";

/**
 * Retrieve CSRF token value
 */
export function csrfToken() {
    let csrfToken = '';
    for (let ca = document.cookie.split(/;\s*/), i = ca.length - 1; i >= 0; i -= 1) {
        if (!ca[i].indexOf(CSRF_COOKIE_NAME)) {
            csrfToken = ca[i].replace(`${CSRF_COOKIE_NAME}=`, '');
            break;
        }
    }
    return csrfToken;
}
