// @flow

//
// React
//
import React from 'react';

//
// Tenant
//
import {
    useNavigateTo,
} from '../../../../../lib/trix-web-components-tenant';

//
// Formatters
//
import {
    fmtDate,
    fmtMoney,
} from '../../../../../lib/trix-web-data-commons';

//
// Material UI
//
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '0.875rem',
    },

    context: {
        fontSize: '0.725rem',
        color: theme.palette.text.secondary,
        padding: theme.spacing(0.5, 1, 0.5),
    },

    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

//
// Props
//
type Props = {
    //
    // Data
    //
    item: any,
}

//
// Component
//
const SaleOrderExecutionStepTimelineItem = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    const navigateTo = useNavigateTo();
    // End hooks

    return(
        <div className={classes.root}>
            {props.item.type}

            {(props.item.type === 'SHIPPED') ? (
                <div className={classes.context}>
                    <div>{props.item.shippingCompany}</div>
                    <div>{props.item.shipmentNo} / {fmtDate(props.item.shipmentDate)}</div>
                    <div>{fmtMoney(props.item.shipmentAmount)}</div>
                </div>
            ) : null}
        </div>
    );
};
export default SaleOrderExecutionStepTimelineItem;
