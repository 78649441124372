//
// React
//
import React from 'react';

type Event = {
    name: string,
    payload: any,
}

type Listener = {
    // listener identification
    identification: string,

    // handled events
    events: { },

    // event handler
    onEvent: (event: Event) => void,
}

export const createEventDispatcherContext = () => {
    let listeners = [];

    return {
        setClientEventListener: (listener: any) => {
            // remove listener (if it exists)
            listeners = listeners.filter(l => l.identification !== listener.identification);
            // add again new listener
            listeners.push(listener);
        },

        sendClientEvent: (destination: string, event: Event) => {
            for (let listener of listeners) {
                if (destination && destination !== listener.identification) {
                    continue;
                }
                if (listener.events[event.name]) {
                    listener.onEvent(event);
                }
            }
        }
    }
}

// Create a hal forms context for the components bound to that components tree
const HalFormsContext = React.createContext(undefined);

export default HalFormsContext;
