// @flow

//
// Types
//
import { type ActionState } from '../../../trix-web-data-commons';

//
// State
//
export type State = ActionState;

//
// Configuration
//
type Configuration = {
    operationActionsTypes: {
        OPERATION_REQUESTED: string,
        OPERATION_EXECUTED_SUCCESS: string,
        OPERATION_EXECUTED_FAILURE: string,
    },
};

//
// Reducer factory
//
export const createOperationReducer = (cfg: Configuration): any => (
    state: State = {
        error: null,
        inProgress: false,
        executions: [],
    },
    action: any
): State => {
    if (!cfg.operationActionsTypes) {
        return state;
    }

    switch (action.type) {
        case cfg.operationActionsTypes.OPERATION_REQUESTED:
            return reduceOperationRequested(state, action);
        case cfg.operationActionsTypes.OPERATION_EXECUTED_SUCCESS:
            return reduceOperationExecuted_Success(state, action);
        case cfg.operationActionsTypes.OPERATION_EXECUTED_FAILURE:
            return reduceOperationExecuted_Failure(state, action);
    }

    return state;
};

//
// Composite reducers
//
const reduceOperationRequested = (state: State, action: any) => {
    return {
        inProgress: true,
        error: state.executions.length > 0 ? state.error : null,
        executions: [
            ...state.executions,
            {
                id: action.meta.executionId,
                data: action.payload,
            },
        ],
    };
};

const reduceOperationExecuted_Success = (state: State, action: any) => {
    const executions = state.executions.filter(
        e => e.id !== action.meta.executionId
    );

    return {
        inProgress: executions.length > 0,
        error: null,
        executions,
    };
};

const reduceOperationExecuted_Failure = (state: State, action: any) => {
    const executions = state.executions.filter(
        e => e.id !== action.meta.executionId
    );

    return {
        inProgress: executions.length > 0,
        // TODO: merge errors
        error: action.payload,
        executions,
    };
};
