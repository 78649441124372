// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

//
// React
//
import React, { type Node, useEffect } from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
    useDispatchLocal,
} from '../../redux-fantasy-reducers';

//
// Hal forms
//
import withHalFormsAction from '../extender/withHalFormsAction';
import {
    getHalFormsComponent,
} from '../tools/halFormsTemplate';
import {
	getSelector,
} from '../tools/halFormsRedux';

//
// Extenders
//
import withHalFormsDataSource from '../extender/withHalFormsDataSource';

//
// Formatters
//
import {
    fmtDate,
    fmtTime,
} from '../../trix-web-data-commons';

//
// Material UI
//
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';

import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    withComponentsRegistry,
} from '../../trix-web-components-registry';

//
// Components
//
const TimelineEvent = (props: {
    halFormsTemplate: any,
    getComponent: (id: string) => void,
    item: any,
}) => {
    const classes = useStyles();

    const timelineItemId = `timelineItem:${props.halFormsTemplate.id}`;
    const Component = props.getComponent(timelineItemId);

    return (
        <TimelineItem classes={{
            missingOppositeContent: classes.missingOppositeContent
        }}>
            <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent>
                <div>
                    <Typography color='secondary' className={classes.eventDateTime}>
                        {fmtDate(props.item.dateTime)} {fmtTime(props.item.dateTime)}
                    </Typography>

                    <Typography variant='body2' className={classes.eventTitle}>
                        {props.item.title}
                    </Typography>
                    {(Component) ? 
                        (
                            <Component
                                item={props.item}
                            />
                        ) : null
                    }

                    {/*  */}
                </div>
            </TimelineContent>
        </TimelineItem>
    );
}

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {},

    title: {
        fontWeight: 200,
        lineHeight: 2,
    },

    eventDateTime: {
        fontWeight: 200,
        lineHeight: 2,
    },
    eventTitle: {
        fontWeight: 200,
        lineHeight: 2,
    },
    eventContent: {
        // fontWeight: 200,
        // lineHeight: 1.5,
        fontSize: '0.875rem',
    },

    missingOppositeContent: {
        '&::before': {
            flex: 0,
        }
    }
}));

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Action Executor Extender props
    //
    __halFormsActionExtender: {
        executeHalFormsAction: (halFormsAction: any, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
        executeHalFormsActionByName: (halFormsActionName: string, data: any, container: any, meta?: {
            onSuccess?: (data: any) => void,
        }) => void,
    },

    //
    // Data Source Extender props
    //
    __dataSourceExtender: {
        dataSource: any,
        getDataSourceType: () => string,
        loadData: (params: any) => void,
        isDataLoadNeeded: (params: any) => boolean,
        isLoadingData: boolean,
        data: any,
        params: any,
    },

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,

    //
    // Components registry
    //
    __componentsRegistry: {
        getComponent: (type: string) => any,
    },

    //
    // Context
    //
    __context: any,

    //
    // Callbacks
    //
    onClose: () => void,
} // End of Props

//
// Component
//
const HalFormsTimeline = (props: Props) => {
    //
    // Hooks
    //
    const dispatchLocal = useDispatchLocal();

    const dataSource = (props.__dataSourceExtender) ? 
        props.__dataSourceExtender.dataSource : null;
    useEffect(() => {
        // skip initial data load in case of no data source specified for the container
        if (!dataSource) {
            return;
        }

        const params = props.data;

        if (props.__dataSourceExtender && 
                !props.__dataSourceExtender.isDataLoadNeeded(params)) {
            return;
        }
        props.__dataSourceExtender.loadData(params);
    }, [dataSource]);

    const pageInstanceState = useLocalSelector (s => s);

    const classes = useStyles();
    // End hooks

    const items = (props.__dataSourceExtender.data) ? props.__dataSourceExtender.data.items : [];

    // determine if any operation is in progress
    let isOperationInProgress = false;
    let actions = (props.__halFormsTemplate.actions || []);
    let actionsExecutions = {};
    for (let i=0; i<actions.length; i++) {
        const isInProgressSelector = getSelector 
            (props.__ducks)
            (actions[i].id)
            ('isInProgress');
        isOperationInProgress = isOperationInProgress || 
                isInProgressSelector (pageInstanceState);

        const executionsSelector = getSelector 
            (props.__ducks)
            (actions[i].id)
            ('getExecutions');
        const executions = executionsSelector(pageInstanceState);
        if (executions) {
            actionsExecutions = {
                ...actionsExecutions,
                [actions[i].name]: executions
            }
        }
    }

    const context = {
        // ...props.__context,
        // dataSources: {
        //     [dataSourceId]: {
        //         reload: () => props.__dataSourceExtender.loadData({})
        //     }
        // }
    };
    // back reference data source
    const dataSourceId: string = (props.__halFormsTemplate && props.__halFormsTemplate.dataSource && 
            props.__halFormsTemplate.dataSource.id) ? props.__halFormsTemplate.dataSource.id : '-';
    if (props.__dataSourcesRef && dataSource && dataSource.id) {
        props.__dataSourcesRef.current = {
            ...props.__dataSourcesRef.current,
            [dataSource.id]: {
                reload: () => props.__dataSourceExtender.loadData({})
            }
        };
    }

    return (
        <Grid container direction='column' className={classes.root}>
            <div className={classes.progress}>
                {isOperationInProgress ? (
                    <LinearProgress variant='indeterminate' />
                ) : null}
            </div>

            <Grid
                item
                container
                direction='column'
                className={classes.content}
            >
                {props.__dataSourceExtender.isLoadingData ? (
                    <Grid item style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Timeline align='left'>
                        {(items || []).map((item, i) => 
                            <TimelineEvent
                                key={i}
                                halFormsTemplate={props.__halFormsTemplate}
                                getComponent={props.__componentsRegistry.getComponent}
                                item={item}
                            />
                        )}
                    </Timeline>
                )}
            </Grid>
            {/* {menuButtons} */}
        </Grid>
    );
};
export default withComponentsRegistry (
    withHalFormsAction ({
        getHalFormsActions: (halFormsTemplate: any) => {
            let columnsHalFormsActions = [];
            const columnsSetMaybe = getHalFormsComponent (halFormsTemplate) ('columns');
            if (S.isJust (columnsSetMaybe)) {
                const columnsSet = S.fromMaybe ({}) (columnsSetMaybe);
                const columns = (columnsSet.components) ? columnsSet.components : [];
                columnsHalFormsActions = S.reduce (r => c => {
                    if (c.actions) {
                        return [ ...r, ...c.actions ];
                    }
                    return r;
                }) ([]) (columns);
            }
            return columnsHalFormsActions;
        }
    }) (
        withHalFormsDataSource (HalFormsTimeline)
    )
);