// @flow

//
// React
//
import React from 'react';

//
// Type
//
import { type CellRendererProps } from '../../../../../lib/trix-web-components-table';

//
// Material UI
//
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
        // take width of the content
        width: '1px',
        whiteSpace: 'nowrap',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

//
// Props
//
type Props = CellRendererProps & {
    //
    // Callbacks
    //
    onClick?: () => void,

    //
    // Data
    //
    value: any,
};

//
// Component
//
const PropertyValueSourceCellRenderer = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();

    let value = '';
    if (props.value === 'STATIC') {
        value = 'S';
    }
    if (props.value === 'DYNAMIC') {
        value = 'D';
    }
    if (props.value === 'AUTO') {
        value = 'A';
    }

    return (
        <TableCell 
            padding="normal" 
            className={classes.root}
        >
            <Chip 
                label={value} 
            />
        </TableCell>
    );
};
export default PropertyValueSourceCellRenderer;
