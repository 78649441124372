// @flow

//
// React
//
import React, { type Node, useState } from 'react';

//
// Compnents
//
import AuditLogEntryPropertiesPane from '../pane/AuditLogEntryPropertiesPane';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },

    extension: {
        margin: theme.spacing(1),
    },

    buttonContainer: {
        marginLeft: 'auto',
    }
}));

//
// Props
//
type Props = {
    //
    // State
    //
    rowState: any,

    //
    // Data
    //
    item: any;
} // End of Props

//
// Component
//
const AuditLogTableRowExtension = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    return (
        <Collapse 
            in={!!props.rowState.extended} 
            timeout="auto" 
            unmountOnExit
        >
            <Box margin={1}>
                <AuditLogEntryPropertiesPane
                    item={props.item}
                />
            </Box>
        </Collapse>
    );
}
export default AuditLogTableRowExtension;