// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React from 'react';

import {
    createItemSubItemCellRenderer,
} from '../../../../../lib/trix-web-components-table';

//
// Component
//
const createHumanTaskNameTableColumn = (cfg: any): any => {
    return {
        align: 'left',
        flex: true,
        width: 200,

        renderCell: (props: any) => {
            const cellRendererFactoryProps = {
                onClick: (props.row.status === 'IN_PROGRESS') ?
                    cfg.cellRendererFactoryProps.onClick : null
            }

            return createItemSubItemCellRenderer({
                ...cellRendererFactoryProps,

                getItem: (props: any) => {
                    return get(props, 'row.subject');
                },

                getSubItem: (props: any) => {
                    return get(props, 'row.description');
                }
            }) (props);
        }
    }
};
export default createHumanTaskNameTableColumn;

