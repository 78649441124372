// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

//
// Data
//
import {
    buildUrl,
} from '../../trix-web-data-commons';

// getHalFormsComponent :: HalFormsTemplate -> Name -> Maybe Component
export const getHalFormsComponent = (halFormsTemplate: any): any => (name: string) => 
    S.pipe ([
        // StrMap t -> Maybe Array p
        S.get (S.is ($.Array ($.Object))) ('components'),

        // Maybe Array p -> Maybe Array p
        S.map (S.filter (p => p.name === name)),

        // Maybe Array p -> Maybe p
        S.map (a => a[0]),
    ]) (halFormsTemplate);

// getHalFormsAction :: HalFormsTemplate -> String -> Maybe HalFormsAction
export const getHalFormsAction = (halFormsTemplate: any): any => (name: string) =>
    S.pipe ([
        // StrMap t -> Maybe Array p
        S.get (S.is ($.Array ($.Object))) ('actions'),

        // Maybe Array p -> Maybe Array p
        S.map (S.filter (p => p.name === name)),

        // Maybe Array p -> Maybe p
        S.map (a => a[0]),
    ]) (halFormsTemplate);


export const rewriteHalFormsTemplates = (plugins: any[]): any[] => {
    // rewrite components tree, merging shared components in all 
    // 'embedded container's components 
    let embeddedComponents: any = [];
    plugins.forEach(p => {
        if (!p.templates) {
            return;
        }
        p.templates.forEach(t => {
            if (t.type === 'embeddedComponent') {
                embeddedComponents = [ ...embeddedComponents, t ];
            }
        });
    });

    const pluginsP = plugins.map(p => {
        if (!p.templates) {
            return p;
        }

        const templates = p.templates.map(t => {
            if (!t.components) {
                return t;
            }
            t.components = mergeEmbeddedComponentsInTree (embeddedComponents) (t.components);
            return t;
        });

        return p;
    });

    return pluginsP;
}

const mergeEmbeddedComponentsInTree = (embeddedComponents: any[]): any => (components: any[]) => {
    const componentsP = components.map(c => {
        if (c.type === 'embeddedContainer') {
            // TODO: optimize this. group embedded components in groups, and
            // assign group to an embedded container
            c.components = embeddedComponents;
            return c;
        }

        // drill down the tree
        if (c.components) {
            c.components = mergeEmbeddedComponentsInTree (embeddedComponents) (c.components);
        }

        // drill down into actions for modals
        if (c.actions) {
            c.actions = c.actions.map(a => {
                if (a.component) {
                    const t = mergeEmbeddedComponentsInTree (embeddedComponents) ([a.component]);
                    a.component = t[0];
                }
                return a;
            });
        }

        return c;
    });
    return componentsP;
} 

