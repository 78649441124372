// @flow

//
// React
//
import React from 'react';

//
// Tenant Context
//
import TenantContext from '../context/TenantContext';

import { navigateTo } from '../tools/location';

const withNavigator = (): any => (WrappedComponent: any) => {
    class WithNavigator extends React.Component<any> {
        render(): any {
            return (
                <TenantContext.Consumer>
                    {tenantId => {
                        return (
                            <WrappedComponent
                                {...this.props}
                                navigateToAbsolutePath={(path: string) => {
                                    if (!this.props.history) {
                                        console.error('Expect \'history\' property, but was not found');
                                        return;
                                    }
                                    this.props.history.push(path);
                                }}
                                navigateTo={(path: string) => {
                                    if (!this.props.history) {
                                        console.error('Expect \'history\' property, but was not found');
                                        return;
                                    }
                                    navigateTo(p => this.props.history.push(p), tenantId)(path);
                                }}
                            />
                        );
                    }}
                </TenantContext.Consumer>
            );
        }
    }
    return WithNavigator;
}
export default withNavigator;