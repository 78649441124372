// @flow

//
// React
//
import React from 'react';

//
// Trix Data
//
import {
    type Error,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { makeStyles } from '@mui/styles';

//
// Components
//
import { ComboBox } from '../../../../trix-web-components-combo';

import PlaceholderForFormPaneField from './PlaceholderForFormPaneField';

import { type FormPaneFieldMeta } from './formPaneTypes';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {},
}));

//
// Props
//
type Props = {
    //
    // State
    //
    autoFocus?: boolean,
    label: string,
    description: string,
    required?: boolean,
    disabled?: boolean,
    freeSolo?: boolean,

    //
    // Action handlers
    //
    onLoadData: (
        value: string,
        reject: (error: any) => void,
        resolve: (options: Array<any>) => void
    ) => void,
    onGetOptionLabel: (option: any) => any,
    isOptionEqualToValue: (option: any, value: any) => boolean,

    onChange?: (value: any, meta: any) => void,
    onInputChange?: (value: string, meta: any) => void,

    //
    // Data
    //
    value: any,
    error: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const ComboBoxFormPaneField = (props: Props) => {
    const classes = useStyles();

    const hasError = (props.dataPath) ? hasErrorCausesForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : false;
    const helperText = (props.dataPath) ? formatErrorsForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : '';

    return (
        <PlaceholderForFormPaneField
            label={`${props.label}${(props.required) ? ' *' : ''}`}
            description={props.description}
            // error={props.error}
        >
            <ComboBox
                onLoadData={props.onLoadData}
                onGetOptionLabel={props.onGetOptionLabel}
                isOptionEqualToValue={props.isOptionEqualToValue}
                onChange={(value) => {
                    if (props.onChange) {
                        props.onChange(value, {
                            path: props.dataPath
                        })
                    }
                }}
                onInputChange={(value) => {
                    if (props.onInputChange) {
                        props.onInputChange(value, {
                            path: props.dataPath
                        })
                    }
                }}
                value={props.value}
                error={hasError}
                variant='standard'
                helperText={helperText}
                fullWidth={true}
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                freeSolo={props.freeSolo}

                label={`${props.label}${(props.required) ? ' *' : ''}`}
                description={props.description}
            />
        </PlaceholderForFormPaneField>
    );
};

//
// Use memo to create new component, which is sensible on just a several 
// important properties whose change is going to trigger re-render.
// This pattern is important performance optimization. As a side effect
// all other properties should be initialized on create and will not be 
// possible to be changed after component is created 
//
export default React.memo<Props>(ComboBoxFormPaneField,
    (prevProps: any, nextProps: any) => {
        return prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
		    prevProps.disabled === nextProps.disabled &&
            prevProps.onLoadData === nextProps.onLoadData &&
            prevProps.onGetOptionLabel === nextProps.onGetOptionLabel &&
            prevProps.isOptionEqualToValue === nextProps.isOptionEqualToValue &&
            prevProps.onChange === nextProps.onChange &&
            prevProps.onInputChange === nextProps.onInputChange;
    }
);