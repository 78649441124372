// @flow

//
// React
//
import React, {
    useMemo,
    type ComponentType,
} from 'react';

//
// Context
//
import ComponentsRegistryContext from '../../context/ComponentsRegistryContext';

//
// Functions
//
const getComponent = (components: any) => (name: string) => {
    return (components && components[name]) ? components[name] : null;
}

const getHalFormsCustomizer = (components: any) => (customizer: string, halFormsTemplate: any, 
        target?: string) => {
    let c = null;
    
    //
    // search customizer by path
    //
    let path = `${customizer}:${halFormsTemplate.id}`;
    if (target) {
        path = `${path}.${target}`;
    }
    c = getComponent(components)(path);
    // console.log(`Fetching hal forms customizer ${customizer} by path ${path} got:`, c);
    if (c) {
        return c;
    }

    //
    // search customizer by identifier
    //
    if (halFormsTemplate.identifier) {
        let identifier = `${customizer}:${halFormsTemplate.identifier}`;
        c = getComponent(components)(identifier);
        // console.log(`Fetching hal forms customizer ${customizer} by identifier ${identifier} got:`, c);
        if (c) {
            return c;
        }
    }

    return null;
}

//
// Props
//
type Props = {
}

//
// Wapper component. Utilize useMemo to optimize components rendering on change
//
const Wrapper = (props: {
    WrappedComponent: ComponentType<any>,
    components: any[],
}) => {
    const { 
        WrappedComponent,
        components,

        ...componentProps
    } = props;

    const componentsRegistry = useMemo(() => {
        return {
            getComponent: getComponent(components),
            getHalFormsCustomizer: getHalFormsCustomizer(components),
        }
    }, [components]);

    return (
        <WrappedComponent
            {...componentProps}

            __componentsRegistry={componentsRegistry}
        />
    );
}

//
// Consumes 'components' registry from context and relay them in props
//
const withComponentsRegistry = (WrappedComponent: ComponentType<any>): any => {
    const Component = (props: Props) => {
        // Renders the wrapped component with the context
        return (
            <ComponentsRegistryContext.Consumer>
                {
                    (ctx: { components: any }) => {
                        return (
                            <Wrapper
                                {...props}

                                WrappedComponent={WrappedComponent}
                                components={ctx.components}
                            />
                        );
                    }
                }
            </ComponentsRegistryContext.Consumer>
        );
    };
    return Component;
};
export default withComponentsRegistry;
