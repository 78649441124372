// @flow

//
// Lodash
//
import set from 'lodash/set';

//
// React
//
import React, {
    useCallback,
    useMemo,
} from 'react';

//
// Trix Data
//
import {
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

//
// Component
//
import { 
    TextFormPaneField,
    ComboBoxFormPaneField,
} from '../../../trix-web-components-pane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },

    header: {
        margin: '0.5rem',
    },
}));

//
// Props
//
type Props = {
    //
    // Main
    //
    name: string,
    label?: string,

    //
    // Action handlers
    //
    onChange: (value: any, meta: any) => void,

    //
    // State
    //
    error: any,

    //
    // Data
    //
    data: any,

    //
    // Loaders callbacks
    //
    onLoadCountries?: (value: string, reject: (error: any) => void, resolve: (data: any) => void) => void,
    onLoadCities?: (value: string, reject: (error: any) => void, resolve: (data: any) => void) => void,
    onLoadNeighbourhoods?: (value: string, reject: (error: any) => void, resolve: (data: any) => void) => void,
    onLoadStreets?: (value: string, reject: (error: any) => void, resolve: (data: any) => void) => void,
    onLoadPlaces?: (value: string, reject: (error: any) => void, resolve: (data: any) => void) => void,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const ShippingAddressFormPaneField = (props: Props): any => {
    //
    // Hooks
    //
    const clases = useStyles();
    // End Hooks

    const loadCountriesHandler = useCallback(
        (value: any, reject: any, resolve: any) => {
            if (props.onLoadCountries) {
                props.onLoadCountries(value, reject, resolve);
            } else {
                resolve([
                    {
                        code: 'BG',
                        name: 'България',
                    },
                    {
                        code: 'GR',
                        name: 'Гърция',
                    },
                    {
                        code: 'RO',
                        name: 'Румъния',
                    }
                ]);
            }
        }, [props.onLoadCountries]);
    const getOptionLableCountriesHandler = useCallback(
        (option: any) => {
            const code = (option.code) ? option.code : '';
            const name = (option.name) ? option.name : '';
            return (code && name) ? `${code} / ${name}` : name;
        }, []);
    const isOptionEqualToValueCountriesHandler = useCallback(
        (option: any, value: any) => {
            return option.id === value.id;
        }, []);

    const loadCitiesHandler = useCallback(
        (value: any, reject: any, resolve: any) => {
            if (props.onLoadCities) {
                props.onLoadCities(value, reject, resolve);    
            } else {
                resolve([]);
            }
        }, [props.onLoadCities]);
    const getOptionLableCitiesHandler = useCallback(
        (option: any) => {
            const name = (option.name) ? option.name : '';
            const postalCode = (option.postalCode) ? `(${option.postalCode})` : '';
            const municipality = (option.municipality) ? `(${option.municipality})` : '';
            return `${name} ${postalCode} ${municipality}`;
        }, []);
    const isOptionEqualToValueCitiesHandler = useCallback(
        (option: any, value: any) => {
            return option.id === value.id;
        }, []);

    const loadNeighbourhoodsHandler = useCallback(
        (value: any, reject: any, resolve: any) => {
            if (props.onLoadNeighbourhoods) {
                props.onLoadNeighbourhoods(value, reject, resolve);    
            } else {
                resolve([]);
            }
        }, [props.onLoadNeighbourhoods]);
    const getOptionLableNeighbourhoodsHandler = useCallback(
        (option: any) => {
            const code = (option.code) ? option.code : '';
            const name = (option.name) ? option.name : '';
            return (code && name) ? `${code} / ${name}` : name;
        }, []);
    const isOptionEqualToValueNeighbourhoodsHandler = useCallback(
        (option: any, value: any) => {
            return option.id === value.id;
        }, []);

    const loadStreetsHandler = useCallback(
        (value: any, reject: any, resolve: any) => {
            if (props.onLoadStreets) {
                props.onLoadStreets(value, reject, resolve);    
            } else {
                resolve([]);
            }
        }, [props.onLoadStreets]);
    const getOptionLableStreetsHandler = useCallback(
        (option: any) => {
            const code = (option.code) ? option.code : '';
            const type = (option.type) ? option.type : '';
            const name = (option.name) ? option.name : '';
            // return (code && name) ? `${code} / ${name}` : name;
            return `${type} ${name}`;
        }, []);
    const isOptionEqualToValueStreetsHandler = useCallback(
        (option: any, value: any) => {
            return option.id === value.id;
        }, []);

    const loadPlacesHandler = useCallback(
        (value: any, reject: any, resolve: any) => {
            if (props.onLoadPlaces) {
                props.onLoadPlaces(value, reject, resolve);    
            } else {
                resolve([]);
            }
        }, [props.onLoadPlaces]);        
    const getOptionLablePlacesHandler = useCallback(
        (option: any) => {
            const code = (option.code) ? option.code : '';
            const name = (option.name) ? option.name : '';
            return (code && name) ? `${code} / ${name}` : name;
        }, []);
    const isOptionEqualToValuePlacesHandler = useCallback(
        (option: any, value: any) => {
            return option.id === value.id;
        }, []);

    //
    // Change Handlers
    //
    const changeHandler = useCallback((value: any, meta: any) => {
        let newData = (props.data) ? props.data : {};
        newData = set(newData, meta.path, value);

        props.onChange(newData, {
            path: props.dataPath
        });
    }, [props.data]);

    // const countryInputChangeHandler = useCallback((value: any, meta: any) => {
    //     // props.onChange({
    //     //     name: value
    //     // }, meta);
    // }, []);

    // const cityInputChangeHandler = useCallback((value: any, meta: any) => {
    //     props.onChange({
    //         name: value
    //     }, meta);
    // }, []);

    // const neighbourhoodInputChangeHandler = useCallback((value: any, meta: any) => {
    //     props.onChange({
    //         name: value
    //     }, meta);
    // }, []);

    // const streetInputChangeHandler = useCallback((value: any, meta: any) => {
    //     props.onChange({
    //         name: value
    //     }, meta);
    // }, []);

    // const placeInputChangeHandler = useCallback((value: any, meta: any) => {
    //     props.onChange({
    //         name: value
    //     }, meta);
    // }, []);

    //
    // Components
    //
    const destinationType = (props.data && props.data.ref1) ? props.data.ref1 : 'address';

    const addressDestinationElement = useMemo(() => (
        <Button
            color='primary'
            size='small'
            disabled={destinationType === 'address'}
            onClick={(event) => {
                changeHandler('address', { path: `ref1` });
            }}
        >адрес</Button>
    ), [destinationType]);
    const officeDestinationElement = useMemo(() => (
        <Button
            color='primary'
            size='small'
            disabled={destinationType === 'office'}
            onClick={(event) => {
                changeHandler('office', { path: `ref1` });
            }}
        >офис</Button>
    ), [destinationType]);

    return (
        <Grid>
            {
                (props.label) ? (
                    <Typography 
                        className={clases.header}
                        variant='body2'
                        color='textSecondary'
                        align='left'
                    >
                        Доставка до {addressDestinationElement} или {officeDestinationElement}
                    </Typography>
                ) : null
            }

            <ComboBoxFormPaneField
                freeSolo={true}
                label='Държава'
                description=''

                value={(props.data) ? props.data.country : null}
                error={props.error}

                onLoadData={loadCountriesHandler}
                onGetOptionLabel={getOptionLableCountriesHandler}
                isOptionEqualToValue={isOptionEqualToValueCountriesHandler}

                onChange={changeHandler}
                // onInputChange={countryInputChangeHandler}

                dataPath='country'
            />

            <TextFormPaneField
                label='Пощенски номер'
                description=''

                value={(props.data) ? props.data.postalCode : ''}
                error={props.error}

                onChange={changeHandler}

                dataPath='postalCode'
            />

            <ComboBoxFormPaneField
                freeSolo={true}
                label='Град'
                description=''

                value={(props.data) ? props.data.city : null}
                error={props.error}

                onLoadData={loadCitiesHandler}
                onGetOptionLabel={getOptionLableCitiesHandler}
                isOptionEqualToValue={isOptionEqualToValueCitiesHandler}

                onChange={changeHandler}
                // onInputChange={cityInputChangeHandler}

                dataPath='city'
            />

            {(destinationType === 'address') ? 
                (
					<React.Fragment>
                        <ComboBoxFormPaneField
                            freeSolo={true}
                            label='Квартал'
                            description=''

                            value={(props.data) ? props.data.neighbourhood : null}
                            error={props.error}

                            onLoadData={loadNeighbourhoodsHandler}
                            onGetOptionLabel={getOptionLableNeighbourhoodsHandler}
                            isOptionEqualToValue={isOptionEqualToValueNeighbourhoodsHandler}

                            onChange={changeHandler}
                            // onInputChange={neighbourhoodInputChangeHandler}

                            dataPath='neighbourhood'
                        />

                        <ComboBoxFormPaneField
                            freeSolo={true}
                            label='Улица'
                            description=''

                            value={(props.data) ? props.data.street : null}
                            error={props.data}

                            onLoadData={loadStreetsHandler}
                            onGetOptionLabel={getOptionLableStreetsHandler}
                            isOptionEqualToValue={isOptionEqualToValueStreetsHandler}

                            onChange={changeHandler}
                            // onInputChange={streetInputChangeHandler}

                            dataPath='street'
                        />

                        <TextFormPaneField
                            autoFocus={false}
                            label='Номер'
                            description=''

                            value={(props.data) ? props.data.houseNumber : ''}
                            error={props.error}

                            onChange={changeHandler}

                            dataPath='houseNumber'
                        />

                        <TextFormPaneField
                            autoFocus={false}
                            label='Адрес 1'
                            description=''
                            
                            value={(props.data) ? props.data.address1 : ''}
                            error={props.error}
                            
                            onChange={changeHandler}

                            dataPath='address1'
                        />

                        <TextFormPaneField
                            autoFocus={false}
                            label='Адрес 2'
                            description=''

                            value={(props.data) ? props.data.address2 : ''}
                            error={props.error}

                            onChange={changeHandler}

                            dataPath='address2'
                        />

                        <TextFormPaneField
                            autoFocus={false}
                            label='Детайли'
                            description=''

                            value={(props.data) ? props.data.details : ''}
                            error={props.error}

                            onChange={changeHandler}

                            dataPath='details'
                        />
                    </React.Fragment>
                ) :
                (
                    <ComboBoxFormPaneField
                        freeSolo={true}
                        label='Офис куриер'
                        description=''

                        value={(props.data) ? props.data.place : null}
                        error={props.data}

                        onLoadData={loadPlacesHandler}
                        onGetOptionLabel={getOptionLablePlacesHandler}
                        isOptionEqualToValue={isOptionEqualToValuePlacesHandler}

                        onChange={changeHandler}
                        // onInputChange={placeInputChangeHandler}

                        dataPath='place'
                    />
                )
            }
        </Grid>
    );
};
export default ShippingAddressFormPaneField;
