// @flow

//
// React
//
import React from 'react';

import Button from '@mui/material/Button';

export const renderAsLink = (onClick?: (item: any) => void, item: any): any => (
    value: string,
) => {
    const content = (value) ? value : '';

    if (!onClick) {
        return content;
    }
    return (
        <Button
            color='primary'
            size='small'
            onClick={(event) => {
                onClick(item);
                
                // don't propagate the event to the document
                if (event.stopPropagation) {
                    // W3C model
                    event.stopPropagation();
                } else {
                    // IE model
                    event.cancelBubble = true;
                }
            }}
        >
            {content}
        </Button>
    );
};