// @flow

//
// React
//
import React from 'react';

//
// Components
//
import AppMenu from '../../../app/AppMenu';

//
// Properties
//
export type Props = {
    menus: any[],
};

//
// Components
//
const Home = (props: Props): any => {
    // hooks
    // end hooks

    return (
        <div
            style={{
                padding: '16px',
            }}
        >
            <AppMenu
                menus={props.menus}
            />
        </div>
    );
};
export default Home;
