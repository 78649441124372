// @flow

//
// React
//
import * as React from 'react';

//
// Props
//
type Props = {
    __TYPE: string,

    //
    // children
    //
    children: any,
}

//
// Component
//
const PageLayoutFooter = (props: Props): any => {
    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
}

PageLayoutFooter.defaultProps = {
    __TYPE: 'PageLayoutFooter'
}

export default PageLayoutFooter;
