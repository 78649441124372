// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../trix-fp-fantasy';

//
// React
//
import React, { useMemo } from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
    useDispatchLocal,
} from '../../redux-fantasy-reducers';

//
// Material UI
//
// import { makeStyles } from '@mui/styles';

//
// Data
//
import {
    concatErrors,
} from '../../trix-web-data-commons';

//
// Hal forms
//
import {
    getActionFactory,
	getSelector,
} from '../tools/halFormsRedux';

//
// Components
//
import {
    FormPane,
    type FormPaneContentProps,
    type FormPaneButtonsProps,
} from '../../trix-web-components-pane';

import HalFormsEditableFormContent from './halformseditableform/HalFormsEditableFormContent';
import HalFormsEditableFormButtons from './halformseditableform/HalFormsEditableFormButtons';

//
// Functions
//
const createContent = (cfg: {
    halFormsTemplate: any,
    ducks: {
        selectors: any,
        actions: any,
    },
    container: any,

    onChange: (value: any, meta: any) => void,
}) => (props: FormPaneContentProps) => {
    const context = useMemo(() => {}, []);

    return (
        <HalFormsEditableFormContent
            __halFormsTemplate={cfg.halFormsTemplate}

            __ducks={cfg.ducks}
            __context={context}

            container={cfg.container}
            data={props.data}
            error={props.error}

            onChange={cfg.onChange}
        />
    );
}

const createButtons = (cfg: {
    componentsRegistry: any,
    halFormsTemplate: any,
    ducks: {
        selectors: any,
        actions: any,
    },
    dataSourcesRef: any,
    context: any,
    container: any,

    configuration: any,
    
    enableButtons: boolean,

    onChange: (value: any, meta: any) => void,
    onClose: () => void,
}) => (props: FormPaneButtonsProps) => {
    if (!cfg.enableButtons) {
        return null;
    }

    return (
        <HalFormsEditableFormButtons
            __componentsRegistry={cfg.componentsRegistry}
            __halFormsTemplate={cfg.halFormsTemplate}
            __ducks={cfg.ducks}
            __dataSourcesRef={cfg.dataSourcesRef}
            __context={cfg.context}
            __configuration={cfg.configuration}

            data={props.data}
            container={cfg.container}

            onClose={cfg.onClose}
        />
    );
}

// const createMenu = (cfg: {
//     ducks: any,
//     halFormsTemplate: any,
//     container: any,
//     data: any,
// }) => (props: FormPaneMenuProps) => {
//     return (
//         <HalFormsMenu
//             __ducks={cfg.ducks}
//             __halFormsTemplate={cfg.halFormsTemplate}
//             __halFormsActions={cfg.halFormsTemplate.actions}
//             container={cfg.container}
//             data={cfg.data}
//             anchorEl={props.anchorEl}
//             open={props.open}
//             onClose={props.onClose}
//         />
//     );
// };

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Data
    //
    __halFormsDataContainerId?: string,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Components registry
    //
    __componentsRegistry: any,

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,

    //
    // Meta
    //
    meta: any,

    //
    // Behaviour
    //
    enableButtons?: boolean,

    //
    // Handers
    //
    onChange?: (value: any, meta: any) => void,
    onClose: () => void,
} // End of Props

//
// Component
//
const HalFormsEditableForm = (props: Props): any => {
    //
    // Hooks
    //
    const dispatchLocal = useDispatchLocal();
    const pageInstanceState = useLocalSelector (s => s);

    // fetch actions state. memoize complex actions traverse 
    const actions = props.__halFormsTemplate.actions || [];
    const inProgress = actions
        .reduce (
            (inProgress, action) => {
                if (inProgress) {
                    return true; 
                } else {
                    return getSelector (props.__ducks) (action.id) ('isInProgress') (pageInstanceState);
                }
            },
            false
        );
    const error = concatErrors(
        actions
            .map (action => {
                return getSelector (props.__ducks) (action.id) ('getError') (pageInstanceState);
            })
            .filter(action => action != null)
    );

    //
    // prepare modal configuration
    //
    // default configuration
    const defaultConfiguration = {
        enableHeader: true,
        enableButtons: true,
    };
    // custom configuration (if any)
    const componentConfigurationId = `configuration:${props.__halFormsTemplate.id}.form`;
    let getConfiguration = props.__componentsRegistry.getComponent(componentConfigurationId);
    const customConfiguration = (getConfiguration) ? getConfiguration() : {};
    // console.log('Fetching form configuration by Id: [' + componentConfigurationId + '] -> customConfiguration:', customConfiguration);
    // merge default and custom configuration
    const configuration = {
        ...defaultConfiguration,
        ...customConfiguration
    };

    let enableButtons = (props.enableButtons === false || configuration.enableButtons === false) ? false : true;

    const onChange = useMemo(() => {
        // Case 1: onChange is controlled by the parent component
        if (props.onChange) {
            return props.onChange;
        }

        // Case 2: Create onChange and memoize, based on specified hal forms data container
        if (props.__halFormsDataContainerId) {
            const halFormsDataContainerId = props.__halFormsDataContainerId;
            const setDataActionFactoryMaybe = getActionFactory 
                (props.__ducks) 
                (halFormsDataContainerId) 
                ('data.changeData');
            if (S.isJust (setDataActionFactoryMaybe)) {
                return (data: any, meta: any) => {
                    let wrappedData = data;
                    if (meta.path && meta.path !== '') {
                    const tokens = meta.path.split('.');
                    for (let i=tokens.length-1; i>=0; i--) {
                            wrappedData = {
                                [tokens[i]]: wrappedData
                            };
                        }
                    };

                    const f = S.maybeToNullable(setDataActionFactoryMaybe);
                    if (f) {
                        const action = f (wrappedData);
                        dispatchLocal(action);
                    } else {
                        console.error('Invalid setData action');
                    }
                };
            }
        }

        // Case 3: Empty onChange
        return (data: any, meta: any) => {
            console.error('HalFormsEditableForm does not have an onChange function handler');
        }
    }, [props.__halFormsTemplate, props.__ducks]);

    let data;
    if (props.onChange) {
        data = props.data;
    } else {
        const halFormsDataContainerId = props.__halFormsDataContainerId;
        const dataSelector = getSelector 
            (props.__ducks)
            (halFormsDataContainerId)
            ('data.getData');
        data = dataSelector(pageInstanceState);
    }

    const FormPaneContent = useMemo(() => createContent({
        halFormsTemplate: props.__halFormsTemplate,
        ducks: props.__ducks,
        container: props.container,

        onChange: onChange,
    }), [props.__halFormsTemplate, props.__ducks, props.container]);
    
    const FormPaneButtons = useMemo(() => createButtons({
        componentsRegistry: props.__componentsRegistry,
        halFormsTemplate: props.__halFormsTemplate,
        ducks: props.__ducks,
        dataSourcesRef: props.__dataSourcesRef,
        container: props.container,
        context: (props.meta && props.meta.context) ? props.meta.context : {},
        
        configuration,

        enableButtons,

        onChange: onChange,
        onClose: props.onClose,
    }), [props.__halFormsTemplate, props.__ducks, props.container, props.meta]);

    return (
        <FormPane
            title={props.__halFormsTemplate.title}
            inProgress={inProgress}
            isLoading={false}

            FormPaneContent={FormPaneContent}
            FormPaneButtons={FormPaneButtons}

            // onCreateMenu={createMenu({
            //     ducks: props.__ducks,
            //     halFormsTemplate: halFormsThreadTemplate,
            //     container: props.container,
            //     data: props.__data,
            // })}
            // data={props.data}
            data={data}
            error={error}
        />
    );
};
export default HalFormsEditableForm;
