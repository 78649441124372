// @flow

//
// React
//
import React, { useMemo } from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
} from '../../redux-fantasy-reducers';

//
// Material UI
//
import { makeStyles } from '@mui/styles';

//
// Hal forms
//
import {
	getSelector,
} from '../tools/halFormsRedux';

//
// Components
//
import {
    FloatPane,
    type FloatPaneContentProps,
    type FloatPaneMenuProps,
} from '../../trix-web-components-pane';

import HalFormsMenu from './HalFormsMenu';
import HalFormsFloatPaneContent from './halformsviewonlyform/HalFormsFloatPaneContent';

//
// Functions
//
const createContent = (cfg: {
    halFormsTemplate: any,
    ducks: {
        selectors: any,
        actions: any,
    },
}) => (props: FloatPaneContentProps) => {
    return (
        <HalFormsFloatPaneContent
            __halFormsTemplate={cfg.halFormsTemplate}
            __ducks={cfg.ducks}
            data={props.data}
            error={props.error}
        />
    );
}

const createMenu = (cfg: {
    ducks: any,
    halFormsTemplate: any,
    halFormsActions: any,
    onClose: () => void,
}) => (props: FloatPaneMenuProps) => {
    return (
        <HalFormsMenu
            __ducks={cfg.ducks}
            __halFormsTemplate={cfg.halFormsTemplate}
            __halFormsActions={cfg.halFormsActions}
            container={props.container}
            data={props.data}
            anchorEl={props.anchorEl}
            open={props.open}
            onCloseMenu={props.onCloseMenu}

            onClose={cfg.onClose}
        />
    );
};

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,

    //
    // Callbacks
    //
    onClose: () => void,
} // End of Props

//
// Component
//
const HalFormsViewOnlyForm = (props: Props): any => {
    //
    // Hooks
    //
    const pageInstanceState = useLocalSelector (s => s);

    const classes = useStyles();
    // End hooks
    
    // fetch actions state
    const actions = props.__halFormsTemplate.actions || [];
    const inProgress = actions
        .reduce (
            (inProgress, action) => {
                if (inProgress) { 
                    return true; 
                } else {
                    return getSelector (props.__ducks) (action.id) ('isInProgress') (pageInstanceState);
                }
            },
            false
        );

    const Content = useMemo(() => {
        return createContent({
            ducks: props.__ducks,
            halFormsTemplate: props.__halFormsTemplate
        });
    }, []);

    const Menu = useMemo(() => {
        return createMenu({
            ducks: props.__ducks,
            halFormsTemplate: props.__halFormsTemplate,
            halFormsActions: props.__halFormsTemplate.actions,
            onClose: props.onClose
        });
    }, []);

    return (
        <>
            <FloatPane
                title={props.__halFormsTemplate.title}
                inProgress={inProgress}
                isLoading={false}
                Content={Content}
                Menu={Menu}
                container={props.container}
                data={props.data}
            />
        </>
    );
};
export default HalFormsViewOnlyForm;