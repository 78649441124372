// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React from 'react';

//
// Material UI
//
import Chip from '@mui/material/Chip';

import LensIcon from '@mui/icons-material/Lens';

import brown from '@mui/material/colors/brown';
import cyan from '@mui/material/colors/cyan';
import yellow from '@mui/material/colors/yellow';
import blueGrey from '@mui/material/colors/blueGrey';

import { makeStyles } from '@mui/styles';

//
// Human tasks statuses
//
const HUMAN_TASK_STATUS = {
    /**
     * Upon creation. Remains CREATED if there are no potential owners.
     */
    CREATED: {
        'label': 'Нова задача',
        'color': blueGrey[100],
    },

    /**
     * Created task with multiple potential owners.
     */
    READY: {
        'label': 'Активирана задача',
        'color': yellow[700],
    },

    /**
     * Created task with single potential owner. Work started. Actual owner set.
     */
    RESERVED: {
        'label': 'Резервирана задача',
        'color': brown[700],
    },

    /**
     * Work started and task is being worked on now. Actual owner set.
     */
    IN_PROGRESS: {
        'label': 'В изпълнение',
        'color': cyan[700],
    },

    /**
     * In any of its active states (Ready, Reserved, InProgress), a task can be suspended,
     * transitioning it into the Suspended state. On resumption of the task, it transitions
     * back to the original state from which it had been suspended.
     */
    READY_SUSPENDED: {
        'label': 'На пауза',
        'color': blueGrey[100],
    },
    RESERVE_SUSPENDED: {
        'label': 'На пауза',
        'color': blueGrey[100],
    },
    IN_PROGRESS_SUSPENDED: {
        'label': 'На пауза',
        'color': blueGrey[100],
    },

    /**
     * Successful completion of the work. One of the final states.
     */
    COMPLETED: {
        'label': 'Изпълнена',
        'color': blueGrey[100],
    },

    /**
     * Unsuccessful completion of the work. One of the final states.
     */
    FAILED: {
        'label': 'Пропаднала',
        'color': blueGrey[100],
    },

    /**
     * Unrecoverable error in human task processing. One of the final states.
     */
    ERROR: {
        'label': 'Завършена с грешка',
        'color': blueGrey[100],
    },

    /**
     * TODO javadoc, One of the final states.
     */
    EXITED: {
        'label': 'Прекратена',
        'color': blueGrey[100],
    },

    /**
     * Task is no longer needed - skipped. This is considered a‚
     * outcome of a task. One of the final states.
     */
    OBSOLETE: {
        'label': 'Остаряла и затворена',
        'color': blueGrey[100],
    }
}

//
// Props
//
type Props = {
    //
    // Data
    //
    humanTaskStatus: string;
};

//
// Component
//
const HumanTaskStatusChip = (props: Props): any => {
    return (
        <Chip
            label={get(HUMAN_TASK_STATUS, `${props.humanTaskStatus}.label`)}
            size='small'
            variant='outlined'
            color='primary'
            icon={<LensIcon
                style={{
                    color: get(HUMAN_TASK_STATUS, `${props.humanTaskStatus}.color`),
                }}
            />}
        />
    );
};
export default HumanTaskStatusChip;
