// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React from 'react';

//
// Material UI
//
import Typography from '@mui/material/Typography';

//
// Type
//
import {
    type CellRendererFactoryProps,
    type CellProps,
} from '../types/CellProps';

//
// Helper
//
import { renderAsLink } from './tools';

//
// Component
//
const createAddressCellRenderer = (cfg: CellRendererFactoryProps): any => (props: CellProps): any => {
    return (
        <div>
            {renderAsLink(cfg.onClick, props.row)(`${props.value.code} / ${props.value.name}`)}
        </div>
    );
};
export default createAddressCellRenderer;
