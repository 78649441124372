// @flow

//
// Lodash
//
import debounce from 'lodash/debounce';

//
// React
//
import React, { 
    type Node,
    useState,
    useCallback,
    useEffect,
} from 'react';

//
// Material UI
//
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

import SearchIcon from '@mui/icons-material/Search';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {},

    searchField: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        minWidth: 400,
        width: '100%',
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },

    iconButton: {
        padding: 10,
    },
}));

//
// Props
//
type Props = {
    //
    // State
    //
    placeholder?: string,

    //
    // Actions
    //
    onChange: (value: string) => void,
}; // End of Props

//
// Component
//
const SearchField = (props: Props): Node => {
    //
    // Hooks
    //
    const [searchText, setSearchText] = useState('');

    const classes = useStyles();

    //
    // Debounced load data handler
    //
    const debouncedChangeHandler = debounce(value => {
        if (props.onChange) {
            props.onChange(value);
        }
    }, 500);

    const changeHandler = useCallback((e: any) => {
        setSearchText(e.target.value);       
        debouncedChangeHandler(e.target.value);        
    }, []);

    // stop the invocation of debounced function after unmounting
    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, []);

    return (
        <Grid container direction='row' wrap='nowrap'>
            <Paper className={classes.searchField}>
                <InputBase
                    className={classes.input}
                    placeholder={props.placeholder}
                    value={searchText}
                    onChange={changeHandler}
                />
                <IconButton
                    type='submit'
                    className={classes.iconButton}
                    onClick={() => props.onChange(searchText)}
                    size="large">
                    <SearchIcon />
                </IconButton>
            </Paper>
        </Grid>
    );
};
export default SearchField;
