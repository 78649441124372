// @flow

//
// React
//
import * as React from 'react';

//
// Utils
//
import { findChildByType } from '../../../trix-web-utils-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';

//
// Components
//
import PageLayoutHeader from './PageLayoutHeader';
import PageLayoutContent from './PageLayoutContent';
import PageLayoutFooter from './PageLayoutFooter';

//
// Props
//
type Child =
    | React.Element<typeof PageLayoutHeader>
    | React.Element<typeof PageLayoutContent>
    | React.Element<typeof PageLayoutFooter>;

type Props = {
    //
    // Visual
    //
    className?: any,

    //
    // children
    //
    children: Child | Child[],
};

/**
 * Reponsive page layout, which contains:
 * - header
 * - content
 * - footer
 *
 * @param props
 */
function PageLayout(props: Props): any {
    const header = findChildByType(props.children, 'PageLayoutHeader');
    const content = findChildByType(props.children, 'PageLayoutContent');
    const footer = findChildByType(props.children, 'PageLayoutFooter');

    return (
        <Grid
            container
            direction='column'
        >
            <Grid 
                item
                container
                wrap='nowrap'
            >
                {header}
            </Grid>

            <Grid 
                item 
                container
                direction='column'
                alignItems='center'
                sx={{
                    flexGrow: 1,
                    paddingLeft: theme => theme.spacing(1),
                    paddingRight: theme => theme.spacing(1),
                    paddingBottom: theme => theme.spacing(1)
                }}
            >
                {content}
            </Grid>

            <Grid item>{footer}</Grid>
        </Grid>
    );
}

PageLayout.Header = PageLayoutHeader;
PageLayout.Content = PageLayoutContent;
PageLayout.Footer = PageLayoutFooter;

export default PageLayout;
