// @flow

//
// React
//
import React, { useContext, useEffect } from 'react';

//
// Trix Middleware
//
import { get as httpGet } from '../../../../../../../lib/trix-web-middleware-commons';

//
// Redux
//
import { useDispatch, useSelector } from 'react-redux';

//
// Tenant
//
import { TenantContext } from '../../../../../../../lib/trix-web-components-tenant';

//
// API
//
import { getFindCompanyByTenantIdUrl } from '../../../../../state/api';

//
// Ducks
//
import { appSelector } from '../../../../../state/ducks';
import {
    Actions,
    Selectors,
} from '../../../../../state/ducks/app/index';

//
// Material UI
//
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    divider: {
        // height: '70%',
        height: '25px',
    },
}));

//
// Props
//
type Props = {

};

//
// Component
//
const TenantHeaderItem = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    const dispatch = useDispatch();

    const tenantId = useContext(TenantContext);

    useEffect(() => {
        if (!tenantId) {
            return;
        }

        httpGet(
            // url
            getFindCompanyByTenantIdUrl({
                tenantId
            }),
            
            // options
            null,
            
            // reject callback
            () => {
                
            },

            // resolve callback
            (data) => {
                dispatch(Actions.setOrganization(data.body));
            }
        )
    }, [tenantId]);

    const organization = useSelector(state => Selectors.organization(appSelector(state)));
    // end hooks

    const organizationName = (organization && organization.name) ? organization.name.bg : '';

    return (
        <React.Fragment>
            <Divider
                className={classes.divider}
                orientation='vertical'
                variant='middle'
            />
            <Typography variant='subtitle1'>{organizationName}</Typography>
        </React.Fragment>
    );
};
export default TenantHeaderItem;
