// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Chip from '@mui/material/Chip';

const createPropertyValueSourceTableColumn = (cfg: any): any => {
    return {
        align: 'center',
        flex: false,
        width: 50,

        renderCell: (props: any): any => {
            let value = '';
            if (props.value === 'STATIC') {
                value = 'S';
            }
            if (props.value === 'DYNAMIC') {
                value = 'D';
            }
            if (props.value === 'AUTO') {
                value = 'A';
            }

            return (
                <Chip 
                    label={value} 
                />
            );
        }
    }
}
export default createPropertyValueSourceTableColumn;
