// @flow 

//
// Redux
//
import { combineReducers } from 'redux';

// Namespace
const NS = 'shell/app';

//
// Actions
//
export const ActionTypes = {
    LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
};

export type LocationChangeAction = {
    type: '@@router/LOCATION_CHANGE',
    payload: any,
}


// End Actions

//
// Action creators
//
export const Actions = {
}
