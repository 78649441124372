// @flow

export function getPropertyTypes(): any {
    return [
        'TEXT',
        'I8N_TEXT',
        'INTEGER',
        'DOUBLE',
        'DATE',
        'BOOLEAN',
    ];
}

export function resolvePropertyValueSource(valueSource: string): any {
    switch (valueSource) {
        case 'STATIC':
            return 'Въвежда се от потребител';
        case 'DYNAMIC': 
            return 'Избира се от номенклатура';
        case 'AUTO':
            return 'Генерира се от системата';
    }
    return 'N/A';
}

export function resolvePropertyValueType(valueType: string): any {
    switch (valueType) {
        case 'TEXT':
            return 'Текст';
        case 'I8N_TEXT': 
            return 'Многоезичен текст';
        case 'INTEGER':
            return 'Цяло число';
        case 'DOUBLE':
            return 'Дробно число';
        case 'DATE':
            return 'Дата';
        case 'BOOLEAN':
            return 'Да/Не'
    }
    return 'Непознат тип';
}

export function resolveAccessType(accessType: string): any {
    switch (accessType) {
        case 'PUBLIC':
            return 'Публична';
        case 'SYSTEM': 
            return 'Системна';
    }
    return 'N/A';
}
