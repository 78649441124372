// @flow

import get from 'lodash/get';

//
// React
//
import React, { type Node } from 'react';

//
// Material UI
//
import Box from '@mui/material/Box';
import Image from 'mui-image';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles(theme => ({
}));

//
// Props
//
type Props = {
    stock: {
        sku: string,
        code: string,
        name: any,
    },
};

//
// Component
//
const StockImageRendererPane = (props: Props): Node => {
    //
    // Hooks
    //
    const classes = useStyles();

    // End Hooks

    // PATCH: 
    const sku = (props.stock) ? props.stock.sku : '';
    const imageUrl = `http://79.124.17.213/rp/${sku.substring(0, sku.length - 2)}_1.jpg`;

    return (
        <Box style={{ padding: '8px', width: '100px' }}>
            <Image
                src={imageUrl}
                // onClick={() => console.log('onClick')}
                // aspectRatio={(16/9)}
                // disableSpinner
            />
        </Box>
    );
}
export default StockImageRendererPane;
