// @flow

//
// Lodash
//
import get from 'lodash/get';
import set from 'lodash/set';
import assign from 'lodash/assign';
import merge from 'lodash/merge';

//
// Types
//
import { type ActionState } from '../../../../trix-web-data-commons';

//
// State
//
export type State = {};

//
// Configuration
//
type Configuration = {
    initialData: any,
    reduce: (state: State, action: any) => State,
};

//
// Reducer
//
export const createReducer = (cfg: Configuration): any => (
    state: State = cfg.initialData,
    action: any
): State => {
    return cfg.reduce(state, action);
};

//
// Composite reducers
//
export const reduceDataSet = (payloadPath?: string): any => (state: State, action: any) => {
    if (!action.payload) {
        return state;
    }
    return (payloadPath) ? get(action.payload, payloadPath) : action.payload;
};

export const reduceDataMerge = (payloadPath?: string, destinationPath?: string): any => (state: State, action: any) => {
    if (!action.payload) {
        return state;
    }
    let dataToMerge = action.payload;
    if (payloadPath) {
        dataToMerge = get(dataToMerge, payloadPath);
    }
    if (destinationPath) {
        dataToMerge = set({}, destinationPath, dataToMerge);
    }
    return merge({}, state, dataToMerge);
};

export const reduceDataChanged = (payloadPath?: string): any => (state: State, action: any) => {
    if (!action.payload) {
        return state;
    }
    const actionData = (payloadPath) ? get(action.payload, payloadPath) : action.payload;
    return assign({}, state, actionData);
};

export const reduceDataFlush = (emptyState: any): any => (state: State, action: any) => {
    return emptyState;
};