// @flow

//
// Axios
//
import axios from 'axios';
// TODO: check if js-file-download might be replaced with axious
import fileDownload from 'js-file-download';

//
// API
//
import {
    CSRF_HEADER_NAME,
    csrfToken,
} from './csrf';

import {
    getHttpOptions,
} from '../context/http';

import {
    type Error,

    isError,

    toError,
    serverError,
    networkError,
    clientError,
} from '../../trix-web-data-commons';

/**
 * Get JSON data
 * 
 * @param url fetch url
 * 
 * @return TODO: check axios cancel request
 */
export function get(url: string, options: Object, 
        reject: (error: Object) => void,
        resolve: (body: Object) => void): any {
    const globalOptions = getHttpOptions();

    let headers = {
        'Accept': 'application/json, application/prs.hal-forms+json'
    };
    if (globalOptions && globalOptions.headers) {
        headers = {
            ...headers,
            ...globalOptions.headers,
        }
    }
    if (options && options.headers) {
        headers = {
            ...headers,
            ...options.headers,
        }
    }

    return axios.get(
        // url
        toFqdn(url),

        // options
        {
            timeout: 15000,

            headers,
        })

        .then(response => {
            handleAxiosJsonResponse(resolve, response);
        })
        
        .catch(error => {
            handleAxiosError(reject, error);
        });
}

/**
 * Post JSON data
 * 
 * @param url fetch url
 * @param data json data
 * 
 * @return TODO: check axios cancel request
 */
export function post(url: string, data: Object, 
        reject: (error: Object) => void,
        resolve: (body: Object) => void): any {
    const globalOptions = getHttpOptions();
    let headers = {};
    if (globalOptions && globalOptions.headers) {
        headers = {
            ...headers,
            ...globalOptions.headers,
        }
    }

    return axios.post(
        // url
        toFqdn(url),

        // data
        JSON.stringify(data),

        // options
        {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
                // 'Accept': 'application/json',
                'Accept': 'application/json, application/prs.hal-forms+json',
    
                ...headers,

                // csrf header
                [CSRF_HEADER_NAME]: csrfToken(),
            },
            body: JSON.stringify(data),
        })
        
        .then(response => {
            handleAxiosJsonResponse(resolve, response);
        })

        .catch(error => {
            handleAxiosError(reject, error);
        });
}

/**
 * Get HTML data
 * 
 * @param url fetch url
 * 
 * @return TODO: check axios cancel request
 */
export function getHtml(url: string, data: Object, 
        reject: (error: Object) => void,
        resolve: (html: string) => void): any {
    const globalOptions = getHttpOptions();

    let headers = {};
    if (globalOptions && globalOptions.headers) {
        headers = {
            ...headers,
            ...globalOptions.headers,
        }
    }

    return axios.get(
        // url
        toFqdn(url),

        // options
        {
            headers: {
                // 'Accept': 'application/json',
                ...headers,
            },
        })

        .then(response => {

        })

        .catch(error => {

        });

    //     // callback
    //     (error: any, response: any, body: any) => {
    //         if (error) {
    //             reject(networkError('Грешка при връзка към сървъра'));
    //         }

    //         // handle error from server
    //         if (!(response.statusCode >= 200 && response.statusCode < 300)) {
    //             let json = {};
    //             try {
    //                 json = JSON.parse(body);
    //             } catch (e) {
    //             }

    //             if (isError(json)) {
    //                 reject(toError(json.causes));
    //             } else {
    //                 reject(serverError('Възникна неопределена грешка'));
    //             }

    //             return;
    //         }

    //         // success
    //         resolve(body);
    //     }
    // );
}

export function getFile(url: string, data: Object, 
        reject: (error: Object) => void,
        resolve: (data: any) => void): any {
    const globalOptions = getHttpOptions();

    let headers = {};
    if (globalOptions && globalOptions.headers) {
        headers = {
            ...headers,
            ...globalOptions.headers,
        }
    }

    // return request(
    //     {
    //         method: 'GET',
    //         url: toFqdn(url),

    //         // https://stackoverflow.com/questions/14855015/getting-binary-content-in-node-js-using-request
    //         encoding: null,

    //         options: {
    //             headers: {
    //                 ...headers,
    //             }
    //         }
    //     },

    //     // callback
    //     (error: any, response: any, data: any) => {
    //         if (error) {
    //             reject(networkError('Грешка при връзка към сървъра'));
    //         }

    //         // TODO: assure HTTP code == 2000

    //         const disposition = response.headers['content-disposition'];
    //         const matches = /"([^"]*)"/.exec(disposition);
    //         const filename = (matches != null && matches[1] ? matches[1] : 'file');
    //         // TODO: throw error if no content disposition and file name fetched ...

    //         fileDownload(data, filename);

    //         resolve({});
    //     }
    // );
}

/**
 * Post multipart request
 *
 * @param url fetch url
 * @param parts array of parts to post
 *
 * @return TODO: check axios cancel request
 */
export function postMultipart(url: string, parts: any[],
        reject: (error: Object) => void,
        resolve: (body: Object) => void): any {    
//     const globalOptions = getHttpOptions();
//     let headers = {
//         'Accept': 'application/json, application/prs.hal-forms+json'
//     };
//     if (globalOptions && globalOptions.headers) {
//         headers = {
//             ...headers,
//             ...globalOptions.headers,
//         }
//     }
    
//     return request.post(toFqdn(url),
//         // options
//         {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//                 'X-Requested-With': 'XMLHttpRequest',
//                 'Accept': 'application/json',

//                 // csrf header
//                 [CSRF_HEADER_NAME]: csrfToken(),

//                 ...headers,
//             },

//             // Some variations in different HTTP implementations require a newline/CRLF before,
//             // after, or both before and after the boundary of a multipart/related request
//             // preambleCRLF: true,
//             // postambleCRLF: true,

//             // Ex part:
//             //  - 'Content-Type': 'text/plain'
//             //  - body: '....'
//             multipart: parts,
//             // multipart: {
//             //     chunked: false,
//             //     data: parts
//             // },

//             // multipart: [
//             //     {
//             //         'content-type': 'application/json',
//             //         body: JSON.stringify({foo: 'bar', _attachments: {'message.txt': {follows: true, length: 18, 'content_type': 'text/plain' }}})
//             //     },
//             //     { body: 'I am an attachment' },
//             //     { body: fs.createReadStream('image.png') }
//             // ],
//         },

//         // callback
//         (error: any, response: any, body: any) => {
//             if (error) {
//                 reject(networkError('Грешка при връзка към сървъра'));
//             }

//             // handle error from server
//             if (!(response.statusCode >= 200 && response.statusCode < 300)) {
//                 let json = {};
//                 try {
//                     json = JSON.parse(body);
//                 } catch (e) {
//                 }

//                 if (isError(json)) {
//                     reject(toError(json.causes));
//                 } else {
//                     reject(serverError('Възникна неопределена грешка'));
//                 }

//                 return;
//             }

//             // success
//             let json = {};
//             try {
//                 json = JSON.parse(body);
//             } catch (e) {
//             }
//             resolve(json);
//         }
//     );
}

//
// Error handling
//
function handleAxiosError(reject: (error: Object) => void, error: any) {
    if (error.response) {
        if (error.response.status == 401) {
            reject(serverError('Отказан достъп'));
            return;
        }
        
        if (!(error.response.status >= 200 && error.response.status < 300)) {
            if (isError(error.response.data)) {
                reject(toError(error.response.data.causes));
            } else {
                reject(serverError('Възникна неопределена грешка: ' + error.response.data));
            }
            return;
        }

        reject(serverError(error.response.data));
        return;
    }

    if (error.request) {
        reject(networkError('Грешка при връзка към сървъра (' + error.message + ')'));
        return;
    }

    reject(clientError('Грешка при изпъление на заявка към съврвъра'));
}

function handleAxiosJsonResponse(resolve: (data: any) => void, response: any) {
    resolve({
        body: response.data
    });
}

//
// Url utils
//
function toFqdn(url: string) {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
    }
    if (window && window.location) {
        return `${window.location.origin}${url}`;
    }
    throw clientError('Can\'t compose FQDN url');
}
