// @flow

//
// Lodash
//
import get from 'lodash/get';

//
// React
//
import React, { useState, useEffect } from 'react';

//
// Trix Middleware
//
import { get as httpGet } from '../../../../../../lib/trix-web-middleware-commons';

//
// Material UI
//
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { makeStyles } from '@mui/styles';

//
// Styles
//
const useStyles = makeStyles((theme) => ({
    root: {
        margin: '50px auto',
    },
}));

//
// Props
//
type Props = {
    //
    // Handlers
    //
    onTenantSelected: (tenantId: number) => void,
};

//
// Desk
//
const TenantSelector = (props: Props): any => {
    //
    // Hooks
    //
    const classes = useStyles();

    const [ companies, setCompanies ] = useState([]);

    useEffect(() => {
        httpGet(
            // url
            '/api/v1/tenant/company/companies/search/byAuthorizedTenants',
            
            // options
            {
                headers: {
                    'X-Tenant': 0
                }
            },
            
            // reject callback
            () => { 
                // TODO: show error
            },

            // resolve callback
            (data) => {
                setCompanies(get(data, 'body._embedded.companies', []));
            }
        )
    }, []);

    // End hooks

    return (
        <Paper className={classes.root}>
            <List>
                {companies.map((o, i) => {
                    return (
                        <React.Fragment key={i}>
                            <ListItem
                                alignItems='flex-start' 
                                button 
                                onClick={() => {
                                    props.onTenantSelected(o.tenantId);
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        alt={get(o, 'name.bg', '')}
                                    >
                                        {get(o, 'name.bg', '').substring(0, 1)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={get(o, 'name.bg', '')}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component='span'
                                                variant='body2'
                                                className={classes.inline}
                                                color='textPrimary'
                                            >
                                                {o.responsiblePerson.bg} | 
                                            </Typography>
                                            {` ${get(o, 'addressCountry.bg')}, ${get(o, 'addressCity.bg')} ${get(o, 'addressStreet.bg')} ${get(o, 'houseNumber')}`}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            {(i < companies.length - 1) ? 
                                (<Divider 
                                    variant='inset' 
                                    component='li' 
                                />) : 
                                null
                            }
                        </React.Fragment>
                    );
                })}
            </List>
        </Paper>
    );
};
export default TenantSelector;
