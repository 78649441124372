// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    TextFloatPaneField,
} from '../../../../../lib/trix-web-components-pane';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // Hal forms
    //
    label: string,

    //
    // Data
    //
    data: any;
};

//
// Component
//
const ShipmentConfigurationField = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    return (
        <Grid item>
            <TextFloatPaneField
                label={props.label}
                value={(props.data) ? `${props.data.code} / ${props.data.name}` : '-'}
            />
        </Grid>
    );
};
export default ShipmentConfigurationField;
