// @flow

//
// React
//
import React from 'react';

//
// Trix Data
//
import {
    type Error,
    hasErrorCausesForLocation,
    formatErrorsForLocation,
} from '../../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { makeStyles } from '@mui/styles';

//
// Component
//
import PlaceholderForFormPaneField from './PlaceholderForFormPaneField';

import { type FormPaneFieldMeta } from './formPaneTypes';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    root: {
    },
}));

//
// Props
//
type Props = {
    //
    // State
    //
    autoFocus: boolean,
    disabled: boolean,
    required?: boolean,
    label: string,
    description: string,

    //
    // Action handlers
    //
    onChange: (value: string, meta: FormPaneFieldMeta) => void,

    //
    // Data
    //
    value: boolean,
    error: any,

    //
    // Meta data. Meta data is passed back along with changed value 
    // to the change handler
    //
    dataPath: string,
}; // End of Props

//
// Component
//
const CheckboxFormPaneField = (props: Props) => {
    const classes = useStyles();

    const hasError = (props.dataPath) ? hasErrorCausesForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : false;
    const helperText = (props.dataPath) ? formatErrorsForLocation(
        props.error,
        'PARAMETER',
        props.dataPath
    ) : '';

    return (
        <PlaceholderForFormPaneField>
            <FormControlLabel
                label={`${props.label}${(props.required) ? ' *' : ''}`}
                control={
                    <Checkbox
                        disabled={props.disabled}
                        size='small'
                        checked={props.value}

                        label={`${props.label}${(props.required) ? ' *' : ''}`}
                        description={props.description}

                        error={hasError}
                        helperText={helperText}
                        onChange={event => props.onChange(event.target.checked, {
                            path: props.dataPath
                        })}
                    />
                }
            />
        </PlaceholderForFormPaneField>
    );
};

//
// Use memo to create new component, which is sensible on just a several 
// important properties whose change is going to trigger re-render.
// This pattern is important performance optimization. As a side effect
// all other properties should be initialized on create and will not be 
// possible to be changed after component is created 
//
export default React.memo<Props>(CheckboxFormPaneField,
    (prevProps: any, nextProps: any) => {
        return prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.disabled === nextProps.disabled &&
            prevProps.autoFocus === nextProps.autoFocus && 
            prevProps.onChange === nextProps.onChange;
    }
);