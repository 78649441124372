// @flow

//
// React
//
import React from 'react';

//
// React router
//
import { useNavigate } from 'react-router-dom';

//
// Material UI
//
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import { makeStyles } from '@mui/styles';

import LaunchIcon from '@mui/icons-material/Launch';

import { red } from '@mui/material/colors';

//
// Styles
//
const useStyles = makeStyles(theme => ({
    applications: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    card: {
    },
    launchIcon: {
        fontSize: '1rem',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

//
// Properties
//
export type Props = {};

//
// Components
//
const Applications = (props: Props): any => {
    // hooks
    const navigate = useNavigate();

    // styles
    const classes = useStyles();

    const applications = [
        {
            name: 'Продукти',
            description: 'Управление на номенклатура продукти',
            url: '/core/product',
        },
    ];

    return (
        <div className={classes.applications}>
            <Grid container spacing={2}>
                {applications.map((application, i) => (
                    <Grid
                        key={`application_${i}`}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <Avatar className={classes.avatar}>
                                        {application.name.substring(0, 1)}
                                    </Avatar>
                                }
                                action={
                                    <IconButton
                                        onClick={() =>
                                            navigate(application.url)
                                        }
                                        size="large">
                                        <LaunchIcon
                                            className={classes.launchIcon}
                                        />
                                    </IconButton>
                                }
                                title={application.name}
                                subheader={application.description}
                            />
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );

    // return (
    //     <div
    //         className={style(
    //             csstips.fillParent,
    //             csstips.padding(em(1)))
    //         }
    //     >
    //         <Segment padded>
    //             <Menu text vertical>
    //                 <Menu.Item header>Продукти</Menu.Item>

    //                 <Menu.Item
    //                     onClick={() => props.history.push(`/core/product/product/list`)}
    //                 >
    //                     Продукти
    //                 </Menu.Item>

    //                 <Menu.Item
    //                     onClick={() => props.history.push(`/core/product/group/list`)}
    //                 >
    //                     Групи
    //                 </Menu.Item>

    //                 <Menu.Item
    //                     onClick={() => props.history.push(`/core/product/manufacturer/list`)}
    //                 >
    //                     Производители
    //                 </Menu.Item>
    //             </Menu>
    //         </Segment>
    //     </div>
    // );
};
export default Applications;
