// @flow

//
// React
//
import React from 'react';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ButtonBase from '@mui/material/ButtonBase';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DescriptionIcon from '@mui/icons-material/Description';

import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

//
// Tenant
//
import {
    useNavigateTo,
} from '../../../../../lib/trix-web-components-tenant';

//
// Styled components
//
const ImageButton = styled(ButtonBase)(({ theme }) => ({
    width: '150px',
    height: '100px',
    [theme.breakpoints.down('md')]: {
        // width: '180px',
        // height: '120px',
        // width: '100% !important', // Overrides inline-style
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            // border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
}));

//
// Properties
//
export type Props = {
    menuItem: any,
};

//
// Components
//
const AppMenuItem = (props: Props): any => {
    const navigateTo = useNavigateTo();

    return (
        <ImageButton
            focusRipple
            onClick={() => navigateTo(props.menuItem.path)}
        >
            <ImageSrc style={{
                backgroundColor: '#009FDA',
            }} />

            {/* <ImageSrc style={{ 
                    backgroundImage: `url(https://images.unsplash.com/photo-1569428034239-f9565e32e224?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=60)` }} /> */}

            <ImageBackdrop className='MuiImageBackdrop-root' />
            <Image>
                <Grid container direction='column' style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <DescriptionIcon
                        fontSize='large'
                        style={{
                            color: 'primary',
                            fontSize: '2.0rem',
                        }}
                    />
                    <Typography
                        component='span'
                        variant='body2'
                        color='inherit'
                    >
                        {props.menuItem.title}
                    </Typography>
                </Grid>
            </Image>
        </ImageButton>
    );
}
export default AppMenuItem;
