// @flow

//
// React
//
import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react';

//
// Redux Fantasy
//
import {
    useLocalSelector,
} from '../../redux-fantasy-reducers';

//
// Data
//
import {
    concatErrors,
} from '../../trix-web-data-commons';

import {
    createSetDataInState,
} from '../../trix-web-components-pane';

//
// Hal forms
//
import {
	getSelector,
} from '../tools/halFormsRedux';

//
// Material UI
//
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    withComponentsRegistry,
} from '../../trix-web-components-registry';

import {
    type DataDialogContentProps,
    type DataDialogButtonsProps,
    DataDialog,
} from '../../trix-web-components-dialog';

import HalFormsContainer from './HalFormsContainer';
import HalFormsContainerRowPlain from './halformscontainer/HalFormsContainerRowPlain';
import HalFormsTabs from './HalFormsTabs';
import HalFormsEditableForm from './HalFormsEditableForm';
import HalFormsViewOnlyForm from './HalFormsViewOnlyForm';
import HalFormsModalButtons from './halformsmodal/HalFormsModalButtons';

//
// Functions
//
const createContent = (cfg: {
    componentsRegistry: any,

    halFormsTemplate: any,

    ducks: {
        selectors: any,
        actions: any,
    },
    dataSourcesRef: any,
    container: any,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => void,
    onClose: () => void,
}) => (props: DataDialogContentProps) => {
    const context = useMemo(() => {
        return {
            onClose: cfg.onClose
        }
    }, [cfg.onClose]);

    if (cfg.halFormsTemplate.type === 'form') {
        if (cfg.halFormsTemplate.editable) {
            return (
                <HalFormsEditableForm
                    key='halFormsModal-0'

                    __componentsRegistry={cfg.componentsRegistry}

                    __halFormsTemplate={cfg.halFormsTemplate}
                    __ducks={cfg.ducks}
                    __dataSourcesRef={cfg.dataSourcesRef}
                    __context={context}
                
                    container={cfg.container}
                    data={props.data}
                    meta={null}
                    error={props.error}
                    
                    enableButtons={false}

                    onChange={cfg.onChange}
                    onClose={cfg.onClose}
                />
            );
        } else {
            return (
                <HalFormsViewOnlyForm
                    key='halFormsModal-0'

                    __halFormsTemplate={cfg.halFormsTemplate}
                    __ducks={cfg.ducks}
                    __dataSourcesRef={cfg.dataSourcesRef}
                    
                    container={cfg.container}
                    data={props.data}

                    onClose={cfg.onClose}
                />
            );
        }
    }

    if (cfg.halFormsTemplate.type === 'tabsContainer') {
        return (
            <HalFormsTabs
                key='halFormsModal-0'

                __halFormsTemplate={cfg.halFormsTemplate}
                __ducks={cfg.ducks}
                __dataSourcesRef={cfg.dataSourcesRef}
                __context={context}
            
                container={cfg.container}
                data={props.data}
                error={props.error}

                onChange={cfg.onChange}
                onClose={cfg.onClose}
            />
        );
    }

    return (
        <HalFormsContainer
            key='halFormsModal-0'

            HalFormsContainerRow={HalFormsContainerRowPlain}

            __halFormsTemplate={cfg.halFormsTemplate}
            __ducks={cfg.ducks}            
            __dataSourcesRef={cfg.dataSourcesRef}
            __context={context}
                        
            container={cfg.container}
            data={props.data}
            error={props.error}

            onChange={cfg.onChange}
            onClose={cfg.onClose}
        />
    );
}

const createButtons = (cfg: {
    componentsRegistry: any,
    halFormsTemplate: any,
    ducks: {
        selectors: any,
        actions: any,
    },
    container: any,
    context: any,

    dataSourcesRef: any,
    
    onClose: () => void,
}) => (props: DataDialogButtonsProps) => {
    return (
        <HalFormsModalButtons
            __componentsRegistry={cfg.componentsRegistry}
            __halFormsTemplate={cfg.halFormsTemplate}
            __ducks={cfg.ducks}
            __context={cfg.context}

            __dataSourcesRef = {cfg.dataSourcesRef}
                        
            container={cfg.container}
            data={props.data}

            onClose={cfg.onClose}
        />
    );
};

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Refs
    //
    __dataSourcesRef: any,

    //
    // State
    //
    isOpen: boolean,

    //
    // Container
    //
    container: any,

    //
    // Data
    //
    data: any,

    //
    // Meta
    //
    meta: any,

    //
    // Handlers
    //
    onClose: () => void,

    //
    // Components registry
    //
    __componentsRegistry: any,
} // End of Props

//
// Component
//
const HalFormsModal = (props: Props) => {
    //
    // Hooks
    //
    const [data, setData] = useState({});

    // update local state from data props, when data props changes
    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const changeHandler = useCallback(createSetDataInState(setData), []);

    const closeHandler = useCallback(() => {
        props.onClose();

        // cleanup modal data
        setData({});
    }, []);

    //
    // prepare modal configuration
    //
    // default configuration
    const defaultConfiguration = {
        enableHeader: true,
        enableButtons: true,
    };
    // custom configuration (if any)
    const componentConfigurationId = `configuration:${props.__halFormsTemplate.id}.modal`;
    let getConfiguration = props.__componentsRegistry.getComponent(componentConfigurationId);
    const customConfiguration = (getConfiguration) ? getConfiguration() : {};
    // merge default and custom configuration
    const configuration = {
        ...defaultConfiguration,
        ...customConfiguration
    };

    const DataDialogContent = useMemo(() => createContent({
        componentsRegistry: props.__componentsRegistry,

        halFormsTemplate: props.__halFormsTemplate,
        ducks: props.__ducks,
        dataSourcesRef: props.__dataSourcesRef,

        container: props.container,

        onChange: changeHandler,
        onClose: closeHandler,
    }), [props.__halFormsTemplate, props.__ducks, props.data, props.container]);
    
    const DataDialogButtons = useMemo(() => {
        if (configuration.enableButtons || !props.__halFormsTemplate.actions || 
                props.__halFormsTemplate.actions <= 0) {
            return createButtons({
                componentsRegistry: props.__componentsRegistry,
                halFormsTemplate: props.__halFormsTemplate,
                ducks: props.__ducks,
                container: props.container,
                context: (props.meta && props.meta.context) ? props.meta.context : {},
                onClose: closeHandler,

                dataSourcesRef: props.__dataSourcesRef
            });
        } else {
            return undefined;
        }
    }, [props.__halFormsTemplate, props.__ducks, props.container, props.meta]);

    if (!props.isOpen) {
        return null;
    }

    return (
        <DataDialog
            enableHeader={configuration.enableHeader}
            enableButtons={configuration.enableButtons}

            DataDialogContent={DataDialogContent}
            DataDialogButtons={DataDialogButtons}

            onClose={closeHandler}
            isOpen={!!props.isOpen}
            title={props.__halFormsTemplate.title}

            inProgress={false}

            data={data}
        />
    );
};
export default withComponentsRegistry (HalFormsModal);