// @flow

import ProductGroupNameField from './components/form/ProductGroupNameField';
import CreateUpdateProductGroupPropertyForm from './components/form/CreateUpdateProductGroupPropertyForm';
import CreateProductForm from './components/form/CreateProductForm';
import UpdateProductForm from './components/form/UpdateProductForm';
import CreateUpdateProductVariationForm from './components/form/CreateUpdateProductVariationForm';

import createPropertyValueSourceTableColumn from './components/table/PropertyValueSourceTableColumn';
import createPropertyValueTypeTableColumn from './components/table/PropertyValueTypeTableColumn';
import createPropertyMandatoryTableColumn from './components/table/PropertyMandatoryTableColumn';
import createVariationPropertiesNameValueTableColumn from './components/table/VariationPropertiesNameValueTableColumn';

import getProductMainPropertiesTableConfiguration from './components/configuration/productMainPropertiesTableConfiguration';
import getProductVariationsTableConfiguration from './components/configuration/productVariationsTableConfiguration';
import getProductInstancePropertiesTableConfiguration from './components/configuration/productInstancePropertiesTableConfiguration';

export const components = {
    'form:trix-web-plugin-core-product.manageProductGroupPage.container.productGroupMainProperties.action.onAddItem.editor': CreateUpdateProductGroupPropertyForm,
    'form:trix-web-plugin-core-product.manageProductGroupPage.container.productGroupMainProperties.action.onRowClick.editor': CreateUpdateProductGroupPropertyForm,

    'form:trix-web-plugin-core-product.manageProductGroupPage.container.productGroupVariationProperties.action.onAddItem.editor': CreateUpdateProductGroupPropertyForm,
    'form:trix-web-plugin-core-product.manageProductGroupPage.container.productGroupVariationProperties.action.onRowClick.editor': CreateUpdateProductGroupPropertyForm,

    'form:trix-web-plugin-core-product.manageProductGroupPage.container.productGroupInstanceProperties.action.onAddItem.editor': CreateUpdateProductGroupPropertyForm,
    'form:trix-web-plugin-core-product.manageProductGroupPage.container.productGroupInstanceProperties.action.onRowClick.editor': CreateUpdateProductGroupPropertyForm,

    'form:trix-web-plugin-core-product.listProductsPage.container.table.action.onAddItem.createProductForm': CreateProductForm,
    'form:trix-web-plugin-core-product.manageProductPage.container.form.action.onEdit.updateProductForm': UpdateProductForm,

    'form:trix-web-plugin-core-product.manageProductPage.container.productVariations.action.onAddItem.editor': CreateUpdateProductVariationForm,

    'formPaneField:PRODUCT_GROUP_NAME': ProductGroupNameField,

    'tableColumn:PROPERTY_VALUE_SOURCE': createPropertyValueSourceTableColumn,
    'tableColumn:PROPERTY_VALUE_TYPE': createPropertyValueTypeTableColumn,
    'tableColumn:PROPERTY_MANDATORY': createPropertyMandatoryTableColumn,
    // 'tableCellRenderer:PROPERTY_CODE_NAME_DESCRIPTION': PropertyCodeNameDescriptionCellRenderer,
    'tableColumn:VARIATION_PROPERTIES_NAME_VALUE': createVariationPropertiesNameValueTableColumn,

    'configuration:trix-web-plugin-core-product.manageProductPage.container.productMainProperties.table': getProductMainPropertiesTableConfiguration,
    'configuration:trix-web-plugin-core-product.manageProductPage.container.productVariations.table': getProductVariationsTableConfiguration,
    'configuration:trix-web-plugin-core-product.manageProductPage.container.productInstanceProperties.table': getProductInstancePropertiesTableConfiguration,
}

