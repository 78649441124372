// @flow

//
// FP
//
import * as $ from 'sanctuary-def';
import S from '../../../trix-fp-fantasy';

//
// React
//
import React from 'react';

//
// Trix Data
//
import {
    type Error
} from '../../../trix-web-data-commons';

//
// Material UI
//
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import { makeStyles } from '@mui/styles';

//
// Components
//
import {
    withComponentsRegistry,
} from '../../../trix-web-components-registry';

import {
    TextFormPaneField,
    I8nTextFormPaneField,
    CheckboxFormPaneField,
    RadioGroupFormPaneField,
    QuantityFormPaneField,
    QuantityWithOpenUomFormPaneField,
    MoneyFormPaneField,
    DateFormPaneField,
    DateRangeFormPaneField
} from '../../../trix-web-components-pane';
import {
    PropertiesSelectorFormPaneField
} from '../../../trix-web-components-property';

import HalFormsCheckboxGroupFormPaneField from './field/HalFormsCheckboxGroupFormPaneField';
import HalFormsRadioGroupFormPaneField from './field/HalFormsRadioGroupFormPaneField';
import HalFormsPropertiesSelectorFormPaneField from './field/HalFormsPropertiesSelectorFormPaneField';
import HalFormsAddressFormPaneField from './field/HalFormsAddressFormPaneField';
import HalFormsShippingAddressFormPaneField from './field/HalFormsShippingAddressFormPaneField';

import {
    evaluateCondition,
} from '../../tools/halFormsCondition';

//
// Functions
//
const createFields = (props: {
    getComponent: (type: string) => any,
    getHalFormsCustomizer: (customizer: string, halFormsTemplate: any, target: string) => any,

    classes: any,
    form: any,
    components: any[],
    ducks: {
        selectors: any,
        actions: any,
    },
    container: any,
    context: any,
    data: any,
    error: any,

    onChange: (data: any, meta: any) => void,
}): any => {
    // wrap up components into sections
    const components = props.components || [];
    let sections = [];
    for (let i = 0; i < components.length; i++) {
        if (components[i].fieldType === 'section') {
            sections.push(components[i]);
            continue;
        }
        if (sections.length === 0) {
            sections.push({
                fieldType: 'section',
                components: [],
            })
        }
        sections[sections.length - 1].components.push(components[i]);
    }

    const conditionContext = {
        container: props.container,
        data: props.data
    };

    return (
        <Grid container direction='column' className={props.classes.root}>
            {
                sections.map((s, si, array) => {
                    if (s.visibleIf) {
                        const isVisible = evaluateCondition(s.visibleIf, conditionContext);
                        if (!isVisible) {
                            return null;
                        }
                    }

                    return (
                        <React.Fragment key={`${si}-fragment`}>
                            {
                                (si > 0) ? (
                                    <Grid
                                        key={`${si}-divider`} 
                                        item
                                    >
                                        <Divider/>
                                    </Grid>
                                ) : null
                            }
                            <Grid
                                key={si}
                                item 
                                container 
                                spacing={1}
                                className={props.classes.fieldsBlock}
                                direction='column'
                            >
                                {
                                    (s.components || []).map((c, ci) => {
                                        if (c.visibleIf) {
                                            const isVisible = evaluateCondition(c.visibleIf, conditionContext);
                                            if (!isVisible) {
                                                return null;
                                            }
                                        }

                                        if (c.type === 'field') {
                                            if (c.fieldType === 'section') {
                                                return (
                                                    createFields ({
                                                        getComponent: props.getComponent,
                                                        getHalFormsCustomizer: props.getHalFormsCustomizer,
                                                        classes: props.classes,
                                                        form: props.form,
                                                        components: c.components,
                                                        ducks: props.ducks,
                                                        data: props.data,
                                                        container: props.container,
                                                        context: props.context,
                                                        error: props.error,
                                                        onChange: props.onChange
                                                    })
                                                );
                                            }

                                            if (c.fieldType === 'text') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.String)) (c.name.split('.')) (props.data));
                                                if (!value) {
                                                    value = '';
                                                }

                                                return (
                                                    <Grid key={ci} item>
                                                        <TextFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'textarea') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.String)) (c.name.split('.')) (props.data));
                                                if (!value) {
                                                    value = '';
                                                }

                                                return (
                                                    <Grid key={ci} item>
                                                        <TextFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            multiline={true}
                                                            
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'number') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                                if (!value) {
                                                    value = 0;
                                                }

                                                return (
                                                    <Grid key={ci} item>
                                                        <TextFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'i8n_text') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <I8nTextFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}
                                                            
                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'checkbox') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Boolean)) (c.name.split('.')) (props.data));
                                                if (!value) {
                                                    value = false;
                                                }
                                                return (
                                                    <Grid key={ci} item>
                                                        <CheckboxFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'quantity') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <QuantityFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'quantityWithOpenUom') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <QuantityWithOpenUomFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'weight') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <QuantityFormPaneField
                                                            dimension='WEIGHT'
                                                            
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'money') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <MoneyFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'radio') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <HalFormsRadioGroupFormPaneField
                                                            __halFormsTemplate={c}
                                                            __ducks={props.ducks}
                                                            container={props.container}

                                                            data={props.data}

                                                            getComponent={props.getComponent}
                                                            getHalFormsCustomizer={props.getHalFormsCustomizer}

                                                            autoFocus={(si === 0 && ci === 0)}
                                                            label={c.title}
                                                            description={c.description}

                                                            value={value}
                                                            error={props.error}

                                                            onChange={props.onChange}

                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'checkboxGroup') {
                                                // const options = (c.options && c.options.inline) ? c.options.inline : [];
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <HalFormsCheckboxGroupFormPaneField
                                                            __halFormsTemplate={c}
                                                            __ducks={props.ducks}
                                                            container={props.container}

                                                            data={props.data}

                                                            getComponent={props.getComponent}
                                                            getHalFormsCustomizer={props.getHalFormsCustomizer}

                                                            autoFocus={(si === 0 && ci === 0)}
                                                            label={c.title}
                                                            description={c.description}

                                                            value={value}
                                                            error={props.error}

                                                            onChange={props.onChange}

                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'date') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <DateFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'dateRange') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <DateRangeFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'propertySelector') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <PropertiesSelectorFormPaneField
                                                            autoFocus={(si === 0 && ci === 0)}
                                                            disabled={false}
                                                            label={c.title}
                                                            description={c.description}

                                                            onChange={props.onChange}

                                                            value={value}
                                                            error={props.error}
                                                            
                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'address') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <HalFormsAddressFormPaneField
                                                            __halFormsTemplate={c}
                                                            container={props.container}

                                                            data={props.data}

                                                            name={c.name}
                                                            label={c.title}
                                                            
                                                            value={value}
                                                            error={props.error}

                                                            onChange={props.onChange}

                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            // TODO: export as configurable pluged-in field
                                            if (c.fieldType === 'shipping_address') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Object)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <HalFormsShippingAddressFormPaneField
                                                            __halFormsTemplate={c}
                                                            container={props.container}

                                                            data={props.data}

                                                            name={c.name}
                                                            label={c.title}
                                                            
                                                            value={value}
                                                            error={props.error}

                                                            onChange={props.onChange}

                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            if (c.fieldType === 'selector') {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <HalFormsPropertiesSelectorFormPaneField
                                                            __halFormsTemplate={c}
                                                            __ducks={props.ducks}
                                                            container={props.container}

                                                            data={props.data}

                                                            getComponent={props.getComponent}
                                                            getHalFormsCustomizer={props.getHalFormsCustomizer}

                                                            autoFocus={(si === 0 && ci === 0)}
                                                            label={c.title}
                                                            description={c.description}

                                                            value={value}
                                                            error={props.error}

                                                            onChange={props.onChange}

                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }

                                            // try to fetch components from the registry
                                            const Component = props.getComponent(`formPaneField:${c.fieldType}`);
                                            // console.log(`Search for form pane field component by id: [formPaneField:${c.fieldType}]`);
                                            if (null != Component) {
                                                let value = S.maybeToNullable 
                                                    (S.gets (S.is ($.Any)) (c.name.split('.')) (props.data));
                                                return (
                                                    <Grid key={ci} item>
                                                        <Component
                                                            __form={props.form}
                                                            __halFormsTemplate={c}
                                                            __ducks={props.ducks}
                                                            container={props.container}
                                                            
                                                            // TODO: check if this is a duplication
                                                            container={props.container}

                                                            name={c.name}
                                                            label={c.title}
                                                            description={c.description}

                                                            disabled={!!c.readOnly}

                                                            data={props.data}
                                                            error={props.error}

                                                            value={value}
                                                            onChange={props.onChange}

                                                            dataPath={c.name}
                                                        />
                                                    </Grid>
                                                );
                                            }
                                        }

                                        return null;
                                    })
                                }
                            </Grid>
                        </React.Fragment>
                    )
                })
            }
        </Grid>
    );
};

//
// Styles
//
const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: theme.spacing(3),
    },
}));

//
// Props
//
type Props = {
    //
    // Hal forms template
    //
    __halFormsTemplate: any,

    //
    // Ducks
    //
    __ducks: {
        selectors: any,
        actions: any,
    },

    //
    // Context
    //
    __context: any,

    //
    // Components registry
    //
    __componentsRegistry: any,

    //
    // Data
    //
    data: any,
    container: any,
    error?: Error,

    //
    // Handlers
    //
    onChange: (value: any, meta: any) => void,
} // End of Props

//
// Component
//
const HalFormsEditableFormContent = (props: Props) => {
    //
    // Hooks
    //
    const classes = useStyles();
    // End hooks

    // check if there is a custom form provided
    let Form = props.__componentsRegistry.getHalFormsCustomizer('form', props.__halFormsTemplate)
    if (Form) {
        return(
            <Form
                __halFormsTemplate={props.__halFormsTemplate}
                __ducks={props.__ducks}
                __context={props.__context}

                data={props.data}
                container={props.container}
                error={props.error}

                onChange={props.onChange}
            />
        );
    }

    return createFields ({
        getComponent: props.__componentsRegistry.getComponent,
        getHalFormsCustomizer: props.__componentsRegistry.getHalFormsCustomizer,
        form: props.__halFormsTemplate,
        components: props.__halFormsTemplate.components,
        ducks: props.__ducks,
        container: props.container,
        context: props.__context,

        classes,
        data: props.data,
        error: props.error,
        
        onChange: props.onChange,
    });
}
export default withComponentsRegistry(HalFormsEditableFormContent);